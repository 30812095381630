import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import axios from "axios";
import Loader from "components/loader/Loader";
import { OrderElement } from "model/orderElement/OrderElement";
import { useEffect, useState } from "react";

export interface Props {
	isOpen: boolean;
	orderElement: OrderElement;
	title: string;
	done(): void;
}
const ContentModal = (props: Props) => {
	const [HTMLContent, setHTMLContent] = useState("");
	const [isBusy, setIsBusy] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios.get<string>(props.orderElement.fileUrl ?? "");
			setHTMLContent(response.data);
			console.log("response");
		};

		if (props.isOpen && !isBusy) {
			try {
				setIsBusy(true);
				fetchData().catch((error) => console.log(error));
			} catch (error) {
				console.log(error);
			} finally {
				setIsBusy(false);
			}
		}
	}, [props.isOpen]);

	return (
		<IonModal isOpen={props.isOpen}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{props.title}</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={() => props.done()} strong>
							Done
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<Loader isVisible={isBusy} />

				{props.orderElement?.fileUrl ? (
					<div
						dangerouslySetInnerHTML={{
							__html: HTMLContent,
						}}
					></div>
				) : (
					<p>Keine Vorschau verfügbar</p>
				)}
			</IonContent>
		</IonModal>
	);
};
export default ContentModal;
