import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { Honor } from "model/Honor";
import { Tenant } from "model/Tenant";

class HonorService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<Honor>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/honor`, {
			params,
			headers: { userId: userId },
		});
		return res.data;
	}
}
const honorService = new HonorService();
export default honorService;
