import { IonChip, IonItem, IonLabel, IonNote } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import CreatorChip from "components/creator/CreatorChip";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { pencilOutline } from "ionicons/icons";
import { Mail, mailString } from "model/Mail";
import { useState, VFC } from "react";

import EditModal from "./EditModal";
import DetailModalMail from "./DetailModalMail";

export interface Props {
	mail: Mail;
}
const MailTeaser: VFC<Props> = ({ mail }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		<>
			<IonItem
				onClick={() => setIsEditModalOpen(true)}
				button
				detail={true}
				detailIcon={pencilOutline}
				key={`${mail.id}_mail_teaser_list_item`}
				id={mail.id}
				disabled={mail.isBusy}
			>
				<DetailModalMail isOpen={isEditModalOpen} detailItem={mail} onCancel={() => setIsEditModalOpen(false)} />
				<div className="ion-padding">
					<IonLabel className="ion-margin-bottom">{mail.mailAddress}</IonLabel>
					<IonNote className="ion-margin-bottom">
						<Subtitle text={i18n._(t`common.CreatedBy`)} />
					</IonNote>
					<CreatorChip creator={mail.creator} />
					<IonNote className="ion-margin-bottom">
						<Subtitle text={i18n._(t`common.SharedTo`)} />
					</IonNote>

					{mail.scopedSharingTenants?.length ?? 0 > 1 ? (
						mail.scopedSharingTenants?.map((tenant, i) => {
							return <ShareTenantChip scopedSharingTenant={tenant} />;
						})
					) : (
						<IonChip outline={false}>
							<IonLabel>Keine Freigaben</IonLabel>
						</IonChip>
					)}
				</div>
			</IonItem>
		</>
	);
};

export default MailTeaser;
