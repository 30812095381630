import { RefresherEventDetail } from "@ionic/core";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { FC, useState, VFC } from "react";
import { useSelector } from "react-redux";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import Teaser from "./Teaser";
import { LogElement } from "model/LogElement";
import { getAllLogElements, getIsFetching } from "store/log/Log.selectors";
import { OrderByType } from "model/OrderByType";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}
const LogList: FC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getAllLogElements);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());

	const renderItem = (item: LogElement) => {
		return <Teaser logElement={item} />;
	};
	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery({ orderby: "CreatedAt", orderbytype: OrderByType.descending }));
		}
		event.detail.complete();
	};

	return <InfiniteBaseList items={list} onInfinite={onInfinite} onRefresh={reloadEntries} renderItem={renderItem} isFetching={false} />;
};
export default LogList;
