import { BaseListQueryInstance } from "model/BaseListQuery";
import { Tenant } from "model/Tenant";

export interface BaseListTenantQuery {
	query: BaseListQueryInstance;
	tenant?: Tenant;
}

export function newBaseListTenantQuery(query: BaseListQueryInstance, tenant?: Tenant): BaseListTenantQuery {
	return {
		query: query,
		tenant: tenant,
	};
}
