import { IonCol, IonGrid, IonProgressBar, IonRow } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import { CustomProgressBar } from "components/infinite/CustomProgressBar";
import Page from "components/page/Page";
import MyClothing from "components/profile/MyConfectionInformations";
import MyHonors from "components/profile/MyHonors";
import MyLicences from "components/profile/MyLicences";
import MyMedicalInfo from "components/profile/MyMedicalInformations";
import MyQualifications from "components/profile/MyQualifications";
import MyTeams from "components/profile/MyTeams";
import ProfileCard from "components/profile/ProfileCard/ProfileCard";
import { newBaseListQuery } from "model/BaseListQuery";
import { FC, useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { reloadHonors } from "store/honor/Honor.thunks";
import { reloadLicenses } from "store/license/License.thunks";
import { reloadAddresses } from "store/profile/address/Address.thunks";
import { reloadBankAccounts } from "store/profile/bankAccount/BankAccount.thunks";
import { reloadConfectionInformations } from "store/profile/confectionInformation/ConfectionInformation.thunks";
import { reloadMails } from "store/profile/mail/Mail.thunks";
import { reloadMedicalInformation } from "store/profile/medicalInformation/MedicalInformation.thunks";
import { reloadPhones } from "store/profile/phone/Phone.thunks";
import { getIsFetching, getSelectedTenant } from "store/profile/Profile.selectors";
import { reloadQualifications } from "store/qualification/Qualification.thunks";
import { reloadTeams } from "store/team/Team.thunks";

const ProfilePage: FC = () => {
	const isFetching = useSelector(getIsFetching);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch<any>(reloadAddresses(newBaseListQuery()));
		dispatch<any>(reloadBankAccounts(newBaseListQuery()));
		dispatch<any>(reloadMails(newBaseListQuery()));
		dispatch<any>(reloadPhones(newBaseListQuery()));
		dispatch<any>(reloadTeams(newBaseListQuery()));
		dispatch<any>(reloadQualifications(newBaseListQuery()));
		dispatch<any>(reloadLicenses(newBaseListQuery()));
		dispatch<any>(reloadHonors(newBaseListQuery()));
		dispatch<any>(reloadConfectionInformations(newBaseListQuery()));
		dispatch<any>(reloadMedicalInformation(newBaseListQuery()));
	}, []);
	return (
		<Page title={i18n._(t`pages.myProfile`)}>
			<CustomProgressBar isFetching={isFetching} />
			<IonGrid>
				<IonRow>
					<IonCol pushXs="0" pushSm="3" pushMd="2" pushLg="0" pushXl="0" size="6" sizeMd="8" sizeLg="6" sizeSm="6" sizeXs="12" sizeXl="4">
						<ProfileCard />
					</IonCol>
					<IonCol size="8" sizeLg="6" sizeXl="8" sizeXs="12">
						<IonGrid>
							<IonRow>
								<IonCol size="6" sizeLg="12" sizeXl="6" sizeXs="12">
									<MyTeams />
									<MyLicences />
									<MyQualifications />
								</IonCol>
								<IonCol size="6" sizeLg="12" sizeXl="6" sizeXs="12">
									<MyHonors />
									<MyClothing />

									<MyMedicalInfo />
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonCol>
				</IonRow>
			</IonGrid>
		</Page>
	);
};
export default ProfilePage;
