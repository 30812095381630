import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "model/Profile";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { emptyState } from "store/State";

import { deleteIdToken, fetchChatUser, fetchProfile, updateIdToken, updateProfile, updateProfileAvatar, updateProfileTenantAvatar } from "./Profile.thunks";
import { TalkJsUser } from "model/chat/TalkJsUser";
import { IdTokenClaims } from "model/IdTokenClaims";

export const initialState = {
	...emptyState<Profile>(),
	scope: ScopeType.default,
	idTokenClaim: null as unknown as IdTokenClaims,
	chatUser: null as unknown as TalkJsUser,
	selectedTenant: null as unknown as Tenant,
	selectedTenantId: null as unknown as string,
};

export type ProfileState = typeof initialState;

const slice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		profileUpdated(state, action: PayloadAction<Profile>) {
			//state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		},
		profileUpdating(state) {
			state.isFetching = true;
			state.isFetched = false;
		},
		profileRejected(state, action) {
			state.isFetching = false;
			state.isFetched = false;
			state.error = action.payload;
		},
		profileClear() {
			return initialState;
		},
		profileScopeChanged(state, action) {
			state.scope = action.payload;
		},
		selectTenant(state, action: PayloadAction<Tenant>) {
			state.selectedTenant = action.payload;
		},

		selectTenantById(state, action: PayloadAction<string>) {
			state.selectedTenantId = action.payload;

			const res = state.value?.tenants?.find((x) => x.id == action.payload);
			if (!res) {
				return;
			}
			state.selectedTenant = res;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(fetchProfile.fulfilled, updateProfile.fulfilled), (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
			if (state.selectedTenantId) {
				const res = state.value?.tenants?.find((x) => x.id == state.selectedTenantId);
				if (!res) {
					return;
				}
				state.selectedTenant = res;
			}
		}),
			builder.addMatcher(isAnyOf(deleteIdToken.fulfilled), (state, action) => {
				state.idTokenClaim = null as unknown as IdTokenClaims;
			}),
			builder.addMatcher(isAnyOf(fetchChatUser.fulfilled), (state, action) => {
				state.chatUser = action.payload;
				state.isFetching = false;
				state.isFetched = false;
			}),
			builder.addMatcher(isAnyOf(fetchChatUser.rejected), (state, action) => {
				state.isFetching = false;
				state.isFetched = false;
			}),
			builder.addMatcher(isAnyOf(updateIdToken.fulfilled), (state, action) => {
				console.log("updateIdToken.fulfilled", action.payload);
				state.idTokenClaim = action.payload;
			}),
			builder.addMatcher(isAnyOf(updateProfileAvatar.fulfilled), (state, action) => {
				switch (action.meta.arg.scopeType) {
					case ScopeType.default:
						state.value.avatarImageUrl = action.payload.original?.url;
						break;
					case ScopeType.extern:
						state.value.externAvatarImageUrl = action.payload.original?.url;
						break;
					case ScopeType.intern:
						state.value.internAvatarImageUrl = action.payload.original?.url;
						break;
				}
				state.isFetching = false;
				state.isFetched = false;
			}),
			builder.addMatcher(isAnyOf(updateProfileTenantAvatar.fulfilled), (state, action) => {
				const res = state.value?.tenants?.find((x) => x.id == action.meta.arg.tenantId);

				if (!res) {
					state.isFetching = false;
					state.isFetched = false;
					return;
				}
				const indexOfRes = state.value?.tenants?.indexOf(res);

				switch (action.meta.arg.scopeType) {
					case ScopeType.default:
						res.personAvatarImageUrl = action.payload.original?.url;
						break;
					case ScopeType.extern:
						res.personExternAvatarImageUrl = action.payload.original?.url;
						break;
					case ScopeType.intern:
						res.personInternAvatarImageUrl = action.payload.original?.url;
						break;
				}
				state.value.tenants[indexOfRes] = res;
				state.isFetching = false;
				state.isFetched = false;
			}),
			builder.addMatcher(
				isAnyOf(
					fetchProfile.pending,
					updateProfile.pending,
					updateProfileAvatar.pending,
					updateProfileTenantAvatar.pending,
					fetchChatUser.pending,
				),
				(state, action) => {
					state.isFetching = true;
					state.isFetched = false;
				},
			),
			builder.addMatcher(
				isAnyOf(fetchProfile.rejected, updateProfile.rejected, updateProfileAvatar.rejected, updateProfileTenantAvatar.rejected),
				(state, action) => {
					state.isFetching = false;
					state.isFetched = false;
					state.error = action.error.message;
				},
			);
	},
});
export const { profileUpdated, profileUpdating, profileClear, profileRejected, profileScopeChanged, selectTenant, selectTenantById } = slice.actions;
export default slice.reducer;
