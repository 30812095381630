import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import Page from "components/page/Page";
import { FC } from "react";

interface Props {
	title: string;
	children: any;
	pageColor?: string | undefined;
	onViewEnter?: () => void;
	onViewLeave?: () => void;
	onViewEntering?: () => void;
	onViewLeaving?: () => void;
}
const ProfilePageLayout: FC<Props> = ({ title, children, pageColor, onViewEnter, onViewLeave, onViewLeaving, onViewEntering }) => {
	return (
		<Page
			title={title}
			backLink="/profile"
			backTitle={i18n._(t`My profile`)}
			pageColor={pageColor}
			onViewEnter={onViewEnter}
			onViewLeave={onViewLeave}
			onViewLeaving={onViewLeaving}
			onViewEntering={onViewEntering}
			backButton={false}
		>
			{children}
		</Page>
	);
};
export default ProfilePageLayout;
