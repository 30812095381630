import { IonAvatar, IonButton, IonCard, IonCol, IonFab, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonNote, IonRow, IonTitle } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { FC } from "react";
import { Tenant } from "model/Tenant";
import { checkImageAvatarUrl, checkTenantImageAvatarUrl } from "helper/CheckImageUrlHelper";
import PhotoUploader from "components/PhotoUploader/PhotoUploader";
import "./AvatarTenantScope.scss";
import AvatarTenantScope from "./AvatarTenantScope";
import { ScopeType } from "model/ScopeType";
import { peopleCircleSharp } from "ionicons/icons";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/Profile.selectors";
export interface Props {
	scopedSharingTenant: Tenant;
}
const TenantAvatarTeaser: FC<Props> = ({ scopedSharingTenant }) => {
	const isFetching = useSelector(getIsFetching);
	return (
		<>
			<IonItem
				lines="inset"
				className=" ion-margin-bottom ion-margin-right"
				button={false}
				disabled={isFetching}
				key={scopedSharingTenant.id}
				id={scopedSharingTenant.id}
			>
				<IonGrid>
					<IonRow className="ion-margin-top ion-margin-bottom">
						<IonCol size="12" sizeLg="12" sizeMd="12" sizeXl="12" sizeXs="12">
							<IonItem lines="none">
								{scopedSharingTenant.avatarImageUrl == undefined ||
								scopedSharingTenant.avatarImageUrl == "" ||
								scopedSharingTenant.avatarImageUrl == null ? (
									<>
										<IonIcon size="large" src={peopleCircleSharp}></IonIcon>
									</>
								) : (
									<IonAvatar>
										<IonImg src={checkTenantImageAvatarUrl(scopedSharingTenant.avatarImageUrl)} />
									</IonAvatar>
								)}
								<IonTitle>{scopedSharingTenant.name}</IonTitle>
							</IonItem>
						</IonCol>
						<IonCol size="4">
							<AvatarTenantScope
								imageUrl={scopedSharingTenant.personAvatarImageUrl}
								scopeType={ScopeType.default}
								tenantId={scopedSharingTenant.id}
							/>
						</IonCol>
						<IonCol size="4">
							<AvatarTenantScope
								imageUrl={scopedSharingTenant.personInternAvatarImageUrl}
								scopeType={ScopeType.intern}
								tenantId={scopedSharingTenant.id}
							/>
						</IonCol>
						<IonCol size="4">
							<AvatarTenantScope
								imageUrl={scopedSharingTenant.personExternAvatarImageUrl}
								scopeType={ScopeType.extern}
								tenantId={scopedSharingTenant.id}
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonItem>
		</>
	);
};

export default TenantAvatarTeaser;
