import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import EventDetailSkeleton from "components/event/eventDetail/EventDetailSkeleton";
import EventDetail from "components/eventDetail/eventDetail";
import Page from "components/page/Page";
import { Event } from "model/Event";
import { FC, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEvent } from "store/event/Event.selectors";
import { fetchEventById, fetchEventParticipant, fetchEventRegistrationById, getContactPersons, getEventDocuments } from "store/event/Event.thunks";
import { getProfile } from "store/profile/Profile.selectors";

interface MatchParams {
	id: string;
}

const EventDetailPage: FC = () => {
	console.debug("render EventDetailPage");
	const params = useParams<MatchParams>();
	const [event, participationInfo] = useGetDetail(params.id);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (event?.tenantId) {
			dispatch<any>(getEventDocuments([event.tenantId, params.id]));
		}
	}, [event]);

	return (
		<Page title={event?.name ?? ""} backLink="/events" backTitle={i18n._(t`Events`)} tabBarHidden={true} backButton={true}>
			{!event || event == undefined ? <EventDetailSkeleton /> : <EventDetail event={event} />}
		</Page>
	);
};

export default EventDetailPage;

const useGetDetail = (id: string): [Event | undefined, string] => {
	const event = useSelector(getEvent(id));
	const dispatch = useAppDispatch();
	const userId = useSelector(getProfile)?.id;

	useEffect(() => {
		console.log("useEffect getDetail", event, userId);
		if (!event) {
			dispatch<any>(fetchEventById(id));

			/*
	 		Promise.all([eventService.get(id), eventService.getParticipationIfo(id)]).then(([event, participationInfo]) => {
	 			setItem(event);
	 			setParticipationInfo(participationInfo);
	 		});
	 		*/
			return;
		}

		if (event?.creator && event.documents == null) {
			dispatch<any>(getEventDocuments([event?.creator?.id, id]));
		}
		if (event.creator?.id && event.contactPersons == null) {
			dispatch<any>(getContactPersons([event.creator?.id, id]));
		}
		if (!event?.eventRegistration && userId) {
			dispatch<any>(fetchEventRegistrationById([id, userId]));
		}
	}, [event?.tenantId, id, userId]);

	return [event, userId];
};
