import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import Page from "components/page/Page";
import ProfileValidationModal from "components/profileValidation/ProfileValidationModal";
import List from "components/team/List";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newValidationModel } from "model/profileValidation/ValidationModel";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { getSelectedTenant } from "store/profile/Profile.selectors";
import { fetchValidationModels, reloadValidationModels } from "store/profileValidation/ProfileValidation.thunks";

export const ProfileValidationProcessPage: VFC = () => {
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch<any>(reloadValidationModels(newBaseListTenantQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	return <ProfileValidationModal validationModel={newValidationModel()} />;
};
