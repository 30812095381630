import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { newBaseList } from "model/BaseList";
import { Invoice } from "model/Invoice";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";

import { fetchInvoices, reloadInvoices } from "./Invoice.thunks";

export const initialState = {
	...defaultState(newBaseList<Invoice>()),
	selectedTenant: null as unknown as Tenant,
};

export type InvoiceState = typeof initialState;

const slice = createSlice({
	name: "invoice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(fetchInvoices.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadInvoices.fulfilled), (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadInvoices.pending, fetchInvoices.pending), (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addMatcher(isAnyOf(reloadInvoices.rejected, fetchInvoices.rejected), (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
		});
	},
});
export default slice.reducer;
