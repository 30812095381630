import {
	IonProgressBar,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonImg,
	IonItem,
	IonCardHeader,
	IonCardSubtitle,
	IonChip,
	IonSkeletonText,
	IonCardTitle,
	IonLabel,
	IonButton,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { FC } from "react";

const EventDetailSkeleton: FC = () => {
	return (
		<>
			<IonProgressBar hidden={false} type="indeterminate"></IonProgressBar>

			<IonGrid>
				<IonRow>
					<IonCol id="headerCol" sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="10" sizeXl="5" size="5" pushMd="1" pushLg="1" pushXl="0">
						<IonCard disabled={true}>
							<IonImg src="https://public.cdn.dso-verein.de/img/stock/event/empty_event.jpeg" />
							<IonItem lines="full">
								<IonCardHeader style={{ width: "100%" }}>
									<IonCardSubtitle style={{ width: "100%" }}>
										<IonChip style={{ width: "25%" }}>
											<IonSkeletonText animated style={{ width: "100%" }} />
										</IonChip>
									</IonCardSubtitle>
									<IonCardTitle style={{ width: "100%" }}>
										<IonSkeletonText animated style={{ width: "60%" }} />
									</IonCardTitle>
								</IonCardHeader>
							</IonItem>
							<IonGrid>
								<IonRow>
									<IonCol id="infoCol">
										<IonItem lines="full">
											<IonLabel color="normal" slot="start">
												<h2>
													<Trans>event.date</Trans>
												</h2>
											</IonLabel>
											<IonSkeletonText slot="end" animated style={{ width: "40%" }} />
										</IonItem>
										<IonItem lines="full">
											<IonLabel color="normal" slot="start">
												<h2>
													<Trans>event.end</Trans>
												</h2>
											</IonLabel>
											<IonSkeletonText slot="end" animated style={{ width: "40%" }} />
										</IonItem>
										<IonItem lines="full">
											<IonLabel color="normal" slot="start">
												<h2>
													<Trans>event.deadline</Trans>
												</h2>
											</IonLabel>
											<IonSkeletonText slot="end" animated style={{ width: "40%" }} />
										</IonItem>
										<IonItem lines="none">
											<IonLabel color="normal" slot="start">
												<h2>
													<Trans>event.fee</Trans>
												</h2>
											</IonLabel>
											<IonSkeletonText slot="end" animated style={{ width: "40%" }} />
										</IonItem>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonCard>
						<IonButton
							size="default"
							color="primary"
							title={i18n._("event.takePart")}
							expand="full"
							className="btn-take-part"
							disabled={true}
						>
							<Trans>event.takePart</Trans>
						</IonButton>
					</IonCol>
					<IonCol id="descriptionCol" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="7">
						<IonCard>
							<IonItem lines="full">
								<IonCardHeader style={{ width: "100%" }}>
									<IonCardSubtitle>Beschreibung</IonCardSubtitle>
									<h4>
										<IonSkeletonText style={{ width: "30%" }} />
									</h4>
								</IonCardHeader>
							</IonItem>

							<IonItem color="light">
								<IonCardTitle style={{ width: "100%" }}>
									<h4>
										<IonSkeletonText style={{ width: "100%" }} />
									</h4>
									<h4>
										<IonSkeletonText style={{ width: "100%" }} />
									</h4>
									<h4>
										<IonSkeletonText style={{ width: "100%" }} />
									</h4>
									<h4>
										<IonSkeletonText style={{ width: "100%" }} />
									</h4>
								</IonCardTitle>
							</IonItem>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
};
export default EventDetailSkeleton;
