import { i18n } from "@lingui/core";
import { de, en } from "make-plural/plurals";

i18n.loadLocaleData({
	en: { plurals: en },
	de: { plurals: de },
});

export const defaultLocale = "de";

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
	const { messages } = await import(`./locales/${locale}/messages`);
	i18n.load(locale, messages);
	i18n.activate(locale);
}
