import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { ContactPerson } from 'model/ContactPerson';
import { DocumentElement } from 'model/DocumentElement';
import { Team } from "model/Team";
import { Tenant } from "model/Tenant";

class TeamService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<Team>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/team`, {
			params,
			headers: { userId: userId },
		});
		return res.data;
	}
	async getDocuments(id: string, tenantId: string): Promise<BaseList<DocumentElement>> {
		const res = await axios.get<BaseList<DocumentElement>>(
			`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantId}/team/${id}/documentupload`,
		);
		return res.data;
	}
	async getContactPersons(id: string, tenantId: string): Promise<BaseList<ContactPerson>> {
		const res = await axios.get<BaseList<ContactPerson>>(
			`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantId}/team/${id}/contactperson`,
		);
		return res.data;
	}
	async getById(id: string): Promise<Team> {
		const res = await axios.get<Team>(`${process.env.REACT_APP_API_BASE_URL}/api/team/${id}`);
		return res.data;
	}
}
const teamService = new TeamService();
export default teamService;
