import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { MedicalInformation } from "model/MedicalInformation";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";

import {
	addMedicalInformation,
	deleteMedicalInformation,
	fetchMedicalInformation,
	reloadMedicalInformation,
	shareMedicalInformation,
	updateMedicalInformation,
} from "./MedicalInformation.thunks";

export const initialState = {
	...defaultState<BaseList<MedicalInformation>>(newBaseList<MedicalInformation>()),
	scope: ScopeType.extern,
	selectedTenant: null as unknown as Tenant,
	toShare: null as unknown as MedicalInformation | undefined,
};

export type MedicalInformationtate = typeof initialState;

const slice = createSlice({
	name: "medicalinformation",
	initialState,
	reducers: {
		cancelShareMedicalInformation(state: any): void {
			state.toShare = undefined;
			state.isFetching = false;
			state.isFetched = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(addMedicalInformation.fulfilled, (state, action) => {
			state.value.items.push(action.payload);
			action.payload.isBusy = false;

			state.isFetching = false;
			state.isFetched = true;
			state.toShare = action.payload;
		});
		builder.addCase(deleteMedicalInformation.fulfilled, (state, action) => {
			state.value.items = state.value.items.filter((x) => x.id != action.payload);
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addCase(reloadMedicalInformation.pending, (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addCase(reloadMedicalInformation.fulfilled, (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(updateMedicalInformation.fulfilled, shareMedicalInformation.fulfilled), (state, action) => {
			if (!action.payload) {
				return;
			}
			const oldItem = state.value.items.find((x) => x.id === action.payload.id)!;
			const index = state.value.items.indexOf(oldItem);
			action.payload.isBusy = false;
			state.value.items[index] = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(reloadMedicalInformation.fulfilled), (state, action) => {
				state.value = action.payload;
				state.isFetching = false;
				state.isFetched = true;
			});
		builder.addMatcher(isAnyOf(fetchMedicalInformation.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(
				isAnyOf(
					updateMedicalInformation.pending,
					addMedicalInformation.pending,
					deleteMedicalInformation.pending,
					shareMedicalInformation.pending,
				),
				(state, action) => {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.isBusy = true;
						state.value.items[index] = oldItem;
					}
					state.isFetching = true;
					state.isFetched = false;
				},
			),
			builder.addMatcher(
				isAnyOf(updateMedicalInformation.rejected, deleteMedicalInformation.rejected, shareMedicalInformation.rejected),
				(state, action) => {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.isBusy = false;
						state.value.items[index] = oldItem;
					}
					state.isFetching = false;
					state.isFetched = false;
					state.error = action.error.message;
				},
			);
		builder.addMatcher(isAnyOf(fetchMedicalInformation.rejected, reloadMedicalInformation.rejected), (state, action) => {
			state.isFetching = false;
			state.isFetched = false;
			state.error = action.error.message;
		});
	},
});
export default slice.reducer;
