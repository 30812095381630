import { Tenant } from "./Tenant";

export interface ScopedSharingTenant {
	headerImageUrl: null;
	avatarImageUrl: string;
	name: string;
	id: string;
	isLocked: boolean;
	isIntern: boolean;
	isExtern: boolean;
	isDefault: boolean;
}
export function convertTenantToScopedSharingTenant(tenant: Tenant): ScopedSharingTenant {
	return {
		id: tenant.id,
		name: tenant.name,
		headerImageUrl: null,
		avatarImageUrl: tenant.avatarImageUrl || "",
		isLocked: false,
		isIntern: false,
		isExtern: false,
		isDefault: false,
	};
}
