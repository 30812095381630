import { BaseList, newBaseList } from "model/BaseList";
import { Event } from "model/Event";
import { createSelector } from "reselect";

import { RootState } from "..";

export const getAllEvents = (state: RootState): BaseList<Event> => state.event.value || newBaseList<Event>();
export const getIsFetching = (state: RootState): boolean => state.event.isFetching;

export const getLatestEvents = createSelector(getAllEvents, (events: BaseList<Event>) => events.items.slice(0, 5) || []);
export const getEvent = (id: string) => createSelector(getAllEvents, (list) => list.items.find((item) => item.id === id));
export const getContactPersonIsFetching = (state: RootState): boolean => state.event.contactPersonIsFetching;
