import { IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import PhotoUploader from "components/PhotoUploader/PhotoUploader";
import EditModal from "components/profile/EditModal";
import { format, toDate } from "date-fns-tz";
import { callOutline, cardOutline, chevronForwardOutline, homeOutline, mailOutline, pencilOutline } from "ionicons/icons";
import { addressString } from "model/Address";
import { bankAccountString } from "model/BankAccount";
import { mailString } from "model/Mail";
import { phoneString } from "model/Phone";
import { Profile } from "model/Profile";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAddress } from "store/profile/address/Address.selectors";
import { getBankAccount } from "store/profile/bankAccount/BankAccount.selectors";
import { getMail } from "store/profile/mail/Mail.selectors";
import { getPhone } from "store/profile/phone/Phone.selectors";
import { getAvatarImageUrl, getIsFetching, getProfile } from "store/profile/Profile.selectors";
import { updateProfile } from "store/profile/Profile.thunks";
import { formatName } from "utils";

import css from "./ProfileCard.module.scss";

const ProfileCard: VFC = () => {
	const profile = useSelector(getProfile);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const dispatch = useAppDispatch();
	const isFetching = useSelector(getIsFetching);

	const address = useSelector(getAddress);
	const phone = useSelector(getPhone);
	const mail = useSelector(getMail);
	const bankAccount = useSelector(getBankAccount);
	const avatarImageUrl = useSelector(getAvatarImageUrl);

	function onEditModalDone(editProfile: Profile) {
		dispatch<any>(updateProfile(editProfile));
		setIsEditModalOpen(false);
	}
	return (
		<div className={css["container"]} data-key="profile-card">
			<EditModal isOpen={isEditModalOpen} profile={profile} onCancel={() => setIsEditModalOpen(false)} onDone={onEditModalDone} />

			<IonCard>
				<IonCardTitle>
					<IonGrid>
						<IonRow>
							<IonCol size="2"></IonCol>

							<IonCol size="8">
								<PhotoUploader
									imageUrl={avatarImageUrl}
									styles={{ avatar: { avatar: css["avatar"], avatarDragActive: css["drag-active"] } }}
								/>
							</IonCol>

							<IonCol size="2"></IonCol>
						</IonRow>
					</IonGrid>
				</IonCardTitle>
				<IonCardContent>
					<IonItem disabled={isFetching} button detail detailIcon={pencilOutline} onClick={(e) => setIsEditModalOpen(true)}>
						<IonGrid>
							<IonRow>
								<IonCol size="2"></IonCol>

								<IonCol size="8">
									<p className={css["name"]}>{formatName(profile)}</p>
								</IonCol>

								<IonCol size="2"></IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="9.5">
									<IonLabel>Geboren am:</IonLabel>
									{profile?.birthdate != null ? (
										<>
											<p className={css["profileItem"]}>
												&nbsp;
												{format(toDate(profile.birthdate, { timeZone: "Europe/Berlin" }), "dd.MM.yyyy", {
													timeZone: "Europe/Paris",
												})}
											</p>
										</>
									) : (
										<>
											<IonLabel slot="end">
												<IonSkeletonText></IonSkeletonText>
											</IonLabel>
										</>
									)}
								</IonCol>

								<IonCol size="0.5"></IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="9.5">
									<IonLabel>Geburtsort:</IonLabel>
									<p className={css["profileItem"]}>&nbsp;{profile?.birthplace}</p>
								</IonCol>

								<IonCol size="0.5"></IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
					<IonItem button detail detailIcon={chevronForwardOutline} routerLink={"/profile/phone"}>
						<IonGrid>
							<IonRow>
								<IonCol size="1.5">
									<IonIcon className={css["profileItemIcon"]} size="large" icon={callOutline} />
								</IonCol>

								<IonCol size="9.5">
									<p className={css["profileItem"]}>&nbsp;{phoneString(phone)}</p>
								</IonCol>

								<IonCol size="0.5"></IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
					<IonItem button detail detailIcon={chevronForwardOutline} routerLink={"/profile/mail"}>
						<IonGrid>
							<IonRow>
								<IonCol size="1.5">
									<IonIcon size="large" className={css["profileItemIcon"]} icon={mailOutline} />
								</IonCol>

								<IonCol size="9.5">
									<p className={css["profileItem"]}>&nbsp;{mailString(mail)}</p>
								</IonCol>

								<IonCol size="0.5"></IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
					<IonItem button detail detailIcon={chevronForwardOutline} routerLink={"/profile/address"}>
						<IonGrid>
							<IonRow>
								<IonCol size="1.5">
									<IonIcon size="large" className={css["profileItemIcon"]} icon={homeOutline} />
								</IonCol>

								<IonCol size="9.5">
									<p className={css["profileItem"]}>&nbsp; {addressString(address)}</p>
								</IonCol>
								<IonCol size="0.5"></IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
					<IonItem button detail detailIcon={chevronForwardOutline} routerLink={"/profile/bankaccount"}>
						<IonGrid>
							<IonRow>
								<IonCol size="1.5">
									<IonIcon size="large" icon={cardOutline} className={css["profileItemIcon"]} />
								</IonCol>

								<IonCol size="9.5">
									<p className={css["profileItem"]}> &nbsp;{bankAccountString(bankAccount)}</p>
								</IonCol>
								<IonCol size="0.5"></IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
				</IonCardContent>
			</IonCard>
		</div>
	);
};

export default ProfileCard;
