import { IonCard, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import Loader from "components/loader/Loader";
import { newBaseListQuery } from "model/BaseListQuery";
import { EventsFilter } from "model/events/EventsFilter";
import { newLoadEventsQuery } from "model/events/LoadEventsQuery";
import { OrderByType } from "model/OrderByType";
import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching, getLatestEvents } from "store/event/Event.selectors";
import { reloadEvents } from "store/event/Event.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

import css from "./LatestEvents.module.scss";
import SmallEventTeaser from "./SmallEventTeaser";

const LatestEventList: VFC = () => {
	const dispatch = useAppDispatch();
	const events = useSelector(getLatestEvents);
	const selectedTenant = useSelector(getSelectedTenant);
	const isFetching = useSelector(getIsFetching);

	useEffect(() => {
		dispatch<any>(
			reloadEvents(
				newLoadEventsQuery(
					newBaseListQuery({
						page: 1,
						pagesize: 10,
						orderby: "StartTime",
						orderbytype: OrderByType.ascending,
					}),
					undefined,
					selectedTenant,
					new Date()?.toUTCString(),
				),
			),
		);
	}, [selectedTenant]);

	return (
		<>
			<Loader isVisible={isFetching} />

			<IonCard>
				{events.map((event) => {
					return <SmallEventTeaser event={event} key={event.id} />;
				})}
				<IonItem routerLink="/events" color="amber-50" button>
					<IonLabel>
						<IonRouterLink routerLink={`/events`}>
							<Trans>latestEventsList.allMyEvents</Trans>
						</IonRouterLink>
					</IonLabel>
				</IonItem>
			</IonCard>
		</>
	);
};
export default LatestEventList;
