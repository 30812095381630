import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import BlogEntry from "model/BlogEntry";
import { Tenant } from "model/Tenant";

class BlogEntryService {
	async getList(query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<BlogEntry>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantIds", tenant.id);
		}

		query.toEntries().forEach(([key, value]) => {
			if (key === "orderby") {
				value = "CreatedAt";
			}
			if (key === "orderbytype") {
				value = "descending";
			}
			params.set(key, value as string);
		});

		const res = await axios.get(`${process.env.REACT_APP_API_SEARCH_URL}/api/blogentry`, { params });
		return res.data;
	}
}
const blogEntryService = new BlogEntryService();
export default blogEntryService;
