import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import MedicalInformationList from "components/medicalInformation/List";
import EditMedicalInformationModal from "components/medicalInformation/MedicalInformationModal";
import ProfileValidationFooter from "components/profileValidation/components/ProfileValidationFooter";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { add } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newMedicalInformation } from "model/MedicalInformation";
import { useContext, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/medicalInformation/MedicalInformation.selectors";
import { fetchMedicalInformation, reloadMedicalInformation } from "store/profile/medicalInformation/MedicalInformation.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

export const ProfileValidationProcessMedicalInformationsPage: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewItemModalOpen, setIsNewItemModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);
	const isLoading = useSelector(getIsFetching);
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);
	useEffect(() => {
		dispatch<any>(reloadMedicalInformation(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchMedicalInformation(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadMedicalInformation(newBaseListQuery(basListQuery)));
	};
	return (
		<IonPage>
			<IonContent>
				<ProfileValidationHeader title="Sonstige Angaben" progress={3} />

				<EditMedicalInformationModal
					isOpen={isNewItemModalOpen}
					editItem={newMedicalInformation()}
					onCancel={() => setIsNewItemModalOpen(false)}
				/>
				<MedicalInformationList onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={() => setIsNewItemModalOpen(true)}>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>
			</IonContent>
			<ProfileValidationFooter
				isLoading={isLoading}
				progress={3}
				routerLinkNext="/validation/profile/1/address"
				routerLinkBack="/validation/profile/1/confectioninformation"
			/>
		</IonPage>
	);
};
