import { OAuth2Client } from "@byteowls/capacitor-oauth2";
import { LoginCapacitorAzureB2cOptions } from "./options/LoginCapacitorAzureB2cOptions";
import { RefreshCapacitorAzureB2cOptions } from "./options/RefreshCapacitorAzureB2cOptions";
import { Preferences } from "@capacitor/preferences";
import { AuthorizationResult } from "./types/AuthorizationResult";
import { IdTokenClaims } from "model/IdTokenClaims";
import { PCKEOptionsHelper, PCKEOptionsHelperGetVerifierAsync, PCKEOptionsHelperRemoveVerifierAsync } from "./PCKEOptionsHelper";
import { TokenCodeChallengeResult } from "./types/TokenCodeChallengeResult";
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import { useAppDispatch } from 'app/hooks';
import { deleteIdToken } from 'store/profile/Profile.thunks';

export class CapacitorOAuth2Service {
	//storageTokenKey = `token`;
	storageTokenKey = `${process.env.REACT_APP_REDIRECT_URL_WEB}-token`;

	async getParsedIdTokenOrAuthorizeAsync(): Promise<IdTokenClaims | null> {
		const idTokenResult = await this.getParsedIdTokenAsync();
		if (idTokenResult != null) {
			return idTokenResult;
		} else {
			return await this.authorizeAsync();
		}
	}

	async getParsedIdTokenAsync(): Promise<IdTokenClaims | null> {
		const parsedToken = await this.getTokenAsync();
		if (parsedToken == undefined) {
			await this.clearTokenAsync(); //double check we cleared everything
			return null;
		}
		// check if token is till valid
		if (parsedToken.expires_in + parsedToken.createdAt < ((Date.now() / 1000) | 0)) {
			// check if refresh token is still valid
			if (parsedToken.refresh_token_expires_in + parsedToken.createdAt < ((Date.now() / 1000) | 0)) {
				await this.clearTokenAsync();
				return null;
			}
			// get new access token with refresh token
			const newAccessToken = await OAuth2Client.refreshToken(RefreshCapacitorAzureB2cOptions(parsedToken.refresh_token));
			if (newAccessToken == undefined) {
				await this.clearTokenAsync(); //double check we cleared everything
				return null;
			}
			return this.parseJwt(newAccessToken.id_token) as IdTokenClaims;
		}
		return this.parseJwt(parsedToken.id_token) as IdTokenClaims;
	}

	// Must return null , so that after the auth the redirect URL contains the token and that value shall be used
	async authorizeAsync() {
		try {
			// To use CODE we have to modify the Options to get the code_verifier and save it
			// Without this modification we are unable to get new access tokens
			// for every request to code_verifier is needed which is not accessable by the library
			const pckeModifiedOptions = await PCKEOptionsHelper(LoginCapacitorAzureB2cOptions);
			const response = (await OAuth2Client.authenticate(pckeModifiedOptions)) as AuthorizationResult;
			if (!response) {
				return null;
			}
			if (response.access_token_response == undefined) {
				return null;
			}

			this.setTokenAsync(response.access_token_response);
			return this.parseJwt(response.access_token_response?.id_token);
			/*
			if (response.code != undefined) {
				const challengeResult = await this.challengeTokenAsync(response.authorization_response.code);
				console.log("on authorizeAsync challengeResult", challengeResult);
				if (challengeResult == undefined) {
					console.log("on authorizeAsync challengeTokenResult is undefined");
					return null;
				}
				if (challengeResult.id_token == undefined) {
					console.log("on authorizeAsync challengeTokenResult.id_token is undefined");
					return null;
				}

				return this.parseJwt(challengeResult.id_token);
			}
*/
			console.log("on authorizeAsync response.id_token is undefined");
			return null;
		} catch (error) {
			console.debug("OAuth rejected", error);
		} finally {
			await Preferences.set({
				key: "auth_pending",
				value: "0",
			});
		}
		return null;
	}
	async refreshTokenAsync() {
		const token = await this.getTokenAsync();
		const fetchData = {
			method: "POST",
			body: {},
			headers: new Headers({
				Host: process.env.REACT_APP_KNOWN_AUTHORITY as string,
				"Content-Type": "application/x-www-form-urlencoded",
			}),
		} as RequestInit;
		const url = process.env.REACT_APP_TOKEN_ENDPOINT as string;

		const scope = `openid offline_access ${process.env.REACT_APP_B2C_CLIENT_ID}`;
		const queryParams = new URLSearchParams({
			grant_type: "refresh_token",
			client_id: process.env.REACT_APP_B2C_CLIENT_ID as string,
			scope: scope,
			redirect_uri: process.env.REACT_APP_REDIRECT_URL_WEB as string,
			refresh_token: token?.refresh_token as string,
		});
		const result = await fetch(url + "?" + queryParams, fetchData);
		const jsonResult = (await result.json()) as TokenCodeChallengeResult;
		jsonResult.scope = scope;
		jsonResult.createdAt = Date.now();
		await this.setTokenAsync(jsonResult);
		return jsonResult;
	}
	async challengeTokenAsync(code: string) {
		const fetchData = {
			method: "POST",
			body: {},
			headers: new Headers({
				Host: process.env.REACT_APP_KNOWN_AUTHORITY as string,
				"Content-Type": "application/x-www-form-urlencoded",
			}),
		} as RequestInit;
		const url = process.env.REACT_APP_TOKEN_ENDPOINT as string;
		const code_verfier = await PCKEOptionsHelperGetVerifierAsync();

		const scope = `${process.env.REACT_APP_B2C_CLIENT_ID} offline_access `;
		const queryParams = new URLSearchParams({
			grant_type: "authorization_code",
			client_id: process.env.REACT_APP_B2C_CLIENT_ID as string,
			scope: scope,
			redirect_uri: process.env.REACT_APP_REDIRECT_URL_WEB as string,
			code: code,
			code_verifier: code_verfier,
		});
		const result = await fetch(url + "?" + queryParams, fetchData);
		const jsonResult = (await result.json()) as TokenCodeChallengeResult;
		jsonResult.scope = scope;
		jsonResult.createdAt = Date.now();
		await this.setTokenAsync(jsonResult);
		await PCKEOptionsHelperRemoveVerifierAsync();
		return jsonResult;
	}
	async logOutAsync() {
		const pckeModifiedOptions = await PCKEOptionsHelper(LoginCapacitorAzureB2cOptions);
		const logoutResult = await OAuth2Client.logout(pckeModifiedOptions);
		const token = await this.getTokenAsync();
		await Preferences.clear();
		//Clear all data for in the local storage
		localStorage.clear();

		//Clear all cookies for the tab in the browser
		document.cookie.split(";").forEach((c) => {
			document.cookie = c
				.replace(/^ +/, "")
				.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
		});

		const deviceInfo = await Device.getInfo();
		switch (deviceInfo.platform) {
			case "web":
				window.location.replace(`${process.env.REACT_APP_AUTH_LOGOUT}?id_token_hint=${token?.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URL_WEB}` ?? "");

				setTimeout(() => {
					window.location.reload();

				}, 2000);

				break;
			case "ios":
				Browser.addListener('browserPageLoaded', () => {
					Browser.close();
					window.location.reload();
				});
				await Browser.open({ url: `${process.env.REACT_APP_AUTH_LOGOUT}?id_token_hint=${token?.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URL_IOS}` ?? "", presentationStyle: "fullscreen" });
				break;
			case "android":
				Browser.addListener('browserPageLoaded', () => {
					Browser.close();
					window.location.reload();
				});
				await Browser.open({ url: `${process.env.REACT_APP_AUTH_LOGOUT}?id_token_hint=${token?.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URL_ANDROID}` ?? "" });
				window.location.reload();
				break;
			default:
				await Browser.open({ url: `${process.env.REACT_APP_AUTH_LOGOUT}?id_token_hint=${token?.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URL_WEB}` ?? "" });
				break;
		}

	}
	async getTokenAsync(): Promise<TokenCodeChallengeResult | null> {
		const prefResult = await Preferences.get({ key: this.storageTokenKey });
		const token = prefResult.value as string | undefined | null;
		if (token == undefined) {
			return null;
		}

		const parsedToken = JSON.parse(token) as TokenCodeChallengeResult;
		return parsedToken;
	}
	async setTokenAsync(token: TokenCodeChallengeResult | null): Promise<TokenCodeChallengeResult | null> {
		const stringifiedResult = JSON.stringify(token);
		//		console.log("setTokenAsync", stringifiedResult);
		await Preferences.set({
			key: this.storageTokenKey,
			value: stringifiedResult,
		});
		return token;
	}
	async clearTokenAsync() {
		//		console.log("clearTokenAsync");
		await Preferences.remove({ key: this.storageTokenKey });
	}
	//Helper
	parseJwt(token: string) {
		if (token == undefined) {
			return null;
		}

		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join(""),
		);

		return JSON.parse(jsonPayload);
	}
}
