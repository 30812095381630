import { createSelector } from "@reduxjs/toolkit";
import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { Mail } from "model/Mail";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";

import { RootState } from "../..";
import { getScope, getSelectedTenant } from "../Profile.selectors";

export const getAllMails = (state: RootState): BaseList<Mail> => state.mail.value || newBaseList<Mail>();
export const getIsFetching = (state: RootState): boolean => state.mail.isFetching;
export const getIsShareInternFetching = (state: RootState): boolean => state.mail.isShareInternFetching;
export const getIsShareExternFetching = (state: RootState): boolean => state.mail.isShareExternFetching;

export const getMails = createSelector(getAllMails, getSelectedTenant, (mails: BaseList<Mail>, tenant: Tenant | null) => {
	return tenant
		? filterBaseListItems(mails, (item) => item.scopedSharingTenants?.some((t) => t.id === tenant.id) || item.creator?.id == tenant.id)
		: mails;
});

export const getMail = createSelector(
	getAllMails,
	getSelectedTenant,
	getScope,
	(mails: BaseList<Mail>, tenant: Tenant | null, scopeType?: ScopeType): Mail | undefined => {
		let result;
		switch (scopeType) {
			case ScopeType.default:
				if (tenant) {
					result = mails.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isDefault);
				} else {
					result = mails.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isDefault === true)[0]);
				}
				break;
			case ScopeType.intern:
				if (tenant) {
					result = mails.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isIntern);
				} else {
					result = mails.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isIntern === true)[0]);
				}
				break;
			case ScopeType.extern:
				if (tenant) {
					result = mails.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isExtern);
				} else {
					result = mails.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isExtern === true)[0]);
				}
				break;
		}

		return result !== undefined ? result : mails.items.length ? mails.items[0] : undefined;
	},
);
