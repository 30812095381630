import { BaseListQueryInstance } from "model/BaseListQuery";
import { Tenant } from "model/Tenant";

export interface LoadEventsQuery {
	query: BaseListQueryInstance;
	tenant?: Tenant;
	userId?: string;
	startTime?: string;
}

export function newLoadEventsQuery(query: BaseListQueryInstance, userId?: string, tenant?: Tenant, startTime?: string): LoadEventsQuery {
	return {
		query: query,
		tenant: tenant,
		userId: userId,
		startTime: startTime,
	};
}
