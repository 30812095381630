import { IonCard, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import Loader from "components/loader/Loader";
import { newBaseListQuery } from "model/BaseListQuery";
import { newLoadBlogEntriesQuery } from "model/blogEntry/LoadBlogEntriesQuery";
import { newLoadEventsQuery } from "model/events/LoadEventsQuery";
import { OrderByType } from "model/OrderByType";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { reloadBlogEntries } from "store/blogEntry/BlogEntry.thunks";
import { getAllOrderElements, getIsFetching, getLatestOrderElements } from "store/orderElement/OrderElement.selectors";
import { reloadOrderElements } from "store/orderElement/OrderElement.thunks";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

import Teaser from "./Teaser";

const LatestOrderElementsList: VFC = () => {
	const orderElements = useSelector(getLatestOrderElements);
	const dispatch = useAppDispatch();
	const selectedTenant = useSelector(getSelectedTenant);
	const profile = useSelector(getProfile);
	const isFetching = useSelector(getIsFetching);

	useEffect(() => {
		dispatch<any>(
			reloadOrderElements(
				newBaseListTenantQuery(
					newBaseListQuery({
						page: 1,
						pagesize: 300,
						orderby: "CreatedAt",
						orderbytype: OrderByType.descending,
					}),
					selectedTenant,
				),
			),
		);
	}, [selectedTenant, profile]);

	return (
		<>
			<Loader isVisible={isFetching} />
			<IonCard color="amber-50">
				{orderElements?.map((orderElement) => {
					return <Teaser orderElement={orderElement} key={orderElement.id} />;
				})}
				<IonItem color="amber-50" button>
					<IonLabel>
						<IonRouterLink routerLink={`/messages`}>
							<Trans>My messages</Trans>
						</IonRouterLink>
					</IonLabel>
				</IonItem>
			</IonCard>
		</>
	);
};
export default LatestOrderElementsList;
