import { IonLoading, IonPage } from "@ionic/react";
import { FC } from "react";

/// <summary>
/// This page is shown when the user is logged out or starting the app and the app is checking if the user is logged in.
/// </summary>
const LoggedOutCheckToken: FC = () => {
	return (
		<IonPage color="amber-900">
			<IonLoading message="Sie werden weitergeleitet." isOpen={true} duration={3000}></IonLoading>
		</IonPage>
	);
};

export default LoggedOutCheckToken;
