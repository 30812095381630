import {
	IonAvatar,
	IonButton,
	IonCheckbox,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonToolbar,
} from "@ionic/react";
import { convertTenantToScopedSharingTenant, ScopedSharingTenant } from "model/ScopedSharingTenant";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getAllTenants } from "store/profile/Profile.selectors";
import "./styles.scss";
import { closeOutline, peopleCircleSharp } from "ionicons/icons";
import { Trans } from "@lingui/macro";
import { ScopeType } from "model/ScopeType";

export interface Props {
	scopedSharingTenants?: ScopedSharingTenant[];
	scopeType: ScopeType;
	done(tenant: ScopedSharingTenant): void;
	cancel(): void;
}

const ShareScopeTenantSelectModal: FC<Props> = ({ scopedSharingTenants, scopeType, done, cancel }) => {
	const checkDisabled = (tenant: ScopedSharingTenant) =>
		(scopeType == ScopeType.intern && tenant.isIntern == true) || (scopeType == ScopeType.extern && tenant.isExtern == true);

	return (
		<>
			<IonHeader color="amber-50">
				<div className="ion-text-center">
					<IonToolbar color="amber-300">
						<IonButton
							fill="clear"
							slot="start"
							onClick={() => {
								cancel();
							}}
						>
							<IonIcon color="medium" icon={closeOutline}></IonIcon>
						</IonButton>
						<IonLabel slot="start">
							<h2>
								<Trans>Freigabe</Trans>
							</h2>
						</IonLabel>
					</IonToolbar>
				</div>
			</IonHeader>
			<IonContent>
				<IonList id="modal-list" inset={true}>
					{scopedSharingTenants?.map((item) => (
						<IonItem button={false} lines="full" className="ion-padding-bottom" key={`${item.id}_tenant_select_item`}>
							{item.avatarImageUrl == null || item.avatarImageUrl == undefined || item.avatarImageUrl == "" ? (
								<IonIcon size="large" slot="start" src={peopleCircleSharp}></IonIcon>
							) : (
								<IonAvatar slot="start">
									<IonImg src={item.avatarImageUrl}></IonImg>
								</IonAvatar>
							)}
							<IonLabel className="ion-margin-bottom" slot="start">
								{item.name}
							</IonLabel>
							<IonButton onClick={(e) => done(item)} disabled={checkDisabled(item)} slot="end">
								{checkDisabled(item) ? <Trans>Bereits hinzugefügt</Trans> : <Trans>Freigeben</Trans>}
							</IonButton>
						</IonItem>
					))}
				</IonList>
			</IonContent>
		</>
	);
};

export default ShareScopeTenantSelectModal;
