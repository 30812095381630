import { Creator } from "./Creator";
import { IsBusy } from "./IsBusy";
import { ScopedSharingTenant } from "./ScopedSharingTenant";

export interface Address extends IsBusy {
	name?: string;
	postbox?: string;
	postcode?: string;
	street?: string;
	city?: string;
	region?: string;
	streetnumber?: string;
	country?: string;
	id?: string;
	scopedSharingTenants?: ScopedSharingTenant[];
	creator?: Creator;
}

export function newAddress(): Address {
	return {
		id: undefined,
	};
}

export function addressString(address?: Address): string | undefined {
	const values = [address?.street, address?.streetnumber, address?.postcode, address?.postbox, address?.city, address?.country].filter((x) => x);

	return values?.join(" ");
}
