import "./style.scss";
import "../style.scss";

import {
	IonButton,
	IonCard,
	IonCol,
	IonGrid,
	IonIcon,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonNote,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonTitle,
} from "@ionic/react";
import { priceRestrictionTypeToString } from "helper/PriceRestrictionTypeToString";
import { alertCircleOutline, banOutline, checkmarkCircleOutline, refreshOutline } from "ionicons/icons";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import * as React from "react";
import { useState } from "react";

interface IEventAddressPartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventAddressPart: React.FunctionComponent<IEventAddressPartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const [isValid, setIsValid] = useState(false);
	const [additionalRequiredGroupsSelected, setAdditionalRequiredGroupsSelected] = useState(false);
	const [isPriceSelected, setIsPriceSelected] = useState(false);

	if (!event.paymentRequired) {
		return <></>;
	}

	const checkAllRequiredAdditionalServicesSelected = () => {
		if (event.contract?.additionalServiceGroups == undefined) {
			return true;
		}
		const countRequiredAddtionalServices = event.contract?.additionalServiceGroups?.filter((additionalService) => {
			additionalService.isRequired == true;
		}).length;
		const selectedRequiredAddtionalServices = eventRegistration.additionalServiceGroups?.filter((additionalService) => {
			additionalService.isRequired == true;
		}).length;
		const result = countRequiredAddtionalServices == selectedRequiredAddtionalServices;
		setAdditionalRequiredGroupsSelected(result);
		return result;
	};

	const checkRequiredPriceSelected = () => {
		const result = eventRegistration.priceId != undefined;
		setIsPriceSelected(result);
		return result;
	};
	React.useEffect(() => {
		if (eventRegistration == undefined || event == undefined) {
			return;
		}
		const priceCheck = checkRequiredPriceSelected();
		const requiredServicesCheck = checkAllRequiredAdditionalServicesSelected();

		const allValid = priceCheck && requiredServicesCheck;
		setIsValid(allValid);
	}, [eventRegistration]);

	return (
		<>
			<div className="firstDivider"></div>
			<IonCard>
				<IonGrid className={isValid ? "gridSuccess" : "gridNoSuccess"}>
					<IonRow>
						<IonCol size="0" sizeLg="1" sizeMd="0" sizeXl="1" sizeXs="0">
							{isValid ? (
								<IonIcon className="isDoneIcon" src={checkmarkCircleOutline} />
							) : (
								<IonIcon className="notDoneIcon" src={alertCircleOutline} />
							)}
						</IonCol>
						<IonCol size="11" sizeLg="11" sizeMd="11" sizeXl="11" sizeXs="12">
							<IonCard>
								<IonItemDivider className={isPriceSelected ? "rowDividerSuccess" : "rowDivider"} mode="ios">
									Preis:
									<IonLabel color="danger">*</IonLabel>
								</IonItemDivider>
								<div className="rowItem">
									<IonRadioGroup
										onIonChange={(e) => {
											eventRegistration.priceId = e.detail.value;
											setEventRegistration(eventRegistration);
										}}
									>
										{event.contract?.price.prices.map((price, i) => {
											return (
												<IonItem key={price.id} lines="full" disabled={!price.isActive} className="ion-activatable">
													<IonLabel slot="start">
														{price.priceRestriction?.type != undefined ? (
															<IonNote slot="start">
																{priceRestrictionTypeToString(price.priceRestriction?.type)}
															</IonNote>
														) : (
															<></>
														)}
														<br />
														{price.description}
													</IonLabel>

													<IonLabel slot="end">{price.amount}€</IonLabel>

													<IonRadio mode="md" slot="end" value={price.id} />
												</IonItem>
											);
										})}
									</IonRadioGroup>
								</div>
							</IonCard>

							<IonCard>
								<IonItemDivider className={additionalRequiredGroupsSelected ? "rowDividerSuccess" : "rowDivider"} mode="ios">
									Zusatzoptionen:
								</IonItemDivider>
								<div className="rowItem">
									{event.contract?.additionalServiceGroups?.map((addtionalService, i) => {
										return (
											<IonCard id={addtionalService.id}>
												<IonItemDivider
													className={
														eventRegistration.additionalServiceGroups?.find((x) => x.id == addtionalService.id) !=
														undefined
															? "rowDividerSuccess"
															: "rowDivider"
													}
													mode="ios"
												>
													{addtionalService.name}
													{addtionalService.isRequired ? <IonLabel color="danger">*</IonLabel> : <></>}
												</IonItemDivider>
												<div className="rowItem">
													<IonRadioGroup
														id={addtionalService.id}
														allowEmptySelection={!addtionalService.isRequired}
														value={
															eventRegistration?.additionalServiceGroups?.find((x) => x.id == addtionalService.id)
																?.additionalServices[0]?.id
														}
														onIonChange={(e) => {
															if (eventRegistration.additionalServiceGroups == undefined) {
																eventRegistration.additionalServiceGroups = [];
															} else {
																//Remove the selectedService from the Registration
																eventRegistration.additionalServiceGroups =
																	eventRegistration?.additionalServiceGroups?.filter(
																		(x) => x.id != addtionalService.id,
																	);
															}
															//Create deep copy and filter the Options for the selected one
															if (e.detail.value == undefined) {
																setEventRegistration(eventRegistration);
															} else {
																const selectedAddtionalServiceToAdd = JSON.parse(JSON.stringify(addtionalService)); //Create deep copy
																selectedAddtionalServiceToAdd.additionalServices =
																	selectedAddtionalServiceToAdd?.additionalServices?.filter(
																		(y: any) => y.id == e.detail.value,
																	);
																if (selectedAddtionalServiceToAdd == undefined) {
																	return;
																}
																eventRegistration.additionalServiceGroups?.push(selectedAddtionalServiceToAdd);
																//Add the deep copy of the selected Option to the Registration
																setEventRegistration(eventRegistration);
															}
														}}
													>
														{addtionalService.additionalServices?.map((additonalServiceOption, i) => {
															return (
																<IonItem
																	key={additonalServiceOption.id}
																	lines="full"
																	//																	disabled={!price.isActive}
																	className="ion-activatable"
																>
																	<IonLabel slot="start">{additonalServiceOption.name}</IonLabel>

																	<IonLabel slot="end">{additonalServiceOption.moneyAmount}€</IonLabel>

																	<IonRadio mode="md" slot="end" value={additonalServiceOption.id} />
																</IonItem>
															);
														})}
													</IonRadioGroup>
												</div>
											</IonCard>
										);
									})}
								</div>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventAddressPart;
