import axios from "axios";
import { ClothingFormValues } from "components/confectionInformation/ClothingForm";
import { MedicalInfoFormValues } from "components/medicalInformation/MedicalInfoForm";
import { ImageInformation } from "model/ImageInformation";
import TenantImage from "model/IUpdatePersonAvatarInTenantDto";
import { TalkJsUser } from "model/chat/TalkJsUser";
import { ProfileUpdate } from "model/person/PersonUpdate";
import { Profile } from "model/Profile";
import { VertexLabelType } from "model/VertexLabelType";
import { scopeNumber } from 'model/ScopeType';
import IUpdatePersonAvatarDto from 'model/IUpdatePersonAvatarDto';

class ProfileService {
	private profile?: Profile;

	setProfile(profile: Profile) {
		this.profile = profile;
	}

	async get(userId: string): Promise<Profile> {
		const vertices = [
			VertexLabelType.address,
			VertexLabelType.mail,
			VertexLabelType.phone,
			VertexLabelType.bankAccount,
			VertexLabelType.medicalInformation,
			VertexLabelType.confectionInformation,
			VertexLabelType.honor,
			VertexLabelType.qualification,
			VertexLabelType.license,
			VertexLabelType.membership,
			VertexLabelType.event,
			VertexLabelType.team,
			VertexLabelType.orderElement,
			//VertexLabelType.invoice,
		];

		const params = new URLSearchParams(
			vertices.map((vertex) => {
				return ["selectedVertexLabels", vertex];
			}),
		);

		const res = await axios.get(`${process.env.REACT_APP_API_SEARCH_URL}/api/association/person/${userId}?${params.toString()}`, {
			headers: { userId: this.profile?.id },
		});
		return res.data;
	}

	async update(profile: ProfileUpdate): Promise<Profile> {
		const res = await axios.put(`${process.env.REACT_APP_API_ME_URL}/api/me`, profile, { headers: { userId: this.profile?.id } });
		return res.data;
	}

	async updatePhoto(dto: IUpdatePersonAvatarDto): Promise<ImageInformation> {
		const formData = new FormData();
		formData.append(dto.file.name, dto.file);

		const res = await axios.put(`${process.env.REACT_APP_API_ME_URL}/api/me/image/${scopeNumber(dto.scopeType)}/upload`, formData, {
			headers: { userId: this.profile?.id },
		});
		return res.data;
	}
	async updateTenantPhoto(file: TenantImage): Promise<ImageInformation> {
		const formData = new FormData();
		formData.append(file?.file?.name, file.file);

		const res = await axios.put(
			`${process.env.REACT_APP_API_ME_URL}/api/me/image/tenant/${file.tenantId}/${scopeNumber(file.scopeType)}/upload`,
			formData,
			{
				headers: { userId: this.profile?.id },
			},
		);
		return res.data;
	}
	async updateClothing() {
		const data = {
			confectionSize: 0,
			clothingPartType: "head",
			name: "string",
		};
		try {
			const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/confectioninformation`, data, {
				headers: { userId: this.profile?.id },
			});
			console.log(res);
		} catch (e) {
			console.log(e);
		}
	}

	async addClothing(data: ClothingFormValues) {
		try {
			const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/confectioninformation`, data, {
				headers: { userId: this.profile?.id },
			});
			console.log(res);
		} catch (e) {
			console.log(e);
		}
	}

	async addMedicalInfo(data: MedicalInfoFormValues) {
		try {
			const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/medicalinformation`, data, {
				headers: { userId: this.profile?.id },
			});
			console.log(res);
		} catch (e) {
			console.log(e);
		}
	}

	async updateMedicalInfo() {
		const data = {
			type: "allergy",
			name: "string",
			description: "string",
		};
		try {
			const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/medicalinformation`, data, {
				headers: { userId: this.profile?.id },
			});
			console.log(res);
		} catch (e) {
			console.log(e);
		}
	}

	async getMedicalInfo() {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/medicalinformation`, { headers: { userId: this.profile?.id } });
			console.log(res);
		} catch (e) {
			console.log(e);
		}
	}
	async getChatUser(userId: string): Promise<TalkJsUser> {
		const res = await axios.get<TalkJsUser>(`${process.env.REACT_APP_API_ME_URL}/api/me/chat/account`, {
			headers: { userId: userId },
		});
		return res.data as TalkJsUser;
	}
}

export default new ProfileService();
