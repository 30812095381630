import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { VFC } from "react";
import { useSelector } from "react-redux";
import { getAllConfectionInformations } from "store/profile/confectionInformation/ConfectionInformation.selectors";

import InfoBlock from "./InfoBlock/InfoBlock";

const MyConfectionInformations: VFC = () => {
	const { i18n } = useLingui();
	const confectionInformations = useSelector(getAllConfectionInformations)?.items.slice(0, 5);

	const columns = {
		name: i18n._(t`confectionInformation.name`),
	};

	const rows = confectionInformations?.map((confectionInformation) => {
		return { id: confectionInformation.id, name: confectionInformation.name };
	});

	return (
		<>
			<InfoBlock
				key={`confectioninformation_infoblock`}
				id="confectionInformations"
				title={i18n._(t`My confection informations`)}
				columns={columns}
				rows={rows}
				dataKey={"clothing"}
				editable
				link={{
					link: "/profile/confectionInformations",
					title: i18n._(t`profile.confectionInformations.all`),
				}}
			/>
		</>
	);
};

export default MyConfectionInformations;
