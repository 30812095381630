import { t } from "@lingui/macro";
import {
	homeOutline,
	newspaperOutline,
	listOutline,
	calendarOutline,
	personOutline,
	mailOutline,
	callOutline,
	walletOutline,
	shirtOutline,
	personCircleOutline,
	documentTextOutline,
	cardOutline,
	peopleOutline,
	ribbonOutline,
	schoolOutline,
	layersOutline,
	chatbubblesOutline,
	lockClosedOutline,
	arrowUpCircleOutline,
	helpBuoyOutline,
} from "ionicons/icons";
import { newPage } from "model/Page";

export const topPages = [
	newPage(`Dashboard`, "/", homeOutline),
	//newPage(i18n._(t`Chat`), "/inbox", chatbubblesOutline),
	newPage(`News`, "/blog", newspaperOutline),
	newPage(`Events`, "/events", listOutline),
	newPage(`Calendar`, "/calendar", calendarOutline),
];

export const profilePages = [
	newPage(t`Profile`, "/profile", personOutline, undefined, undefined, [
		newPage(`Anschrift`, "/profile/address", homeOutline),
		newPage(`Mail`, "/profile/mail", mailOutline),
		newPage(`Telefon`, "/profile/Phone", callOutline),
		newPage(`Konto`, "/profile/bankaccount", walletOutline),
		newPage(`Kleidung`, "/profile/confectionInformations", shirtOutline),
		newPage(`Profilbild`, "/profile/avatar", personCircleOutline),
		newPage(`miscellaneous`, "/profile/miscellaneous", listOutline),
	]),
	newPage(`Invoices`, "/invoices", documentTextOutline),
	newPage(`Messages`, "/messages", mailOutline),
	newPage(`Licenses`, "/licenses", cardOutline),
];
export const memberShipPages = [newPage(`Teams`, "/teams", peopleOutline)];
export const listPages = [newPage(`Honors`, "/honors", ribbonOutline), newPage(`Qualifikationen`, "/qualifications", schoolOutline)];
export const bottomPages = [
	newPage(t`Tenants`, "/clubs", layersOutline),
	//		newPage(i18n._(t`profilevalidation`), "/validation/profile", layersOutline),
	//	newPage(i18n._(t`profilevalidationdetail`), "/validation/profile/1/info", layersOutline),
	newPage(`Chat (Beta)`, "/inbox", chatbubblesOutline),

	newPage(`Datenschutz`, "/logs", lockClosedOutline),

	newPage(
		`NewUpdates`,
		"https://support.sportcloud.de/hc/de/sections/4409540029842-Updates",
		arrowUpCircleOutline,
		undefined,
		undefined,
		undefined,
		true,
	),

	newPage(
		`Helpcenter`,
		"https://support.sportcloud.de/hc/de/categories/4408058320530-Mein-DSO",
		helpBuoyOutline,
		undefined,
		undefined,
		undefined,
		true,
	),
];
