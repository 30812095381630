import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react";
import { useLingui } from "@lingui/react";
import { TabBarId } from "helper/UIHelper";
import { newspaperOutline, calendarOutline, ellipsisHorizontalOutline } from "ionicons/icons";
import { FC } from "react";

interface MobileTabBarProps {
	Routes: JSX.Element;
	onMoreClicked: () => void;
}

export const MobileTabBar: FC<MobileTabBarProps> = ({ Routes, onMoreClicked }: MobileTabBarProps) => {
	const { i18n } = useLingui();
	return (
		<IonTabs>
			{Routes}

			<IonTabBar color="amber-50" mode="md" id={TabBarId} slot="bottom">
				<IonTabButton tab="blog" href="/blog">
					<IonIcon icon={newspaperOutline} />
					<IonLabel>{i18n._("News")}</IonLabel>
				</IonTabButton>

				<IonTabButton tab="calendar" href="/calendar">
					<IonIcon icon={calendarOutline} />
					<IonLabel>{i18n._("Calendar")}</IonLabel>
				</IonTabButton>
				{/*

	<IonTabButton tab="chat" href="/inbox">
		<IonIcon icon={chatbubblesOutline} />
		<IonLabel>{"Chat"}</IonLabel>
	</IonTabButton>

		<IonTabButton tab="chat" href="/inbox">
		<IonIcon icon={chatbubblesOutline} />
		<IonLabel>{i18n._("Chat")}</IonLabel>
	</IonTabButton>
	*/}
				<IonTabButton
					tab="more"
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						onMoreClicked();
					}}
				>
					<IonIcon icon={ellipsisHorizontalOutline} />
					<IonLabel>{i18n._("more")}</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);
};
