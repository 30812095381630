import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

export enum VertexLabelType {
	committee = "committee",
	page = "page",
	phone = "phone",
	mail = "mail",
	address = "address",
	contactInformation = "contactinformation",
	person = "person",
	group = "group",
	dataFile = "datafile",
	bankAccount = "bankaccount",
	membership = "membership",
	sharedInformation = "sharedinformation",
	association = "association",
	team = "team",
	teamMember = "teammember",
	qualification = "qualification",
	honor = "honor",
	event = "event",
	requirement = "requirement",
	blogEntry = "blogEntry",
	menu = "menu",
	medicalInformation = "medicalinformation",
	confectionInformation = "confectioninformation",
	office = "office",
	sponsor = "sponsor",
	globalDivision = "globaldivision",
	sponsorContract = "sponsorcontract",
	company = "company",
	themeSettings = "themesettings",
	identifier = "identifier",
	category = "category",
	license = "license",
	licenseExtension = "licenseextension",
	globalDiscipline = "globaldiscipline",
	associationDiscipline = "associationeiscipline",
	associationDivision = "associationeivision",
	invoice = "invoice",
	invoiceReceipt = "invoicereceipt",
	outgoingInvoice = "outgoinginvoice",
	advertisingFormat = "advertisingformat",
	advertisingContent = "advertisingcontent",
	advertisingPackage = "advertisingpackage",
	orderElement = "orderelement",
}

export function vertexLabelString(vertexLabelType?: VertexLabelType): string | undefined {
	if (!vertexLabelType) {
		return "";
	}

	switch (vertexLabelType) {
		case VertexLabelType.committee:
			return i18n._(t`Committee`);
		case VertexLabelType.page:
			return i18n._(t`Page`);
		case VertexLabelType.phone:
			return i18n._(t`Phone number`);
		case VertexLabelType.mail:
			return i18n._(t`Mail address`);
		case VertexLabelType.address:
			return i18n._(t`Address`);
		case VertexLabelType.contactInformation:
			return i18n._(t`Contact information`);
		case VertexLabelType.person:
			return i18n._(t`Person`);
		case VertexLabelType.bankAccount:
			return i18n._(t`Bank account`);
		case VertexLabelType.membership:
			return i18n._(t`Membership`);
		case VertexLabelType.association:
			return i18n._(t`Association`);
		case VertexLabelType.team:
			return i18n._(t`Team`);
		case VertexLabelType.teamMember:
			return i18n._(t`Team member`);
		case VertexLabelType.qualification:
			return i18n._(t`Qualification`);
		case VertexLabelType.honor:
			return i18n._(t`Honor`);
		case VertexLabelType.event:
			return i18n._(t`Event`);
		case VertexLabelType.blogEntry:
			return i18n._(t`Blog entry`);
		case VertexLabelType.medicalInformation:
			return i18n._(t`Medical information`);
		case VertexLabelType.confectionInformation:
			return i18n._(t`Confection information`);
		case VertexLabelType.office:
			return i18n._(t`Office`);
		case VertexLabelType.sponsor:
			return i18n._(t`Sponsor`);
		case VertexLabelType.company:
			return i18n._(t`Company`);
		case VertexLabelType.license:
			return i18n._(t`License`);
		case VertexLabelType.invoice:
			return i18n._(t`Invoice`);
		case VertexLabelType.invoiceReceipt:
			return i18n._(t`Invoice receipt`);
		case VertexLabelType.outgoingInvoice:
			return i18n._(t`Outgoing invoice`);
		default:
			return vertexLabelType;
	}
}
