import "./Events.css";

import { IonCol, IonGrid, IonLabel, IonListHeader, IonRow, IonSearchbar, IonSegment, IonSegmentButton } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useAppDispatch } from "app/hooks";
import EventList from "components/event/EventList";
import Page from "components/page/Page";
import { newBaseListQuery } from "model/BaseListQuery";
import { Event } from "model/Event";
import { EventsFilter } from "model/events/EventsFilter";
import { newLoadEventsQuery } from "model/events/LoadEventsQuery";
import { OrderByType } from "model/OrderByType";
import { FC, useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { fetchEvents, reloadEvents } from "store/event/Event.thunks";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

const EventsPage: FC = () => {
	console.log("render EventsPage");
	const dispatch = useAppDispatch();

	const [searchValue, setSearchValue] = useState("");
	const [searchDateTimeValue, setSearchDateTimeValue] = useState<Date | undefined>(new Date());
	const dateTimePickerRef = useRef<DatePickerComponent>(null);

	// const [view, setView] = useState(EventsView.List);
	const [filter, setFilter] = useState(EventsFilter.All);

	const selectedTenant = useSelector(getSelectedTenant);
	const userId = useSelector(getProfile)?.id;

	useEffect(() => {
		reloadEntries();
	}, [filter, selectedTenant, searchValue, searchDateTimeValue]);

	const reloadEntries = async (): Promise<void> => {
		dispatch<any>(
			reloadEvents(
				newLoadEventsQuery(
					newBaseListQuery({ orderby: "StartTime", orderbytype: OrderByType.ascending, searchfields: ["name"], keyword: searchValue }),
					filter === EventsFilter.My ? userId : undefined,
					selectedTenant,
					searchDateTimeValue?.toUTCString(),
				),
			),
		);
	};

	const loadEntries = async (): Promise<void> => {
		dispatch<any>(
			fetchEvents(
				newLoadEventsQuery(
					newBaseListQuery({ orderby: "StartTime", orderbytype: OrderByType.ascending, searchfields: ["name"], keyword: searchValue }),
					filter === EventsFilter.My ? userId : undefined,
					selectedTenant,
					searchDateTimeValue?.toUTCString(),
				),
			),
		);
	};

	const switchFilter = (filter: EventsFilter | undefined): void => {
		switch (filter) {
			case EventsFilter.All:
			case EventsFilter.My:
				setFilter(filter);
				break;
			default:
				break;
		}
	};
	return (
		<Page title={i18n._(t`Events`)}>
			<IonListHeader lines="full">
				<IonGrid>
					<IonRow>
						<IonCol size="8" sizeXs="12">
							<IonSegment mode="ios" value={filter} onIonChange={(e) => switchFilter(e.detail.value as EventsFilter)}>
								<IonSegmentButton value={EventsFilter.All}>
									<IonLabel>Alle Veranstaltungen</IonLabel>
								</IonSegmentButton>
								<IonSegmentButton color="amber-50" value={EventsFilter.My}>
									<IonLabel>Meine Veranstaltungen</IonLabel>
								</IonSegmentButton>
							</IonSegment>
						</IonCol>
						{/* <IonCol></IonCol>
								<IonCol>
									<IonSegment mode="ios" value={view} onIonChange={(e) => switchView(e.detail.value as EventsView)}>
										<IonSegmentButton value={EventsView.List}>
											<IonIcon icon={listIcon}></IonIcon>
										</IonSegmentButton>
										<IonSegmentButton value={EventsView.Calendar}>
											<IonIcon icon={calendarIcon}></IonIcon>
										</IonSegmentButton>
									</IonSegment>
								</IonCol> */}
					</IonRow>
					<IonRow>
						<IonCol size="6">
							<IonSearchbar
								color="amber-50"
								debounce={600}
								onIonChange={(e) => {
									if (e.detail.value != undefined) {
										setSearchValue(e.detail.value);
									}
								}}
								placeholder="Suche"
								value={searchValue}
								showClearButton="always"
							/>
						</IonCol>
						<IonCol size="6">
							<DatePickerComponent
								id="searchdatepicker"
								onFocus={(e) => dateTimePickerRef.current?.show()}
								onChange={(e: any) => setSearchDateTimeValue(e.nativeEvent.text)}
								value={searchDateTimeValue}
								format="dd.MM.yyyy"
								placeholder="Veranstaltungsbeginn"
								ref={dateTimePickerRef}
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonListHeader>
			<EventList onPageScrollEnd={loadEntries} reload={reloadEntries} />
		</Page>
	);
};

export default EventsPage;
