import {
	IonButton,
	IonCard,
	IonChip,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonNote,
	IonSpinner,
	IonToolbar,
	useIonToast,
} from "@ionic/react";
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { addOutline, callOutline, closeOutline, copyOutline, pencilOutline } from "ionicons/icons";
import { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getProfile } from "store/profile/Profile.selectors";
import EditModal from "./EditModal";
import ScopeShareExternToTenant from "components/shareTenant/ScopeShareExternToTenant";
import { deleteSharePhoneScoped, sharePhoneScoped, updatePhone } from "store/profile/phone/Phone.thunks";
import { getIsFetching, getIsShareExternFetching, getIsShareInternFetching } from "store/profile/phone/Phone.selectors";
import { Address } from "model/Address";
import ScopeShareInternToTenant from "components/shareTenant/ScopeShareInternToTenant";
import { Phone } from "model/Phone";
import Loader from "components/loader/Loader";
import ShareTenantSelectModal from "components/shareTenant/ShareTenantSelectModal";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { newUpsertModel } from "model/UpsertModel";
import { CloseFooter } from "components/common/CloseFooter";
import { ShareTenantChipContent } from "components/common/ShareTenantChipContent";

export interface Props {
	isOpen: boolean;
	detailItem: Phone;
	onCancel(): void;
}

const DetailModalPhone: FC<Props> = ({ isOpen, detailItem, onCancel }) => {
	const dispatch = useAppDispatch();
	const profile = useSelector(getProfile);
	const isShareInternFetching = useSelector(getIsShareInternFetching);
	const isShareExternFetching = useSelector(getIsShareExternFetching);
	const isFetching = useSelector(getIsFetching);
	const selectRefShare = useRef<HTMLIonSelectElement>(null);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [present] = useIonToast();
	const [shareTenantSelectModalIsOpen, setShareTenantSelectModalIsOpen] = useState(false);
	const [sharedTenants, setSharedTenants] = useState(detailItem.scopedSharingTenants ?? new Array<ScopedSharingTenant>());

	function onShare(finalSharedTenants: ScopedSharingTenant[]) {
		const upsertItem = { ...detailItem };
		upsertItem.scopedSharingTenants = finalSharedTenants;
		dispatch<any>(updatePhone(newUpsertModel<Phone>(profile.id, upsertItem)));
		setShareTenantSelectModalIsOpen(false);
	}
	function onShareCancel() {
		setShareTenantSelectModalIsOpen(false);
	}
	function closeModal() {
		onCancel();
	}
	const sharedTenantChips = (entity: Address) => {
		if (entity?.scopedSharingTenants == undefined || entity?.scopedSharingTenants.length == 0) {
			return (
				<>
					<IonChip outline={false}>
						<IonLabel>Keine Freigaben</IonLabel>
					</IonChip>
				</>
			);
		}

		const result = entity?.scopedSharingTenants?.map((tenant, i) => {
			return <ShareTenantChip key={tenant.id} scopedSharingTenant={tenant} />;
		});
		return result;
	};
	const presentToast = () => {
		present({
			message: "Erfolgreich in die Zwischenablage kopiert.",
			duration: 1500,
			position: "top",
		});
	};
	return (
		<>
			<IonModal color="amber-50" isOpen={isOpen} onDidDismiss={(e) => closeModal()}>
				<EditModal key={"editModal" + detailItem.id} isOpen={editModalOpen} editItem={detailItem} onCancel={() => setEditModalOpen(false)} />

				<IonModal isOpen={shareTenantSelectModalIsOpen}>
					<ShareTenantSelectModal
						creatorId={detailItem.creator?.id}
						scopedSharingTenants={sharedTenants}
						done={onShare}
						cancel={onShareCancel}
					/>
				</IonModal>

				<IonHeader>
					<div className="ion-text-center">
						<IonToolbar color="amber-300">
							<IonButton
								fill="clear"
								slot="start"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									closeModal();
								}}
							>
								<IonIcon color="medium" icon={closeOutline}></IonIcon>
							</IonButton>

							<IonLabel slot="start">
								<h2>
									<Trans>Detail phone</Trans>
								</h2>
							</IonLabel>
							<Loader slot="end" isVisible={isFetching} />
						</IonToolbar>
					</div>
				</IonHeader>
				<IonContent color="amber-50">
					<div>
						<IonCard>
							<IonItem
								onClick={() => {
									navigator.clipboard.writeText(`+${detailItem.phoneNumberCountryCode}${detailItem.phoneNumber}`);
									presentToast();
								}}
								className="ion-margin"
								detail={false}
								button={true}
								lines="none"
							>
								<IonIcon icon={callOutline} slot="start" />
								<IonLabel class="ion-text-justify" slot="start">
									{`+${detailItem.phoneNumberCountryCode}${detailItem.phoneNumber}`}
								</IonLabel>

								<IonIcon
									icon={copyOutline}
									onClick={() => {
										navigator.clipboard.writeText(`+${detailItem.phoneNumberCountryCode}${detailItem.phoneNumber}`);
										presentToast();
									}}
									slot="end"
								/>
							</IonItem>
						</IonCard>
					</div>
					<IonItem lines="none">
						<IonChip
							className="ion-padding-end ion-padding-start"
							slot="end"
							disabled={isFetching}
							onClick={(e) => setEditModalOpen(true)}
							outline={false}
							color="primary"
						>
							<ShareTenantChipContent isBusy={isFetching} />
						</IonChip>
					</IonItem>
					<IonItem
						disabled={isFetching}
						className="ion-padding-bottom"
						lines="full"
						onClick={(e) => selectRefShare.current?.open(e.nativeEvent)}
					>
						<div>
							<div className="ion-margin-bottom">
								<IonNote>Geteilt mit</IonNote>
							</div>

							{sharedTenantChips(detailItem)}
							<IonChip
								disabled={isFetching}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setShareTenantSelectModalIsOpen(true);
								}}
								outline={false}
								color="primary"
							>
								{isFetching ? (
									<>
										<IonSpinner name="dots" className="ion-margin"></IonSpinner>
									</>
								) : (
									<>
										<IonIcon size="small" icon={pencilOutline} />
										<IonLabel>Freigabe anpassen</IonLabel>
									</>
								)}
							</IonChip>
						</div>
					</IonItem>
					<IonItem disabled={detailItem?.scopedSharingTenants?.length == 0} lines="full" className="ion-padding-bottom">
						<ScopeShareInternToTenant
							scopedSharingTenants={detailItem?.scopedSharingTenants}
							isFetching={isShareInternFetching}
							share={(dtoModal) => dispatch<any>(sharePhoneScoped(dtoModal))}
							deleteShare={(dtoModal) => dispatch<any>(deleteSharePhoneScoped(dtoModal))}
							userId={profile.id}
							entityId={detailItem.id ?? ""}
						/>
					</IonItem>

					<IonItem disabled={detailItem?.scopedSharingTenants?.length == 0} lines="none">
						<ScopeShareExternToTenant
							scopedSharingTenants={detailItem?.scopedSharingTenants}
							isFetching={isShareExternFetching}
							share={(dtoModal) => dispatch<any>(sharePhoneScoped(dtoModal))}
							deleteShare={(dtoModal) => dispatch<any>(deleteSharePhoneScoped(dtoModal))}
							userId={profile.id}
							entityId={detailItem.id ?? ""}
						/>
					</IonItem>
				</IonContent>

				<CloseFooter onClick={closeModal} />
			</IonModal>
		</>
	);
};

export default DetailModalPhone;
