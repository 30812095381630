import "./style.scss";
import "../style.scss";

import { IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import { Event } from "model/Event";
import * as React from "react";

interface IEventIBANPartProps {
	event: Event;
}

const EventIBANPart: React.FunctionComponent<IEventIBANPartProps> = ({ event }) => {
	return (
		<>
			<div className="firstDivider"></div>
			<IonItemDivider mode="ios">IBAN:</IonItemDivider>
			<IonItem lines="none">
				<IonItem lines="none">
					<IonLabel>{event.eventRegistration?.bankAccount?.iban}</IonLabel>
				</IonItem>
			</IonItem>
		</>
	);
};

export default EventIBANPart;
