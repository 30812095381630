/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "../theme/variables.scss";
import "../theme/theme.scss";
import "./App.css";
import { IonApp, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Menu from "components/menu/Menu";
import { StatusBar } from "@capacitor/status-bar";
import { Routes } from "./Routes";
import { TabContex } from "context/TabContext";
import React, { FC, useEffect } from "react";
import { Device } from "@capacitor/device";
import { Desktop, Mobile, hideTabs, showTabs } from "helper/UIHelper";
import { MobileTabBar } from "components/tabBar/MobileTabBar";
import { AppListenerHelper } from "helper/AppListenerHelper";
import { registerNotifications } from "helper/PushNotificationHelper";

const StartApp: FC = () => {
	const menuRef = React.useRef<HTMLIonMenuElement>(null);
	const [hidden, setHidden] = React.useState<boolean>(false);
	const [themeSet, setThemeSet] = React.useState<boolean>(false);
	AppListenerHelper();

	useEffect(() => {
		const registerPush = async () => {
			const deviceInfo = await Device.getInfo();
			if (deviceInfo.platform !== "web") {
				await registerNotifications();
			}
		};
		registerPush();
	}, []);

	useEffect(() => {
		if (hidden) {
			hideTabs();
		} else {
			showTabs();
		}
	}, [hidden]);

	useEffect(() => {
		const setStatusBarLight = async () => {
			const deviceInfo = await Device.getInfo();
			if (deviceInfo.platform !== "web") {
				//		await StatusBar.setBackgroundColor({ color: "#f59e0bb3" });
			}
		};
		setStatusBarLight();
	}, [themeSet]);

	return (
		<IonApp>
			<TabContex.Provider value={{ hidden, setHidden }}>
				<Mobile>
					<IonSplitPane when="md" contentId="menu-content">
						<IonReactRouter>
							<Menu menuRef={menuRef} />
							<MobileTabBar Routes={Routes} onMoreClicked={() => menuRef.current?.open()} />
						</IonReactRouter>
					</IonSplitPane>
				</Mobile>

				<Desktop>
					<IonReactRouter>
						<IonSplitPane when="md" contentId="menu-content">
							<Menu menuRef={menuRef} />
							{Routes}
						</IonSplitPane>
					</IonReactRouter>
				</Desktop>
			</TabContex.Provider>
		</IonApp>
	);
};
export default StartApp;
