import "./Avatar.scss";

import { IonAvatar, IonImg } from "@ionic/react";
import { VFC } from "react";

interface AvatarProps {
	imageUrl?: string;
	className?: string;
}

const Avatar: VFC<AvatarProps> = (props: AvatarProps) => {
	return (
		<IonAvatar className="avatar">
			{props.imageUrl == undefined || props.imageUrl == "" || props.imageUrl == null ? (
				<IonImg src="assets/images/avatar.png" />
			) : (
				<IonImg src={props.imageUrl} />
			)}
		</IonAvatar>
	);
};

export default Avatar;
