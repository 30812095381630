import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { newBaseList } from "model/BaseList";
import { Event } from "model/Event";
import { EventProgressType } from "model/events/EventProgressType";
import { defaultState } from "store/State";

import {
	fetchEventById,
	fetchEventContractById,
	fetchEventParticipant,
	fetchEventRegistrationById,
	fetchEvents,
	getContactPersons,
	getEventDocuments,
	reloadEvents,
	wirthdrawParticipation,
} from "./Event.thunks";

export const initialState = {
	...defaultState(newBaseList<Event>()),
	contactPersonIsFetching: false,
};

export type EventState = typeof initialState;

const slice = createSlice({
	name: "events",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(fetchEventParticipant.fulfilled), (state, action) => {
			if (state.value) {
				const oldItem = state.value.items.find((x) => x.id === action.payload.eventId)!;
				if (action.payload?.item) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.eventProgressType = action.payload.item.eventProgressType;
					state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
					state.value.items.push(oldItem);
				} else {
					const index = state.value.items.indexOf(oldItem);
					if (oldItem) {
						oldItem.eventProgressType = EventProgressType.NoMember;

						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(oldItem);
					}
				}
			}
			//state.value.items = state.value.items.sort((objA, objB) => new Date(objB.startTime).getTime() - new Date(objA.startTime).getTime());
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(fetchEventRegistrationById.fulfilled), (state, action) => {
				if (state.value && action.payload) {
					const oldItem = state.value.items.find((x) => x.id === action.payload.eventId)!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.eventRegistration = action.payload;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(oldItem);
					}
				} else if (state.value) {
					// arg0 is event, arg1 is person
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg[0])!;
					if (oldItem) {
						oldItem.eventProgressType = EventProgressType.NoMember;
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(getEventDocuments.fulfilled), (state, action) => {
				if (state.value) {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg[1])!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.documents = action.payload;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(oldItem);
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(getContactPersons.fulfilled), (state, action) => {
				if (state.value) {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg[1])!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.contactPersons = action.payload;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(oldItem);
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(fetchEventContractById.fulfilled), (state, action) => {
				if (state.value) {
					const oldItem = state.value.items.find((x) => x.id === action.payload.foreignKeyId)!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.contract = action.payload;
						oldItem.contractId = action.payload.id;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(oldItem);
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(fetchEventById.fulfilled), (state, action) => {
				if (state.value) {
					const oldItem = state.value.items.find((x) => x.id === action.payload.id)!;
					if (!oldItem) {
						action.payload.eventProgressType = EventProgressType.Unknown;
						state.value.items.push(action.payload);
					} else {
						const index = state.value.items.indexOf(oldItem);
						action.payload.documents = oldItem.documents;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(action.payload);
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(wirthdrawParticipation.fulfilled), (state, action) => {
				if (state.value) {
					console.log(action);
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg[0])!;
					if (oldItem) {
						oldItem.eventProgressType = EventProgressType.Unknown;
						oldItem.eventRegistration = undefined;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(oldItem);
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(getContactPersons.fulfilled), (state, action) => {
				if (state.value) {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg[1])!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.contactPersons = action.payload;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(oldItem);
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(getContactPersons.pending), (state, action) => {
				state.contactPersonIsFetching = true;
			});
		builder.addMatcher(isAnyOf(getContactPersons.fulfilled, getContactPersons.rejected), (state, action) => {
			state.contactPersonIsFetching = false;
		});
		builder.addMatcher(isAnyOf(fetchEvents.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(reloadEvents.fulfilled), (state, action) => {
				state.value = action.payload;
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(
				isAnyOf(
					fetchEventById.pending,
					reloadEvents.pending,
					fetchEventContractById.pending,
					fetchEvents.pending,
					fetchEventParticipant.pending,
					wirthdrawParticipation.pending,
				),
				(state, action) => {
					state.isFetching = false;
					state.isFetched = true;
				},
			),
			builder.addMatcher(
				isAnyOf(
					fetchEventById.rejected,
					reloadEvents.rejected,
					fetchEventContractById.rejected,
					fetchEvents.rejected,
					fetchEventParticipant.rejected,
					wirthdrawParticipation.rejected,
				),
				(state, action) => {
					state.isFetching = false;
					state.isFetched = true;
					state.error = action.error.message;
				},
			);
	},
});

export default slice.reducer;
