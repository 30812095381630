import { IonAvatar, IonChip, IonLabel } from "@ionic/react";
import { Creator } from "model/Creator";
import { VFC } from "react";
import { useSelector } from "react-redux";
import { getProfile } from "store/profile/Profile.selectors";

export interface Props {
	creator?: Creator;
}
const CreatorChip: VFC<Props> = ({ creator }) => {
	const user = useSelector(getProfile);
	return (
		<IonChip outline={true}>
			{creator?.avatarImageUrl ? (
				<IonAvatar>
					<img src={creator?.avatarImageUrl} />
				</IonAvatar>
			) : (
				<></>
			)}
			{creator?.id == user?.id ? (
				<IonLabel>
					{user.firstName} {user.lastName}
				</IonLabel>
			) : (
				<IonLabel>{creator?.name}</IonLabel>
			)}
		</IonChip>
	);
};
7;

export default CreatorChip;
