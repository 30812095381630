export interface Page {
	title: string;
	path: string;
	icon?: string;
	backTitle?: string;
	backLink?: string;
	subPages?: Page[];
	external: boolean;
}

export function newPage(
	title?: string,
	path?: string,
	icon?: string,
	backTitle?: string,
	backLink?: string,
	subPages?: Page[],
	external?: boolean,
): Page {
	return {
		title: title ?? "",
		path: path ?? "/",
		icon: icon,
		backTitle: backTitle,
		backLink: backLink,
		subPages: subPages,
		external: external ?? false,
	};
}
