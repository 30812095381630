import { IonButton, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonToolbar } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import DecisionAlert, { newButton } from "components/alert/DecisionAlert";
import InputItem from "components/form/InputItem";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { closeOutline, pencilOutline, trashOutline } from "ionicons/icons";
import { ConfectionInformation } from "model/ConfectionInformation";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { newUpsertModel } from "model/UpsertModel";
import { useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import {
	addConfectionInformation,
	deleteConfectionInformation,
	updateConfectionInformation,
} from "store/profile/confectionInformation/ConfectionInformation.thunks";
import { getProfile } from "store/profile/Profile.selectors";

import { PrivacyVerfiyApprovement } from "components/privacy/PrivacyVerfiyApprovement";
import ShareTenantSelectModal from "components/shareTenant/ShareTenantSelectModal";

export interface Props {
	isOpen: boolean;
	editItem: ConfectionInformation;
	onCancel(): void;
}

const EditConfectionInformationModal: VFC<Props> = ({ isOpen, editItem: confectionInformation, onCancel }) => {
	const [checked, setChecked] = useState(false);
	const modal = useRef<HTMLIonModalElement>(null);
	const modalTrigger = `${confectionInformation.id}_select_share_tenant`;
	const dispatch = useAppDispatch();
	const profile = useSelector(getProfile);
	const [savedEditItem, setSavedEditItem] = useState(confectionInformation);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
	const [sharedTenants, setSharedTenants] = useState(confectionInformation.scopedSharingTenants ?? new Array<ScopedSharingTenant>());

	useEffect(() => {
		setSavedEditItem(JSON.parse(JSON.stringify(confectionInformation)));
		editItem = JSON.parse(JSON.stringify(confectionInformation));
		setSharedTenants(confectionInformation.scopedSharingTenants ?? new Array<ScopedSharingTenant>());
		setChecked(false);
	}, [isOpen]);

	let editItem = JSON.parse(JSON.stringify(savedEditItem)) as ConfectionInformation;

	function onConfirmDelete() {
		const upsertItem = { ...confectionInformation };
		dispatch<any>(deleteConfectionInformation(newUpsertModel<ConfectionInformation>(profile.id, upsertItem)));
		closeModal();
	}
	function onEditModalDone(itemToUpdate: ConfectionInformation) {
		const upsertItem = { ...itemToUpdate };
		upsertItem.scopedSharingTenants = sharedTenants;
		if (itemToUpdate.id) {
			dispatch<any>(updateConfectionInformation(newUpsertModel<ConfectionInformation>(profile.id, upsertItem)));
		} else {
			dispatch<any>(addConfectionInformation(newUpsertModel<ConfectionInformation>(profile.id, upsertItem)));
		}
		closeModal();
	}
	function onDismissDelete() {
		setIsDeleteAlertOpen(false);
	}
	function onShare(tenants: ScopedSharingTenant[]) {
		setSharedTenants(tenants);
		editItem.scopedSharingTenants = tenants;
		setSavedEditItem(editItem);
	}
	function onShareCancel() {
		console.log(editItem.scopedSharingTenants);
	}
	const deleteButtons = [
		newButton(i18n._(t`Cancel`), "cancel"),
		newButton(i18n._(t`Delete`), "delete", "danger", () => {
			onConfirmDelete();
		}),
	];
	function closeModal() {
		onCancel();
		setChecked(false);
	}
	return (
		<>
			<IonModal key={`${confectionInformation?.id}_confectionInformation_modal`} isOpen={isOpen}>
				<IonModal trigger={modalTrigger} ref={modal}>
					<ShareTenantSelectModal
						creatorId={editItem.creator?.id}
						scopedSharingTenants={sharedTenants}
						done={onShare}
						cancel={onShareCancel}
					/>
				</IonModal>
				<DecisionAlert
					isOpen={isDeleteAlertOpen}
					header={i18n._(t`Delete confectioninformation`)}
					message={i18n._(t`Are you sure you want to delete`)}
					onDismiss={onDismissDelete}
					buttons={deleteButtons}
				/>
				<IonHeader color="amber-50">
					<div className="ion-text-center">
						<IonToolbar color="amber-300">
							<IonButton
								fill="clear"
								slot="start"
								onClick={() => {
									closeModal();
								}}
							>
								<IonIcon color="medium" icon={closeOutline}></IonIcon>
							</IonButton>
							{editItem.id ? (
								<IonLabel slot="start">
									<h2>
										<Trans>Edit ConfectionInformation</Trans>
									</h2>
								</IonLabel>
							) : (
								<IonLabel slot="start">
									<h2>
										<Trans>Create ConfectionInformation</Trans>
									</h2>
								</IonLabel>
							)}

							{editItem.id && editItem.creator?.label != "association" ? (
								<>
									<IonButton slot="end" fill="clear" onClick={() => setIsDeleteAlertOpen(true)}>
										<IonIcon color="medium" icon={trashOutline}></IonIcon>
									</IonButton>
									{/*
									<IonButton slot="end" fill="clear" onClick={(e) => selectRefShare.current?.open(e.nativeEvent)}>
										<IonIcon color="medium" icon={lockOpenOutline}></IonIcon>
									</IonButton>
										*/}
								</>
							) : (
								<></>
							)}
						</IonToolbar>
					</div>
				</IonHeader>
				<IonContent>
					<InputItem
						name={i18n._(t`ClothingPart`)}
						placeholder={i18n._(t`ClothingPart`)}
						value={editItem.clothingPartType}
						onChange={(value) => {
							editItem.clothingPartType = value;
							setSavedEditItem(editItem);
						}}
					/>
					<InputItem
						name={i18n._(t`Confectionsize`)}
						placeholder={i18n._(t`Confectionsize`)}
						value={editItem.confectionSize}
						onChange={(value) => {
							editItem.confectionSize = value;
							setSavedEditItem(editItem);
						}}
					/>
					{editItem.id ? (
						<IonItem detail={true} detailIcon={pencilOutline} lines="none" id={modalTrigger}>
							<div>
								<div className="ion-margin-bottom">
									<IonLabel className="ion-margin" position="stacked">
										Geteilt mit
									</IonLabel>
								</div>

								{sharedTenants.length ?? 0 > 1 ? (
									<></>
								) : (
									<IonChip outline={false}>
										<IonLabel>Keine Freigaben</IonLabel>
									</IonChip>
								)}
								{sharedTenants.map((tenant, i) => {
									return <ShareTenantChip scopedSharingTenant={tenant} />;
								})}
							</div>
						</IonItem>
					) : (
						<></>
					)}
				</IonContent>
				<PrivacyVerfiyApprovement checked={checked} setChecked={setChecked} />
				<IonButton disabled={!checked} expand="block" size="default" onClick={() => onEditModalDone(editItem)}>
					<IonLabel>Speichern</IonLabel>
				</IonButton>
			</IonModal>
		</>
	);
};

export default EditConfectionInformationModal;
