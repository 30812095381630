import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { License } from "model/License";
import { VFC } from "react";
import { useSelector } from "react-redux";
import { getAllLicenses } from "store/license/License.selectors";

import InfoBlock from "./InfoBlock/InfoBlock";

const MyLicences: VFC = () => {
	const { i18n } = useLingui();

	const licenses = useSelector(getAllLicenses)?.items.slice(0, 5);

	const columns = {
		name: i18n._(t`name`),
	};

	const rows = licenses?.map((license: License) => {
		return { id: license.id as string, name: license.name as string };
	});

	return (
		<InfoBlock
			id="licenses"
			title={i18n._(t`profile.myLicences`)}
			columns={columns}
			rows={rows}
			dataKey={"licenses"}
			link={{
				link: "licenses",
				title: i18n._(t`profile.myLicenses.all`),
			}}
		/>
	);
};

export default MyLicences;
