import { Toast } from "@capacitor/toast";
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCheckbox,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonItem,
	IonLoading,
	IonPage,
	IonRow,
	useIonAlert,
	useIonLoading,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { AccordionComponent, AccordionItemsDirective, AccordionItemDirective } from "@syncfusion/ej2-react-navigations";
import { useAppDispatch } from "app/hooks";
import { useCapacitorOAuth2 } from "auth/CapacitorOAuth2Hook";
import axios from "axios";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { newBaseListQuery } from "model/BaseListQuery";
import { IdTokenClaims } from "model/IdTokenClaims";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useContext, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";
import { fetchProfile, updateIdToken } from "store/profile/Profile.thunks";
import { reloadValidationModels } from "store/profileValidation/ProfileValidation.thunks";

export const ProfileValidationProcessAGBPage: VFC = () => {
	const capacitorAuth = useCapacitorOAuth2();
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();
	const profile = useSelector(getProfile);
	const [presentAlert] = useIonAlert();
	const location = useLocation();
	const history = useHistory();
	const [present, dismiss] = useIonLoading();
	const [showLoading, setShowLoading] = useState(false);
	const [check1, setCheck1] = useState(false);
	const [check2, setCheck2] = useState(false);
	const tabContext = useContext(TabContex);
	const auth = useCapacitorOAuth2();
	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);
	useEffect(() => {
		dispatch<any>(reloadValidationModels(newBaseListTenantQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	const finish = async () => {
		try {
			setShowLoading(true);

			const authResult = await auth.refreshTokenAsync();
			const jwt = capacitorAuth.parseJwt(authResult.id_token);
			console.log(jwt);
			const personId = jwt.person_id;

			const res = await axios.post(
				`${process.env.REACT_APP_API_BASE_URL}/api/person/setupcompleted`,
				{
					PersonId: personId,
					SetupCompleted: true,
				},
				{
					headers: { userId: personId },
				},
			);
			if (res.status == 200) {
				console.log(res);
				await Toast.show({
					text: "Sie haben ihren Account erfolgreich eingerichtet.",
					duration: "short",
					position: "center",
				});

				await Toast.show({
					text: "Sie werden in kürze weitergeleitet.",
					duration: "long",
					position: "center",
				});

				await Toast.show({
					text: "Alle Änderungen wurden erfolgreich gespeichert. Sie werden automatisch weitergeleitet.",
					duration: "long",
					position: "center",
				});
				setTimeout(async () => {
					await auth.logOutAsync();
					window.location.reload(); //force logout check
				}, 3000);

				//	const authResult = await auth.logOutAsync();

				//		dispatch<any>(updateIdToken(capacitorAuth.parseJwt(authResult.id_token)));
				//		dispatch<any>(fetchProfile(capacitorAuth.parseJwt(authResult.id_token).person_Id));

				//Timeout für 5 Sekunde
				//setTimeout(() => window.location.reload(), 5000);
			}
		} catch (error) {
			presentAlert({
				header: "Fehler",
				message: "Leider ist ein Fehler bei Ihrer Anmeldung aufgetreten.",
				buttons: ["OK"],
			});
			setShowLoading(false);
		} finally {
			setTimeout(() => setShowLoading(false), 5000);
		}
	};

	return (
		<IonPage>
			<IonContent>
				<IonLoading isOpen={showLoading} message={"Ihr Account wird eingerichtet. Sie werden automatisch abgemeldet."} />
				<ProfileValidationHeader title="Benutzerkonto" progress={9} />
				<div className="ion-padding">
					<IonGrid>
						<IonRow>
							<IonCol></IonCol>
							<IonCol size="8" sizeXs="12" sizeSm="10">
								<IonFooter>
									<AccordionComponent>
										<AccordionItemsDirective>
											<AccordionItemDirective
												expanded={true}
												header={i18n._(t`Datenschutzinformationen`)}
												content={i18n._(
													t`Die App befolgt die Datenschutzbestimmungen und verwendet die Daten nur für die gewünschten Funktionen. Die App speichert die Daten auf dem Server und verwendet sie nur für die gewünschten Funktionen. Die App kann Daten übertragen, um die gewünschten Funktionen zu ermöglichen. Die App verwendet Sicherheitsmaßnahmen, um die Daten zu schützen. Der Nutzer hat das Recht, die gespeicherten Daten zu sehen und zu ändern. Der Nutzer kann die gespeicherten Daten jederzeit löschen. Für Fragen oder Probleme kann der Nutzer den Support kontaktieren.`,
												)}
											/>
										</AccordionItemsDirective>
									</AccordionComponent>
								</IonFooter>
							</IonCol>
							<IonCol></IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>

			<IonFooter>
				<IonGrid>
					<IonRow>
						<IonCol></IonCol>
						<IonCol size="8" sizeXs="12" sizeSm="10">
							<IonCard>
								<IonCardHeader>
									<IonCardSubtitle>Klicken Sie auf Abschließen um ihren Account zu vervollständigen</IonCardSubtitle>
								</IonCardHeader>

								<IonCardContent>
									<IonButton onClick={() => finish()} fill="solid" expand="full">
										Abschließen
									</IonButton>
								</IonCardContent>
							</IonCard>
						</IonCol>
						<IonCol></IonCol>
					</IonRow>
				</IonGrid>
			</IonFooter>
		</IonPage>
	);
};
