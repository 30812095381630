import { BankAccount } from "model/BankAccount";
import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";

import { RootState } from "../..";
import { getScope, getSelectedTenant } from "../Profile.selectors";

export const getAllBankAccounts = (state: RootState): BaseList<BankAccount> => state.bankAccount.value || newBaseList<BankAccount>();
export const getIsFetching = (state: RootState): boolean => state.bankAccount.isFetching;

export const getBankAccounts = createSelector(getAllBankAccounts, getSelectedTenant, (bankAccounts: BaseList<BankAccount>, tenant: Tenant | null) => {
	return tenant
		? filterBaseListItems(bankAccounts, (item) => item.scopedSharingTenants?.some((t) => t.id === tenant.id) || item.creator?.id == tenant.id)
		: bankAccounts;
});
export const getBankAccount = createSelector(
	getAllBankAccounts,
	getSelectedTenant,
	getScope,
	(bankAccounts: BaseList<BankAccount>, tenant: Tenant | null, scopeType?: ScopeType): BankAccount | undefined => {
		let result;
		switch (scopeType) {
			case ScopeType.default:
				if (tenant) {
					result = bankAccounts.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isDefault);
				} else {
					result = bankAccounts.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isDefault === true)[0]);
				}
				break;
			case ScopeType.intern:
				if (tenant) {
					result = bankAccounts.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isIntern);
				} else {
					result = bankAccounts.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isIntern === true)[0]);
				}
				break;
			case ScopeType.extern:
				if (tenant) {
					result = bankAccounts.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isExtern);
				} else {
					result = bankAccounts.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isExtern === true)[0]);
				}
				break;
		}

		return result !== undefined ? result : bankAccounts.items.length ? bankAccounts.items[0] : undefined;
	},
);

export const makeGetBankAccounts = createSelector(getAllBankAccounts, getSelectedTenant, (bankAccounts, tenant) => {
	if (tenant) {
		bankAccounts.items = bankAccounts.items?.filter((a) => a.scopedSharingTenants?.map((t) => t.id).includes(tenant?.id ?? ""));
	}

	return bankAccounts;
});
