import "./style.scss";
import "../style.scss";

import { IonAvatar, IonCard, IonImg, IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import * as React from "react";
import { useSelector } from "react-redux";
import { getAvatarImageUrl, getProfile } from "store/profile/Profile.selectors";

interface IEventPricePartProps {
	event: Event;
	setEventRegistration: (eventRegistration: EventRegistration) => void;

	eventRegistration: EventRegistration;
}

const EventPricePart: React.FunctionComponent<IEventPricePartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const profile = useSelector(getProfile);
	const avatarImageUrl = useSelector(getAvatarImageUrl);

	return (
		<>
			<IonCard>
				<IonItemDivider mode="ios">Teilnehmer:</IonItemDivider>
				<IonItem lines="none" className="userCard">
					<IonAvatar slot="start">
						<IonImg src={avatarImageUrl == null ? "https://ionicframework.com/docs/img/demos/avatar.svg" : avatarImageUrl}></IonImg>
					</IonAvatar>
					<IonLabel>
						{profile?.firstName} {profile?.lastName}
						<br></br>
					</IonLabel>
				</IonItem>
			</IonCard>
		</>
	);
};

export default EventPricePart;
