import { ParseJwt, ParseJwtAndGetPersonId } from 'auth/PCKEOptionsHelper';
import { TokenCodeChallengeResult } from 'auth/types/TokenCodeChallengeResult';
import axios from "axios";
import { Approval } from 'model/Approval';
import { IdTokenClaims } from 'model/IdTokenClaims';
import Token from "model/Token";

class ApprovalService {
	async approveAsync(token: TokenCodeChallengeResult, acceptedApprovalVersionIds: Array<string>): Promise<any> {

		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/approval/accept`,
			{ acceptedApprovalVersionIds: acceptedApprovalVersionIds },
			{ headers: { authorization: `Bearer ${token.access_token}`, oid: ParseJwtAndGetPersonId(token.id_token), userId: ParseJwtAndGetPersonId(token.id_token) } },
		);
		return res;
	}

	async getMissingApprovalsAsync(token: TokenCodeChallengeResult): Promise<any> {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_ME_URL}/api/me/approval/missing`,
				{
					headers: {
						authorization: `Bearer ${token.access_token}`,
						userId: ParseJwtAndGetPersonId(token.id_token), oid: ParseJwtAndGetPersonId(token.id_token)
					}
				},
			);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
}
const approvalService = new ApprovalService();
export default approvalService;
