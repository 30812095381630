import { ScopeType } from 'model/ScopeType';

export function scopeTypeToQuery(scopeType?: ScopeType): string | undefined {
	if (!scopeType) {
		return "";
	}
	if (scopeType == ScopeType.default) {
		return "";
	}
	const scopeTypeQueryParameter = scopeType == ScopeType.extern ? "public" : "intern";

	return "&scopeType=" + scopeTypeQueryParameter;
}
