import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

export enum CommunicationUseCaseType {
	invite = "invite",
	notify = "notify",
	setpassword = "setpassword",
	participate = "participate",
	participateinfo = "participateinfo",
	waiting = "waiting",
	waitinginfo = "waitinginfo",
	confirmationofparticipation = "confirmationofparticipation",
}

export function communicationUseCaseString(useCase?: CommunicationUseCaseType): string | undefined {
	if (!useCase) {
		return "";
	}

	switch (useCase) {
		case CommunicationUseCaseType.invite:
			return i18n._(t`Invite`);
		case CommunicationUseCaseType.notify:
			return i18n._(t`Notify`);
		case CommunicationUseCaseType.setpassword:
			return i18n._(t`SetPassword`);
		case CommunicationUseCaseType.participate:
			return i18n._(t`Participate`);
		case CommunicationUseCaseType.participateinfo:
			return i18n._(t`ParticipateInfo`);
		case CommunicationUseCaseType.waiting:
			return i18n._(t`Waiting`);
		case CommunicationUseCaseType.waitinginfo:
			return i18n._(t`WaitingInfo`);
		case CommunicationUseCaseType.confirmationofparticipation:
			return i18n._(t`ConfirmationOfParticipation`);
		default:
			return useCase;
	}
}
