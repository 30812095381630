import "./DashboardPage.scss";
import { IonCol, IonGrid, IonProgressBar, IonRow } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import LastMessages from "components/dashboard/lastMessages/LastMessages";
import LatestBlog from "components/dashboard/latestBlog/LatestBlog";
import MiniCalendar from "components/dashboard/miniCalendar/MiniCalendar";
import DashboardHeader from "components/dashboardHeader/DashboardHeader";
import Page from "components/page/Page";
import { FC, memo, VFC } from "react";
import { useSelector } from "react-redux";
import { getAvatarImageUrl, getDetailHeaderImageUrl, getIsFetching, getProfile } from "store/profile/Profile.selectors";
import { useMediaQuery } from "react-responsive";
import { CustomProgressBar } from "components/infinite/CustomProgressBar";

const DashboardPage: FC = () => {
	console.log("render DashboardPage");
	const profile = useSelector(getProfile);
	const avatarImageUrl = useSelector(getAvatarImageUrl);
	const headerImageUrl = useSelector(getDetailHeaderImageUrl);
	const isFetching = useSelector(getIsFetching);

	return (
		<Page title={i18n._(t`Dashboard`)} logo={useMediaQuery({ maxWidth: 819 })}>
			<CustomProgressBar isFetching={isFetching} />
			<DashboardHeader profile={profile} avatarImageUrl={avatarImageUrl} headerImageUrl={headerImageUrl} />
			<IonGrid>
				<IonRow>
					<IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="4">
						<MiniCalendar />
					</IonCol>

					<IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="4">
						<LatestBlog />
					</IonCol>

					<IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="4">
						<LastMessages />
					</IonCol>
				</IonRow>
			</IonGrid>
		</Page>
	);
};

export default memo(DashboardPage);
