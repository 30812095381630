import { IonAlert } from "@ionic/react";

export interface Button {
	text: string;
	role?: string;
	cssClass?: string;
	handler?(): void;
}

export function newButton(text: string, role?: string, cssClass?: string, handler?: () => void): Button {
	return {
		text: text,
		role: role,
		cssClass: cssClass,
		handler: handler,
	};
}

export interface Props {
	isOpen: boolean;
	header: string;
	subHeader?: string;
	message: string;
	onDismiss(): void;
	buttons: Array<Button>;
}

const DecisionAlert = (props: Props) => {
	return (
		<IonAlert
			isOpen={props.isOpen}
			onDidDismiss={props.onDismiss}
			header={props.header}
			subHeader={props.subHeader}
			message={props.message}
			buttons={props.buttons}
		/>
	);
};

export default DecisionAlert;
