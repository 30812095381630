import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { Mail } from "model/Mail";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";
import { addMail, deleteMail, deleteShareMailScoped as deleteShareMailScoped, fetchMails, reloadMails, shareMail, shareMailScoped, updateMail } from "./Mail.thunks";
import { findAndUpdateScopeSharedTenant } from 'helper/SliceHelper';

export const initialState = {
	...defaultState<BaseList<Mail>>(newBaseList<Mail>()),

	scope: ScopeType.extern,
	selectedTenant: null as unknown as Tenant,
	toShare: null as unknown as Mail | undefined,
	isShareInternFetching: false,
	isShareExternFetching: false,
};

export type MailState = typeof initialState;

const slice = createSlice({
	name: "mail",
	initialState,
	reducers: {
		cancelShareMail(state) {
			state.toShare = undefined;
			state.isFetching = false;
			state.isFetched = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(addMail.fulfilled, (state, action) => {
			state.value.items.push(action.payload);
			state.isFetching = false;
			state.isFetched = true;
			state.toShare = action.payload;
		});
		builder.addCase(deleteMail.fulfilled, (state, action) => {
			state.value.items = state.value.items.filter((x) => x.id != action.payload);
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addCase(reloadMails.pending, (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addCase(reloadMails.fulfilled, (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addCase(deleteShareMailScoped.fulfilled, (state, action) => {
			const oldItem = state.value.items.find((x) => x.id === action.meta.arg.entityId)!;
			const indexOfOldItem = state.value.items.indexOf(oldItem);
			if (oldItem == null) {
				return;
			}
			oldItem.scopedSharingTenants = findAndUpdateScopeSharedTenant(oldItem.scopedSharingTenants, action.meta.arg.tenantId, action.meta.arg.scopeType, false);
			state.value.items[indexOfOldItem] = oldItem;
			action.meta.arg.scopeType == ScopeType.extern ? (state.isShareExternFetching = false) : (state.isShareInternFetching = false);
		});
		builder.addCase(shareMailScoped.fulfilled, (state, action) => {
			const oldItem = state.value.items.find((x) => x.id === action.meta.arg.entityId)!;
			const indexOfOldItem = state.value.items.indexOf(oldItem);
			if (oldItem == null) {
				return;
			}
			oldItem.scopedSharingTenants = findAndUpdateScopeSharedTenant(oldItem.scopedSharingTenants, action.meta.arg.tenantId, action.meta.arg.scopeType, true);
			state.value.items[indexOfOldItem] = oldItem;
			action.meta.arg.scopeType == ScopeType.extern ? (state.isShareExternFetching = false) : (state.isShareInternFetching = false);
		});
		builder.addCase(shareMailScoped.rejected, (state, action) => {
			const scope = action.meta.arg.scopeType;
			scope == ScopeType.extern ? (state.isShareExternFetching = false) : (state.isShareInternFetching = false);
		});
		builder.addCase(shareMailScoped.pending, (state, action) => {
			const scope = action.meta.arg.scopeType;
			scope == ScopeType.extern ? (state.isShareExternFetching = true) : (state.isShareInternFetching = true);
		});

		builder.addMatcher(isAnyOf(updateMail.fulfilled, shareMail.fulfilled), (state, action) => {
			if (!action.payload) {
				return;
			}
			const oldMail = state.value.items.find((x) => x.id === action.payload.id)!;
			const index = state.value.items.indexOf(oldMail);
			action.payload.isBusy = false;
			state.value.items[index] = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(fetchMails.fulfilled), (state, action) => {
				if (!state.value) {
					state.value = action.payload;
				} else {
					const items = [...state.value.items, ...action.payload.items];
					state.value = { ...action.payload, items };
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(updateMail.pending, addMail.pending, deleteMail.pending, shareMail.pending), (state, action) => {
				const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
				if (oldItem) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.isBusy = true;
					state.value.items[index] = oldItem;
				}

				state.isFetching = true;
				state.isFetched = false;
			}),
			builder.addMatcher(isAnyOf(updateMail.rejected, deleteMail.rejected, shareMail.rejected), (state, action) => {
				const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
				if (oldItem) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.isBusy = false;
					state.value.items[index] = oldItem;
				}

				state.isFetching = false;
				state.isFetched = false;
				state.error = action.error.message;
			});
	},
});
export const { cancelShareMail } = slice.actions;
export default slice.reducer;
