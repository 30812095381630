import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { VFC } from "react";

interface Props {
	name: string;
	placeholder?: string;
	value: string | undefined;
	onChange: (value: string) => void;
	disabled?: boolean;
}

const InputItem: VFC<Props> = ({ name, placeholder, value, onChange, disabled }) => {
	return (
		<IonItem>
			<IonLabel position="stacked">{name}</IonLabel>
			<IonInput
				disabled={disabled ?? false}
				value={value}
				placeholder={placeholder}
				onIonChange={(e) => onChange(e.detail.value || "")}
			></IonInput>
		</IonItem>
	);
};

export default InputItem;
