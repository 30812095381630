import { IonAvatar, IonButton, IonChip, IonIcon, IonLabel } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { Tag } from "model/Tag";
import { FC, VFC } from "react";

interface Props {
	tag: Tag;
}

const TenantChip: FC<Props> = ({ tag }) => {
	return (
		<IonChip
			style={{ background: tag.backgroundColor, color: tag.textColor, borderColor: tag.borderColor }}
			outline={true}
			className="ion-margin-top"
		>
			<IonLabel>{tag.name}</IonLabel>
		</IonChip>
	);
};

export default TenantChip;
