import { useAppDispatch } from "app/hooks";
import Avatar from "components/avatar/Avatar";
import IUpdatePersonAvatarInTenantDto from "model/IUpdatePersonAvatarInTenantDto";
import { useCallback, VFC } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import profileService from "services/ProfileService";
import { getSelectedTenant } from "store/profile/Profile.selectors";
import { updateProfileAvatar, updateProfileTenantAvatar } from "store/profile/Profile.thunks";

import css from "./PhotoUploader.module.scss";
import { ScopeType } from "model/ScopeType";
import IUpdatePersonAvatarDto from "model/IUpdatePersonAvatarDto";

interface PhotoUploaderProps {
	imageUrl?: string;
	className?: string;
	styles?: Record<string, any>;
}

const PhotoUploader: VFC<PhotoUploaderProps> = (props: PhotoUploaderProps) => {
	const dispatch = useAppDispatch();
	const selectedTenant = useSelector(getSelectedTenant);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		console.log(acceptedFiles);
		if (selectedTenant == null) {
			const data = { file: acceptedFiles[0], scopeType: ScopeType.default } as IUpdatePersonAvatarDto;
			dispatch<any>(updateProfileAvatar(data));
		} else {
			const data = { tenantId: selectedTenant.id, file: acceptedFiles[0], scopeType: ScopeType.default } as IUpdatePersonAvatarInTenantDto;
			dispatch<any>(updateProfileTenantAvatar(data));
		}
		/*
		const file = acceptedFiles[0];
		profileService.updatePhoto(file);
*/
	}, []);

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: {
			"image/png": [".png", ".jpg", ".jpeg"],
		},
	});

	return (
		<div key={`${props.imageUrl}_uploaderDiv`} {...getRootProps()} className={css["uploader"]}>
			<input {...getInputProps()} />
			<Avatar
				imageUrl={props.imageUrl}
				className={`${props.styles?.avatar.avatar} ${isDragActive ? props.styles?.avatar.avatarDragActive : ""}`}
			/>
		</div>
	);
};

export default PhotoUploader;
