import "./DateItem.scss";

import { IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Ref, useRef, VFC } from "react";

interface Props {
	name: string;
	placeholder: string;
	value: string | undefined;
	onChange: (value: string) => void;
}

const DateItem: VFC<Props> = ({ name, placeholder, value, onChange }) => {
	const dateTimeRef = useRef() as Ref<HTMLIonDatetimeElement> | undefined;
	const datetime = useRef<null | HTMLIonDatetimeElement>(null);

	return (
		<>
			<IonModal isOpen={false} keepContentsMounted={true}>
				<IonDatetime
					ref={datetime}
					locale="de-DE"
					showDefaultButtons={true}
					doneText={i18n._(t`Done`)}
					cancelText={i18n._(t`Cancel`)}
					color="primary"
					value={value as string}
					id="datetime"
					presentation="date"
					multiple={false}
					firstDayOfWeek={1}
					onIonChange={(e) => {
						const dateTimeString = (e.detail.value as string).substring(0, 19) + "Z";
						onChange(new Date(dateTimeString).toISOString());
					}}
				/>
			</IonModal>
			<IonItem>
				<IonLabel position="stacked">{name}&nbsp;</IonLabel>
				<IonDatetimeButton datetime="datetime" />
			</IonItem>
		</>
	);
};

export default DateItem;
