import "./AccountPage.scss";

import { Toast } from "@capacitor/toast";
import { IonApp, IonGrid, IonPage, IonTitle } from "@ionic/react";
import { useLingui } from "@lingui/react";

import Page from "components/page/Page";
import { FC } from "react";

const AccountPage: FC = () => {
	const { i18n } = useLingui();

	async function showToast() {
		await Toast.show({
			text: "Es wurde eine Email an Sie versendet.",
			position: "top",
			duration: "long",
		});
	}

	return <></>;
};

export default AccountPage;
