import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { License } from "model/License";
import { Tenant } from "model/Tenant";

class LicenseService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<License>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/license`, {
			params,
			headers: { userId: userId },
		});
		return res.data;
	}
}
const licenseService = new LicenseService();
export default licenseService;
