export function currencyString(number?: number): string | undefined {
	if (!number || isNaN(number)) {
		return "";
	}

	return `${number.toLocaleString("de", {
		style: "currency",
		currency: "EUR",
	})}`;
}
