import { BaseList, newBaseList } from "model/BaseList";
import BlogEntry from "model/BlogEntry";
import { createSelector } from "reselect";

import { RootState } from "..";

export const getAllBlogEntries = (state: RootState): BaseList<BlogEntry> => state.blogEntry.value || newBaseList<BlogEntry>();
export const isBlogEntryBusy = (state: RootState): boolean => state.blogEntry?.isFetching;

export const getBlogEntry = (id: string) => createSelector(getAllBlogEntries, (list) => list?.items.find((entry) => entry.id === id));
export const getLatestBlogEntry = createSelector(getAllBlogEntries, (list) => list?.items[0]);
