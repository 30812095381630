import { IonItem, IonItemSliding, IonLabel } from "@ionic/react";
import { dateTimeString } from "helper/DateHelper";
import { Event } from "model/Event";
import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { Subtitle } from "../text/subtitle/Subtitle";
import { Title } from "../text/title/Title";

export interface Props extends RouteComponentProps {
	event: Event;
}
const EventTeaser: FC<Props> = (props) => {
	return (
		<IonItemSliding className={"type-" + props.event.eventType?.toString().toLowerCase()}>
			<IonItem color="amber-50" routerLink={`events/${props.event.id}`}>
				<IonLabel>
					<Title text={props.event.name} />
					<Subtitle
						text={
							props.event.startTime && props.event.endTime
								? dateTimeString(new Date(props.event.startTime)) + " - " + dateTimeString(new Date(props.event.endTime))
								: undefined
						}
					/>
				</IonLabel>
			</IonItem>
		</IonItemSliding>
	);
};
export default withRouter(EventTeaser);
