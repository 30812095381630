import "./style.scss";

import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCheckbox,
	IonItem,
	IonNote,
	IonText,
	IonTitle,
} from "@ionic/react";
import { TabContex } from "context/TabContext";
import { Approval } from "model/Approval";
import { useContext, useEffect } from "react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

interface PrivacyVerfiyApprovementProps {
	checked: boolean;
	setChecked: (checked: boolean) => void;
}
export const PrivacyVerfiyEventApprovement = ({ checked, setChecked }: PrivacyVerfiyApprovementProps) => {
	return (
		<>
			<IonCard>
				<IonItem>
					Nutzungsbedingungen zur Verarbeitung personenbezogener Daten
					<IonTitle> </IonTitle>
				</IonItem>
				<IonItem>
					<IonCheckbox checked={checked} onIonChange={(e) => setChecked(e.detail.checked)} slot="start" />
					<IonCardContent>
						<IonNote>
							Um an der Veranstaltung teilzunehmen, werden dem Verein die für die Veranstaltung erforderlichen Daten freigegeben. Wo
							welche Daten freigegeben wurden kannst du jederzeit in deinem Profil sehen.
						</IonNote>
					</IonCardContent>
				</IonItem>
			</IonCard>
		</>
	);
};
