import "./Menu.scss";

import {
	IonContent,
	IonHeader,
	IonImg,
	IonItem,
	IonItemDivider,
	IonItemGroup,
	IonLabel,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonNote,
	IonRouterLink,
	IonToolbar,
} from "@ionic/react";
import { Page } from "model/Page";
import { FC, Ref, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getIdTokenState, getSelectedTenant } from "store/profile/Profile.selectors";

import MenuItem from "./MenuItem";
import { topPages, memberShipPages, listPages, bottomPages, profilePages } from "./MenuElements";

interface Props {
	menuRef: Ref<HTMLIonMenuElement> | null;
}

function renderlistItems(pages: Page[]) {
	const rowLen = pages.length;

	return pages
		.filter((route) => !!route.path)

		.map((p) => (
			<IonMenuToggle key={p.title} auto-hide="false">
				<MenuItem page={p} isSubPage={false} />
			</IonMenuToggle>
		));
}

const Menu: FC<Props> = ({ menuRef }, ref: Ref<HTMLIonSelectElement> | undefined) => {
	const idToken = useSelector(getIdTokenState);

	const location = useLocation();
	const history = useHistory();

	// Privacy and Setup Check
	// If UserToken does not have privacy accepted, redirect to privacy page
	// If UserToken does not have setup completed, redirect to setup page
	useEffect(() => {
		if (!location) return;
		if (!idToken) return;

		// If UserToken does not have setup completed, redirect to setup page
		if (!idToken?.person_setupcompleted) {
			if (!location.pathname.startsWith("/validation", 0)) {
				history.push("/validation/profile/system/info");
			}
			return;
		}
		// If UserToken does not have privacy accepted, redirect to privacy page
		if (!idToken?.person_privacyaccepted) {
			if (!location.pathname.startsWith("/privacy", 0)) {
				//		history.push("/privacy");
			}
			return;
		}
	}, [idToken]);
	if (idToken && location) {
		//Disable Menu if no privacy is set in
		if (!idToken?.person_privacyaccepted) {
			if (!location.pathname.startsWith("/privacy", 0)) {
				//history.push("/privacy");
			}
			return <></>;
		}

		//Disable menu if not setup completed
		if (!idToken?.person_setupcompleted) {
			if (!location.pathname.startsWith("/validation", 0)) {
				history.push("/validation/profile/system/info");
			}
			return <></>;
		}
	}
	if (!idToken) {
		return <></>;
	}
	return (
		<IonMenu color="amber-50" ref={menuRef} type="overlay" contentId="menu-content" menuId="first">
			<IonContent className="menuContent" color="amber-50">
				<div className="topDiv">
					<IonHeader color="amber-50" className="ion-no-border" mode="md" translucent={true}>
						<IonToolbar color="amber-50">
							<IonItem color="amber-50" className="topItem  ion-padding-end ion-margin-bottom" lines="none">
								<IonImg src={"assets/icon/logo_quer.png"} />
							</IonItem>
						</IonToolbar>
					</IonHeader>

					<div className="divTenantSelect">
						<IonListHeader>
							<IonNote>Allgemein</IonNote>
						</IonListHeader>
						<IonItemGroup>{renderlistItems(topPages)}</IonItemGroup>
						<IonListHeader className="divider2">
							<IonNote>Persönliches</IonNote>
						</IonListHeader>
						<IonItemGroup>{renderlistItems(profilePages)}</IonItemGroup>
						<IonListHeader className="divider2">
							<IonNote>Mitgliedschaften</IonNote>
						</IonListHeader>
						<IonItemGroup>{renderlistItems(memberShipPages)}</IonItemGroup>
						<IonListHeader className="divider2">
							<IonNote>Auszeichnungen</IonNote>
						</IonListHeader>
						<IonItemGroup>{renderlistItems(listPages)}</IonItemGroup>
					</div>
					<div />
				</div>

				<IonItemGroup>
					<IonListHeader className="divider2">
						<IonNote>Sonstiges</IonNote>
					</IonListHeader>
					{renderlistItems(bottomPages)}
				</IonItemGroup>
				<div />
				<IonItemDivider color="amber-50" className="divider" mode="md" />

				<IonRouterLink target="_blank" href="https://www.sportcloud.de/" className="inprintContainer" color="medium">
					<IonLabel className="inprintTextPrivacy">Impressum</IonLabel>
					<br />
					<IonLabel>Datenschutz</IonLabel>
				</IonRouterLink>
			</IonContent>
		</IonMenu>
	);
};
export default Menu;
