import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditPhoneModal from "components/phone/EditModal";
import List from "components/phone/List";
import ProfileValidationFooter from "components/profileValidation/components/ProfileValidationFooter";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { add } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newPhone } from "model/Phone";
import { useContext, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/phone/Phone.selectors";
import { fetchPhones, reloadPhones } from "store/profile/phone/Phone.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

export const ProfileValidationProcessPhonesPage: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewPhoneModalOpen, setIsNewPhoneModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);
	const isLoading = useSelector(getIsFetching);
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);

	useEffect(() => {
		dispatch<any>(reloadPhones(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchPhones(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadPhones(newBaseListQuery(basListQuery)));
	};
	return (
		<IonPage>
			<IonContent>
				<ProfileValidationHeader title="Telefon" progress={5} />

				<EditPhoneModal isOpen={isNewPhoneModalOpen} editItem={newPhone()} onCancel={() => setIsNewPhoneModalOpen(false)} />
				<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={() => setIsNewPhoneModalOpen(true)}>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>
			</IonContent>

			<ProfileValidationFooter
				isLoading={isLoading}
				progress={5}
				routerLinkNext="/validation/profile/1/bank"
				routerLinkBack="/validation/profile/1/address"
			/>
		</IonPage>
	);
};
