import "./Invoices.css";

import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import InvoiceList from "components/invoice/List";
import Page from "components/page/Page";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newLoadInvoiceQuery } from "model/invoice/LoadInvoiceQuery";
import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { fetchInvoices, reloadInvoices } from "store/invoice/Invoice.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

const Invoices: VFC = () => {
	const dispatch = useAppDispatch();
	const selectedTenant = useSelector(getSelectedTenant);

	useEffect(() => {
		dispatch<any>(reloadInvoices(newLoadInvoiceQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchInvoices(newLoadInvoiceQuery(newBaseListQuery(basListQuery), selectedTenant)));
	};

	return (
		<Page title={i18n._(t`My invoices`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<InvoiceList onPageScrollEnd={loadEntries} />
		</Page>
	);
};

export default Invoices;
