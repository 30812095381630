import { RefresherEventDetail } from "@ionic/core";
import { IonCol } from "@ionic/react";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Qualification } from "model/Qualification";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllQualifications, getIsFetching } from "store/qualification/Qualification.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import Teaser from "./Teaser";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const List: VFC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getAllQualifications);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());

	const renderItem = (item: Qualification) => {
		return (
			<IonCol size="12" size-sm="6" size-lg="4" size-xl="3" key={item.id}>
				<Teaser qualification={item} />
			</IonCol>
		);
	};
	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery());
		}
		event.detail.complete();
	};
	return <InfiniteBaseList items={list} isFetching={isBusy} renderItem={renderItem} onInfinite={onInfinite} onRefresh={reloadEntries} />;
};
export default List;
