import { createSlice } from "@reduxjs/toolkit";
import Token from "model/Token";
import { defaultState } from "store/State";

import { setToken } from "./Token.thunks";

export const initialState = {
	...defaultState<Token>({}),
};

export type AccountState = typeof initialState;

const slice = createSlice({
	name: "token",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(setToken.fulfilled, (state, action) => {
			state.value = action.payload;
		});
	},
});
export default slice.reducer;
