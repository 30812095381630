import { IonChip, IonIcon, IonLabel, IonModal, IonNote, IonSpinner } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { FC, useRef, useState } from "react";
import ShareTenantChip from "./ShareTenantChip";
import { ScopeType } from "model/ScopeType";
import { Trans } from "@lingui/macro";
import ShareScopeTenantSelect from "./ShareScopeTenantSelect";
import { ShareModelDto, newShareModelDto } from "model/ShareModelDto";
import ShareScopeTenantChip from "./ShareScopeTenantChip";
import ShareTenantSelectModal from "./ShareTenantSelectModal";
import ShareScopeTenantSelectModal from "./ShareScopeTenantSelectModal";

export interface Props {
	scopedSharingTenants: Array<ScopedSharingTenant> | undefined;
	isFetching: boolean;
	share: (shareModalDto: ShareModelDto) => void;
	deleteShare: (shareModalDto: ShareModelDto) => void;
	userId: string;
	entityId: string;
}
const ScopeShareInternToTenant: FC<Props> = ({ scopedSharingTenants, isFetching, share, deleteShare, userId, entityId }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	if (scopedSharingTenants == undefined) {
		return <></>;
	}

	const onNewShareDone = (newSharedTenant: any) => {
		setIsModalOpen(false);
		const tenantId = Array.isArray(newSharedTenant) ? newSharedTenant[0].id : newSharedTenant.id;
		const newTenantToShare = newShareModelDto(userId, tenantId, entityId, ScopeType.intern);
		share(newTenantToShare);
	};
	const onDeleteShare = (newSharedTenant: ScopedSharingTenant) => {
		deleteShare(newShareModelDto(userId, newSharedTenant.id, entityId, ScopeType.intern));
	};
	const onShareCancel = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<IonModal key={`${entityId}_intern_select_modal`} isOpen={isModalOpen}>
				<ShareScopeTenantSelectModal
					scopedSharingTenants={scopedSharingTenants}
					done={onNewShareDone}
					scopeType={ScopeType.intern}
					cancel={onShareCancel}
				/>
			</IonModal>

			<div className="ion-padding-top">
				<IonNote className="ion-padding-bottom">
					<Trans>Share Intern</Trans>
				</IonNote>
				<div className="ion-margin-top">
					{scopedSharingTenants?.length ?? 0 > 1 ? (
						scopedSharingTenants
							?.filter((x) => x.isIntern)
							.map((tenant, i) => {
								return (
									<ShareScopeTenantChip
										key={tenant.id}
										onDelete={(localTenant) => onDeleteShare(localTenant)}
										scopedSharingTenant={tenant}
									/>
								);
							})
					) : (
						<IonChip outline={false}>
							<IonLabel>Keine Freigaben</IonLabel>
						</IonChip>
					)}

					<IonChip disabled={isFetching} onClick={(e) => setIsModalOpen(true)} outline={false} color="primary">
						{isFetching ? (
							<>
								<IonSpinner name="dots" className="ion-margin"></IonSpinner>
							</>
						) : (
							<>
								<IonIcon icon={addOutline} />
								<IonLabel>Hinzufügen</IonLabel>
							</>
						)}
					</IonChip>
				</div>
			</div>
		</>
	);
};

export default ScopeShareInternToTenant;
