import "./style.scss";
import "../style.scss";

import {
	IonButton,
	IonCard,
	IonCol,
	IonGrid,
	IonIcon,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
} from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditModal from "components/mail/EditModal";
import { addOutline, alertCircleOutline, checkmarkCircleOutline, refreshOutline } from "ionicons/icons";
import { newBaseListQuery } from "model/BaseListQuery";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import { Mail, newMail } from "model/Mail";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAllMails } from "store/profile/mail/Mail.selectors";
import { reloadMails } from "store/profile/mail/Mail.thunks";

interface IEventMailPartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventMailPart: React.FunctionComponent<IEventMailPartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const mails = useSelector(getAllMails);
	const refSelect = useRef<HTMLIonSelectElement>(null);
	const [showModal, setShowModal] = useState(false);
	const [mail, setMail] = useState(newMail());
	const [selectedItem, setSelectedItem] = useState<Mail | undefined>(undefined);
	const [hasClicked, setHasClicked] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		setMail(newMail());
	}, [showModal]);

	React.useEffect(() => {
		//	dispatch<any>(reloadBankAccounts(newBaseListQuery()));
		if (hasClicked) {
			refSelect.current?.open();
		}
	}, [mails]);

	return (
		<>
			<EditModal editItem={mail} isOpen={showModal} onCancel={() => setShowModal(false)} />
			<div className="firstDivider"></div>
			<IonCard color="amber-50">
				<IonGrid color="amber-50" className={selectedItem == null ? "gridNoSuccess" : "gridSuccess"}>
					<IonRow>
						<IonCol size="0" sizeSm="0" sizeLg="1" sizeMd="0" sizeXl="1" sizeXs="0">
							{selectedItem == null ? (
								<IonIcon mode="md" className="notDoneIcon" src={alertCircleOutline} />
							) : (
								<IonIcon mode="md" className="isDoneIcon" src={checkmarkCircleOutline} />
							)}
						</IonCol>
						<IonCol size="11" sizeSm="12" sizeLg="11" sizeMd="12" sizeXl="11" sizeXs="12">
							<IonCard color="amber-50">
								<IonItemDivider className={selectedItem == null ? "rowDivider" : "rowDividerSuccess"} mode="ios">
									E-Mail:
									<IonLabel color="danger">*</IonLabel>
									<IonButton onClick={(e) => dispatch<any>(reloadMails(newBaseListQuery()))} color="medium" slot="end" fill="clear">
										<IonIcon className="buttonColor" size="small" src={refreshOutline}></IonIcon>
									</IonButton>
								</IonItemDivider>
								<IonItem className="rowItem" lines="none">
									<IonSelect
										onClick={(e) => {
											dispatch<any>(reloadMails(newBaseListQuery()));
										}}
										mode="ios"
										title="E-Mail"
										onIonChange={(e) => {
											if (e.detail) {
												eventRegistration.emailId = e.detail.value?.id;
												eventRegistration.mailModel = e.detail.value;
												eventRegistration.email = e.detail.value?.mailAddress;
												setEventRegistration(eventRegistration);
												setSelectedItem(e.detail.value);
												console.log(eventRegistration);
											}
										}}
										ref={refSelect}
										selectedText={selectedItem?.mailAddress}
										slot="start"
										placeholder="E-Mail auswählen"
										className="rowSelect"
									>
										{mails?.items
											?.slice()
											.reverse()
											?.map((item, i) => {
												console.log(item);
												return (
													<IonSelectOption value={item} key={item.id}>
														{item.mailAddress}
													</IonSelectOption>
												);
											})}
									</IonSelect>
									<IonButton
										color="medium"
										slot="end"
										fill="clear"
										onClick={(e) => {
											setShowModal(true);
											setHasClicked(true);
										}}
									>
										<IonIcon className="buttonColor" size="large" src={addOutline}></IonIcon>
									</IonButton>
								</IonItem>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventMailPart;
