import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { OrderElement } from "model/orderElement/OrderElement";
import { Tenant } from "model/Tenant";

class OrderElementService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<OrderElement>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.toEntries().forEach(([key, value]) => {
			if (key === "orderby") {
				value = "CreatedAt";
			}
			if (key === "orderbytype") {
				value = "descending";
			}
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/orderelements`, {
			params,
			headers: { userId: userId },
		});
		return res.data;
	}
}
const orderElementService = new OrderElementService();
export default orderElementService;
