import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { LogElement } from 'model/LogElement';
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";
import { fetchLogElements, reloadLogElements } from './Log.thunks';

export const initialState = {
	...defaultState<BaseList<LogElement>>(newBaseList<LogElement>()),

	scope: ScopeType.extern,
	selectedTenant: null as unknown as Tenant,
};

export type LogState = typeof initialState;

const slice = createSlice({
	name: "logElement",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(fetchLogElements.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadLogElements.fulfilled), (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadLogElements.pending, fetchLogElements.pending), (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addMatcher(isAnyOf(reloadLogElements.rejected, fetchLogElements.rejected), (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
		});
	},
});

export default slice.reducer;
