import { Address } from "model/Address";
import { BankAccount } from "model/BankAccount";
import { Mail } from "model/Mail";
import { Price } from "model/payment/Price";
import { Phone } from "model/Phone";

import { EventProgressType } from "./EventProgressType";
import { AdditionalServiceGroup } from "model/payment/AdditionalServiceGroup";
import { SelectedAdditionalService } from "model/payment/SelectedAdditionalService";

export default interface EventRegistration {
	personId: string;
	eventId: string;
	addressId?: string | undefined;
	address?: Address | undefined;
	phoneId?: string | undefined;
	phonenumber?: string | undefined;
	phoneModel?: Phone | undefined;
	emailId?: string | undefined;
	mailModel?: Mail | undefined;
	email?: string | undefined;
	bankAccountId?: string | undefined;
	bankAccount?: BankAccount | undefined;
	note?: string | undefined;
	approvals?: Array<string> | undefined;
	tenantId?: string | undefined;
	tenantName?: string | undefined;
	priceId?: string | undefined;
	price?: Price | undefined;
	confirmationUrl?: string;
	additionalServiceGroups?: AdditionalServiceGroup[];
	contractId?: string | undefined;
	eventProgressType?: EventProgressType;
	selectedAdditionalServices?: SelectedAdditionalService[];
}
export function newEventRegistration(): EventRegistration {
	return {
		personId: "",
		eventId: "",
		approvals: new Array<string>(),
		selectedAdditionalServices: new Array<SelectedAdditionalService>(),
	};
}

export function newEventRegistrationByEvent(eventId: string, personId: string, contractId: string | null | undefined): EventRegistration {
	return {
		eventId: eventId,
		personId: personId,
		contractId: contractId ?? undefined,
		approvals: new Array<string>(),
		selectedAdditionalServices: new Array<SelectedAdditionalService>(),
	};
}
