import {
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonChip,
	IonCol,
	IonGrid,
	IonImg,
	IonItem,
	IonProgressBar,
	IonRow,
	IonSkeletonText,
	IonTitle,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import Page from "components/page/Page";
import { TextOrEmpty } from "components/text/textOrEmpty/TextOrEmpty";
import BlogEntry from "model/BlogEntry";
import { ComponentType, selectComponent } from "model/Component";
import { VFC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBlogEntry } from "store/blogEntry/BlogEntry.selectors";

interface MatchParams {
	id: string;
}
const BlogDetailPage: VFC = () => {
	const { id } = useParams<MatchParams>();

	//const entry: BlogEntry | undefined = useSelector(getBlogEntry(id));
	const entry: BlogEntry | undefined = useSelector(getBlogEntry(id));
	if (!entry) {
		return (
			<Page title="" backLink="/events" backTitle={i18n._(t`Events`)}>
				<IonProgressBar hidden={false} type="indeterminate"></IonProgressBar>

				<IonGrid>
					<IonRow>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="10" sizeXl="5" size="5" pushMd="1" pushLg="1" pushXl="0">
							<IonCard disabled={true}>
								<IonImg src="https://public.cdn.dso-verein.de/img/stock/event/empty_event.jpeg" />
								<IonItem lines="full">
									<IonCardHeader style={{ width: "100%" }}>
										<IonCardSubtitle style={{ width: "100%" }}>
											<IonChip style={{ width: "25%" }}>
												<IonSkeletonText animated style={{ width: "100%" }} />
											</IonChip>
										</IonCardSubtitle>
										<IonCardTitle style={{ width: "100%" }}>
											<IonSkeletonText animated style={{ width: "60%" }} />
										</IonCardTitle>
									</IonCardHeader>
								</IonItem>
							</IonCard>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="7">
							<IonCard>
								<IonItem lines="full">
									<IonCardHeader style={{ width: "100%" }}>
										<h4>
											<IonSkeletonText style={{ width: "30%" }} />
										</h4>
									</IonCardHeader>
								</IonItem>

								<IonItem color="light">
									<IonCardTitle style={{ width: "100%" }}>
										<h4>
											<IonSkeletonText style={{ width: "100%" }} />
										</h4>
										<h4>
											<IonSkeletonText style={{ width: "100%" }} />
										</h4>
										<h4>
											<IonSkeletonText style={{ width: "100%" }} />
										</h4>
										<h4>
											<IonSkeletonText style={{ width: "100%" }} />
										</h4>
										<h4>
											<IonSkeletonText style={{ width: "100%" }} />
										</h4>
										<h4>
											<IonSkeletonText style={{ width: "100%" }} />
										</h4>
										<h4>
											<IonSkeletonText style={{ width: "100%" }} />
										</h4>
									</IonCardTitle>
								</IonItem>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</Page>
		);
	}
	return (
		<Page title={entry.title} backLink="/blog" backTitle={i18n._(t`Blog entries`)} backButton={true} tabBarHidden={true}>
			<IonGrid>
				<IonRow>
					<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="10" sizeXl="5" size="5" pushMd="1" pushLg="1" pushXl="0">
						<IonCard disabled={false}>
							<IonImg
								className="card-detail-hero"
								src={
									selectComponent(ComponentType.IMAGE, entry.components || [])?.imageUrl ??
									"https://public.cdn.dso-verein.de/img/stock/blog/news-xs.jpeg"
								}
							/>
						</IonCard>
					</IonCol>
					<IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="7">
						<IonCard>
							<IonItem lines="full">
								<h4 className="ion-text-wrap">{entry.title}</h4>
							</IonItem>

							<IonItem>
								<TextOrEmpty text={selectComponent(ComponentType.TEXT, entry.components || [])?.content} isHtml={true} />
							</IonItem>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</Page>
	);
};
export default BlogDetailPage;
