import { App } from '@capacitor/app';

App.addListener("appStateChange", ({ isActive }) => {
	console.log("App state changed. Is active?", isActive);
});

App.addListener("appUrlOpen", (data) => {
	console.log("App opened with URL:", data);
	if (data.url.includes("logout")) {
		//		dispatch<any>(deleteIdToken());
	}
});

App.addListener("appRestoredResult", (data) => {
	console.log("Restored state:", data);
});
export const AppListenerHelper = () => {
	App.addListener("appStateChange", ({ isActive }) => {
		console.log("App state changed. Is active?", isActive);
	});

	App.addListener("appUrlOpen", (data) => {
		console.log("App opened with URL:", data);
		if (data.url.includes("logout")) {
			//		dispatch<any>(deleteIdToken());
		}
	});

	App.addListener("appRestoredResult", (data) => {
		console.log("Restored state:", data);
	});
}
