import "./style.scss";

import { IonCard, IonCardContent, IonCheckbox, IonItem, IonNote, IonTitle } from "@ionic/react";

interface PrivacyVerfiyApprovementProps {
	checked: boolean;
	setChecked: (checked: boolean) => void;
}
export const PrivacyVerfiyApprovement = ({ checked, setChecked }: PrivacyVerfiyApprovementProps) => {
	return (
		<>
			<IonCard>
				<IonItem>
					Hinweis zur Verarbeitung personenbezogener Daten
					<IonTitle> </IonTitle>
				</IonItem>
				<IonItem>
					<IonCheckbox checked={checked} onIonChange={(e) => setChecked(e.detail.checked)} slot="start" />
					<IonCardContent>
						<IonNote>
							Aufgrund unserer Datenschutz Policy müssen die Daten selbst freigegeben werden. Ohne Deine aktive Mitwirkung bleiben die
							Daten nur bei Dir und werden keinem Verein angezeigt.
						</IonNote>
					</IonCardContent>
				</IonItem>
			</IonCard>
		</>
	);
};
