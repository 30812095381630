import { IonAvatar, IonCol, IonGrid, IonImg, IonItem, IonLabel, IonRow } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { FC } from "react";
import { checkImageAvatarUrl } from "helper/CheckImageUrlHelper";
import { Profile } from "model/Profile";
import { SPORTCLOUD_LOGO } from "helper/ImageConstants";
import "./AvatarTenantScope.scss";
import AvatarTenantScope from "./AvatarTenantScope";
import { ScopeType } from "model/ScopeType";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/Profile.selectors";
export interface Props {
	profile: Profile;
}
const SportcloudTeaser: FC<Props> = ({ profile }) => {
	const isFetching = useSelector(getIsFetching);

	return (
		<IonItem
			lines="none"
			disabled={isFetching}
			className="ion-margin-top ion-margin-bottom ion-margin-right"
			button={false}
			key={profile.id}
			id={profile.id}
		>
			<IonGrid>
				<IonRow className="ion-margin-top ion-margin-bottom">
					<IonCol size="12" sizeLg="12" sizeMd="12" sizeXl="12" sizeXs="12">
						<IonItem lines="none">
							<IonImg slot="start" style={{ width: "250px" }} src={SPORTCLOUD_LOGO} />
						</IonItem>
					</IonCol>
					<IonCol size="4">
						<AvatarTenantScope imageUrl={profile.avatarImageUrl} scopeType={ScopeType.default} />
					</IonCol>
					<IonCol size="4">
						<AvatarTenantScope imageUrl={profile.internAvatarImageUrl} scopeType={ScopeType.intern} />
					</IonCol>
					<IonCol size="4">
						<AvatarTenantScope imageUrl={profile.externAvatarImageUrl} scopeType={ScopeType.extern} />
					</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};

export default SportcloudTeaser;
