import { IonItem, IonItemSliding, IonLabel } from "@ionic/react";
import { License } from "model/License";

import { Title } from "../text/title/Title";

export interface Props {
	license: License;
}
const Teaser = (props: Props) => {
	return (
		<IonItemSliding>
			<IonItem>
				<IonLabel>
					<Title text={props.license?.licenseType} />
				</IonLabel>
			</IonItem>
		</IonItemSliding>
	);
};
export default Teaser;
