import { IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonRow } from "@ionic/react";
import { BaseList } from "model/BaseList";
import { ReactNode } from "react";

export interface Props<T> {
	list: BaseList<T>;
	renderItem: (item: T, index: number) => ReactNode;
	onInfinite(event: CustomEvent<void>): void;
}
export const InfiniteGrid = <T extends unknown>({ list, renderItem, onInfinite }: Props<T>) => {
	const items = list?.items || [];

	return (
		<>
			<IonGrid>
				<IonRow>{items.map((item, index) => renderItem(item, index))}</IonRow>
			</IonGrid>
			<IonInfiniteScroll onIonInfinite={onInfinite}>
				<IonInfiniteScrollContent loadingSpinner="dots" />
			</IonInfiniteScroll>
		</>
	);
};
