import { IonAlert, IonButton, IonCard, IonChip, IonFab, IonFabButton, IonIcon, IonItem, IonLabel, IonThumbnail, IonTitle } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import SportcloudTeaserList from "components/avatar/avatarList/SportcloudTeaserList";
import { add, helpCircle, helpCircleOutline, helpOutline } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

import ProfilePageLayout from "./ProfilePageLayout";
import SportcloudTeaser from "components/avatar/avatarList/SportcloudTeaser";
import ExplainImageUsageModal from "components/avatar/avatarList/ExplainImageUsageModal";

const AvatarImagesPage: FC = () => {
	const [helpModalOpen, sethelpModalOpen] = useState(false);

	const profile = useSelector(getProfile);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		//dispatch<any>(fetchAddresses(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		//dispatch<any>(reloadAddresses(newBaseListQuery(basListQuery)));
	};
	return (
		<ProfilePageLayout pageColor="amber-50" title={i18n._(t`ProfilImages`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<ExplainImageUsageModal isOpen={helpModalOpen} onCancel={() => sethelpModalOpen(false)} />
			<IonItem lines="none"></IonItem>
			<IonCard className="ion-margin-top ion-padding-top">
				<IonItem lines="none">
					<IonChip color="amber-900" className="ion-margin ion-padding">
						<IonLabel>Standard Angaben</IonLabel>
					</IonChip>
					<IonButton slot="end" shape="round" fill="clear" onClick={() => sethelpModalOpen(true)}>
						<IonIcon size="large" color="amber-400" icon={helpCircleOutline} />
					</IonButton>
				</IonItem>
				<SportcloudTeaser profile={profile} />
			</IonCard>
			<IonItem lines="none"></IonItem>

			<IonCard className="ion-margin-top ion-padding-top">
				<IonItem className=" " lines="none">
					<IonChip color="amber-900" className="ion-margin ion-padding">
						<IonLabel>Vereinsspezifische Angaben</IonLabel>
					</IonChip>
				</IonItem>

				<SportcloudTeaserList onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
				<IonItem lines="none"></IonItem>
			</IonCard>
		</ProfilePageLayout>
	);
};

export default AvatarImagesPage;
