import { RefresherEventDetail } from "@ionic/core";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { OrderByType } from "model/OrderByType";
import { OrderElement } from "model/orderElement/OrderElement";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching, getOrderElements } from "store/orderElement/OrderElement.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import Teaser from "./Teaser";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}
const List: VFC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getOrderElements);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());

	const renderItem = (item: OrderElement) => {
		return <Teaser orderElement={item} />;
	};
	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery({ orderby: "CreatedAt", orderbytype: OrderByType.descending }));
		}
		event.detail.complete();
	};

	return <InfiniteBaseList items={list} onInfinite={onInfinite} onRefresh={reloadEntries} renderItem={renderItem} isFetching={false} />;
};
export default List;
