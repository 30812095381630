import "./style.scss";
import "../style.scss";

import {
	IonButton,
	IonCard,
	IonCol,
	IonGrid,
	IonIcon,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonSpinner,
} from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import axios from "axios";
import EditModal from "components/bankAccount/EditModal";
import { addOutline, alertCircleOutline, checkmarkCircleOutline, refreshOutline } from "ionicons/icons";
import { BankAccount, newBankAccount } from "model/BankAccount";
import { newBaseListQuery } from "model/BaseListQuery";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import { OpenIBANResult } from "model/OpenIBANResult";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { newUpsertModel } from "model/UpsertModel";
import * as React from "react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAllBankAccounts, getIsFetching } from "store/profile/bankAccount/BankAccount.selectors";
import { addBankAccount, reloadBankAccounts } from "store/profile/bankAccount/BankAccount.thunks";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

interface IEventIBANPartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventIBANPart: React.FunctionComponent<IEventIBANPartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const bankAccounts = useSelector(getAllBankAccounts);
	const isFetching = useSelector(getIsFetching);
	const dispatch = useAppDispatch();

	const refSelect = useRef<HTMLIonSelectElement>(null);
	const [bankAccount, setBankAccount] = useState(newBankAccount());
	const [selectedItem, setSelectedItem] = useState<BankAccount | undefined>(undefined);
	const [hasClicked, setHasClicked] = useState(false);
	const modal = useRef<HTMLIonModalElement>(null);

	React.useEffect(() => {
		//	dispatch<any>(reloadBankAccounts(newBaseListQuery()));
		if (hasClicked) {
			refSelect.current?.open();
		}
	}, [bankAccounts]);

	return (
		<>
			<EditModal editItem={bankAccount} modalRef={modal} onCancel={() => modal.current?.dismiss(false)} />
			<div className="firstDivider"></div>
			<IonCard>
				<IonGrid className={selectedItem == null ? "gridNoSuccess" : "gridSuccess"}>
					<IonRow>
						<IonCol size="0" sizeLg="1" sizeMd="0" sizeXl="1" sizeXs="0">
							{selectedItem == null ? (
								<IonIcon className="notDoneIcon" src={alertCircleOutline} />
							) : (
								<IonIcon className="isDoneIcon" src={checkmarkCircleOutline} />
							)}
						</IonCol>
						<IonCol size="11" sizeLg="11" sizeMd="11" sizeXl="11" sizeXs="12">
							<IonCard>
								<IonItemDivider className={selectedItem == null ? "rowDivider" : "rowDividerSuccess"} mode="ios">
									IBAN:
									<IonLabel color="danger">*</IonLabel>
									<IonButton color="medium" slot="end" fill="clear">
										<IonIcon color="medium" size="small" src={refreshOutline}></IonIcon>
									</IonButton>
								</IonItemDivider>
								<IonItem className="rowItem" lines="none">
									<IonSelect
										onClick={(e) => {
											dispatch<any>(reloadBankAccounts(newBaseListQuery()));
										}}
										title="IBAN:"
										disabled={isFetching}
										onIonChange={async (e) => {
											if (e.detail) {
												eventRegistration.bankAccountId = e.detail.value.id;
												const bankAccount = JSON.parse(JSON.stringify(e.detail.value)) as BankAccount;

												if (eventRegistration.bankAccount?.bic == null || eventRegistration.bankAccount == undefined) {
													const res = await axios.get<OpenIBANResult>(
														`https://openiban.com/validate/${bankAccount.iban}?getBIC=true&validateBankCode=true`,
													);
													if (res.data.valid) {
														console.log(res);
														bankAccount.bic = res.data.bankData?.bic;
														eventRegistration.bankAccount = bankAccount;
														console.log(bankAccount);
													} else {
														return;
													}
												}
												setEventRegistration(eventRegistration);
												setSelectedItem(e.detail.value);
											}
										}}
										mode="ios"
										ref={refSelect}
										selectedText={selectedItem?.iban}
										slot="start"
										placeholder="IBAN wählen"
										className="rowSelect"
									>
										{bankAccounts?.items
											?.slice()
											.reverse()
											?.map((item, i) => {
												return (
													<IonSelectOption value={item} key={item.id}>
														{item.iban}
													</IonSelectOption>
												);
											})}
									</IonSelect>
									{isFetching ? (
										<IonSpinner slot="end" />
									) : (
										<IonButton
											color="medium"
											slot="end"
											fill="clear"
											onClick={(e) => {
												modal.current?.present();
												setHasClicked(true);
											}}
										>
											<IonIcon color="medium" size="large" src={addOutline}></IonIcon>
										</IonButton>
									)}
								</IonItem>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventIBANPart;
