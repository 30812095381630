import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import EditModal from "components/confectionInformation/ConfectionInformationModal";
import List from "components/confectionInformation/List";
import { add } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newConfectionInformation } from "model/ConfectionInformation";
import { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { fetchConfectionInformations, reloadConfectionInformations } from "store/profile/confectionInformation/ConfectionInformation.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

import ProfilePageLayout from "./ProfilePageLayout";

export const ConfectionInformationsPage: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewItemModalOpen, setIsNewItemModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);
	useEffect(() => {
		dispatch<any>(reloadConfectionInformations(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchConfectionInformations(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadConfectionInformations(newBaseListQuery(basListQuery)));
	};
	return (
		<ProfilePageLayout title={i18n._(t`ConfectionInformations`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<EditModal isOpen={isNewItemModalOpen} editItem={newConfectionInformation()} onCancel={() => setIsNewItemModalOpen(false)} />
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => setIsNewItemModalOpen(true)}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
		</ProfilePageLayout>
	);
};
