import {
	IonCard,
	IonCardContent,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonRouterLink,
	IonRow,
} from "@ionic/react";
import { Trans } from "@lingui/macro";
import EditButton from "components/buttons/EditButton";
import { FC, VFC } from "react";
import { useHistory } from "react-router-dom"; // version 5.2.0

import css from "./InfoBlock.module.scss";

interface Columns {
	[key: string]: string;
}

interface Row {
	id: string | undefined;
	[key: string]: string | undefined;
}

interface Link {
	title: string;
	link: string;
}

interface InfoBlockProps {
	id?: string;
	title?: string;
	columns?: Columns;
	rows?: Row[];
	link?: Link;
	dataKey?: string;
	editable?: boolean;
	noBackground?: boolean;
	addButtonClick?: () => void;
}

const InfoBlock: FC<InfoBlockProps> = ({
	id = "",
	title = "",
	columns = {},
	rows = [],
	link = undefined,
	dataKey = "",
	editable = false,
	noBackground = false,
	addButtonClick,
}) => {
	const history = useHistory();

	return (
		<div key={`${id}_info_block_item`} id={id}>
			<IonCard key={`${id}_info_block_item`} routerLink={link?.link} button>
				<IonCardTitle>
					<IonItem className={css["infoBlockHeader"]} button={link != undefined} detail={link != undefined}>
						<p className={css["infoBlockHeaderText"]}>{title}</p>
					</IonItem>
				</IonCardTitle>

				<IonCardContent>
					<IonGrid>
						{rows.map((row, index) => {
							return (
								<div key={index}>
									{Object.entries(columns).map(([column]) => {
										return row[column] != "" ? <IonItem key={row.id + id}>{row[column]}</IonItem> : <></>;
									})}
								</div>
							);
						})}
					</IonGrid>
				</IonCardContent>
			</IonCard>
		</div>
	);
};

export default InfoBlock;
