import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import Page from "components/page/Page";
import TenantList from "components/tenant/TenantList";
import { add } from "ionicons/icons";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllTenants } from "store/profile/Profile.selectors";

import RegisterToTenant from "./RegisterToTenant";

const TenantsPage: VFC = () => {
	const tenants = useSelector(getAllTenants);
	const [isModelOpen, setisModelOpen] = useState(false);
	const onViewEnter = () => {
		console.log("Enter Tenantspage");
	};

	const onViewLeave = () => {
		console.log("Leave Tenantspage");
	};

	return (
		<Page title={i18n._(t`Tenants`)} onViewEnter={onViewEnter} onViewLeave={onViewLeave}>
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => setisModelOpen(!isModelOpen)}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
			<TenantList />

			<RegisterToTenant isOpen={isModelOpen} setIsOpen={setisModelOpen} />
		</Page>
	);
};

export default TenantsPage;
