import { Creator } from "./Creator";
import { IsBusy } from "./IsBusy";
import { ScopedSharingTenant } from "./ScopedSharingTenant";

export interface MedicalInformation extends IsBusy {
	name: string | undefined;
	medicalInformationType?: string;
	description?: string;
	id: string | undefined;
	scopedSharingTenants?: ScopedSharingTenant[];
	creator?: Creator;
}
export function newMedicalInformation(): MedicalInformation {
	return {
		name: undefined,
		id: undefined,
	};
}
