import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Mail } from "model/Mail";
import { UpsertModel } from "model/UpsertModel";
import mailService from "services/MailService";

import { RootState } from "../..";
import { ShareModelDto } from 'model/ShareModelDto';
import { ScopeType } from 'model/ScopeType';

const baseString = "mail";
export const fetchMails = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Mail>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: BaseListQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			const query = newBaseListQuery(filter);

			const list = await mailService.get(state.profile.idTokenClaim.person_id, query);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: BaseListQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const listeSate = state.mail;

			if (listeSate.value && listeSate.value.page) {
				if (listeSate.value.items.length === listeSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const reloadMails = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Mail>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: BaseListQuery, thunkAPI) => {
	try {
		const query = newBaseListQuery(filter);
		const state = thunkAPI.getState() as RootState;

		const list = await mailService.get(state.profile.idTokenClaim.person_id, query);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});

export const addMail = createAsyncThunk<Mail, UpsertModel<Mail>, { rejectValue: string }>(
	`${baseString}/add`,
	async (addModel: UpsertModel<Mail>, thunkAPI) => {
		console.log("addMail");
		try {
			const mailResult = await mailService.add(addModel.userId, addModel.item);
			return mailResult;
		} catch (ex: any) {
			console.log("ex", ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);

export const updateMail = createAsyncThunk<Mail, UpsertModel<Mail>, { rejectValue: string }>(
	`${baseString}/update`,
	async (updateModel: UpsertModel<Mail>, thunkAPI) => {
		try {
			const mailResult = await mailService.update(updateModel.userId, updateModel.item);
			return mailResult;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const deleteMail = createAsyncThunk<string | undefined, UpsertModel<Mail>, { rejectValue: string }>(
	`${baseString}/delete`,
	async (deleteModel: UpsertModel<Mail>, thunkAPI) => {
		try {
			const mailResult = await mailService.delete(deleteModel.userId, deleteModel.item);
			return deleteModel.item.id;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const shareMail = createAsyncThunk<Mail, UpsertModel<Mail>, { rejectValue: string }>(
	`${baseString}/share`,
	async (shareModel: UpsertModel<Mail>, thunkAPI) => {
		try {
			const mailResult = await mailService.share(shareModel.userId, shareModel.item);
			return mailResult;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const shareMailScoped = createAsyncThunk<Mail, ShareModelDto, { rejectValue: string }>(
	`${baseString}/shareScoped`,
	async (shareModel: ShareModelDto, thunkAPI) => {
		try {
			if (shareModel.scopeType == ScopeType.intern) {
				const result = await mailService.shareIntern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
				return result;
			}
			const result = await mailService.shareExtern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
			return result;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const deleteShareMailScoped = createAsyncThunk<Mail, ShareModelDto, { rejectValue: string }>(
	`${baseString}/deleteShareScoped`,
	async (shareModel: ShareModelDto, thunkAPI) => {
		try {
			if (shareModel.scopeType == ScopeType.intern) {
				const result = await mailService.deleteShareIntern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
				return result;
			}
			const result = await mailService.deleteShareExtern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
			return result;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	}
);
