import { Event } from "model/Event";
import { FC, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllEvents, getIsFetching } from "store/event/Event.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import EventTeaser from "./EventTeaser";

export interface Props {
	onPageScrollEnd: () => Promise<void>;
	reload: () => Promise<void>;
}

const EventList: FC<Props> = (props: Props) => {
	const list = useSelector(getAllEvents);
	const isBusy = useSelector(getIsFetching);

	const renderItem = (item: Event) => {
		return <EventTeaser event={item} key={item.id} />;
	};
	const onInfinite = async (event: any) => {
		if (isBusy) {
			return;
		}
		await props.onPageScrollEnd();
		event.target.complete();
	};
	const onReload = async (event: any) => {
		if (isBusy) {
			return;
		}
		await props.reload();
		event.target.complete();
	};
	return <InfiniteBaseList items={list} renderItem={renderItem} onInfinite={onInfinite} onRefresh={onReload} isFetching={false} />;
};
export default EventList;
