import { IonCardContent, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import { Trans } from "@lingui/macro";
import { Card } from "components/card/Card";
import { TextTeaser } from "components/text/teaser/TextTeaser";
import BlogEntry from "model/BlogEntry";
import { ComponentType, selectComponent } from "model/Component";
import { FC, VFC } from "react";
import { getLatestBlogEntry } from "store/blogEntry/BlogEntry.selectors";
import { reloadBlogEntries } from "store/blogEntry/BlogEntry.thunks";

export interface Props {
	entry: BlogEntry;
}

const BlogTeaser: FC<Props> = ({ entry }) => {
	if (entry == undefined) {
		return <></>;
	}
	return (
		<Card
			key={entry.id}
			title={entry.title}
			subtitle={entry.tags?.map((x) => x.name).join(" | ") ?? undefined}
			headerImageUrl={
				selectComponent(ComponentType.IMAGE, entry.components || [])?.imageUrl ??
				"https://public.cdn.dso-verein.de/img/stock/blog/news-xs.jpeg"
			}
			routerLink={`blog/${entry.id}`}
		>
			<TextTeaser text={selectComponent(ComponentType.TEXT, entry.components || [])?.content} />
		</Card>
	);
};

export default BlogTeaser;
