import { Trans } from "@lingui/macro";
import LatestOrderElementsList from "components/orderElement/LatestList";
import { VFC } from "react";

const LastMessages: VFC = () => {
	return (
		<div>
			<h2>
				<Trans>Messages</Trans>
			</h2>
			<LatestOrderElementsList />
		</div>
	);
};

export default LastMessages;
