import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Team } from "model/Team";
import teamService from "services/TeamService";

import { RootState } from "..";
import { DocumentElement } from 'model/DocumentElement';
import { ContactPerson } from 'model/ContactPerson';

const baseString = "team";
export const fetchTeams = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Team>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: BaseListQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			const query = newBaseListQuery(filter);

			const list = await teamService.get(state.profile.idTokenClaim.person_id, query);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: BaseListQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const listeSate = state.qualification;

			if (listeSate.value && listeSate.value.page) {
				if (listeSate.value.items.length === listeSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const reloadTeams = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Team>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: BaseListQuery, thunkAPI) => {
	try {
		const query = newBaseListQuery(filter);
		const state = thunkAPI.getState() as RootState;

		const list = await teamService.get(state.profile.idTokenClaim.person_id, query);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});
export const getTeamDocuments = createAsyncThunk<BaseList<DocumentElement>, [string, string], { rejectValue: string }>(
	`${baseString}/getEventDocuments`,
	async ([eventId, tenantId], thunkAPI) => {
		try {
			const item = await teamService.getDocuments(tenantId, eventId);
			return item;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const getContactPersons = createAsyncThunk<BaseList<ContactPerson>, [string, string], { rejectValue: string }>(
	`${baseString}/getContactPersons`,
	async ([eventId, tenantId], thunkAPI) => {
		try {
			const item = await teamService.getContactPersons(tenantId, eventId);
			return item;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const fetchTeamById = createAsyncThunk<Team, string, { rejectValue: string }>(`${baseString}/getById`, async (id: string, thunkAPI) => {
	try {
		const item = await teamService.getById(id);
		return item;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
	}
});
