import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Qualification } from "model/Qualification";
import qualificationService from "services/QualificationService";

import { RootState } from "..";

const baseString = "qualification";
export const fetchQualifications = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Qualification>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: BaseListQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			const query = newBaseListQuery(filter);

			const list = await qualificationService.get(state.profile.idTokenClaim.person_id, query);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: BaseListQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const listeSate = state.qualification;

			if (listeSate.value && listeSate.value.page) {
				if (listeSate.value.items.length === listeSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const reloadQualifications = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Qualification>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: BaseListQuery, thunkAPI) => {
	try {
		const query = newBaseListQuery(filter);

		const state = thunkAPI.getState() as RootState;

		const list = await qualificationService.get(state.profile.idTokenClaim.person_id, query);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});
