import { BaseListQuery } from "./BaseListQuery";
import { OrderByType } from "./OrderByType";

export interface BaseList<T> extends BaseListQuery {
	items: Array<T>;
	maximumcount: number;
}

export function newBaseList<T>(emptyItemCount?: number, newT?: new () => T): BaseList<T> {
	const baseList = {
		items: new Array<T>(),
		maximumcount: 0,
		page: 0,
		pagesize: 25,
		orderbytype: OrderByType.ascending,
	};

	if (emptyItemCount && newT) {
		for (let i = 0; i < emptyItemCount; i++) {
			baseList.items.push(new newT());
		}
	}

	return baseList;
}

export function filterBaseListItems<T>(baseList: BaseList<T>, filter: (item: T) => boolean): BaseList<T> {
	if (baseList?.items) {
		const newBaseList = { ...baseList };
		newBaseList.items = baseList?.items?.filter(filter);
		return newBaseList;
	}
	return baseList;
}
