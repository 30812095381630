import { RefresherEventDetail } from "@ionic/core";
import { InfiniteBaseList } from "components/infinite/InfiniteBaseList";
import { BankAccount } from "model/BankAccount";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getBankAccounts, getIsFetching } from "store/profile/bankAccount/BankAccount.selectors";

import Teaser from "./Teaser";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const List: VFC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getBankAccounts);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());
	/*
	const renderItem = (item: Address) => {
		const itemMemo = useMemo(() => <Teaser address={item} />, [item]);

		return itemMemo;
	};
	*/
	const renderItem = (item: BankAccount) => <Teaser key={`${item.id}_bankaccount_teaser_list`} bankAccount={item} />;

	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery());
		}
		event.detail.complete();
	};
	return <InfiniteBaseList items={list} isFetching={isBusy} renderItem={renderItem} onInfinite={onInfinite} onRefresh={reloadEntries} />;
};

export default List;
