import { HasTenant } from "model/HasTenant";
import { Profile } from "model/Profile";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";

import { RootState } from "..";
import { TalkJsUser } from "model/chat/TalkJsUser";
import { IdTokenClaims } from "model/IdTokenClaims";

export const tenantFilter = <T extends HasTenant>(items: T[], tenant: Tenant | null): T[] => {
	if (tenant) {
		return items.filter((x) => x.tenantId === tenant.id);
	}

	return items;
};
export const getIdTokenState = (state: RootState): IdTokenClaims => state.profile.idTokenClaim;

export const getState = (state: RootState): RootState => state;
export const getProfile = (state: RootState): Profile => state.profile.value;
export const getIsFetching = (state: RootState): boolean => state.profile.isFetching;

export const getChatUser = (state: RootState): TalkJsUser => state.profile.chatUser;

export const getAllTenants = createSelector(getProfile, (profile) => profile?.tenants || []);
export const getSelectedTenant = (state: RootState): Tenant => state.profile.selectedTenant;

export const getTenantById = (state: RootState, id: string): Tenant | undefined => state.profile.value.tenants.find((x) => x.id === id);

export const getAllUserEvents = createSelector(getProfile, (profile) => profile?.events || []);

export const getScope = (state: RootState): ScopeType => state.profile.scope;

export const getAvatarImageUrl = createSelector(
	getProfile,
	getSelectedTenant,
	getScope,
	(profile: Profile, tenant: Tenant | null, scopeType?: ScopeType): string | undefined => {
		// If we dont return undefined we cant trigger a fallback for an AvatarImage Icon
		if (tenant) {
			const ressourcePath = profile?.tenants?.find((x) => x.id === tenant?.id)?.personAvatarImageUrl;
			if (ressourcePath == null) {
				return undefined;
			}
			return ressourcePath;
		} else {
			return profile?.avatarImageUrl;
		}
	},
);

export const getDetailHeaderImageUrl = createSelector(
	getProfile,
	getSelectedTenant,
	getScope,
	(profile: Profile, tenant: Tenant | null, scopeType?: ScopeType): string | undefined => {
		if (!tenant) {
			tenant = profile?.tenants[0];
		}

		return tenant?.themeInfos?.find((x) => x.themeType === "public")?.detailHeaderImage;
	},
);
