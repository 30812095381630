import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Phone } from "model/Phone";
import { UpsertModel } from "model/UpsertModel";
import phoneService from "services/PhoneService";

import { RootState } from "../..";
import { ShareModelDto } from 'model/ShareModelDto';
import { ScopeType } from 'model/ScopeType';

const baseString = "phone";
export const fetchPhones = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Phone>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: BaseListQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			const query = newBaseListQuery(filter);

			const list = await phoneService.get(state.profile.idTokenClaim.person_id, query);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: BaseListQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const listeSate = state.phone;

			if (listeSate.value && listeSate.value.page) {
				if (listeSate.value.items.length === listeSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const reloadPhones = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Phone>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: BaseListQuery, thunkAPI) => {
	try {
		const query = newBaseListQuery(filter);
		const state = thunkAPI.getState() as RootState;

		const list = await phoneService.get(state.profile.idTokenClaim.person_id, query);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});
export const addPhone = createAsyncThunk<Phone, UpsertModel<Phone>, { rejectValue: string }>(
	`${baseString}/add`,
	async (addModel: UpsertModel<Phone>, thunkAPI) => {
		console.log("Add phone");
		try {
			const phoneResult = await phoneService.add(addModel.userId, addModel.item);
			return phoneResult;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);

export const updatePhone = createAsyncThunk<Phone, UpsertModel<Phone>, { rejectValue: string }>(
	`${baseString}/update`,
	async (updateModel: UpsertModel<Phone>, thunkAPI) => {
		try {
			const phoneResult = await phoneService.update(updateModel.userId, updateModel.item);
			return phoneResult;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const deletePhone = createAsyncThunk<string | undefined, UpsertModel<Phone>, { rejectValue: string }>(
	`${baseString}/delete`,
	async (deleteModel: UpsertModel<Phone>, thunkAPI) => {
		try {
			await phoneService.delete(deleteModel.userId, deleteModel.item);
			return deleteModel.item.id;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data);
		}
	},
);
export const sharePhone = createAsyncThunk<Phone, UpsertModel<Phone>, { rejectValue: string }>(
	`${baseString}/share`,
	async (shareModel: UpsertModel<Phone>, thunkAPI) => {
		try {
			const phoneResult = await phoneService.share(shareModel.userId, shareModel.item);
			return phoneResult;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);

export const sharePhoneScoped = createAsyncThunk<Phone, ShareModelDto, { rejectValue: string }>(
	`${baseString}/shareScoped`,
	async (shareModel: ShareModelDto, thunkAPI) => {
		try {
			if (shareModel.scopeType == ScopeType.intern) {
				const result = await phoneService.shareIntern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
				return result;
			}
			const result = await phoneService.shareExtern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
			return result;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const deleteSharePhoneScoped = createAsyncThunk<Phone, ShareModelDto, { rejectValue: string }>(
	`${baseString}/deleteShareScoped`,
	async (shareModel: ShareModelDto, thunkAPI) => {
		try {
			if (shareModel.scopeType == ScopeType.intern) {
				const result = await phoneService.deleteShareIntern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
				return result;
			}
			const result = await phoneService.deleteShareExtern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
			return result;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	}
);
