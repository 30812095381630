import { IonAvatar, IonIcon, IonImg, IonItem, IonLabel, IonNote } from "@ionic/react";
import { useLingui } from "@lingui/react";
import { callOutline, mailOutline } from "ionicons/icons";
import { ContactPerson } from "model/ContactPerson";
import { FC, useState } from "react";
import ContactPersonCardModal from "./ContactPersonCardModal";
import { checkImageAvatarUrls } from "helper/CheckImageUrlHelper";
import { ScopeType } from "model/ScopeType";

interface ContactPersonListItemProps {
	tenantId: string;
	contactPerson: ContactPerson | undefined;
	scopeType: ScopeType;
}

const ContactPersonListItem: FC<ContactPersonListItemProps> = ({ tenantId, contactPerson, scopeType }) => {
	const { i18n } = useLingui();
	const [modalOpen, setmodalOpen] = useState(false);
	return (
		<>
			<IonItem
				id={contactPerson?.id}
				key={`${contactPerson?.id}_ContactPerson_Item_teaser_list`}
				onClick={(e) => setmodalOpen(true)}
				button
				color="amber-50"
				mode="md"
				lines="none"
			>
				<ContactPersonCardModal
					contactPerson={contactPerson}
					tenantId={tenantId}
					scopeType={scopeType}
					isOpen={modalOpen}
					onDismiss={() => setmodalOpen(false)}
				/>
				<IonAvatar slot="start">
					<IonImg
						src={checkImageAvatarUrls([
							contactPerson?.internAvatarImageUrl,
							contactPerson?.externAvatarImageUrl,
							contactPerson?.avatarImageUrl,
						])}
					></IonImg>
				</IonAvatar>
				<div>
					<IonLabel>
						{contactPerson?.firstName} {contactPerson?.lastName}
					</IonLabel>
					<IonNote>{contactPerson?.ContactPersonTypes?.map((x) => i18n._(x) + " ")}</IonNote>
				</div>
				<IonIcon slot="end" icon={callOutline}></IonIcon>
				<IonIcon slot="end" icon={mailOutline}></IonIcon>
			</IonItem>
		</>
	);
};

export default ContactPersonListItem;
