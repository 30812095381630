import { createAsyncThunk } from "@reduxjs/toolkit";
import Token from "model/Token";

const baseString = "token";
export const setToken = createAsyncThunk<Token, Token, { rejectValue: string }>(`${baseString}/set`, async (token: Token, thunkAPI) => {
	try {
		return token;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data);
	}
});
