import { IonSkeletonText } from "@ionic/react";
import he from "he";
import { VFC } from "react";

export interface Props {
	text?: string;
	isHtml?: boolean;
}

export const TextTeaser: VFC<Props> = ({ text, isHtml }) => {
	if (text) {
		if (isHtml === true) {
			return <div className="lines max-5" dangerouslySetInnerHTML={{ __html: text }}></div>;
		} else {
			return <p className="lines max-5">{he.decode(text.replace(/<[^>]+>/g, ""))}</p>;
		}
	}

	return (
		<>
			<p>
				<IonSkeletonText animated />
			</p>
			<p>
				<IonSkeletonText animated />
			</p>
			<p>
				<IonSkeletonText animated style={{ width: "80%" }} />
			</p>
		</>
	);
};
