import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getAllTeams } from "store/team/Team.selectors";

import InfoBlock from "./InfoBlock/InfoBlock";

const MyTeams: FC = () => {
	const { i18n } = useLingui();

	const teams = useSelector(getAllTeams)?.items.slice(0, 5);

	const columns = {
		name: i18n._(t`team`),
	};

	const rows = teams?.map((team) => {
		return { id: team.id, name: team.name };
	});

	return (
		<InfoBlock
			id="teams"
			title={i18n._(t`profile.myTeams`)}
			columns={columns}
			rows={rows}
			dataKey={"teams"}
			link={{
				link: "teams",
				title: i18n._(t`profile.myTeams.all`),
			}}
		/>
	);
};

export default MyTeams;
