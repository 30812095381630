import "./style.scss";
import "../style.scss";

import { IonButton, IonIcon, IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import { refreshOutline } from "ionicons/icons";
import { Event } from "model/Event";
import * as React from "react";

interface IEventAddressPartProps {
	event: Event;
}

const EventAddressPart: React.FunctionComponent<IEventAddressPartProps> = ({ event }) => {
	if (!event.paymentRequired) {
		return <></>;
	}
	console.log(event);

	return (
		<>
			<div className="firstDivider"></div>

			<IonItemDivider mode="ios">
				Gewählter Preis:
				<IonButton color="medium" slot="end" fill="clear"></IonButton>
			</IonItemDivider>
			<IonItem lines="none">
				<IonLabel slot="start">{event.eventRegistration?.price?.description}</IonLabel>
				<IonLabel slot="end">{event.eventRegistration?.price?.amount}€</IonLabel>
			</IonItem>
			<IonItemDivider mode="ios">
				Gebuchte Zusatzoptionen:
				<IonButton color="medium" slot="end" fill="clear"></IonButton>
			</IonItemDivider>
			{event.eventRegistration?.selectedAdditionalServices?.map((additionalService, i) => {
				return (
					<IonItem id={additionalService.additionalServiceId} lines="none">
						<IonLabel slot="start">{additionalService?.name}</IonLabel>
						<IonLabel slot="end">{additionalService?.moneyAmount}€</IonLabel>
					</IonItem>
				);
			})}
		</>
	);
};

export default EventAddressPart;
