import { Creator } from "../Creator";
import { IsBusy } from "../IsBusy";
import { ScopedSharingTenant } from "../ScopedSharingTenant";

export interface ValidationModel extends IsBusy {
	id?: string;
	clubs?: boolean;
	address?: boolean;
	mails?: boolean;
	phones?: boolean;
	banks?: boolean;

	medicalinformations?: boolean;
	confectioninformations?: boolean;
	agbs?: boolean;
	basicdata?: boolean;

	scopedSharingTenants?: ScopedSharingTenant[];
	creator?: Creator;
}

export function newValidationModel(): ValidationModel {
	return {
		id: undefined,
		clubs: true,
		address: true,
		mails: true,
		phones: true,
		banks: true,

		medicalinformations: true,
		confectioninformations: true,
		agbs: true,
		basicdata: true,
	};
}
