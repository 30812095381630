import "./style.scss";

import {
	IonAvatar,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonChip,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonNote,
	IonRow,
	IonSpinner,
} from "@ionic/react";
import { callOutline, documentOutline, mailOutline, phonePortraitOutline } from "ionicons/icons";
import { FC, memo, useState } from "react";

import { useHistory } from "react-router";
import { useAppDispatch } from "app/hooks";
import { Team } from "model/Team";
import ContactPersonListItem from "components/contactPerson/ContactPersonListItem";
import { ContactPerson } from "model/ContactPerson";
import TenantHomepageSelect from "components/tenant/TenantHomepageSelect";
import { useSelector } from "react-redux";
import { getContactPersonIsFetching } from "store/team/Team.selectors";
import { ScopeType } from "model/ScopeType";

interface TeamDetailProps {
	team: Team;
}

const TeamDetail: FC<TeamDetailProps> = ({ team }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const [showTenantContactModal, setShowTenantContactModal] = useState(false);
	const isContactPersonFetching = useSelector(getContactPersonIsFetching);

	return (
		<>
			<TenantHomepageSelect
				isOpen={showTenantContactModal}
				onDismiss={() => setShowTenantContactModal(false)}
				tenantId={team.creator?.id ?? team.tenantId}
			/>
			<IonGrid color="amber-50">
				<IonRow>
					<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="10" sizeXl="5" size="5" pushMd="1" pushLg="1" pushXl="0">
						<IonCard color="amber-300">
							{team.headerImageUrl ? (
								<IonImg src={team.headerImageUrl} className="hero-img" />
							) : (
								<IonImg src="assets/images/team_default.jpeg" className="hero-img" />
							)}
							<IonItem color="amber-50" lines="full">
								<div className="ion-margin-top ion-margin-end">
									<IonNote>{team.creator?.name}</IonNote>
									<h5 className="ion-text-wrap ion-margin-top">{team.name}</h5>
								</div>
							</IonItem>
							<IonCardHeader color="amber-300">
								<IonCardSubtitle>Ansprechpartner</IonCardSubtitle>
							</IonCardHeader>

							{isContactPersonFetching ? (
								<IonItem>
									<IonSpinner />
								</IonItem>
							) : (
								<>
									{team.contactPersons?.items?.map((e: ContactPerson) => (
										<ContactPersonListItem
											scopeType={ScopeType.intern}
											contactPerson={e}
											tenantId={team.creator?.id ?? team.tenantId}
										/>
									))}
									{team.contactPersons?.items?.length == 0 || team.contactPersons == undefined ? (
										<IonItem>
											<IonLabel className="ion-text-justify">
												Keine Ansprechpartner vorhanden. Bitte wenden Sie sich bei Fragen an den Verein.
											</IonLabel>
										</IonItem>
									) : (
										<></>
									)}
								</>
							)}
						</IonCard>
						<IonItem lines="none">
							<IonChip onClick={(e) => setShowTenantContactModal(true)} color="primary" slot="end">
								Verein kontaktieren
							</IonChip>
						</IonItem>
					</IonCol>

					<IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="7">
						<IonCard color="amber-300">
							<IonCardHeader>
								<IonCardSubtitle>Beschreibung</IonCardSubtitle>
								<h5 className="ion-text-wrap">{team.name}</h5>
							</IonCardHeader>

							<IonItem>
								<IonCardContent color="amber-50">
									<div
										dangerouslySetInnerHTML={{
											__html: team.description || "Keine Beschreibung vorhanden.",
										}}
									></div>
								</IonCardContent>
							</IonItem>
						</IonCard>
						<IonCard color="amber-300">
							<IonCardHeader>
								<IonCardSubtitle>Dokumente</IonCardSubtitle>
							</IonCardHeader>

							<IonItem>
								<IonCardContent color="amber-50">
									<IonGrid>
										<IonRow>
											{team.documents?.items?.map((e: any) => (
												<IonCol>
													<IonCard>
														<IonItem button={true} target="_" href={e.fileUrl} detailIcon="file">
															<IonAvatar slot="start">
																<IonIcon size="large" icon={documentOutline} />
															</IonAvatar>
															<IonLabel> {e.fileName}</IonLabel>
														</IonItem>
													</IonCard>
												</IonCol>
											))}
											{team.documents?.items?.length == 0 ? "Keine Beschreibung vorhanden." : <></>}
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonItem>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
};

export default memo(TeamDetail);
