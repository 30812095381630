import { IonChip, IonItem, IonLabel, IonNote } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import CreatorChip from "components/creator/CreatorChip";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { pencilOutline } from "ionicons/icons";
import { Phone, phoneString } from "model/Phone";
import { useState, VFC } from "react";

import EditPhoneModal from "./EditModal";
import DetailModalPhone from "./DetailModalPhone";

export interface Props {
	phone: Phone;
}
const PhoneTeaser: VFC<Props> = ({ phone: phone }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		<>
			<IonItem
				onClick={() => setIsEditModalOpen(true)}
				button
				detail={true}
				detailIcon={pencilOutline}
				key={`${phone.id}_phone_teaser_list`}
				id={phone.id}
				disabled={phone.isBusy}
			>
				<DetailModalPhone isOpen={isEditModalOpen} detailItem={phone} onCancel={() => setIsEditModalOpen(false)} />
				<div className="ion-padding">
					<IonLabel>
						<IonLabel className="ion-margin-bottom">{phoneString(phone)}</IonLabel>
						<IonNote>
							<Subtitle text={i18n._(t`common.CreatedBy`)} />
						</IonNote>
						<CreatorChip creator={phone.creator} />
						<IonNote>
							<Subtitle text={i18n._(t`common.SharedTo`)} />
						</IonNote>

						{phone.scopedSharingTenants?.length ?? 0 > 1 ? (
							phone.scopedSharingTenants?.map((tenant, i) => {
								return <ShareTenantChip scopedSharingTenant={tenant} />;
							})
						) : (
							<IonChip outline={false}>
								<IonLabel>Keine Freigaben</IonLabel>
							</IonChip>
						)}
					</IonLabel>
				</div>
			</IonItem>
		</>
	);
};

export default PhoneTeaser;
