import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import LatestEventList from "components/event/LatestEventsList";
import EventViewSwitcher from "components/EventViewSwitcher/EventViewSwitcher";
import { EventsView } from "model/events/EventsView";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getSelectedTenant } from "store/profile/Profile.selectors";

const MiniCalendar: VFC = () => {
	const [view, setView] = useState(EventsView.List);
	const { i18n } = useLingui();

	const selectedTenant = useSelector(getSelectedTenant);

	const switchView = (view: EventsView): void => {
		setView(view);
	};

	return (
		<>
			<div>
				<EventViewSwitcher title={i18n._(t`Events`)} onChange={switchView} />
				{view === EventsView.List ? <LatestEventList /> : <LatestEventList />}
			</div>
		</>
	);

	{
		/*
		<div>
			<EventViewSwitcher title={i18n._(t`Events`)} onChange={switchView} />
			{view === EventsView.List ? <LatestEventList /> : <Calendar tenant={selectedTenant} />}
		</div>
			*/
	}
};

export default MiniCalendar;
