import { IonCol } from "@ionic/react";
import { InfiniteGrid } from "components/infinite/InfiniteGrid";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import BlogEntry from "model/BlogEntry";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getAllBlogEntries, isBlogEntryBusy } from "store/blogEntry/BlogEntry.selectors";

import BlogTeaser from "./BlogTeaser";

interface BlogGridProps {
	onPageScrollEnd: (baseListQuery: BaseListQuery) => Promise<void>;
}

const Grid: FC<BlogGridProps> = ({ onPageScrollEnd }) => {
	const list = useSelector(getAllBlogEntries);
	const isBusy = useSelector(isBlogEntryBusy);

	const [baseList, setBaseList] = useState(newBaseListQuery());
	const renderItem = (item: BlogEntry, index: number) => {
		if (index === 0) {
			return (
				<>
					<IonCol key={item.id + "col0"} id={item.id + "col0"} size="12" size-sm="12" size-lg="6" size-xl="6" pushLg="3" pushXl="3">
						<BlogTeaser entry={item} key={item.id} />
					</IonCol>
					<IonCol
						key={item.id + item.id}
						id={item.id + item.id}
						className="ion-hide-lg-down"
						size="0"
						size-sm="0"
						size-lg="3"
						size-xl="3"
					></IonCol>
				</>
			);
		}

		return (
			<IonCol key={item.id + "col"} id={item.id + "col"} size="12" size-sm="6" size-lg="4" size-xl="4">
				<BlogTeaser entry={item} key={item.id} />
			</IonCol>
		);
	};
	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};

	return <InfiniteGrid list={list} renderItem={renderItem} onInfinite={onInfinite} />;
};
export default Grid;
