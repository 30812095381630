import { IonChip, IonItem, IonLabel, IonNote } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import CreatorChip from "components/creator/CreatorChip";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { pencilOutline } from "ionicons/icons";
import { ConfectionInformation } from "model/ConfectionInformation";
import { useState, VFC } from "react";

import EditConfectionInformationModal from "./ConfectionInformationModal";

export interface Props {
	item: ConfectionInformation;
}
const ConfectionInformationTeaser: VFC<Props> = ({ item: confectionInformation }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		<>
			<IonItem
				onClick={() => setIsEditModalOpen(true)}
				button
				detail={true}
				detailIcon={pencilOutline}
				key={`${confectionInformation.id}_confectioninformation_teaser_list_item`}
				id={confectionInformation.id}
				disabled={confectionInformation.isBusy}
			>
				<EditConfectionInformationModal
					key={`${confectionInformation.id}_confectioninformation_modal`}
					isOpen={isEditModalOpen}
					editItem={confectionInformation}
					onCancel={() => setIsEditModalOpen(false)}
				/>
				<div className="ion-padding">
					<IonLabel>
						<IonLabel className="ion-padding-bottom">
							<IonLabel>
								<h2>{i18n._(t`${confectionInformation?.clothingPartType}`)}</h2>
							</IonLabel>
							<IonNote>
								<IonLabel>{confectionInformation?.confectionSize}</IonLabel>
							</IonNote>
						</IonLabel>
						<IonNote>
							<Subtitle text={i18n._(t`common.CreatedBy`)} />
						</IonNote>
						<CreatorChip creator={confectionInformation.creator} />
						<IonNote>
							<Subtitle text={i18n._(t`common.SharedTo`)} />
						</IonNote>

						{confectionInformation.scopedSharingTenants?.length ?? 0 > 1 ? (
							confectionInformation.scopedSharingTenants?.map((tenant, i) => {
								return <ShareTenantChip scopedSharingTenant={tenant} />;
							})
						) : (
							<IonChip outline={false}>
								<IonLabel>Keine Freigaben</IonLabel>
							</IonChip>
						)}
					</IonLabel>
				</div>
			</IonItem>
		</>
	);
};

export default ConfectionInformationTeaser;
