import "./style.scss";

import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem } from "@ionic/react";
import { TabContex } from "context/TabContext";
import { Approval } from "model/Approval";
import { useContext, useEffect } from "react";

interface PrivacyApprovalProps {
	approval: Approval;
}
export default function PrivacyApproval({ approval }: PrivacyApprovalProps) {
	const tabContext = useContext(TabContex);
	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);
	const getFiles = () => {
		const approvalVersions = approval.approvalversions;
		if (!approvalVersions) {
			return [];
		}
		const files = approvalVersions[0].files;
		return files;
	};
	return (
		<IonCard id={approval.id}>
			<IonItem>
				<div>
					<IonCardHeader>
						<IonCardSubtitle>Nutzung der Software</IonCardSubtitle>
						<IonCardTitle>{approval.name}</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{approval.description}
						<br></br>
						{getFiles().length != 0 ? (
							<a
								href=""
								target="_blank"
								onClick={() => {
									{
										getFiles().forEach((file) => window.open(file.fileurl));
									}
								}}
							>
								Vollständige Dokumente anzeigen
							</a>
						) : (
							<></>
						)}
					</IonCardContent>
				</div>
			</IonItem>
		</IonCard>
	);
}
