import "./style.scss";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import {
	IonAvatar,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonLoading,
	IonModal,
	IonRouterLink,
	IonRow,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import { arrowBackOutline, downloadOutline } from "ionicons/icons";
import { Event } from "model/Event";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchEventContractById } from "store/event/Event.thunks";
import { getProfile } from "store/profile/Profile.selectors";

import EventAddressPart from "./parts/EventAddressPart";
import EventAGBPart from "./parts/EventAGBPart";
import EventClubPart from "./parts/EventClubPart";
import EventIBANPart from "./parts/EventIBANPart";
import EventNotePart from "./parts/EventNotePart";
import EventPricePart from "./parts/EventPricePart";
import EventStartPart from "./parts/EventStartPart";
import EventTakePart from "./parts/EventTakePart";

interface IEventParticipateStartProps {
	event: Event;
	isOpen: boolean;
	dismiss: () => void;
}

const EventParticipateOverview: React.FunctionComponent<IEventParticipateStartProps> = ({ event, isOpen, dismiss }) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		if ((event.paymentRequired || event.contractId) && !event.contract) {
			//	dispatch<any>(fetchEventContractById(event.id ?? ""));
		}
	}, [event.contract]);

	return (
		<IonModal className="eventModel ion-no-padding ion-justify-content-center" isOpen={isOpen}>
			<IonContent className="ion-no-padding" fullscreen={true}>
				<IonHeader mode="md">
					<IonToolbar color="primary">
						<IonButtons slot="start">
							<IonButton onClick={dismiss}>
								<IonIcon icon={arrowBackOutline} />
							</IonButton>
						</IonButtons>
						<IonTitle size="large">Anmeldung</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonGrid className="ion-justify-content-center ion-no-padding">
					<IonRow>
						<IonCol
							className="ion-no-padding"
							sizeXs="12"
							sizeSm="8"
							sizeMd="6"
							sizeLg="7"
							sizeXl="5"
							pushSm="2"
							pushMd="3"
							pushLg="2.5"
							pushXl="3.5"
						>
							{event.eventRegistration ? (
								<>
									<EventStartPart event={event} />
									<EventTakePart event={event} />
									{event?.contract ? <EventPricePart event={event} /> : <></>}
									{event?.addressRequired || event?.contract ? <EventAddressPart event={event} /> : <></>}
									{event?.ibanRequired || event?.contract ? <EventIBANPart event={event} /> : <></>}

									<EventClubPart event={event} />
									<EventNotePart event={event} />
									{event?.contract ? <EventAGBPart event={event} /> : <></>}

									<div className="buttomDivider"></div>
									<IonItemDivider hidden={!event?.eventRegistration?.confirmationUrl} mode="ios">
										Aktionen:
									</IonItemDivider>

									{event?.eventRegistration?.confirmationUrl ? (
										<IonItem lines="none">
											<IonButton
												size="default"
												color="tertiary"
												expand="block"
												className="btn-take-part"
												target="_"
												href={event?.eventRegistration?.confirmationUrl}
											>
												<IonIcon slot="end" icon={downloadOutline} />
												Teilnahmebestätigung
											</IonButton>
										</IonItem>
									) : (
										<></>
									)}
									<IonButton
										size="default"
										fill="solid"
										color="danger"
										className="ion-align-self-end"
										title={i18n._("event.takePart")}
										expand="block"
										onClick={dismiss}
										hidden={true}
									>
										<Trans>Absagen</Trans>
									</IonButton>
								</>
							) : (
								<>
									<IonLoading message="Ihre Anmeldung wird vorbereitet." isOpen={isOpen} />
								</>
							)}
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonFooter className="ion-no-border">
					<IonItem lines="none">
						<IonAvatar slot="start">
							<IonImg src={"assets/icon/favicon-96x96.png"} />
						</IonAvatar>
						<IonRouterLink slot="start" target="_blank" href="https://www.dso-verein.de/impressum" color="medium">
							<IonLabel>Impressum</IonLabel>
							<IonLabel>Datenschutz</IonLabel>
						</IonRouterLink>
						<IonRouterLink slot="end" target="_blank" href="https://support.dso-verein.de" color="medium">
							<IonLabel>Helpcenter</IonLabel>
						</IonRouterLink>
					</IonItem>
				</IonFooter>
			</IonContent>
		</IonModal>
	);
};

export default EventParticipateOverview;
