import "./style.scss";
import "../style.scss";

import { IonButton, IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonNote, IonRow } from "@ionic/react";
import { alertCircleOutline, checkmarkCircleOutline, downloadOutline, refreshOutline } from "ionicons/icons";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getAvatarImageUrl, getProfile } from "store/profile/Profile.selectors";

interface IEventAGBPartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventAGBPart: React.FunctionComponent<IEventAGBPartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const [allChecked, setAllChecked] = useState(true);
	const [approvals, setApprovals] = useState<string[]>(new Array<string>());

	if (!event) {
		return <></>;
	}
	if ((event?.contract?.necessaryApprovals?.length ?? -1) <= 0 ?? false) {
		return <></>;
	}
	useEffect(() => {
		const allCheckedVar = event.contract?.necessaryApprovals.length == approvals.length;
		setAllChecked(!allCheckedVar);
	}, [approvals]);

	return (
		<>
			<div className="firstDivider"></div>

			<IonCard>
				<IonGrid className={allChecked ? "gridNoSuccess" : "gridSuccess"}>
					<IonRow>
						<IonCol size="0" sizeLg="1" sizeMd="0" sizeXl="1" sizeXs="0">
							{allChecked ? (
								<IonIcon className="notDoneIcon" src={alertCircleOutline} />
							) : (
								<IonIcon className="isDoneIcon" src={checkmarkCircleOutline} />
							)}
						</IonCol>
						<IonCol size="11" sizeLg="11" sizeMd="11" sizeXl="11" sizeXs="12">
							<IonCard>
								<IonItemDivider className={allChecked ? "rowDivider" : "rowDividerSuccess"} mode="ios">
									Rechtliches:
									<IonLabel color="danger">*</IonLabel>
									<IonButton color="medium" slot="end" fill="clear">
										<IonIcon color="medium" size="small" src={refreshOutline}></IonIcon>
									</IonButton>
								</IonItemDivider>
								{event.contract?.necessaryApprovals.map((neccesaryApproval, i) => {
									return (
										<IonItem className="rowItem ion-activatable" key={i} mode="ios" lines="full">
											<IonIcon slot="start" src={downloadOutline} />

											<IonLabel slot="start">
												<br />
												{neccesaryApproval?.approval?.name}
												<br />
												<IonNote slot="start">
													<div
														className="lines max-1"
														dangerouslySetInnerHTML={{ __html: neccesaryApproval?.approval?.description ?? "" }}
													></div>
												</IonNote>
											</IonLabel>
											<IonCheckbox
												slot="end"
												mode="md"
												checked={eventRegistration.approvals?.includes(neccesaryApproval.id)}
												onIonChange={(e) => {
													if (e.detail.checked) {
														eventRegistration.approvals = eventRegistration.approvals?.filter(
															(x) => x != neccesaryApproval.id,
														);
														eventRegistration.approvals?.push(neccesaryApproval.id);
														setEventRegistration(eventRegistration);
													} else {
														eventRegistration.approvals = eventRegistration.approvals?.filter(
															(x) => x != neccesaryApproval.id,
														);
														setEventRegistration(eventRegistration);
													}
													setApprovals(eventRegistration?.approvals ?? new Array<string>());
												}}
											/>
										</IonItem>
									);
								})}
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventAGBPart;
