import { useState, useEffect, useContext } from "react";
import { CapacitorOAuth2Context } from "./CapacitorOAuth2Context";

export const useCapacitorOAuth2 = () => {
	const currentCapacitorOAuth2Context = useContext(CapacitorOAuth2Context);

	if (!currentCapacitorOAuth2Context) {
		throw new Error("useCapacitorOAuth2 has to be used within <CurrentUserContext.Provider>");
	}
	return currentCapacitorOAuth2Context;
};
