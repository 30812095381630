import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { useLingui } from "@lingui/react";
import { openOutline } from "ionicons/icons";
import { Page } from "model/Page";
import { FC } from "react";
import { useHistory } from "react-router-dom";

interface Props {
	page: Page;
	isSubPage: boolean;
}
const MenuItem: FC<Props> = ({ page, isSubPage }) => {
	const history = useHistory();
	const { i18n } = useLingui();
	const isActive = (): boolean => {
		if (page.path === "/") {
			if (history.location.pathname === "/") {
				return true;
			}
			return false;
		}

		return history.location.pathname.startsWith(page.path);
	};

	return (
		<>
			<IonItem
				button={true}
				detail={page.external}
				detailIcon={openOutline}
				routerLink={!page.external ? page.path : undefined}
				href={page.external ? page.path : undefined}
				target={page.external ? "_blank" : undefined}
				lines="none"
				routerDirection="none"
				className={isActive() ? "selected ion-margin-start" : "ion-margin-start"}
			>
				<IonIcon icon={page.icon} className={isSubPage === true ? "ion-margin-start" : undefined} />
				<IonLabel color={isActive() ? "amber-900" : "amber-950"} className={isActive() ? "ion-margin-start" : "ion-margin-start"}>
					{i18n._(page.title)}
				</IonLabel>
			</IonItem>
			{page.subPages && history.location.pathname.startsWith(page.path) ? (
				<div className="ion-margin-start">
					{page.subPages?.map((sp) => (
						<MenuItem page={sp} isSubPage={true} />
					))}
				</div>
			) : (
				<> </>
			)}
		</>
	);
};
export default MenuItem;
