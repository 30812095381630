import { IonRouterOutlet } from "@ionic/react";
import SignInRedirectCallback from "components/auth/SignInRedirectCallback";
import TeamDetailPage from "pages/team/TeamDetailPage";
import AccountPage from "pages/account/AccountPage";
import BlogDetailPage from "pages/blog/BlogDetailPage";
import BlogPage from "pages/blog/BlogPage";
import { ChatPage } from "pages/chat/ChatPage";
import DashboardPage from "pages/dashboard/DashboardPage";
import EventDetailPage from "pages/events/EventDetailPage";
import EventsPage from "pages/events/Events";
import EventsCalendarPage from "pages/events/EventsCalendarPage";
import { HonorsPage } from "pages/honor/HonorsPage";
import InvoicesPage from "pages/invoices/Invoices";
import { LicensesPage } from "pages/license/LicensesPage";
import LogPage from "pages/log/LogPage";
import MessagesPage from "pages/messages/Messages";
import { PrivacyApprovalPage } from "pages/privacy/PrivacyApprovalPage";
import Addresses from "pages/profile/Addresses";
import AvatarImagesPage from "pages/profile/AvatarImagesPage";
import BankAccounts from "pages/profile/BankAccounts";
import { ConfectionInformationsPage } from "pages/profile/ConfectionInformationsPage";
import Mails from "pages/profile/Mails";
import { MedicalInformationsPage } from "pages/profile/MedicalInformationsPage";
import Phones from "pages/profile/Phones";
import ProfilePage from "pages/profile/ProfilePage";
import { ProfileValidationListPage } from "pages/profileValidation/ProfileValidationListPage";
import { ProfileValidationProcessPage } from "pages/profileValidation/ProfileValidationProcessPage";
import { QualificationsPage } from "pages/qualification/QualificationsPage";
import { TeamsPage } from "pages/team/TeamsPage";
import TenantsPage from "pages/tenant/TenantsPage";
import { Route } from "react-router";

export const Routes = (
	<IonRouterOutlet id="menu-content">
		<Route path="/signin-oidc" exact={true} strict>
			<SignInRedirectCallback />
		</Route>
		<Route path={"/"} exact strict>
			<DashboardPage />
		</Route>
		<Route path={"/clubs"} exact strict>
			<TenantsPage />
		</Route>
		<Route path={"/blog"} exact>
			<BlogPage />
		</Route>
		<Route path={"/blog/:id"} exact>
			<BlogDetailPage />
		</Route>

		<Route path={"/events"} exact>
			<EventsPage />
		</Route>

		<Route path={"/events/:id"} exact>
			<EventDetailPage />
		</Route>
		<Route path={"/profile"} exact>
			<ProfilePage />
		</Route>

		<Route path={"/account"} exact>
			<AccountPage />
		</Route>

		<Route path={"/inbox"} exact>
			<ChatPage />
		</Route>

		<Route path={"/profile/mail"} exact>
			<Mails />
		</Route>
		<Route path={"/profile/phone"} exact>
			<Phones />
		</Route>
		<Route path={"/profile/bankaccount"} exact>
			<BankAccounts />
		</Route>
		<Route path={"/profile/avatar"} exact>
			<AvatarImagesPage />
		</Route>
		<Route path={"/profile/address"} exact>
			<Addresses />
		</Route>
		<Route path={"/profile/miscellaneous"} exact>
			<MedicalInformationsPage />
		</Route>
		<Route path={"/profile/confectionInformations"} exact>
			<ConfectionInformationsPage />
		</Route>
		<Route path={"/teams"} exact>
			<TeamsPage />
		</Route>
		<Route path={"/teams/:id"} exact>
			<TeamDetailPage />
		</Route>
		<Route path={"/licenses"} exact>
			<LicensesPage />
		</Route>

		<Route path={"/qualifications"} exact>
			<QualificationsPage />
		</Route>
		<Route path={"/honors"} exact>
			<HonorsPage />
		</Route>
		<Route path={"/calendar"} exact>
			<EventsCalendarPage />
		</Route>
		<Route path={"/validation/profile"} exact>
			<ProfileValidationListPage />
		</Route>
		<Route path={"/validation/profile/:id"} exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path={"/validation/profile/:id/info"} exact>
			<ProfileValidationProcessPage />
		</Route>

		<Route path={"/validation/profile/:id/profile"} exact>
			<ProfileValidationProcessPage />
		</Route>

		<Route path={"/validation/profile/:id/address"} exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path="/validation/profile/:id/mail" exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path="/validation/profile/:id/phone" exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path="/validation/profile/:id/confectioninformation" exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path="/validation/profile/:id/bank" exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path="/validation/profile/:id/miscellaneous" exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path="/validation/profile/:id/agb" exact>
			<ProfileValidationProcessPage />
		</Route>
		<Route path="/validation/profile/:id/clubs" exact>
			<ProfileValidationProcessPage />
		</Route>
		{/*

<AuthorizedRoute path={"/profile/honor"} component={Honors} exact />
<AuthorizedRoute path={"/profile/qualification"} component={Qualifications} exact />
*/}
		<Route path={"/logs"} exact>
			<LogPage />
		</Route>
		<Route path={"/privacy"} exact>
			<PrivacyApprovalPage />
		</Route>

		<Route path={"/invoices"} exact>
			<InvoicesPage />
		</Route>
		<Route path={"/messages"} exact>
			<MessagesPage />
		</Route>
		{/*
		<IonTabBar slot="bottom">
			<IonTabButton tab="blog" href={"/blog"}>
				<IonIcon icon={newspaperOutline} />
			</IonTabButton>
			<IonTabButton tab="event" href={"/events"}>
				<IonIcon icon={calendarOutline} />
			</IonTabButton>
			<IonTabButton tab="profile" href={"/profile"}>
				<IonIcon icon={personCircleOutline} />
			</IonTabButton>
			<IonTabButton tab="invoice" href={"/invoices"}>
				<IonIcon icon={documentTextOutline} />
			</IonTabButton>
			<IonTabButton tab="message" href={"/messages"}>
				<IonIcon icon={chatbubblesOutline} />
			</IonTabButton>
		</IonTabBar>
	</IonTabs>
*/}
	</IonRouterOutlet>
);
