import { RefresherEventDetail } from "@ionic/core";
import { IonCol } from "@ionic/react";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Team } from "model/Team";
import { FC, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllTeams, getIsFetching } from "store/team/Team.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import Teaser from "./Teaser";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const List: FC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getAllTeams);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());

	const renderItem = (item: Team) => {
		return (
			<>
				<IonCol size="12" size-sm="12" sizeXs="12" size-lg="12" size-xl="12" key={item.id}>
					<Teaser team={item} />
				</IonCol>
			</>
		);
	};
	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery());
		}
		event.detail.complete();
	};
	return <InfiniteBaseList items={list} isFetching={isBusy} renderItem={renderItem} onInfinite={onInfinite} onRefresh={reloadEntries} />;
};
export default List;
