import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { Phone } from "model/Phone";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";

import { RootState } from "../..";
import { getScope, getSelectedTenant } from "../Profile.selectors";

export const getAllPhones = (state: RootState): BaseList<Phone> => state.phone.value || newBaseList<Phone>();
export const getIsFetching = (state: RootState): boolean => state.phone.isFetching;
export const getIsShareInternFetching = (state: RootState): boolean => state.phone.isShareInternFetching;
export const getIsShareExternFetching = (state: RootState): boolean => state.phone.isShareExternFetching;

export const getPhones = createSelector(getAllPhones, getSelectedTenant, (phones: BaseList<Phone>, tenant: Tenant | null) => {
	return tenant
		? filterBaseListItems(phones, (item) => item.scopedSharingTenants?.some((t) => t.id === tenant.id) || item.creator?.id == tenant.id)
		: phones;
});

export const getPhone = createSelector(
	getAllPhones,
	getSelectedTenant,
	getScope,
	(phones: BaseList<Phone>, tenant: Tenant | null, scopeType?: ScopeType): Phone | undefined => {
		let result;
		switch (scopeType) {
			case ScopeType.default:
				if (tenant) {
					result = phones.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isDefault);
				} else {
					result = phones.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isDefault === true)[0]);
				}
				break;
			case ScopeType.intern:
				if (tenant) {
					result = phones.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isIntern);
				} else {
					result = phones.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isIntern === true)[0]);
				}
				break;
			case ScopeType.extern:
				if (tenant) {
					result = phones.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isExtern);
				} else {
					result = phones.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isExtern === true)[0]);
				}
				break;
		}

		return result !== undefined ? result : phones.items.length ? phones.items[0] : undefined;
	},
);

export const makeGetPhones = createSelector(getAllPhones, getSelectedTenant, (phones, tenant) => {
	if (tenant) {
		phones.items = phones.items?.filter((a) => a.scopedSharingTenants?.map((t) => t.id).includes(tenant?.id ?? ""));
	}

	return phones;
});
