import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { newBaseList } from "model/BaseList";
import { License } from "model/License";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";

import { fetchLicenses, reloadLicenses } from "./License.thunks";

export const initialState = {
	...defaultState(newBaseList<License>()),
	selectedTenant: null as unknown as Tenant,
};

export type LicenseState = typeof initialState;

const slice = createSlice({
	name: "license",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(fetchLicenses.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadLicenses.fulfilled), (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadLicenses.pending, fetchLicenses.pending), (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addMatcher(isAnyOf(reloadLicenses.rejected, fetchLicenses.rejected), (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
		});
	},
});
export default slice.reducer;
