import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonChip,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonModal,
	IonSpinner,
	IonToolbar,
	useIonToast,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { useCapacitorOAuth2 } from "auth/CapacitorOAuth2Hook";
import axios from "axios";
import InputItem from "components/form/InputItem";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { closeOutline, trashOutline, pencilOutline } from "ionicons/icons";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getIdTokenState } from "store/profile/Profile.selectors";

export interface Props {
	isOpen: boolean;
	onCancel(): void;
}

const DeleteAccountModal: FC<Props> = ({ isOpen, onCancel }) => {
	const [present] = useIonToast();
	const [isLoading, setIsLoading] = useState(false);
	const capacitorAuth = useCapacitorOAuth2();

	const buttonClicked = async () => {
		try {
			setIsLoading(true);

			const parsedIdTokeb = await capacitorAuth.getParsedIdTokenAsync();

			const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/b2c/user/${parsedIdTokeb?.sub}/disable`);
			if (res.status == 200) {
				console.log("sendEmail");
			} else {
				console.log("sendEmail");
			}
		} catch (error) {
			console.log(error);
		} finally {
			//Set Loading after 1 Second to prevent flickering
			presentToast();

			await setTimeout(async () => {
				setIsLoading(false);
				onCancel();
				await capacitorAuth.logOutAsync();
			}, 2000);
		}
	};

	const presentToast = () => {
		present({
			message: "Ihre Anfrage wurde erfolgreich versendet. Sie werden nun abgemeldet.",
			duration: 1500,
			position: "top",
		});
	};
	return (
		<IonModal color="amber-50" isOpen={isOpen} onDidDismiss={(e) => onCancel()}>
			<IonHeader>
				<div className="ion-text-center">
					<IonToolbar color="amber-300">
						<IonButton
							disabled={isLoading}
							fill="clear"
							slot="start"
							onClick={() => {
								onCancel();
							}}
						>
							<IonIcon color="medium" icon={closeOutline}></IonIcon>
						</IonButton>

						<IonLabel slot="start">
							<h2>
								<Trans>Löschen deines Sportcloud Accounts</Trans>
							</h2>
						</IonLabel>
					</IonToolbar>
				</div>
			</IonHeader>

			<IonContent color="amber-50">
				<IonCard>
					<IonCardHeader>Feedback</IonCardHeader>
					<IonCardContent>
						Wir bedauern sehr, dass du dich dazu entschieden hast, deinen Sportcloud-Account zu löschen. Dein Feedback ist uns wichtig,
						und wir respektieren deine Entscheidung.
					</IonCardContent>
				</IonCard>
				<IonCard>
					<IonCardHeader>Aufbewahrungspflichten</IonCardHeader>
					<IonCardContent>
						Bitte beachte jedoch, dass es bestimmte rechtliche Aufbewahrungspflichten gibt, die es uns unter Umständen unmöglich machen,
						alle deine Daten unverzüglich zu löschen. Diese Verpflichtungen dienen dazu, die Integrität und Sicherheit unserer Plattform
						sowie die Einhaltung gesetzlicher Vorschriften sicherzustellen.
					</IonCardContent>
				</IonCard>
				<IonCard>
					<IonCardHeader>Deine Daten</IonCardHeader>
					<IonCardContent>
						Wir werden unser Bestes tun, um deine persönlichen Daten zu schützen und nur die erforderlichen Informationen gemäß unseren
						Datenschutzrichtlinien zu behalten. Beachte bitte, dass wir nach dem Löschen keine neuen Daten mehr von dir direkt sammeln
						oder verwenden werden.
					</IonCardContent>
				</IonCard>
			</IonContent>
			<IonButton
				className="ion-margin"
				color="danger"
				expand="block"
				size="default"
				disabled={isLoading}
				onClick={async () => await buttonClicked()}
			>
				{isLoading ? <IonSpinner name="lines" /> : <IonLabel>Account löschen</IonLabel>}
			</IonButton>
		</IonModal>
	);
};

export default DeleteAccountModal;
