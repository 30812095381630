import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { Team } from "model/Team";
import TagChip from "components/chip/TagChip";
import { FC } from "react";

export interface Props {
	team: Team;
}
const Teaser: FC<Props> = ({ team }) => {
	return (
		<IonItem color="amber-50" routerLink={`teams/${team.id}`} lines="full" button detail={true} key={team.id} id={team.id}>
			<div className="ion-margin-bottom">
				<IonNote>{team.creator?.name}</IonNote>
				<IonLabel className="ion-text-wrap">{team.name}</IonLabel>

				<IonNote>
					{team.tagElements?.map((tag) => (
						<TagChip tag={tag}></TagChip>
					))}
				</IonNote>
			</div>
		</IonItem>
	);
};
export default Teaser;
