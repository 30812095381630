import {
	IonAlert,
	IonAvatar,
	IonButton,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonModal,
	IonProgressBar,
	IonSearchbar,
	IonSpinner,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { InfiniteBaseList } from "components/infinite/InfiniteBaseList";
import TenantHomepageSelect from "components/tenant/TenantHomepageSelect";
import { Console } from "console";
import { closeSharp } from "ionicons/icons";
import { BaseList } from "model/BaseList";
import { newBaseListQuery } from "model/BaseListQuery";
import { PersonTenant } from "model/person/PersonTenant";
import { Tenant } from "model/Tenant";
import { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import tenantService from "services/TenantService";
import { getProfile } from "store/profile/Profile.selectors";

interface RegisterToTenantProperties {
	isOpen: boolean;
	setIsOpen: (state: boolean) => void;
}

const RegisterToTenant: VFC<RegisterToTenantProperties> = ({ isOpen, setIsOpen }: RegisterToTenantProperties) => {
	const [searchText, setSearchText] = useState<string>();
	const [isFetching, setIsFetching] = useState(false);

	const [searchResult, setSearchResult] = useState<BaseList<PersonTenant>>();
	const person = useSelector(getProfile);
	useEffect(() => {
		//fetch
		console.log(searchText);
		const fetchData = async () => {
			setIsFetching(true);
			if (person != null) {
				const baseList = newBaseListQuery();
				baseList.keyword = searchText?.toLowerCase();
				baseList.pagesize = 100;
				baseList.searchfields = ["normalizedname"];
				const res = await tenantService.searchTenants(baseList, person?.id);
				setSearchResult(res);
			}
			setIsFetching(false);
		};
		fetchData()
			// make sure to catch any error
			.catch(() => setIsFetching(false));
	}, [searchText]);

	const registerToTenant = () => {
		//Dispatch
		// Done
		setIsOpen(false);
	};

	return (
		<IonModal isOpen={isOpen}>
			<IonContent fullscreen={true}>
				<IonHeader>
					<IonToolbar>
						<IonItem lines="none">
							<IonTitle slot="start" size="large">
								Verein hinzufügen
							</IonTitle>
							<IonButton slot="end" fill="clear" onClick={(e) => setIsOpen(false)}>
								<IonIcon size="medium" icon={closeSharp} />
							</IonButton>
						</IonItem>
					</IonToolbar>
				</IonHeader>
				{isFetching ? <IonProgressBar type="indeterminate" /> : <></>}

				<IonList>
					<IonListHeader>
						<IonSearchbar
							value={searchText}
							onIonChange={(e) => setSearchText(e.detail.value!)}
							debounce={720}
							placeholder="Vereinsname"
							animated={true}
							spellCheck={true}
						></IonSearchbar>
					</IonListHeader>

					<IonListHeader>
						<IonLabel>Wähle einen Verein aus um ihn deinem Konto hinzuzufügen:</IonLabel>
						<IonLabel slot="end">{searchResult?.maximumcount}</IonLabel>
					</IonListHeader>
					<IonList>
						{searchResult?.items.map((personTenant) => {
							return <ListTenantItem tenant={personTenant} registerToTenant={registerToTenant} />;
						})}
					</IonList>
				</IonList>
			</IonContent>
		</IonModal>
	);
};
interface ListTenantItemsProperties {
	tenant: Tenant;
	registerToTenant: () => void;
}
const ListTenantItem: VFC<ListTenantItemsProperties> = ({ tenant, registerToTenant }: ListTenantItemsProperties) => {
	const [showAlert, setShowAlert] = useState(false);

	return (
		<>
			<IonItem id={tenant.id} button={true} onClick={() => setShowAlert(true)}>
				<IonAvatar slot="start">
					<IonImg src={tenant.avatarImageUrl}></IonImg>
				</IonAvatar>
				<IonLabel>
					<IonLabel>{tenant.name}</IonLabel>
				</IonLabel>
			</IonItem>
			<IonAlert
				isOpen={showAlert}
				header="Bestätigung:"
				onDidDismiss={() => {
					setShowAlert(false);
					registerToTenant();
				}}
				message={`Möchtest Du den Verein <br/> <br/> <strong>${tenant.name}</strong> <br/> <br/> zu deinem Konto hinzufügen?`}
				buttons={["Abbrechen", "Hinzufügen"]}
			/>
		</>
	);
};
export default RegisterToTenant;
