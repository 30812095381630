import { VFC, useEffect, useRef, useState } from "react";
import Talk from "talkjs";
import { t } from "@lingui/macro";
import Page from "components/page/Page";
import { i18n } from "@lingui/core";
import "./style.scss";
import { useAppDispatch } from "app/hooks";
import { getChatUser, getIdTokenState, getIsFetching, getProfile } from "store/profile/Profile.selectors";
import { useSelector } from "react-redux";
import { fetchChatUser } from "store/profile/Profile.thunks";
import { IonButton, IonContent, IonSpinner } from "@ionic/react";

export const ChatPage: VFC = () => {
	const [talkLoaded, markTalkLoaded] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	const dispatch = useAppDispatch();
	const loginToken = useSelector(getIdTokenState);

	const isFetching = useSelector(getIsFetching);

	Talk.ready.then(() => markTalkLoaded(true));
	const chatboxEl = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isMounted) {
			return;
		}
		Talk.ready.then(() => markTalkLoaded(true));

		if (talkLoaded && loginToken != null) {
			// User

			const session = new Talk.Session({
				appId: process.env.REACT_APP_TALKJS_APPID ?? "",
				me: new Talk.User(loginToken.chatuser_id),
				signature: loginToken.chatuser_signature,
				//signature: "testSignature",
			});

			const chatbox = session.createInbox(
				//https://talkjs.com/docs/Reference/JavaScript_Chat_SDK/Session/#Session__createInbox
				{
					showFeedHeader: false,
				},
			);
			chatbox.mount(chatboxEl.current);
			setIsMounted(true);
			return () => session.destroy();
		}
	}, [talkLoaded]);

	useEffect(() => {
		loadChatUser();
	}, [loginToken]);

	function loadChatUser() {
		if (isFetching && loginToken != null) {
			dispatch<any>(fetchChatUser(loginToken.chatuser_id));
		}
	}

	return (
		<Page title={i18n._(t`Inbox`)} onViewEnter={() => loadChatUser()}>
			{isFetching ? loginToken != null ? <IonSpinner></IonSpinner> : <IonButton onClick={() => loadChatUser()}>Refresh</IonButton> : <></>}
			<div ref={chatboxEl} className="talkjsInbox" />
		</Page>
	);
};
