import axios from "axios";
import { BankAccount } from "model/BankAccount";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { Tenant } from "model/Tenant";

class BankAccountService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<BankAccount>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.orderby = "CreatedAt";

		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/bankaccount/search`, { params, headers: { userId: userId } });
		return res.data;
	}
	async add(userId: string, bankAccount: BankAccount): Promise<BankAccount> {
		const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/bankaccount`, bankAccount, { headers: { userId: userId } });
		return res.data;
	}
	async update(userId: string, bankAccount: BankAccount): Promise<BankAccount> {
		const res = await axios.put(`${process.env.REACT_APP_API_ME_URL}/api/me/bankaccount/${bankAccount.id}`, bankAccount, {
			headers: { userId: userId },
		});
		return res.data;
	}
	async delete(userId: string, bankAccount: BankAccount): Promise<BankAccount> {
		const res = await axios.delete(`${process.env.REACT_APP_API_ME_URL}/api/me/bankaccount/${bankAccount.id}`, { headers: { userId: userId } });
		return res.data;
	}
	async share(userId: string, bankAccount: BankAccount): Promise<BankAccount> {
		const res = await axios.put(
			`${process.env.REACT_APP_API_ME_URL}/api/me/bankaccount/${bankAccount.id}/share`,
			{ tenantIds: bankAccount.scopedSharingTenants?.map((t) => t.id) },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
}
const bankAccountService = new BankAccountService();
export default bankAccountService;
