import "./style.scss";

import { IonImg } from "@ionic/react";
import { Trans } from "@lingui/macro";
import { Profile } from "model/Profile";
import { VFC } from "react";

import avatar from "../../assets/avatar.png";

export interface Props {
	headerImageUrl?: string;
	avatarImageUrl?: string;
	profile: Profile;
}
export const DashboardHeader: VFC<Props> = (props: Props) => {
	return (
		<div className="card-hero-wrapper">
			<div className="menu-wrapper">
				<div className="title-text">
					<Trans>dashboard.titleText</Trans>
				</div>
				<div className="welcome-text">
					<Trans values={{ name: props.profile?.firstName }}>dashboard.welcomeText</Trans>
				</div>
			</div>
			<div className="card-avatar-wrapper">
				{props.avatarImageUrl == undefined || props.avatarImageUrl == "" ? (
					<IonImg class="card-avatar" src="assets/images/avatar.png" />
				) : (
					<IonImg class="card-avatar" src={props.avatarImageUrl} />
				)}
			</div>
		</div>
	);
};

export default DashboardHeader;
