import { AVATAR_LOGO } from './ImageConstants';

export function checkImageAvatarUrl(imageUrl?: string | undefined): string {

	if (imageUrl == undefined || imageUrl == "" || imageUrl == null) {
		return AVATAR_LOGO;
	}
	return imageUrl;
}
export function checkImageAvatarUrls(imageUrls?: Array<string | undefined> | undefined): string {
	if (imageUrls == undefined || imageUrls == null || imageUrls.length == 0) {
		return AVATAR_LOGO;
	}
	const resultArray = imageUrls.filter(element => {
		return !(element == undefined || element == '' || element == null)
	});
	if (resultArray.length == 0) {
		return AVATAR_LOGO;
	}

	return resultArray[0] ?? AVATAR_LOGO;
}

export function checkTenantImageAvatarUrl(imageUrl?: string | undefined): string {

	if (imageUrl == undefined || imageUrl == "" || imageUrl == null) {
		return AVATAR_LOGO;
	}
	return imageUrl;
}
