import {
	IonAvatar,
	IonButton,
	IonCheckbox,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonToolbar,
} from "@ionic/react";
import { convertTenantToScopedSharingTenant, ScopedSharingTenant } from "model/ScopedSharingTenant";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getAllTenants } from "store/profile/Profile.selectors";
import "./styles.scss";
import { closeOutline, peopleCircleSharp } from "ionicons/icons";
import { Trans } from "@lingui/macro";

export interface Props {
	scopedSharingTenants?: ScopedSharingTenant[];
	creatorId?: string;
	done(tenants: ScopedSharingTenant[]): void;
	cancel(): void;
}

const ShareTenantSelectModal: FC<Props> = ({ scopedSharingTenants, creatorId, done, cancel }) => {
	const tenants = useSelector(getAllTenants)?.map((tenant) => convertTenantToScopedSharingTenant(tenant)) || [];
	const [valueList, setvalueList] = useState(scopedSharingTenants);
	const [hasUpdated, setHasUpdated] = useState(false);

	const isCheckBoxChecked = (scopedSharingTenant: ScopedSharingTenant) => {
		return valueList?.find((x) => x.id == scopedSharingTenant.id) != undefined || scopedSharingTenant.id == creatorId;
	};

	const checkDisabled = (scopedSharingTenant: ScopedSharingTenant) => {
		return scopedSharingTenant.id == creatorId;
	};

	const rowClick = (item: ScopedSharingTenant) => {
		console.log("rowClick");
		setHasUpdated(true);
		const index = valueList?.find((x) => x.id == item.id);
		if (index != undefined) {
			const newList = valueList?.filter((x) => x.id != item.id);
			setvalueList(newList);
		} else {
			const newList = [...(valueList ?? []), item];
			setvalueList(newList);
		}
	};

	const onDone = () => {
		if (valueList == null || undefined) {
			return;
		}
		hasUpdated ? done(valueList) : cancel();
	};
	return (
		<>
			<IonHeader color="amber-50">
				<div className="ion-text-center">
					<IonToolbar color="amber-300">
						<IonButton
							fill="clear"
							slot="start"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								cancel();
							}}
						>
							<IonIcon color="medium" icon={closeOutline}></IonIcon>
						</IonButton>
						<IonLabel slot="start">
							<h2>
								<Trans>Freigabe</Trans>
							</h2>
						</IonLabel>
					</IonToolbar>
				</div>
			</IonHeader>
			<IonContent class="ion-margin">
				<IonList id="modal-list" inset={true}>
					{tenants?.map((item) => (
						<IonItem
							button={false}
							lines="full"
							className="ion-margin-bottom"
							key={`${item.id}_tenant_select_item`}
							disabled={checkDisabled(item)}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								rowClick(item);
							}}
						>
							{item.avatarImageUrl == null || item.avatarImageUrl == undefined || item.avatarImageUrl == "" ? (
								<IonIcon size="large" slot="start" src={peopleCircleSharp}></IonIcon>
							) : (
								<IonAvatar slot="start">
									<IonImg src={item.avatarImageUrl}></IonImg>
								</IonAvatar>
							)}
							<IonLabel className="ion-margin-bottom" slot="start">
								{item.name}
							</IonLabel>
							<IonCheckbox mode="ios" slot="end" value={item} checked={isCheckBoxChecked(item)}></IonCheckbox>
						</IonItem>
					))}
				</IonList>
			</IonContent>
			<IonFooter>
				<IonButton disabled={!hasUpdated} expand="block" size="default" onClick={() => onDone()}>
					<IonLabel>
						<Trans>Save</Trans>
					</IonLabel>
				</IonButton>
			</IonFooter>
		</>
	);
};

export default ShareTenantSelectModal;
