import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import Page from "components/page/Page";
import List from "components/team/List";
import { TabContex } from "context/TabContext";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useContext, useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { getSelectedTenant } from "store/profile/Profile.selectors";
import { fetchValidationModels, reloadValidationModels } from "store/profileValidation/ProfileValidation.thunks";

export const ProfileValidationListPage: VFC = () => {
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);

	useEffect(() => {
		dispatch<any>(reloadValidationModels(newBaseListTenantQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchValidationModels(newBaseListTenantQuery(newBaseListQuery(basListQuery), selectedTenant)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadValidationModels(newBaseListTenantQuery(newBaseListQuery(basListQuery), selectedTenant)));
	};
	return (
		<Page title={i18n._(t`My teams`)}>
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
		</Page>
	);
};
