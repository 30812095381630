import { createSelector } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { Team } from "model/Team";

import { RootState } from "..";

export const getAllTeams = (state: RootState): BaseList<Team> => state.team.value || newBaseList<Team>();

export const getLatestTeams = createSelector(getAllTeams, (events: BaseList<Team>) => events.items.slice(0, 5) || []);
export const getTeam = (id: string) => createSelector(getAllTeams, (list) => list.items.find((item) => item.id === id));
export const getIsFetching = (state: RootState): boolean => state.team.isFetching;
export const getContactPersonIsFetching = (state: RootState): boolean => state.team.contactPersonIsFetching;
