import { RefresherEventDetail } from "@ionic/core";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Phone } from "model/Phone";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching, getPhones } from "store/profile/phone/Phone.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import Teaser from "./Teaser";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const List: VFC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getPhones);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery()); /*
	const renderItem = (item: Address) => {
		const itemMemo = useMemo(() => <Teaser address={item} />, [item]);

		return itemMemo;
	};
	*/
	const renderItem = (item: Phone) => <Teaser key={`${item.id}_phone_teaser_list`} phone={item} />;

	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery());
		}
		event.detail.complete();
	};

	return <InfiniteBaseList items={list} isFetching={isBusy} renderItem={renderItem} onInfinite={onInfinite} onRefresh={reloadEntries} />;
};

export default List;
