import { IonChip, IonItem, IonLabel, IonNote } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import CreatorChip from "components/creator/CreatorChip";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { pencilOutline } from "ionicons/icons";
import { Address, addressString } from "model/Address";
import { FC, useState, VFC } from "react";

import EditAddressModal from "./EditModal";
import DetailModalAddress from "./DetailModalAddress";

export interface Props {
	address: Address;
}
const AddressTeaser: FC<Props> = (props: Props) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	function closeModal() {
		setIsEditModalOpen(false);
		console.log("close modal", isEditModalOpen);
	}
	function openModal() {
		setIsEditModalOpen(true);
		console.log("open modal", isEditModalOpen);
	}
	return (
		<IonItem
			onClick={(e) => {
				console.log("event", e);
				e.stopPropagation();
				e.preventDefault();
				openModal();
			}}
			button
			detail={true}
			detailIcon={pencilOutline}
			key={`${props.address.id}_address_teaser`}
			id={props.address.id}
			disabled={props.address.isBusy}
		>
			<DetailModalAddress key={`${props.address.id}_detail_modal`} isOpen={isEditModalOpen} detailItem={props.address} onCancel={closeModal} />

			<div key={props.address.id} className="ion-padding">
				<IonLabel key={props.address.id}>
					<IonLabel className="ion-margin-bottom">{addressString(props.address)}</IonLabel>
					<IonNote>
						<Subtitle text={i18n._(t`common.CreatedBy`)} />
					</IonNote>
					<CreatorChip creator={props.address.creator} />
					<IonNote>
						<Subtitle text={i18n._(t`common.SharedTo`)} />
					</IonNote>

					{props.address.scopedSharingTenants?.length ?? 0 > 1 ? (
						props.address.scopedSharingTenants?.map((tenant, i) => {
							return <ShareTenantChip key={`${tenant.id}_${props.address.id}_tenant_select_item`} scopedSharingTenant={tenant} />;
						})
					) : (
						<IonChip outline={false}>
							<IonLabel>Keine Freigaben</IonLabel>
						</IonChip>
					)}
				</IonLabel>
			</div>
		</IonItem>
	);
};

export default AddressTeaser;
