import { Creator } from "./Creator";
import { IsBusy } from "./IsBusy";
import { ScopedSharingTenant } from "./ScopedSharingTenant";

export interface BankAccount extends IsBusy {
	zip?: string;
	city?: string;
	bankcode?: string;
	name?: string;
	iban?: string;
	bic?: string;
	id?: string;
	scopedSharingTenants?: ScopedSharingTenant[];
	creator?: Creator;
}

export function bankAccountString(bankAccount?: BankAccount): string | undefined {
	return bankAccount?.iban;
}
export function newBankAccount(): BankAccount {
	return {
		id: undefined,
	};
}
