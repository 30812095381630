import { createSelector } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { Qualification } from "model/Qualification";

import { RootState } from "..";

export const getAllQualifications = (state: RootState): BaseList<Qualification> => state.qualification.value || newBaseList<Qualification>();

export const getLatestQualifications = createSelector(getAllQualifications, (events: BaseList<Qualification>) => events.items.slice(0, 5) || []);
export const getQualifications = (id: string) => createSelector(getAllQualifications, (list) => list.items.find((item) => item.id === id));
export const getIsFetching = (state: RootState): boolean => state.qualification.isFetching;
