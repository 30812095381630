import { RefresherEventDetail } from "@ionic/core";
import { IonInfiniteScroll, IonInfiniteScrollContent, IonProgressBar, IonRefresher, IonRefresherContent } from "@ionic/react";
import { BaseList } from "model/BaseList";
import { ReactElement } from "react";

export interface Props {
	isFetching: boolean;
}
export const CustomProgressBar = ({ isFetching }: Props): ReactElement => {
	if (isFetching) {
		return <IonProgressBar type="indeterminate"></IonProgressBar>;
	} else {
		return <></>;
	}
};
