import { Draft } from '@reduxjs/toolkit';
import { IHasScopeSharingTenants } from 'model/IHasScopeSharingTenants';
import { ScopeType } from 'model/ScopeType';
import { ScopedSharingTenant } from 'model/ScopedSharingTenant';

export const SPORTCLOUD_LOGO = "assets/icon/logo_quer.png";

export const checkArrayAny = (tenant: Array<any>) => tenant == null || tenant == undefined || tenant?.length == 0;

export const findAndUpdateScopeSharedTenant = (scopedSharingTenants: Array<Draft<ScopedSharingTenant>> | undefined,
	tenantIdToUpdate: string, scopeType: ScopeType, newValue: boolean) => {

	// Check if scopedSharingTenants is null or undefined
	if (scopedSharingTenants == null || scopedSharingTenants == undefined) {
		return;
	}

	const oldScopeSharingTenant = scopedSharingTenants.find((x) => x.id == tenantIdToUpdate)!;

	if (oldScopeSharingTenant == null || oldScopeSharingTenant == undefined) {
		return scopedSharingTenants;
	}

	const index = scopedSharingTenants.indexOf(oldScopeSharingTenant);

	if (scopeType == ScopeType.extern) {
		oldScopeSharingTenant.isExtern = newValue;
	} else if (scopeType == ScopeType.intern) {
		oldScopeSharingTenant.isIntern = newValue;
	}

	scopedSharingTenants[index] = oldScopeSharingTenant;

	return scopedSharingTenants;

}
