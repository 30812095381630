import { FC, memo, useEffect, useState } from "react";
import { useCapacitorOAuth2 } from "./CapacitorOAuth2Hook";
import { useAppDispatch } from "app/hooks";
import { fetchProfile, updateIdToken } from "store/profile/Profile.thunks";
import { useSelector } from "react-redux";
import { getIdTokenState } from "store/profile/Profile.selectors";
import { IdTokenClaims } from "model/IdTokenClaims";
import LoggedOutPage from "components/LoggedOut/LoggedOutPage";

const ProtectedRoute = (props: any) => {
	const capacitorAuth = useCapacitorOAuth2();
	const dispatch = useAppDispatch();
	const idToken = useSelector(getIdTokenState);
	const [isTimerDone, setTimerDone] = useState(false);
	setTimeout(() => {
		setTimerDone(true);
	}, 4000);
	function ClearAuthStateFlow() {
		try {
			//			console.log("No token found, redirecting to login");
			//Clear the local storage, so we can start a new auth flow
			localStorage.clear();
			//clear all cookies for this page to prevent the auth flow from failing
			document.cookie.split(";").forEach(function (c) {
				document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
			});
			//Reload the page to restart the auth flow
			//window.location.reload();
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		//If we have a token, we are good to go
		const checkToken = async () => {
			const parsedIdTokenResult = await capacitorAuth.getParsedIdTokenOrAuthorizeAsync();
			if (parsedIdTokenResult == null) {
				console.log("No token found!!!");
				ClearAuthStateFlow();
				return;
			}
			console.log("Token found on CheckToken", parsedIdTokenResult);
			dispatch<any>(updateIdToken(parsedIdTokenResult));
		};

		const challengeToken = async (code: string) => {
			const challengeTokenResult = await capacitorAuth.challengeTokenAsync(code);
			//	console.log(challengeTokenResult);
			const parsedIdTokenResult = capacitorAuth.parseJwt(challengeTokenResult.id_token) as IdTokenClaims;
			//	console.log(parsedIdTokenResult);
			dispatch<any>(updateIdToken(parsedIdTokenResult));
		};

		//Loging URL for debugging the token
		//		console.log(window.location.href);
		try {
			if (idToken == null || idToken == undefined) {
				// ### This part is only needed for web, it does not touch the mobile implementation, cause the mobile implementation is handled by the plugin
				// Response Mode is Query -> look in the URL for it
				const urlParams = new URLSearchParams(window.location.search);
				const myParam = urlParams.get("code");
				if (myParam != null) {
					challengeToken(myParam as string);
					window.history.replaceState("", document.title, window.location.pathname);
				}
				// #### Web parts ends here
				else {
					// this is the normal call for starting the auth flow
					checkToken();
					ClearAuthStateFlow();
				}
			}
		} catch (error) {
			console.log(checkToken, error);
		}
	}, [idToken]);
	useEffect(() => {
		if (idToken == null || idToken == undefined) {
			return;
		}
		//		console.log("Fetch profile on AuthRoute", idToken);
		dispatch<any>(fetchProfile(idToken.person_id));
	}, [idToken]);
	console.log("ProtectedRoute: idToken", idToken);

	//If we have a token, we are good to go
	if (idToken != null && idToken != undefined) {
		//		console.log("ProtectedRoute: idToken", idToken);
		return props.children;
	}
	if (isTimerDone) {
		return <LoggedOutPage />;
	}
	return <></>;
};
export default memo(ProtectedRoute);
