import { IonAvatar, IonChip, IonIcon, IonImg, IonLabel } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { VFC } from "react";

export interface Props {
	scopedSharingTenant: ScopedSharingTenant;
}
const ShareTenantChip: VFC<Props> = ({ scopedSharingTenant }) => {
	if (scopedSharingTenant == undefined) {
		return <></>;
	}
	return (
		<IonChip id={scopedSharingTenant.id + "_Chip"} outline={true}>
			{scopedSharingTenant?.avatarImageUrl ? (
				<IonAvatar>
					<IonImg src={scopedSharingTenant?.avatarImageUrl} />
				</IonAvatar>
			) : (
				<></>
			)}
			<IonLabel>{scopedSharingTenant.name}</IonLabel>
		</IonChip>
	);
};

export default ShareTenantChip;
