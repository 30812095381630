import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQuery, BaseListQueryInstance } from "model/BaseListQuery";
import { Invoice } from "model/Invoice";
import { Tenant } from "model/Tenant";

class InvoiceService {
	async getList(query: BaseListQueryInstance, userId: string, tenant?: Tenant, from?: Date, till?: Date): Promise<BaseList<Invoice>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantIds", tenant.id);
		}
		if (from) {
			params.append("from", from.toISOString());
		}
		if (till) {
			params.append("till", till.toISOString());
		}
		params.append("receiverId", userId);
		query.toEntries().forEach(([key, value]) => {
			if (key === "orderby") {
				value = "InvoiceDate";
			}
			if (key === "orderbytype") {
				value = "descending";
			}
			params.append(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_SEARCH_URL}/api/invoice`, { params });
		return res.data;
	}
}
const invoiceService = new InvoiceService();
export default invoiceService;
