import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditAddressModal from "components/address/EditModal";
import List from "components/address/List";
import ProfileValidationFooter from "components/profileValidation/components/ProfileValidationFooter";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { add } from "ionicons/icons";
import { newAddress } from "model/Address";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useContext, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/address/Address.selectors";
import { fetchAddresses, reloadAddresses } from "store/profile/address/Address.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";
import { reloadValidationModels } from "store/profileValidation/ProfileValidation.thunks";

export const ProfileValidationProcessAddressPage: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);
	const isLoading = useSelector(getIsFetching);
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);

	useEffect(() => {
		dispatch<any>(reloadAddresses(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchAddresses(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadAddresses(newBaseListQuery(basListQuery)));
	};
	useEffect(() => {
		dispatch<any>(reloadValidationModels(newBaseListTenantQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	return (
		<IonPage>
			<IonContent>
				<ProfileValidationHeader title="Adressen" progress={4} />
				<EditAddressModal isOpen={isNewAddressModalOpen} address={newAddress()} onCancel={() => setIsNewAddressModalOpen(false)} />
				<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={() => setIsNewAddressModalOpen(true)}>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>
			</IonContent>
			<ProfileValidationFooter
				isLoading={isLoading}
				progress={4}
				routerLinkNext="/validation/profile/1/phone"
				routerLinkBack="/validation/profile/1/miscellaneous"
			/>
		</IonPage>
	);
};
