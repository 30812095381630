import { OAuth2RefreshTokenOptions } from "@byteowls/capacitor-oauth2";

export const RefreshCapacitorAzureB2cOptions = (refreshToken: string) => {
	if (!refreshToken) {
		console.error("refresh token is missing");
	}

	return {
		appId: process.env.REACT_APP_B2C_CLIENT_ID,
		scope: "openid profile offline_access", // See Azure Portal -> API permission
		accessTokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
		refreshToken: refreshToken,
	} as OAuth2RefreshTokenOptions;
};
