import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonFooter,
	IonHeader,
	IonImg,
	IonPage,
	IonText,
	IonToolbar,
} from "@ionic/react";
import { FC } from "react";

/// <summary>
/// This page is shown when the user is logged out or starting the app and the app is checking if the user is logged in.
/// </summary>
const LoggedOutGetStarted: FC = () => {
	return (
		<IonPage color="amber-50">
			<IonHeader translucent={true} color="primary">
				<IonToolbar>
					<IonImg className="logoImage ion-margin-bottom" src={"assets/icon/logo_quer.png"} />
				</IonToolbar>
			</IonHeader>
			<IonContent className="center" color="amber-50">
				<IonCard>
					<IonCardHeader>
						<IonCardTitle>Jetzt einloggen und dein Vereinsleben neu gestalten!</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<IonText>
							Bereit für ein ultimatives Vereinserlebnis? Mit den neuesten Updates in unserer App ist jetzt der perfekte Zeitpunkt, sich
							einzuloggen. Erlebe ein optimiertes Vereinsmanagement, organisiere Events, und vernetze dich mit deinem Sportclub wie nie
							zuvor. Wir haben die Tools für mühelose Datenpflege und Übersicht noch weiter verbessert. Verpasse nicht die Chance, dein
							Vereinsleben auf die nächste Stufe zu heben. Logge dich jetzt ein und entdecke die neue Sportcloud-Welt!
						</IonText>
					</IonCardContent>
				</IonCard>
			</IonContent>
			<IonFooter className="ion-padding-bottom">
				<IonButton
					expand="full"
					shape="round"
					className="ion-margin ion-align-items-center ion"
					onClick={async (e) => window.location.reload()}
				>
					Jetzt anmelden
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default LoggedOutGetStarted;
