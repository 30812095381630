import "./style.scss";
import "../style.scss";

import { IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import { Event } from "model/Event";
import * as React from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getAvatarImageUrl, getProfile } from "store/profile/Profile.selectors";

interface IEventClubPartProps {
	event: Event;
}

const EventClubPart: React.FunctionComponent<IEventClubPartProps> = ({ event }) => {
	const profile = useSelector(getProfile);
	const tenant = useSelector(getAllTenants).find((t) => t.id == event.tenantId);
	const avatarImageUrl = useSelector(getAvatarImageUrl);
	return (
		<>
			{event.eventRegistration?.tenantName ? (
				<>
					<IonItemDivider mode="ios">Verein:</IonItemDivider>

					<IonItem lines="none">
						<IonLabel>{event.eventRegistration?.tenantName}</IonLabel>
					</IonItem>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default EventClubPart;
