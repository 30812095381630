import { IonButton, IonContent, IonFooter, IonIcon, IonItem, IonList, IonPage, IonToolbar } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import DateItem from "components/form/DateItem";
import InputItem from "components/form/InputItem";
import GenderTypePicker from "components/profile/GenderTypePicker";
import ProfileValidationFooter from "components/profileValidation/components/ProfileValidationFooter";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { newBaseListQuery } from "model/BaseListQuery";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useContext, useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching, getProfile, getSelectedTenant } from "store/profile/Profile.selectors";
import { reloadValidationModels } from "store/profileValidation/ProfileValidation.thunks";

export const ProfileValidationProcessProfilePage: VFC = () => {
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();
	const storedProfile = useSelector(getProfile);
	const profile = { ...storedProfile };
	const isLoading = useSelector(getIsFetching);
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);
	useEffect(() => {
		dispatch<any>(reloadValidationModels(newBaseListTenantQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	return (
		<IonPage>
			<IonContent className="ion-no-padding">
				<ProfileValidationHeader title="Basisdaten" progress={1} />
				<div className="ion-no-padding">
					<IonList>
						<InputItem
							name={i18n._(t`Firstname`)}
							placeholder={i18n._(t`Firstname`)}
							value={profile.firstName}
							onChange={(value) => (profile.firstName = value)}
						/>
						<InputItem
							name={i18n._(t`Lastname`)}
							placeholder={i18n._(t`Lastname`)}
							value={profile.lastName}
							onChange={(value) => (profile.lastName = value)}
						/>
						<GenderTypePicker genderType={profile.gender} onChange={(value) => (profile.gender = value)} />

						<DateItem
							value={profile.birthdate ? new Date(profile.birthdate).toISOString() : undefined}
							placeholder={i18n._(t`Birthdate`)}
							name={i18n._(t`Birthdate`)}
							onChange={(e) => (profile.birthdate = e ?? profile.birthdate)}
						/>
						<InputItem
							name={i18n._(t`Birthplace`)}
							placeholder={i18n._(t`Birthplace`)}
							value={profile.birthplace}
							onChange={(value) => (profile.birthplace = value)}
						/>
					</IonList>
				</div>
			</IonContent>

			<IonFooter>
				<IonToolbar>
					<IonItem lines="none">
						<IonButton disabled={isLoading} slot="end" routerLink={"/validation/profile/1/mail"}>
							Weiter
							<IonIcon icon={chevronForwardOutline} />
						</IonButton>
					</IonItem>
				</IonToolbar>
			</IonFooter>
		</IonPage>
	);
};
