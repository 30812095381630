import { Action, configureStore, getDefaultMiddleware, ThunkAction } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

import { rootReducer } from "./rootReducer";

const middleware = [
	...getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: {
			//ignoredActionPaths: ["payload.user"],
			// Ignore these paths in the state
			ignoredPaths: [],
		},
	}),
];

if (process.env.REACT_APP_ENABLE_LOGGER === "true") {
	//	middleware.push(createLogger());
}

export const store = configureStore({
	reducer: rootReducer,
	middleware,
	devTools: process.env.REACT_APP_ENABLE_DEVTOOLS === "true",
});

export type AppThunk<T = void> = ThunkAction<T, RootState, undefined, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type GetState = typeof store.getState;
