import "./style.scss";
import "../style.scss";

import { IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import { Event } from "model/Event";
import { EventProgressType } from "model/events/EventProgressType";
import * as React from "react";

interface IEventTakePartProps {
	event: Event;
}

const EventTakePart: React.FunctionComponent<IEventTakePartProps> = ({ event }) => {
	if (!event.paymentRequired) {
		return <></>;
	}
	console.log(event);

	return (
		<>
			<div className="firstDivider"></div>

			<IonItemDivider mode="ios">Angemeldet als:</IonItemDivider>

			{event.eventRegistration?.eventProgressType == EventProgressType.Participating ? (
				<IonItem lines="none">
					<IonLabel>Teilnehmer</IonLabel>
				</IonItem>
			) : (
				<IonItem>
					<IonLabel>Warteliste</IonLabel>
				</IonItem>
			)}
		</>
	);
};

export default EventTakePart;
