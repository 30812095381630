import { IonIcon, IonItem, IonItemSliding, IonLabel } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { dateString } from "helper/DateHelper";
import * as NumberHelper from "helper/NumberHelper";
import { checkmarkCircleOutline, informationCircleOutline, receiptOutline } from "ionicons/icons";
import { Invoice } from "model/Invoice";
import { InvoiceType } from "model/InvoiceType";
import { RouteComponentProps, withRouter } from "react-router";

import { Subtitle } from "../text/subtitle/Subtitle";
import { Title } from "../text/title/Title";

export interface Props extends RouteComponentProps {
	invoice: Invoice;
}
const Teaser = (props: Props) => {
	return (
		<IonItemSliding class={"type-" + props.invoice.type?.toString().toLowerCase()}>
			<IonItem href={props.invoice.fileUri} detail={false}>
				<IonLabel>
					<Title text={props.invoice.invoiceNumber ? `Nummer: ${props.invoice.invoiceNumber}` : undefined} />
					<Subtitle text={props.invoice.invoiceDate ? `Rechnungsdatum: ${dateString(new Date(props.invoice.invoiceDate))}` : undefined} />
					{props.invoice.isPaid === true ? (
						<p className="success">
							<IonIcon
								size="small"
								className="ion-margin-end ion-justify-content-center vertical-align-sub"
								icon={checkmarkCircleOutline}
							/>
							Rechnung bezahlt
						</p>
					) : props.invoice.isPaid === false ? (
						<>
							<p className="warning">
								<IonIcon
									size="small"
									className="ion-margin-end ion-justify-content-center vertical-align-sub"
									icon={receiptOutline}
								/>
								Rechnung nicht bezahlt
							</p>
							<p className="default">
								<IonIcon
									size="small"
									className="ion-margin-end ion-justify-content-center vertical-align-sub"
									icon={informationCircleOutline}
								/>
								Bezahlen Sie den Betrag nur, sofern Sie keine Lastschrift-Ermächtigung erteilt haben.
							</p>
						</>
					) : (
						<Subtitle text={undefined} />
					)}
				</IonLabel>
				<div>
					<h4>
						{props.invoice.amountBruttoAsNumber == undefined
							? undefined
							: (props.invoice.type == InvoiceType.outgoing ? "+" : "-") +
							  (props.invoice.amountBruttoAsNumber != undefined
									? NumberHelper.currencyString(props.invoice.amountBruttoAsNumber) ?? ""
									: "")}
					</h4>
				</div>
			</IonItem>
		</IonItemSliding>
	);
};
export default withRouter(Teaser);
