import "./style.scss";
import "../style.scss";

import { IonButton, IonFooter, IonIcon, IonItem, IonToolbar } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import * as React from "react";

interface IProfileValidationFooter {
	progress: number;
	isLoading: boolean;
	routerLinkNext: string;
	routerLinkBack: string;
}

const ProfileValidationFooter: React.FunctionComponent<IProfileValidationFooter> = ({ progress, routerLinkNext, routerLinkBack, isLoading }) => {
	const [buttonDisabled, setButtonDisabled] = React.useState(false);
	function timeoutButton() {
		setTimeout(() => setButtonDisabled(true), 5000);
	}

	return (
		<IonFooter>
			<IonToolbar>
				<IonItem lines="none">
					<IonButton onClick={(e) => timeoutButton()} slot="start" disabled={isLoading || buttonDisabled} routerLink={routerLinkBack}>
						Zurück
						<IonIcon slot="start" icon={chevronBackOutline} />
					</IonButton>
					<IonButton onClick={(e) => timeoutButton()} disabled={isLoading || buttonDisabled} slot="end" routerLink={routerLinkNext}>
						Weiter
						<IonIcon icon={chevronForwardOutline} />
					</IonButton>
				</IonItem>
			</IonToolbar>
		</IonFooter>
	);
};

export default ProfileValidationFooter;
