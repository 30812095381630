import { createSelector } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { Honor } from "model/Honor";

import { RootState } from "..";

export const getAllHonors = (state: RootState): BaseList<Honor> => state.honor.value || newBaseList<Honor>();

export const getLatestHonors = createSelector(getAllHonors, (events: BaseList<Honor>) => events.items.slice(0, 5) || []);
export const getHonor = (id: string) => createSelector(getAllHonors, (list) => list.items.find((item) => item.id === id));
export const getIsFetching = (state: RootState): boolean => state.honor.isFetching;
