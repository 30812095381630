import { createAsyncThunk } from "@reduxjs/toolkit";
import { BankAccount } from "model/BankAccount";
import { BaseList } from "model/BaseList";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { UpsertModel } from "model/UpsertModel";
import bankAccountService from "services/BankAccountService";

import { RootState } from "../..";

const baseString = "bankAccount";
export const fetchBankAccounts = createAsyncThunk<
	// Return type of the payload creator
	BaseList<BankAccount>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: BaseListQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			const query = newBaseListQuery(filter);
			const list = await bankAccountService.get(state.profile.idTokenClaim.person_id, query);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: BaseListQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const listeSate = state.bankAccount;

			if (listeSate.value && listeSate.value.page) {
				if (listeSate.value.items.length === listeSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const reloadBankAccounts = createAsyncThunk<
	// Return type of the payload creator
	BaseList<BankAccount>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: BaseListQuery, thunkAPI) => {
	try {
		const newQuery = newBaseListQuery(filter);
		const state = thunkAPI.getState() as RootState;

		const list = await bankAccountService.get(state.profile.idTokenClaim.person_id, newQuery);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});

export const addBankAccount = createAsyncThunk<BankAccount, UpsertModel<BankAccount>, { rejectValue: string }>(
	`${baseString}/add`,
	async (updateModel: UpsertModel<BankAccount>, thunkAPI) => {
		try {
			const bankAccountResult = await bankAccountService.add(updateModel.userId, updateModel.item);
			return bankAccountResult;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);

export const updateBankAccount = createAsyncThunk<BankAccount, UpsertModel<BankAccount>, { rejectValue: string }>(
	`${baseString}/update`,
	async (updateModel: UpsertModel<BankAccount>, thunkAPI) => {
		try {
			const bankAccountResult = await bankAccountService.update(updateModel.userId, updateModel.item);
			return bankAccountResult;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const deleteBankAccount = createAsyncThunk<string | undefined, UpsertModel<BankAccount>, { rejectValue: string }>(
	`${baseString}/delete`,
	async (deleteModel: UpsertModel<BankAccount>, thunkAPI) => {
		try {
			const bankAccountResult = await bankAccountService.delete(deleteModel.userId, deleteModel.item);
			return deleteModel.item.id;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const shareBankAccount = createAsyncThunk<BankAccount, UpsertModel<BankAccount>, { rejectValue: string }>(
	`${baseString}/share`,
	async (shareModel: UpsertModel<BankAccount>, thunkAPI) => {
		try {
			const bankAccountResult = await bankAccountService.share(shareModel.userId, shareModel.item);
			return bankAccountResult;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
