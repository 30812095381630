import { IonLoading } from "@ionic/react";
import { useAuthContext } from "context/auth/AuthContext";
import { useEffect, useState, VFC } from "react";
import { Redirect } from "react-router";

const SignInRedirectCallback: VFC = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const auth = useAuthContext();

	useEffect(() => {
		auth.signinRedirectCallback().then(() => {
			setIsAuthenticated(true);
		});
	}, []);

	if (isAuthenticated === true) {
		return <Redirect to={auth.redirectTo ?? "/"} />;
	} else {
		return <IonLoading message={"Please wait..."} isOpen={true} />;
	}
};

export default SignInRedirectCallback;
