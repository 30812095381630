import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import EditPhoneModal from "components/phone/EditModal";
import List from "components/phone/List";
import { add } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newPhone, Phone } from "model/Phone";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { newUpsertModel } from "model/UpsertModel";
import { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { addPhone, fetchPhones, reloadPhones } from "store/profile/phone/Phone.thunks";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

import ProfilePageLayout from "./ProfilePageLayout";

const Phones: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewPhoneModalOpen, setIsNewPhoneModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);

	useEffect(() => {
		dispatch<any>(reloadPhones(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchPhones(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadPhones(newBaseListQuery(basListQuery)));
	};
	return (
		<ProfilePageLayout title={i18n._(t`Phones`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<EditPhoneModal isOpen={isNewPhoneModalOpen} editItem={newPhone()} onCancel={() => setIsNewPhoneModalOpen(false)} />
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => setIsNewPhoneModalOpen(true)}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
		</ProfilePageLayout>
	);
};

export default Phones;
