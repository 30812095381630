import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { Invoice } from "model/Invoice";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllInvoices, invoiceIsBusy } from "store/invoice/Invoice.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import Teaser from "./Teaser";

interface InvoiceListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const List: VFC<InvoiceListProps> = ({ onPageScrollEnd }) => {
	const list = useSelector(getAllInvoices);
	const isBusy = useSelector(invoiceIsBusy);

	const [baseList, setBaseList] = useState(newBaseListQuery());
	const renderItem = (item: Invoice) => {
		return <Teaser invoice={item} key={item.id} />;
	};

	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	return <InfiniteBaseList items={list} renderItem={renderItem} onInfinite={onInfinite} isFetching={false} />;
};
export default List;
