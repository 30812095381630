import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import BlogEntryGrid from "components/blogEntry/Grid";
import Page from "components/page/Page";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newLoadBlogEntriesQuery } from "model/blogEntry/LoadBlogEntriesQuery";
import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { fetchBlogEntries, reloadBlogEntries } from "store/blogEntry/BlogEntry.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

const BlogPage: VFC = () => {
	console.log("render BlogPage");
	const dispatch = useAppDispatch();
	const selectedTenant = useSelector(getSelectedTenant);

	useEffect(() => {
		dispatch<any>(reloadBlogEntries(newLoadBlogEntriesQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	const loadEntries = async (baseListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchBlogEntries(newLoadBlogEntriesQuery(newBaseListQuery(baseListQuery), selectedTenant)));
	};

	return (
		<Page title={i18n._(t`Blog entries`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<BlogEntryGrid onPageScrollEnd={loadEntries} />
		</Page>
	);
};

export default BlogPage;
