import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonText,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { Trans } from "@lingui/macro";
import { caretForwardOutline, closeOutline, eyeOutline } from "ionicons/icons";
import { FC } from "react";

export interface Props {
	isOpen: boolean;
	onCancel(): void;
}

const ExplainImageUsageModal: FC<Props> = ({ isOpen, onCancel }) => {
	return (
		<>
			<IonModal color="amber-50" isOpen={isOpen} onDidDismiss={(e) => onCancel()}>
				<IonHeader>
					<div className="ion-text-center">
						<IonToolbar color="amber-300">
							<IonButton
								fill="clear"
								slot="start"
								onClick={() => {
									onCancel();
								}}
							>
								<IonIcon color="medium" icon={closeOutline}></IonIcon>
							</IonButton>

							<IonLabel slot="start">
								<h2>
									<Trans>Standardprofilbild für Sportcloud.de</Trans>
								</h2>
							</IonLabel>
							<IonContent></IonContent>
						</IonToolbar>
					</div>
				</IonHeader>
				<IonContent color="amber-50">
					<div>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle className="ion-margin-top">Dein Profil in Sportcloud.de</IonCardTitle>
								<IonCardSubtitle>
									<IonIcon slot="start" src={eyeOutline} className="ion-margin-end"></IonIcon> Was bedeutet das?
								</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent>
								<IonItem lines="none">
									<IonIcon src={caretForwardOutline} className="ion-margin-end"></IonIcon>
									<IonText className="ion-text-justify ion-margin-start">
										Für eine einheitliche Darstellung und leichtere Identifikation in der Sportcloud.de haben wir einen
										Standardprofilbild-Service implementiert. Wenn du unter dem Punkt "Sportcloud.de" ein Profilbild hochlädst,
										wird dieses Bild als Standardprofilbild für alle Vereine, denen du angehörst, verwendet. Ausgenommen, Du hast
										speziell für einen Verein ein Bild festgelegt.
									</IonText>
								</IonItem>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle className="ion-margin-top">Privat-Profilbild</IonCardTitle>
								<IonCardSubtitle>
									<IonIcon slot="start" src={eyeOutline} className="ion-margin-end"></IonIcon> Sichtbarkeit: Nur für
									Vereinsadministratoren
								</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent>
								<IonItem lines="none">
									<IonIcon src={caretForwardOutline} className="ion-margin-end"></IonIcon>
									<IonText className="ion-text-justify ion-margin-start">
										Dieses Bild wird nur von den Verwaltungsmitgliedern des Vereins gesehen, um administrative Angelegenheiten zu
										erleichtern.
									</IonText>
								</IonItem>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle className="ion-margin-top">Internes Profilbild</IonCardTitle>
								<IonCardSubtitle>
									<IonIcon slot="start" src={eyeOutline} className="ion-margin-end"></IonIcon> Sichtbarkeit: Für Vereinsmitglieder
								</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent>
								<IonItem lines="none">
									<IonIcon src={caretForwardOutline} size="large" className="ion-margin-end"></IonIcon>
									<IonText className="ion-text-justify ion-margin-start">
										Diese Option erlaubt, dass dein Bild innerhalb des Vereins geteilt wird. Es ist für andere Vereinsmitglieder
										sichtbar und dient zur internen Verwendung im Verein.
									</IonText>
								</IonItem>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle className="ion-margin-top">Externes Profilbild</IonCardTitle>
								<IonCardSubtitle>
									<IonIcon slot="start" src={eyeOutline} className="ion-margin-end"></IonIcon> Sichtbarkeit: Vereinsmitglieder und
									öffentlich (z. B. auf der Vereinswebsite)
								</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent>
								<IonItem lines="none">
									<IonIcon src={caretForwardOutline} className="ion-margin-end"></IonIcon>
									<IonText className="ion-text-justify ion-margin-start">
										Wenn du dich für ein externes Profilbild entscheidest, erlaubst du dem Verein, das Bild auch öffentlich zu
										verwenden. Dies könnte beispielsweise auf der Vereinswebsite oder in anderen öffentlichen Kontexten sein.
									</IonText>
								</IonItem>
							</IonCardContent>
						</IonCard>
					</div>
				</IonContent>

				<IonButton expand="block" size="default" onClick={() => onCancel()}>
					<IonLabel>Schließen</IonLabel>
				</IonButton>
			</IonModal>
		</>
	);
};

export default ExplainImageUsageModal;
