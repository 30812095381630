import { IonItem, IonLabel } from "@ionic/react";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import ButtonMore from "components/buttons/ButtonMore";
import isSameDay from "date-fns/isSameDay";
import { Event } from "model/Event";
import { VFC } from "react";
import { formatDate, formatDateTime, formatTime } from "utils";

import { Subtitle } from "../text/subtitle/Subtitle";
import { Title } from "../text/title/Title";

export interface Props {
	event: Event;
}
const SmallEventTeaser: VFC<Props> = ({ event }) => {
	const { i18n } = useLingui();

	const end = isSameDay(new Date(event.startTime), new Date(event.endTime)) ? formatTime(event.endTime) : formatDateTime(event.endTime);

	const text = `${i18n._(t`date`)}: ${formatDate(event.startTime)} | ${i18n._(t`start`)}: ${formatTime(event.startTime)} | ${i18n._(
		t`end`,
	)}: ${end}`;

	return (
		<IonItem color="amber-50" routerLink={`/events/${event.id}`} detail>
			<IonLabel>
				<Title text={event.name} />
				<Subtitle text={text} />
			</IonLabel>
		</IonItem>
	);
};
export default SmallEventTeaser;
