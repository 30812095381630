import { AdditionalService } from "./AdditionalService";
import { AdditionalServiceGroup } from "./AdditionalServiceGroup";

//Used for API Post and Select in Registration
export interface SelectedAdditionalService {
	additionalServiceId: string;
	additionalServiceGroupId: string;
	additionalServiceIsRequired: boolean;
	name: string;
	moneyAmount: number;
}
export function createSelectedAdditionalService(additionalServices: Array<AdditionalServiceGroup>): Array<SelectedAdditionalService> {
	if (additionalServices == undefined) {
		return new Array<SelectedAdditionalService>();
	}
	let result = new Array<SelectedAdditionalService>();
	// Map additionalServices to SelectedAdditionalService
	additionalServices.forEach((item) => (result = result.concat(createNewSelectedAdditionalService(item))));
	return result;
}
export function createNewSelectedAdditionalService(additionalService: AdditionalServiceGroup): Array<SelectedAdditionalService> {
	// Map additionalServices to SelectedAdditionalService
	const result = additionalService.additionalServices.map((additionalService) => {
		return {
			additionalServiceGroupId: additionalService.additionalServiceGroupId,
			additionalServiceId: additionalService.id,
			additionalServiceIsRequired: false,
			name: additionalService.name,
			moneyAmount: additionalService.moneyAmount,
		};
	});
	return result;
}
