import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { newBaseList } from "model/BaseList";
import { Team } from "model/Team";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";

import { fetchTeamById, fetchTeams, getContactPersons, getTeamDocuments, reloadTeams } from "./Team.thunks";

export const initialState = {
	...defaultState(newBaseList<Team>()),
	selectedTenant: null as unknown as Tenant,
	contactPersonIsFetching: false,
};

export type TeamState = typeof initialState;

const slice = createSlice({
	name: "team",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(fetchTeams.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(getTeamDocuments.fulfilled), (state, action) => {
			if (state.value) {
				const oldItem = state.value.items.find((x) => x.id === action.meta.arg[1])!;
				if (oldItem) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.documents = action.payload;
					state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
					state.value.items.push(oldItem);
				}
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(fetchTeamById.fulfilled), (state, action) => {
				if (state.value) {
					const oldItem = state.value.items.find((x) => x.id === action.payload.id)!;
					if (!oldItem) {
						state.value.items.push(action.payload);
					} else {
						const index = state.value.items.indexOf(oldItem);
						action.payload.documents = oldItem.documents;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						state.value.items.push(action.payload);
					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),

			builder.addMatcher(isAnyOf(getTeamDocuments.fulfilled), (state, action) => {
				if (state.value) {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg[1])!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.documents = action.payload;
						state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
						//state.value.items.push(oldItem);
						state.value.items.splice(index, 0, oldItem);

					}
				}
				state.isFetching = false;
				state.isFetched = true;
			}),
			builder.addMatcher(isAnyOf(reloadTeams.fulfilled), (state, action) => {
				state.value = action.payload;
				state.isFetching = false;
				state.isFetched = true;
			});
		builder.addMatcher(isAnyOf(reloadTeams.pending, fetchTeams.pending, fetchTeamById.pending), (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addMatcher(isAnyOf(getContactPersons.fulfilled), (state, action) => {
			if (state.value) {
				const oldItem = state.value.items.find((x) => x.id === action.meta.arg[1])!;
				if (oldItem) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.contactPersons = action.payload;
					state.value.items = state.value.items.filter((x) => x.id != oldItem.id);
					state.value.items.push(oldItem);
				}
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(getContactPersons.pending), (state, action) => {
				state.contactPersonIsFetching = true;
			});
		builder.addMatcher(isAnyOf(getContactPersons.fulfilled, getContactPersons.rejected), (state, action) => {
			state.contactPersonIsFetching = false;
		});
		builder.addMatcher(isAnyOf(reloadTeams.rejected, fetchTeams.rejected, fetchTeamById.rejected), (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
		});
	},
});
export default slice.reducer;
