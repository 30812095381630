import { OAuth2AuthenticateOptions } from "@byteowls/capacitor-oauth2";
import { CryptoLoginHelper } from "./CryptoLoginHelper";
import { Preferences } from "@capacitor/preferences";
import { IdTokenClaims } from 'model/IdTokenClaims';

export const PCKEOptionsHelper = async (options: OAuth2AuthenticateOptions): Promise<OAuth2AuthenticateOptions> => {
	options.pkceEnabled = false;

	let pkceCodeChallenge = "";
	let pkceCodeChallengeMethod = "";
	const pkceCodeVerifier = CryptoLoginHelper.randomString(64);

	await Preferences.set({
		key: CodeVerifierSettingsKey,
		value: pkceCodeVerifier,
	});

	if (CryptoLoginHelper.HAS_SUBTLE_CRYPTO) {
		await CryptoLoginHelper.deriveChallenge(pkceCodeVerifier).then((c) => {
			pkceCodeChallenge = c;
			pkceCodeChallengeMethod = "S256";
		});
	} else {
		pkceCodeChallenge = pkceCodeVerifier;
		pkceCodeChallengeMethod = "plain";
	}

	if (options.additionalParameters != undefined) {
		options.additionalParameters.code_challenge = pkceCodeChallenge;
		options.additionalParameters.code_challenge_method = pkceCodeChallengeMethod;
	}

	//Redirect URL persist path
	if (options.web != undefined) {
		// Custom urls wont work with deeplinks
		//	options.web.redirectUrl = options.web.redirectUrl + window.location.pathname;
	}

	return options as OAuth2AuthenticateOptions;
};
export const PCKEOptionsHelperGetVerifierAsync = async (): Promise<string> => {
	const code_verfier = await Preferences.get({ key: CodeVerifierSettingsKey });
	const code_verfierValue = code_verfier.value as string;

	return code_verfierValue;
};
export const PCKEOptionsHelperRemoveVerifierAsync = async () => {
	await Preferences.remove({ key: CodeVerifierSettingsKey });
};
export const CodeVerifierSettingsKey = `${process.env.REACT_APP_REDIRECT_URL_WEB}-code_verifier`;

export const ParseJwt = (token: string) => {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	return JSON.parse(jsonPayload);
}
export const ParseJwtAndGetPersonId = (token: string) => {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	const jsonParsed = JSON.parse(jsonPayload) as IdTokenClaims;
	const userId = jsonParsed.person_id;
	return userId;
}
export const ClearAuthStateFlow = () => {
	try {
		//			console.log("No token found, redirecting to login");
		//Clear the local storage, so we can start a new auth flow
		localStorage.clear();
		//clear all cookies for this page to prevent the auth flow from failing
		document.cookie.split(";").forEach(function (c) {
			document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
		});
		//Reload the page to restart the auth flow
		//window.location.reload();
	} catch (error) {
		console.log(error);
	}
}
