import { IonSkeletonText } from "@ionic/react";
import { VFC } from "react";

export interface Props {
	text?: string;
}

export const Title: VFC<Props> = ({ text }) => {
	return <div className="font-large">{text ?? <IonSkeletonText animated style={{ width: "60%" }} />}</div>;
};
