import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Address } from "model/Address";
import { BaseList, newBaseList } from "model/BaseList";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";
import { addAddress, deleteAddress, deleteShareAddressScoped, fetchAddresses, reloadAddresses, shareAddress, shareAddressScoped, updateAddress } from "./Address.thunks";
import { findAndUpdateScopeSharedTenant } from 'helper/SliceHelper';

export const initialState = {
	...defaultState<BaseList<Address>>(newBaseList<Address>()),
	scope: ScopeType.extern,
	selectedTenant: null as unknown as Tenant,
	toShare: null as unknown as Address | undefined,
	isShareInternFetching: false,
	isShareExternFetching: false,
};

export type AddressState = typeof initialState;

const slice = createSlice({
	name: "address",
	initialState,
	reducers: {
		cancelShareAddress(state: any): void {
			state.toShare = undefined;
			state.isFetching = false;
			state.isFetched = false;
		},
	},
	extraReducers: (builder) => {

		builder.addCase(addAddress.fulfilled, (state, action) => {
			state.value.items.push(action.payload);
			action.payload.isBusy = false;

			state.isFetching = false;
			state.isFetched = true;
			state.toShare = action.payload;
		});
		builder.addCase(deleteAddress.fulfilled, (state, action) => {
			state.value.items = state.value.items.filter((x) => x.id != action.payload);
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addCase(reloadAddresses.pending, (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addCase(reloadAddresses.fulfilled, (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});

		builder.addCase(deleteShareAddressScoped.fulfilled, (state, action) => {
			const oldItem = state.value.items.find((x) => x.id === action.meta.arg.entityId)!;
			const indexOfOldItem = state.value.items.indexOf(oldItem);
			if (oldItem == null) {
				return;
			}
			oldItem.scopedSharingTenants = findAndUpdateScopeSharedTenant(oldItem.scopedSharingTenants, action.meta.arg.tenantId, action.meta.arg.scopeType, false);
			state.value.items[indexOfOldItem] = oldItem;
			action.meta.arg.scopeType == ScopeType.extern ? (state.isShareExternFetching = false) : (state.isShareInternFetching = false);
		});
		builder.addCase(shareAddressScoped.fulfilled, (state, action) => {
			const oldItem = state.value.items.find((x) => x.id === action.meta.arg.entityId)!;
			const indexOfOldItem = state.value.items.indexOf(oldItem);
			if (oldItem == null) {
				return;
			}
			oldItem.scopedSharingTenants = findAndUpdateScopeSharedTenant(oldItem.scopedSharingTenants, action.meta.arg.tenantId, action.meta.arg.scopeType, true);
			state.value.items[indexOfOldItem] = oldItem;
			action.meta.arg.scopeType == ScopeType.extern ? (state.isShareExternFetching = false) : (state.isShareInternFetching = false);
		});
		builder.addCase(shareAddressScoped.rejected, (state, action) => {
			const scope = action.meta.arg.scopeType;
			scope == ScopeType.extern ? (state.isShareExternFetching = false) : (state.isShareInternFetching = false);
		});
		builder.addCase(shareAddressScoped.pending, (state, action) => {
			const scope = action.meta.arg.scopeType;
			scope == ScopeType.extern ? (state.isShareExternFetching = true) : (state.isShareInternFetching = true);
		});


		builder.addMatcher(isAnyOf(updateAddress.fulfilled, shareAddress.fulfilled), (state, action) => {
			if (!action.payload) {
				return;
			}
			const oldItem = state.value.items.find((x) => x.id === action.payload.id)!;
			const index = state.value.items.indexOf(oldItem);
			action.payload.isBusy = false;
			state.value.items[index] = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		}),


			builder.addMatcher(isAnyOf(reloadAddresses.fulfilled), (state, action) => {
				state.value = action.payload;
				state.isFetching = false;
				state.isFetched = true;
			});

		builder.addMatcher(isAnyOf(fetchAddresses.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(updateAddress.pending, addAddress.pending, deleteAddress.pending, shareAddress.pending), (state, action) => {
				const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
				if (oldItem) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.isBusy = true;
					state.value.items[index] = oldItem;
				}

				state.isFetching = true;
				state.isFetched = false;
			}),
			builder.addMatcher(isAnyOf(updateAddress.rejected, deleteAddress.rejected, shareAddress.rejected), (state, action) => {
				const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
				if (oldItem) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.isBusy = false;
					state.value.items[index] = oldItem;
				}

				state.isFetching = false;
				state.isFetched = false;
				state.error = action.error.message;
			});
	},
});
export const { cancelShareAddress } = slice.actions;
export default slice.reducer;
