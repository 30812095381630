import "./EventViewSwitcher.scss";

import { IonIcon } from "@ionic/react";
import { calendarOutline as calendarIcon, listOutline as listIcon } from "ionicons/icons";
import { EventsView } from "model/events/EventsView";
import { useState, VFC } from "react";

interface EventViewSwitcherProps {
	title: string;
	onChange: (view: EventsView) => void;
}

const EventViewSwitcher: VFC<EventViewSwitcherProps> = ({ title, onChange }) => {
	const [view, setView] = useState(EventsView.List);

	const switchView = (view: EventsView): void => {
		setView(view);
		onChange(view);
	};

	return (
		<div className="event-view-switcher">
			<h2>{title}</h2>
			{/*
							<div className="icons">
				<IonIcon
					icon={listIcon}
					onClick={switchView.bind(null, EventsView.List)}
					className={view === EventsView.List ? "active" : undefined}
				/>
				<IonIcon
					icon={calendarIcon}
					onClick={switchView.bind(null, EventsView.Calendar)}
					className={view === EventsView.Calendar ? "active" : undefined}
				/>
			</div>
				*/}
		</div>
	);
};

export default EventViewSwitcher;
