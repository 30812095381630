import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import DateItem from "components/form/DateItem";
import { Profile } from "model/Profile";

import InputItem from "../form/InputItem";
import GenderTypePicker from "./GenderTypePicker";

export interface Props {
	isOpen: boolean;
	profile: Profile;
	onCancel(): void;
	onDone(profile: Profile): void;
}
const EditModal = (props: Props) => {
	const profile = { ...props.profile };
	return (
		<IonModal isOpen={props.isOpen} onDidDismiss={(e) => props.onCancel()}>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={() => props.onCancel()}>
							<Trans>Cancel</Trans>
						</IonButton>
					</IonButtons>
					<IonTitle>
						<Trans>Edit Profile</Trans>
					</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={() => props.onDone(profile)} strong>
							<Trans>Done</Trans>
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<InputItem
					name={i18n._(t`Firstname`)}
					placeholder={i18n._(t`Firstname`)}
					value={profile.firstName}
					onChange={(value) => (profile.firstName = value)}
				/>
				<InputItem
					name={i18n._(t`Lastname`)}
					placeholder={i18n._(t`Lastname`)}
					value={profile.lastName}
					onChange={(value) => (profile.lastName = value)}
				/>
				<GenderTypePicker genderType={profile.gender} onChange={(value) => (profile.gender = value)} />

				<DateItem
					value={profile.birthdate}
					placeholder={i18n._(t`Birthdate`)}
					name={i18n._(t`Birthdate`)}
					onChange={(e) => (profile.birthdate = e ?? profile.birthdate)}
				/>
				<InputItem
					name={i18n._(t`Birthplace`)}
					placeholder={i18n._(t`Birthplace`)}
					value={profile.birthplace}
					onChange={(value) => (profile.birthplace = value)}
				/>
			</IonContent>
		</IonModal>
	);
};
export default EditModal;
