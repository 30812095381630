import { Creator } from "./Creator";
import { IsBusy } from "./IsBusy";
import { ScopedSharingTenant } from "./ScopedSharingTenant";

export interface Phone extends IsBusy {
	id?: string;
	tenantId?: string;
	phoneNumberCountryCode?: string;
	phoneNumber?: string;
	isDefault?: boolean;
	isIntern?: boolean;
	isExtern?: boolean;
	scopedSharingTenants?: ScopedSharingTenant[];
	creator?: Creator;
}

export function newPhone(): Phone {
	return {
		id: undefined,
	};
}

export function phoneString(phone?: Phone): string | undefined {
	const values = [phone?.phoneNumberCountryCode, phone?.phoneNumber].filter((x) => x);

	if (values) {
		return `+${values?.join(" ")}`;
	}

	return undefined;
}
