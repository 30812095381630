import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

export enum ScopeType {
	default = "default",
	extern = "extern",
	intern = "intern",
}

export function scopeString(scope: ScopeType) {
	switch (scope) {
		case ScopeType.default:
			return i18n._(t`default`);
		case ScopeType.extern:
			return i18n._(t`extern`);
		case ScopeType.intern:
			return i18n._(t`intern`);
		default:
			return scope;
	}
}
export function scopeNumber(scope: ScopeType) {
	switch (scope) {
		case ScopeType.default:
			return 0;
		case ScopeType.extern:
			return 1;
		case ScopeType.intern:
			return 2;
		default:
			return scope;
	}
}
