import { Toast } from "@capacitor/toast";
import {
	IonButton,
	IonCard,
	IonChip,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonNote,
	IonToolbar,
	useIonAlert,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import axios from "axios";
import DecisionAlert, { newButton } from "components/alert/DecisionAlert";
import InputItem from "components/form/InputItem";
import { PrivacyVerfiyApprovement } from "components/privacy/PrivacyVerfiyApprovement";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import ShareTenantSelect from "components/shareTenant/ShareTenantSelect";
import ShareTenantSelectModal from "components/shareTenant/ShareTenantSelectModal";
import { closeOutline, pencilOutline, trashOutline } from "ionicons/icons";
import { Address } from "model/Address";
import { BankAccount, newBankAccount } from "model/BankAccount";
import { OpenIBANResult } from "model/OpenIBANResult";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { newUpsertModel } from "model/UpsertModel";
import { Ref, useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { addBankAccount, deleteBankAccount, updateBankAccount } from "store/profile/bankAccount/BankAccount.thunks";
import { getProfile } from "store/profile/Profile.selectors";

export interface Props {
	modalRef: Ref<HTMLIonModalElement> | null;
	editItem: BankAccount;
	onCancel(): void;
}

const EditModal: VFC<Props> = ({ modalRef, editItem: bankAccount, onCancel }) => {
	const [checked, setChecked] = useState(false);
	const dispatch = useAppDispatch();
	const [presentAlert] = useIonAlert();
	const modal = useRef<HTMLIonModalElement>(null);
	const modalTrigger = `${bankAccount.id}_select_share_tenant`;
	const profile = useSelector(getProfile);
	const [savedEditItem, setSavedEditItem] = useState(bankAccount);
	const [isValidated, setIsValidated] = useState(false);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
	const [sharedTenants, setSharedTenants] = useState(bankAccount.scopedSharingTenants ?? new Array<ScopedSharingTenant>());
	function prepareModal() {
		if (bankAccount.id == null || bankAccount.id == undefined) {
			return;
		}

		setSavedEditItem(JSON.parse(JSON.stringify(bankAccount)));
		editItem = JSON.parse(JSON.stringify(bankAccount));
		const validateIBAN = async () => {
			const res = await axios.get<OpenIBANResult>(`https://openiban.com/validate/${editItem.iban}?getBIC=true&validateBankCode=true`);
			editItem.iban = res.data.iban;
			editItem.bic = res.data.bankData?.bic;
			editItem.name = res.data.bankData?.name;
			editItem.bankcode = res.data.bankData?.bankCode;
			editItem.city = res.data.bankData?.city;
			editItem.zip = res.data.bankData?.zip;
			setIsValidated(res.data.valid ?? false);
		};

		if (bankAccount != null || bankAccount != undefined) {
			validateIBAN();
		}

		setSharedTenants(bankAccount.scopedSharingTenants ?? new Array<ScopedSharingTenant>());
	}

	let editItem = JSON.parse(JSON.stringify(savedEditItem)) as BankAccount;

	async function onValidateIBAN() {
		const res = await axios.get<OpenIBANResult>(`https://openiban.com/validate/${editItem.iban}?getBIC=true&validateBankCode=true`);

		editItem.iban = res.data.iban;
		editItem.bic = res.data.bankData?.bic;
		editItem.name = res.data.bankData?.name;
		editItem.bankcode = res.data.bankData?.bankCode;
		editItem.city = res.data.bankData?.city;
		editItem.zip = res.data.bankData?.zip;
		setSavedEditItem(JSON.parse(JSON.stringify(editItem)));
		if (res.data.valid ?? false) {
			await Toast.show({
				text: "Ihre IBAN ist gültig.",
				position: "top",
				duration: "short",
			});
		} else {
			await Toast.show({
				position: "top",
				duration: "short",
				text: "Ihre IBAN konnte nicht validiert werden. Bitte prüfen Sie ihre Angaben.",
			});
		}
		setIsValidated(res.data.valid ?? false);
	}
	function onRetryValidateIBAN() {
		setIsValidated(false);
		editItem.bic = undefined;
		editItem.name = undefined;
		editItem.bankcode = undefined;
		editItem.city = undefined;
		editItem.zip = undefined;
	}
	function onConfirmDelete() {
		const upsertItem = { ...bankAccount };
		dispatch<any>(deleteBankAccount(newUpsertModel<BankAccount>(profile.id, upsertItem)));
		onCancel();
		setChecked(false);
	}
	function onEditModalDone(itemToUpdate: Address) {
		const upsertItem = { ...itemToUpdate };
		upsertItem.scopedSharingTenants = sharedTenants;
		if (itemToUpdate.id) {
			dispatch<any>(updateBankAccount(newUpsertModel<BankAccount>(profile.id, upsertItem)));
		} else {
			dispatch<any>(addBankAccount(newUpsertModel<BankAccount>(profile.id, upsertItem)));
		}
		editItem = newBankAccount();
		setSavedEditItem(editItem);
		onCancel();
		setChecked(false);
	}
	function onDismissDelete() {
		setIsDeleteAlertOpen(false);
		setChecked(false);
	}
	function onShare(tenants: ScopedSharingTenant[]) {
		setSharedTenants(tenants);
		editItem.scopedSharingTenants = tenants;
		setSavedEditItem(editItem);
	}
	function onShareCancel() {
		console.log(editItem.scopedSharingTenants);
	}
	const deleteButtons = [
		newButton(i18n._(t`Cancel`), "cancel"),
		newButton(i18n._(t`Delete`), "delete", "danger", () => {
			onConfirmDelete();
		}),
	];
	function closeModal() {
		onCancel();
		setChecked(false);
	}
	return (
		<>
			<IonModal ref={modalRef} onWillPresent={(e) => prepareModal()} onDidDismiss={(e) => closeModal()}>
				<DecisionAlert
					isOpen={isDeleteAlertOpen}
					header={i18n._(t`Delete bank account`)}
					message={i18n._(t`Are you sure you want to delete`)}
					onDismiss={onDismissDelete}
					buttons={deleteButtons}
				/>
				<IonModal trigger={modalTrigger} ref={modal}>
					<ShareTenantSelectModal
						creatorId={editItem.creator?.id}
						scopedSharingTenants={sharedTenants}
						done={onShare}
						cancel={onShareCancel}
					/>
				</IonModal>
				<IonHeader>
					<div className="ion-text-center">
						<IonToolbar color="amber-300">
							<IonButton
								fill="clear"
								slot="start"
								onClick={() => {
									closeModal();
								}}
							>
								<IonIcon color="medium" icon={closeOutline}></IonIcon>
							</IonButton>
							<IonLabel slot="start">
								<h2>{editItem.id ? <Trans>Edit bank account</Trans> : <Trans>Create bank account</Trans>}</h2>
							</IonLabel>

							{editItem.id && editItem.creator?.label != "association" ? (
								<>
									<IonButton slot="end" fill="clear" onClick={() => setIsDeleteAlertOpen(true)}>
										<IonIcon color="medium" icon={trashOutline}></IonIcon>
									</IonButton>
									{/*
									<IonButton slot="end" fill="clear" onClick={(e) => selectRefShare.current?.open(e.nativeEvent)}>
										<IonIcon color="medium" icon={lockOpenOutline}></IonIcon>
									</IonButton>
										*/}
								</>
							) : (
								<></>
							)}
							{isValidated && editItem.id == undefined ? (
								<IonButton
									slot="end"
									fill="clear"
									onClick={() => {
										editItem = newBankAccount();
										setSavedEditItem(editItem);
									}}
								>
									<IonNote>Verwerfen </IonNote>
								</IonButton>
							) : (
								<></>
							)}
						</IonToolbar>
					</div>
				</IonHeader>
				<IonContent color="amber-50">
					{!isValidated ? (
						<IonCard>
							<IonItem>
								<IonLabel position="stacked">{i18n._(t`IBAN`)}</IonLabel>
								<IonInput
									autofocus
									value={editItem.iban}
									placeholder={i18n._(t`IBAN eingeben`)}
									onIonChange={(e) => {
										onRetryValidateIBAN();
										editItem.iban = e.detail.value ?? "";
									}}
								></IonInput>
							</IonItem>
						</IonCard>
					) : (
						<></>
					)}
					{isValidated ? (
						<>
							<InputItem
								disabled={true}
								name={i18n._(t`IBAN`)}
								placeholder={i18n._(t`IBAN`)}
								value={editItem.iban}
								onChange={(value) => {
									onRetryValidateIBAN();
									editItem.iban = value;
								}}
							/>
							<InputItem
								disabled={true}
								name={i18n._(t`Name`)}
								placeholder={i18n._(t`Name der Bank`)}
								value={editItem.name}
								onChange={(value) => (editItem.name = value)}
							/>
							<InputItem
								disabled={true}
								name={i18n._(t`BIC`)}
								placeholder={i18n._(t`BIC`)}
								value={editItem.bic}
								onChange={(value) => (editItem.bic = value)}
							/>
							<InputItem
								disabled={true}
								name={i18n._(t`Stadt`)}
								placeholder={i18n._(t`Stadt`)}
								value={editItem.city}
								onChange={(value) => (editItem.city = value)}
							/>
							<InputItem
								disabled={true}
								name={i18n._(t`Postleitzahl`)}
								placeholder={i18n._(t`Postleitzahl`)}
								value={editItem.zip}
								onChange={(value) => (editItem.zip = value)}
							/>
						</>
					) : (
						<></>
					)}
					{editItem.id ? (
						<IonItem detail={true} detailIcon={pencilOutline} lines="none" id={modalTrigger}>
							<div>
								<div className="ion-margin-bottom">
									<IonLabel className="ion-margin" position="stacked">
										Geteilt mit
									</IonLabel>
								</div>

								{sharedTenants.length ?? 0 > 1 ? (
									<></>
								) : (
									<IonChip outline={false}>
										<IonLabel>Keine Freigaben</IonLabel>
									</IonChip>
								)}
								{sharedTenants.map((tenant, i) => {
									return <ShareTenantChip scopedSharingTenant={tenant} />;
								})}
							</div>
						</IonItem>
					) : (
						<></>
					)}
				</IonContent>
				<PrivacyVerfiyApprovement checked={checked} setChecked={setChecked} />

				{isValidated ? (
					<IonButton disabled={!checked} expand="block" size="default" onClick={() => onEditModalDone(editItem)}>
						<IonLabel>Speichern</IonLabel>
					</IonButton>
				) : (
					<IonButton disabled={!checked} expand="block" size="default" onClick={async () => await onValidateIBAN()}>
						<IonLabel>IBAN Prüfen um zu speichern</IonLabel>
					</IonButton>
				)}
			</IonModal>
		</>
	);
};

export default EditModal;
