import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import BlogTeaser from "components/blogEntry/BlogTeaser";
import Loader from "components/loader/Loader";
import { newBaseListQuery } from "model/BaseListQuery";
import { OrderByType } from "model/OrderByType";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { getLatestBlogEntry, isBlogEntryBusy } from "store/blogEntry/BlogEntry.selectors";
import { reloadBlogEntries } from "store/blogEntry/BlogEntry.thunks";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

const LatestBlog: VFC = () => {
	const entry = useSelector(getLatestBlogEntry);
	const isFetching = useSelector(isBlogEntryBusy);

	const orderElements = useSelector(getLatestBlogEntry);
	const dispatch = useAppDispatch();
	const selectedTenant = useSelector(getSelectedTenant);
	const profile = useSelector(getProfile);

	useEffect(() => {
		dispatch<any>(
			reloadBlogEntries(
				newBaseListTenantQuery(
					newBaseListQuery({
						page: 1,
						pagesize: 25,
						orderby: "CreatedAt",
						orderbytype: OrderByType.descending,
					}),
					selectedTenant,
				),
			),
		);
	}, [selectedTenant, profile]);
	return (
		<div>
			<h2>
				<Trans>News</Trans>
			</h2>
			<Loader isVisible={isFetching} />
			{entry ? <BlogTeaser entry={entry} /> : <></>}
		</div>
	);
};

export default LatestBlog;
