import { GenderType } from "model/GenderType";
import { Profile } from "model/Profile";

export interface ProfileUpdate {
	id: string;
	title: string;
	gender: GenderType;
	firstName: string;
	lastName: string;
	birthdate: string;
	birthplace: string;
	age: number;
}
export function newProfileUpdate(profile: Profile): ProfileUpdate {
	return {
		id: profile.id,
		title: profile.title,
		gender: profile.gender,
		firstName: profile.firstName,
		lastName: profile.lastName,
		birthdate: profile.birthdate,
		birthplace: profile.birthplace,
		age: profile.age,
	};
}
