import { RefresherEventDetail } from "@ionic/core";
import { IonCol } from "@ionic/react";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { MedicalInformation } from "model/MedicalInformation";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllMedicalInformations, getIsFetching } from "store/profile/medicalInformation/MedicalInformation.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import MedicalInformationTeaser from "./Teaser";

interface MedicalInformationListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const MedicalInformationList: VFC<MedicalInformationListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getAllMedicalInformations);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());

	const renderItem = (item: MedicalInformation) => {
		return (
			<IonCol size="12" key={item.id}>
				<MedicalInformationTeaser key={`${item.id}_additional_info_teaser_list`} item={item} />
			</IonCol>
		);
	};
	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery());
		}
		event.detail.complete();
	};
	return <InfiniteBaseList items={list} isFetching={isBusy} renderItem={renderItem} onInfinite={onInfinite} onRefresh={reloadEntries} />;
};
export default MedicalInformationList;
