import { IonLabel } from "@ionic/react";
import { VFC } from "react";

export interface Props {
	text?: string;
	isHtml?: boolean;
}

export const TextOrEmpty: VFC<Props> = ({ text, isHtml = false }) => {
	if (text) {
		if (isHtml === true) {
			return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
		} else {
			return <IonLabel>{text}</IonLabel>;
		}
	}

	return <IonLabel> - </IonLabel>;
};
