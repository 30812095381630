import { IonButton, IonFooter, IonItem } from "@ionic/react";
import { FC } from "react";

interface CloseFooterProps {
	onClick: () => void;
}

export const CloseFooter: FC<CloseFooterProps> = ({ onClick }) => {
	return (
		<IonFooter style={{ background: "var(--ion-color-amber-50)" }} color="amber-50">
			<IonButton
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					onClick();
				}}
				form="round"
				expand="block"
				color="amber-400"
				className="ion-no-border ion-padding-end ion-padding-start ion-margin ion-padding-end ion-padding-start"
			>
				Schließen
			</IonButton>
		</IonFooter>
	);
};
