import format from "date-fns/format";
import { Address } from "model/Address";

export function formatName(args: { firstName: string; lastName: string }): string {
	if (!args) {
		return "";
	}
	const { firstName = "", lastName = "" } = args;
	return `${firstName} ${lastName}`;
}

function _format(date: Date | string | undefined | null, formatString: string): string {
	if (!date) {
		return "";
	}
	if (typeof date !== "object") {
		date = new Date(date);
	}
	return format(date, formatString);
}

export function formatDate(date: Date | string | undefined | null): string {
	return _format(date, "dd.MM.yyyy");
}

export function formatDateTime(date: Date | string | undefined | null): string {
	return _format(date, "dd.MM.yyyy HH:mm");
}

export function formatTime(date: Date | string | undefined | null): string {
	return _format(date, "HH:mm");
}

export function formatAddress(address: Address): string {
	return "";
}
