import "./style.scss";
import "../style.scss";

import { IonButton, IonCheckbox, IonIcon, IonItem, IonItemDivider, IonLabel, IonNote } from "@ionic/react";
import { downloadOutline, refreshOutline } from "ionicons/icons";
import { Event } from "model/Event";
import * as React from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getAvatarImageUrl, getProfile } from "store/profile/Profile.selectors";

interface IEventAGBPartProps {
	event: Event;
}

const EventAGBPart: React.FunctionComponent<IEventAGBPartProps> = ({ event }) => {
	const profile = useSelector(getProfile);
	const tenant = useSelector(getAllTenants).find((t) => t.id == event.tenantId);
	const avatarImageUrl = useSelector(getAvatarImageUrl);
	if (!event) {
		return <></>;
	}
	if ((event?.contract?.necessaryApprovals?.length ?? -1) <= 0 ?? false) {
		return <></>;
	}
	return (
		<>
			<div className="firstDivider"></div>

			<IonItemDivider mode="ios">
				Rechtliches:
				<IonLabel color="danger">*</IonLabel>
				<IonButton color="medium" slot="end" fill="clear">
					<IonIcon color="medium" size="small" src={refreshOutline}></IonIcon>
				</IonButton>
			</IonItemDivider>
			{event.contract?.necessaryApprovals.map((neccesaryApproval, i) => {
				return (
					<IonItem key={i} mode="ios" lines="full" className="ion-activatable">
						<IonIcon slot="start" src={downloadOutline} />

						<IonLabel slot="start">
							<br />
							{neccesaryApproval?.approval?.name}
							<br />
							<IonNote slot="start">
								<div
									className="lines max-1"
									dangerouslySetInnerHTML={{ __html: neccesaryApproval?.approval?.description ?? "" }}
								></div>
							</IonNote>
						</IonLabel>
						<IonCheckbox
							slot="end"
							checked={event?.eventRegistration?.approvals?.includes(neccesaryApproval.id)}
							onIonChange={(e) => {
								if (event?.eventRegistration != undefined) {
									if (e.detail.checked) {
										event.eventRegistration.approvals = event?.eventRegistration?.approvals?.filter(
											(x) => x != neccesaryApproval.id,
										);
										event.eventRegistration.approvals?.push(neccesaryApproval.id);
									} else {
										event.eventRegistration.approvals = event.eventRegistration.approvals?.filter(
											(x) => x != neccesaryApproval.id,
										);
									}
								}
							}}
						/>
					</IonItem>
				);
			})}
		</>
	);
};

export default EventAGBPart;
