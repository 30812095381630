import { IonButton, IonInput, IonItem, IonLabel, IonModal, IonSelect, IonSelectOption, useIonPicker } from "@ionic/react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import ClothingForm, { ClothingFormValues } from "components/confectionInformation/ClothingForm";
import { GenderType } from "model/GenderType";
import { useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import ProfileService from "services/ProfileService";
import { getAllConfectionInformations } from "store/profile/confectionInformation/ConfectionInformation.selectors";

import InfoBlock from "./InfoBlock/InfoBlock";

interface GenderTypePickerProps {
	genderType?: GenderType | undefined;
	onChange: (genderType: GenderType) => void;
}

const GenderTypePicker: VFC<GenderTypePickerProps> = ({ genderType, onChange }) => {
	const { i18n } = useLingui();
	const [present] = useIonPicker();
	const [genderTypeState, setgenderTypeState] = useState<GenderType | undefined>(genderType);
	const selectRef = useRef<HTMLIonSelectElement | null>(null);
	useEffect(() => {
		setgenderTypeState(genderType);
	}, [genderType]);

	return (
		<IonItem>
			<IonLabel position="stacked">Geschlecht:</IonLabel>

			<IonSelect
				ref={selectRef}
				compareWith={(a, b) => {
					return JSON.stringify(a).toLowerCase() === JSON.stringify(b).toLowerCase();
				}}
				aria-label="Geschlecht"
				value={genderTypeState}
				interface="popover"
				placeholder="Geschlecht"
				onIonChange={(x) => {
					console.log(x.target.value);
					onChange(x.target.value);
					setgenderTypeState(x.target.value);
				}}
			>
				<IonSelectOption key={GenderType.male} value={GenderType.male}>
					{i18n._(t`male`)}
				</IonSelectOption>
				<IonSelectOption key={GenderType.female} value={GenderType.female}>
					{i18n._(t`female`)}
				</IonSelectOption>
				<IonSelectOption key={GenderType.divers} value={GenderType.divers}>
					{i18n._(t`divers`)}
				</IonSelectOption>
				<IonSelectOption key={GenderType.noinformation} value={GenderType.noinformation}>
					{i18n._(t`noinformation`)}
				</IonSelectOption>
			</IonSelect>
		</IonItem>
	);
};

export default GenderTypePicker;
