import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { IonApp, IonPage, IonTitle, setupIonicReact } from "@ionic/react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { L10n, registerLicense, setCulture } from "@syncfusion/ej2-base";
import { defaultLocale, dynamicActivate } from "i18";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "store";
import * as serviceWorker from "./serviceWorker";
import AuthRoute from "auth/AuthRoute";
import { CapacitorOAuth2Context } from "auth/CapacitorOAuth2Context";
import { CapacitorOAuth2Service } from "auth/CapacitorOAuth2Service";
import StartApp from "app/App";

setupIonicReact({});

/*
import * as n1 from "../node_modules/cldr-data/main/de/currencies.json";
import * as n2 from "../node_modules/cldr-data/main/de/timeZoneNames.json";
import * as n3 from "../node_modules/cldr-data/main/de/numbers.json";
import * as n4 from "../node_modules/cldr-data/main/de/ca-gregorian.json";
import * as s from "../node_modules/cldr-data/supplemental/currencyData.json";
import * as s2 from "../node_modules/cldr-data/supplemental/numberingSystems.json";
loadCldr(n1, n2, n3, n4, s, s2);
*/
// Registering Syncfusion license key
registerLicense(`${process.env.REACT_APP_SF_LICENSE}`);
setCulture("en");

L10n.load({
	de: {
		schedule: {
			day: "Day",
			week: "Week",
			workWeek: "Work Week",
			month: "Month",
			year: "Year",
			agenda: "Agenda",
			weekAgenda: "Week Agenda",
			workWeekAgenda: "Work Week Agenda",
			monthAgenda: "Month Agenda",
			today: "Today",
			noEvents: "No events",
			emptyContainer: "There are no events scheduled on this day.",
			allDay: "All day",
			start: "Start",
			end: "End",
			more: "more",
			close: "Close",
			cancel: "Cancel",
			noTitle: "(No Title)",
			delete: "Delete",
			deleteEvent: "This Event",
			deleteMultipleEvent: "Delete Multiple Events",
			selectedItems: "Items selected",
			deleteSeries: "Entire Series",
			edit: "Edit",
			editSeries: "Entire Series",
			editEvent: "This Event",
			createEvent: "Create",
			subject: "Subject",
			addTitle: "Add title",
			moreDetails: "More Details",
			save: "Save",
			editContent: "How would you like to change the appointment in the series?",
			deleteContent: "Are you sure you want to delete this event?",
			deleteMultipleContent: "Are you sure you want to delete the selected events?",
			newEvent: "New Event",
			title: "Title",
			location: "Location",
			description: "Description",
			timezone: "Timezone",
			startTimezone: "Start Timezone",
			endTimezone: "End Timezone",
			repeat: "Repeat",
			saveButton: "Save",
			cancelButton: "Cancel",
			deleteButton: "Delete",
			recurrence: "Recurrence",
			wrongPattern: "The recurrence pattern is not valid.",
			seriesChangeAlert: "Do you want to cancel the changes made to specific instances of this series and match it to the whole series again?",
			createError:
				"The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
			sameDayAlert: "Two occurrences of the same event cannot occur on the same day.",
			occurenceAlert:
				"Cannot reschedule an occurrence of the recurring appointment if it skips over a later occurrence of the same appointment.",
			editRecurrence: "Edit Recurrence",
			repeats: "Repeats",
			alert: "Alert",
			startEndError: "The selected end date occurs before the start date.",
			invalidDateError: "The entered date value is invalid.",
			blockAlert: "Events cannot be scheduled within the blocked time range.",
			ok: "Ok",
			yes: "Yes",
			no: "No",
			occurrence: "Occurrence",
			series: "Series",
			previous: "Previous",
			next: "Next",
			timelineDay: "Timeline Day",
			timelineWeek: "Timeline Week",
			timelineWorkWeek: "Timeline Work Week",
			timelineMonth: "Timeline Month",
			timelineYear: "Timeline Year",
			editFollowingEvent: "Following Events",
			deleteTitle: "Delete Event",
			editTitle: "Edit Event",
			beginFrom: "Begin From",
			endAt: "End At",
			expandAllDaySection: "Expand-all-day-section",
			collapseAllDaySection: "Collapse-all-day-section",
			searchTimezone: "Search Timezone",
			noRecords: "No records found",
		},
	},
});

const I18nApp = () => {
	useEffect(() => {
		// With this method we dynamically load the catalogs
		dynamicActivate(defaultLocale);
	}, []);

	return (
		<I18nProvider i18n={i18n}>
			<StartApp />
		</I18nProvider>
	);
};

ReactDOM.render(
	<>
		<Provider store={store}>
			<CapacitorOAuth2Context.Provider value={new CapacitorOAuth2Service()}>
				<AuthRoute>
					<I18nApp />
				</AuthRoute>
			</CapacitorOAuth2Context.Provider>
		</Provider>
	</>,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
defineCustomElements(window);
