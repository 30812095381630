import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditBankAccountModal from "components/bankAccount/EditModal";
import List from "components/bankAccount/List";
import ProfileValidationFooter from "components/profileValidation/components/ProfileValidationFooter";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { add } from "ionicons/icons";
import { newBankAccount } from "model/BankAccount";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { useContext, useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/bankAccount/BankAccount.selectors";
import { fetchBankAccounts, reloadBankAccounts } from "store/profile/bankAccount/BankAccount.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

const ProfileValidationProcessBankAccountPage: VFC = () => {
	const dispatch = useAppDispatch();
	const modal = useRef<HTMLIonModalElement>(null);
	const selectedTenant = useSelector(getSelectedTenant);
	const isLoading = useSelector(getIsFetching);
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);
	useEffect(() => {
		dispatch<any>(reloadBankAccounts(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchBankAccounts(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadBankAccounts(newBaseListQuery(basListQuery)));
	};

	return (
		<IonPage>
			<IonContent>
				<ProfileValidationHeader title="Bankdaten" progress={7} />
				<EditBankAccountModal modalRef={modal} editItem={newBankAccount()} onCancel={() => modal.current?.dismiss()} />
				<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={() => modal.current?.present()}>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>
			</IonContent>
			<ProfileValidationFooter
				isLoading={isLoading}
				progress={7}
				routerLinkNext="/validation/profile/1/agb"
				routerLinkBack="/validation/profile/1/phone"
			/>
		</IonPage>
	);
};

export default ProfileValidationProcessBankAccountPage;
