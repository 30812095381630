import { RefresherEventDetail } from "@ionic/core";
import { InfiniteBaseList } from "components/infinite/InfiniteBaseList";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import TenantAvatarTeaser from "./TenantAvatarTeaser";
import { getIsFetching, getProfile } from "store/profile/Profile.selectors";
import { Tenant } from "model/Tenant";
import { newBaseList } from "model/BaseList";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const SportcloudTeaserList: FC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const profile = useSelector(getProfile);
	console.log(profile);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());

	const getItems = (tenants: Tenant[]) => {
		if (tenants == undefined) {
			return newBaseList<Tenant>();
		}

		const baseList = newBaseList<Tenant>();
		baseList.items = tenants;
		baseList.maximumcount = tenants.length;
		return baseList;
	};

	const renderItem = (item: Tenant) => <TenantAvatarTeaser key={item.id} scopedSharingTenant={item} />;

	const onInfinite = async (event: any) => {
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery());
		}
		event.detail.complete();
	};

	return (
		<InfiniteBaseList
			items={getItems(profile.tenants)}
			isFetching={isBusy}
			renderItem={renderItem}
			onInfinite={onInfinite}
			onRefresh={reloadEntries}
		/>
	);
};

export default SportcloudTeaserList;
