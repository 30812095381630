import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import EditAddressModal from "components/address/EditModal";
import List from "components/address/List";
import { add } from "ionicons/icons";
import { newAddress } from "model/Address";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAddresses, reloadAddresses } from "store/profile/address/Address.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

import ProfilePageLayout from "./ProfilePageLayout";

const Addresses: FC = () => {
	const dispatch = useAppDispatch();
	const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);

	useEffect(() => {
		dispatch<any>(reloadAddresses(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchAddresses(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadAddresses(newBaseListQuery(basListQuery)));
	};
	return (
		<ProfilePageLayout title={i18n._(t`Addresses`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<EditAddressModal isOpen={isNewAddressModalOpen} address={newAddress()} onCancel={() => setIsNewAddressModalOpen(false)} />
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => setIsNewAddressModalOpen(true)}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
		</ProfilePageLayout>
	);
};

export default Addresses;
