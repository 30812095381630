import { IonItem, IonItemSliding, IonLabel } from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Subtitle } from "../text/subtitle/Subtitle";
import { LogElement } from "model/LogElement";
import { FC } from "react";
import { format, toDate } from "date-fns-tz";

export interface Props extends RouteComponentProps {
	logElement: LogElement;
}
const Teaser: FC<Props> = ({ logElement }) => {
	if (logElement.logMessages == undefined) {
		return <></>;
	}
	if (logElement.created == undefined) {
		return <></>;
	}
	return (
		<IonItem color="amber-50">
			<IonLabel>
				<Subtitle id={logElement.id} text={i18n._(t`${logElement?.logMessages[0]?.ressourceIdentifier}`)} />
				<Subtitle
					id={logElement.id}
					text={format(toDate(logElement.created!, { timeZone: "Europe/Berlin" }), "dd.MM.yyyy HH:mm", {
						timeZone: "Europe/Paris",
					})}
				/>

				{/*
					<p>{props.orderElement?.updatedAt ? dateTimeStringWithOutHour(new Date(props.orderElement.updatedAt)) : undefined}</p>
					<p>{unhtmlify(htmlBody(props.orderElement.content))}</p>*/}
			</IonLabel>
			{/*
					<div className={props.orderElement.sendState == SendStateType.sent ? "success" : "warning"}>
						{props.orderElement.sendState == SendStateType.sent ? "gesendet" : "nicht gesendet"}
					</div>
				*/}
		</IonItem>
	);
};
export default withRouter(Teaser);
