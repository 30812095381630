import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonPage,
	IonProgressBar,
	IonRow,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from "@syncfusion/ej2-react-navigations";
import { useAppDispatch } from "app/hooks";
import { TabContex } from "context/TabContext";
import { newBaseListQuery } from "model/BaseListQuery";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useContext, useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { getSelectedTenant } from "store/profile/Profile.selectors";
import { reloadValidationModels } from "store/profileValidation/ProfileValidation.thunks";

export const ProfileValidationProcessInfoPage: VFC = () => {
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);
	useEffect(() => {
		dispatch<any>(reloadValidationModels(newBaseListTenantQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	return (
		<IonPage>
			<IonContent>
				<IonHeader>
					<IonToolbar mode="md">
						<IonProgressBar value={0}></IonProgressBar>

						<IonTitle>
							<h4>Willkommen in deinem Sportbereich</h4>
						</IonTitle>
					</IonToolbar>
				</IonHeader>
				<div className="ion-padding">
					<IonGrid>
						<IonRow>
							<IonCol></IonCol>
							<IonCol size="8" sizeXs="12" sizeSm="10">
								<IonFooter>
									<AccordionComponent>
										<AccordionItemsDirective>
											<AccordionItemDirective
												expanded={true}
												header={i18n._(t`INFO_PROFILE_HEADER_1`)}
												content={i18n._(t`INFO_PROFILE_CONTENT_1`)}
											/>
											<AccordionItemDirective
												expanded={true}
												header={i18n._(t`INFO_PROFILE_HEADER_2`)}
												content={i18n._(t`INFO_PROFILE_CONTENT_2`)}
											/>
											<AccordionItemDirective
												expanded={true}
												header={i18n._(t`INFO_PROFILE_HEADER_3`)}
												content={i18n._(t`INFO_PROFILE_CONTENT_3`)}
											/>
											<AccordionItemDirective
												expanded={true}
												header={i18n._(t`INFO_PROFILE_HEADER_4`)}
												content={i18n._(t`INFO_PROFILE_CONTENT_4`)}
											/>
										</AccordionItemsDirective>
									</AccordionComponent>
								</IonFooter>
							</IonCol>
							<IonCol> </IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
			<IonFooter>
				<IonCard>
					<IonCardHeader>
						<IonCardSubtitle>Vervollständigen Sie ihren Account um alle Funktionen zu nutzen</IonCardSubtitle>
					</IonCardHeader>

					<IonCardContent>
						<IonButton routerLink="/validation/profile/1/profile" fill="solid" expand="full">
							Account vervollständigen
						</IonButton>
					</IonCardContent>
				</IonCard>
			</IonFooter>
		</IonPage>
	);
};
