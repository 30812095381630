import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { OrderElement } from "model/orderElement/OrderElement";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";
import { getSelectedTenant } from "store/profile/Profile.selectors";

import { RootState } from "..";

export const getIsFetching = (state: RootState): boolean => state.orderElement.isFetching;

export const getAllOrderElements = (state: RootState): BaseList<OrderElement> => state.orderElement.value || newBaseList<OrderElement>();

export const getOrderElements = createSelector(
	getAllOrderElements,
	getSelectedTenant,
	(orderElements: BaseList<OrderElement>, tenant: Tenant | null) => {
		return tenant ? filterBaseListItems(orderElements, (item) => item.tenantId === tenant.id) : orderElements;
	},
);

export const getLatestOrderElements = createSelector(getOrderElements, (orderElements: BaseList<OrderElement>) => orderElements?.items?.slice(0, 5));
