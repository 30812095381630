import { IonSkeletonText } from "@ionic/react";
import { VFC } from "react";

export interface Props {
	text?: string;
	id?: string;
}

export const Subtitle: VFC<Props> = ({ text, id }: Props) => {
	return (
		<div id={id} className="font-small">
			{text ?? <IonSkeletonText animated style={{ width: "60%" }} />}
		</div>
	);
};
