import "./style.scss";

import { IonBackButton, IonButtons, IonImg, IonMenuButton, IonToolbar } from "@ionic/react";
import ProfileMenuIcon from "components/profileIcon/ProfileMenuIcon";
import { FC } from "react";
import { useLingui } from "@lingui/react";
import { useMediaQuery } from "react-responsive";

export interface Props {
	title: string;
	backLink?: string;
	backButton?: boolean;
	menuButton?: boolean;
	logo?: boolean;
}

const ToolbarContent: FC<Props> = ({ title, backLink, backButton, menuButton, logo }) => {
	const { i18n } = useLingui();
	const Desktop = ({ children }: any) => {
		const isDesktop = useMediaQuery({ minWidth: 820 });
		return isDesktop ? children : null;
	};
	const Mobile = ({ children }: any) => {
		const isTablet = useMediaQuery({ maxWidth: 819 });
		return isTablet ? children : null;
	};
	return (
		<IonToolbar className="titleRow">
			<div className="titleRow">
				<div className="backButtonBox">
					<div className="backButtonBox" id="main">
						<IonButtons slot="start">
							<Mobile>
								{backButton ? <IonBackButton className="title" /> : <></>}

								{menuButton ? <IonMenuButton menu="first"></IonMenuButton> : <></>}
							</Mobile>

							<Desktop>{backLink ? <IonBackButton className="title" mode="md" /> : <IonMenuButton menu="first" />}</Desktop>
						</IonButtons>
						{!logo ? (
							backLink || menuButton ? (
								<>
									<Mobile>
										<h4 className="ion-text-wrap ion-padding-start">{i18n._(title)}</h4>
									</Mobile>

									<Desktop>
										<h4 className="ion-text-wrap ion-padding-start">{i18n._(title)}</h4>
									</Desktop>
								</>
							) : (
								<h4 className="ion-text-wrap ion-padding-start">{i18n._(title)}</h4>
							)
						) : (
							<IonImg className="logoImage ion-margin-bottom " src={"assets/icon/logo_quer.png"} />
						)}
					</div>
				</div>
				<div className="titleBox"></div>
				<div className="avatarBox">
					<ProfileMenuIcon />
				</div>
			</div>
		</IonToolbar>
	);
};

export default ToolbarContent;
