import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { newBaseListQuery } from "model/BaseListQuery";
import { DocumentElement } from "model/DocumentElement";
import { Event } from "model/Event";
import { Contract } from "model/events/Contract";
import EventRegistration from "model/events/EventRegistration";
import { LoadEventsQuery } from "model/events/LoadEventsQuery";
import { OrderByType } from "model/OrderByType";
import eventService from "services/EventService";

import { RootState } from "..";
import { ContactPerson } from 'model/ContactPerson';

const baseString = "event";
export const fetchEvents = createAsyncThunk<BaseList<Event>, LoadEventsQuery, { rejectValue: string }>(
	`${baseString}/getall`,
	async (filter: LoadEventsQuery, thunkAPI) => {
		console.log("filter", filter);
		try {
			const state = thunkAPI.getState() as RootState;
			if (state.event?.value?.page) {
				filter.query = newBaseListQuery({
					page: state.event.value.page + 1,
					orderby: "StartTime",
					orderbytype: OrderByType.ascending,
				});
			}
			const startTime = filter.startTime != undefined ? new Date(filter.startTime) : undefined;

			const list = await eventService.getList(filter.query, filter.userId, filter.tenant, startTime);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
	{
		condition: (filter: LoadEventsQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const eventState = state.event;

			if (eventState.value && eventState.value.page) {
				if (eventState.value.items.length === eventState.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const fetchEventById = createAsyncThunk<Event, string, { rejectValue: string }>(`${baseString}/get`, async (id: string, thunkAPI) => {
	try {
		const item = await eventService.get(id);
		return item;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
	}
});
export const fetchEventRegistrationById = createAsyncThunk<EventRegistration, [string, string], { rejectValue: string }>(
	`${baseString}/getRegistration`,
	async ([eventId, personId], thunkAPI) => {
		try {
			const item = await eventService.getEventRegistration(eventId, personId);
			return item;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const fetchEventParticipant = createAsyncThunk<any, [string, string, string], { rejectValue: string }>(
	`${baseString}/getParticipant`,
	async ([eventId, tenantId, personId], thunkAPI) => {
		try {
			const item = await eventService.getParticipationInfo(eventId, tenantId, personId);
			return { item, eventId };
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const fetchEventContractById = createAsyncThunk<Contract, string, { rejectValue: string }>(
	`${baseString}/get/contract`,
	async (id: string, thunkAPI) => {
		try {
			const item = await eventService.getContract(id);
			return item;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const reloadEvents = createAsyncThunk<BaseList<Event>, LoadEventsQuery, { rejectValue: string }>(
	`${baseString}/reload`,
	async (filter: LoadEventsQuery, thunkAPI) => {
		try {
			filter.query = newBaseListQuery({
				page: 1,
				orderby: "StartTime",
				orderbytype: OrderByType.ascending,
				keyword: filter.query.keyword,
				searchfields: ["Name"],
			});

			const startTime = filter.startTime != undefined ? new Date(filter.startTime) : undefined;

			const list = await eventService.getList(filter.query, filter.userId, filter.tenant, startTime);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const getEventDocuments = createAsyncThunk<BaseList<DocumentElement>, [string, string], { rejectValue: string }>(
	`${baseString}/getEventDocuments`,
	async ([eventId, tenantId], thunkAPI) => {
		try {
			const item = await eventService.getDocuments(tenantId, eventId);
			return item;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const wirthdrawParticipation = createAsyncThunk<boolean, [string, string], { rejectValue: string }>(
	`${baseString}/withdrawParticipation`,
	async ([userId, eventId], thunkAPI) => {
		try {
			const item = await eventService.withdrawParticipation(userId, eventId);
			return item;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const getContactPersons = createAsyncThunk<BaseList<ContactPerson>, [string, string], { rejectValue: string }>(
	`${baseString}/getContactPersons`,
	async ([eventId, tenantId], thunkAPI) => {
		try {
			const item = await eventService.getContactPersons(tenantId, eventId);
			return item;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
