import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getAllQualifications } from "store/qualification/Qualification.selectors";

import InfoBlock from "./InfoBlock/InfoBlock";

const MyQualifications: FC = () => {
	const { i18n } = useLingui();

	const qualifications = useSelector(getAllQualifications)?.items.slice(0, 5);
	const columns = {
		name: i18n._(t`qualification`),
	};

	const rows = qualifications?.map((qualification) => {
		return { id: qualification.id as string, name: qualification.name };
	});

	return (
		<InfoBlock
			id="qualifications"
			title={i18n._(t`profile.myQualifications`)}
			columns={columns}
			rows={rows}
			dataKey={"qualifications"}
			link={{
				link: "qualifications",
				title: i18n._(t`profile.myQualifications.all`),
			}}
		/>
	);
};

export default MyQualifications;
