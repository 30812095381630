import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { VFC } from "react";
import { useSelector } from "react-redux";
import { getAllMedicalInformations } from "store/profile/medicalInformation/MedicalInformation.selectors";

import InfoBlock from "./InfoBlock/InfoBlock";

const MyMedicalInformations: VFC = () => {
	const { i18n } = useLingui();
	const medicalInformations = useSelector(getAllMedicalInformations)?.items.slice(0, 5);

	const columns = {
		name: i18n._(t`medicalInfo.name`),
	};

	const rows = medicalInformations?.map((miscellaneous) => {
		return { id: miscellaneous.id as string, name: miscellaneous.name as string };
	});

	return (
		<>
			<InfoBlock
				id="miscellaneous"
				title={i18n._(t`My medical informations`)}
				columns={columns}
				rows={rows}
				dataKey={"medical"}
				editable
				link={{
					link: "/profile/miscellaneous",
					title: i18n._(t`profile.myMedicalInfo.all`),
				}}
			/>
		</>
	);
};

export default MyMedicalInformations;
