import { IonChip, IonItem, IonLabel, IonNote } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import CreatorChip from "components/creator/CreatorChip";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { Title } from "components/text/title/Title";
import { pencilOutline } from "ionicons/icons";
import { MedicalInformation } from "model/MedicalInformation";
import { useState, VFC } from "react";

import EditMedicalInformationModal from "./MedicalInformationModal";

export interface Props {
	item: MedicalInformation;
}
const MedicalInformationTeaser: VFC<Props> = ({ item: medicalInformation }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		<>
			<IonItem
				onClick={() => setIsEditModalOpen(true)}
				button
				detail={true}
				detailIcon={pencilOutline}
				key={`${medicalInformation?.id}_medical_information_teaser_list_item`}
				id={medicalInformation.id}
				disabled={medicalInformation.isBusy}
			>
				<EditMedicalInformationModal isOpen={isEditModalOpen} editItem={medicalInformation} onCancel={() => setIsEditModalOpen(false)} />
				<div className="ion-padding">
					<IonLabel>
						<IonLabel className="ion-padding-bottom">
							<IonLabel>
								<h2>{medicalInformation?.name}</h2>
							</IonLabel>
							<IonNote>
								<IonLabel>{medicalInformation?.description}</IonLabel>
							</IonNote>
						</IonLabel>
						<IonNote className="ion-margin-top">
							<Subtitle text={i18n._(t`common.CreatedBy`)} />
						</IonNote>
						<CreatorChip creator={medicalInformation.creator} />
						<IonNote>
							<Subtitle text={i18n._(t`common.SharedTo`)} />
						</IonNote>
						{medicalInformation.scopedSharingTenants?.length ?? 0 > 1 ? (
							medicalInformation.scopedSharingTenants?.map((tenant, i) => {
								return <ShareTenantChip scopedSharingTenant={tenant} />;
							})
						) : (
							<IonChip outline={false}>
								<IonLabel>Keine Freigaben</IonLabel>
							</IonChip>
						)}
					</IonLabel>
				</div>
			</IonItem>
		</>
	);
};

export default MedicalInformationTeaser;
