import { createSelector } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { Invoice } from "model/Invoice";
import { Tenant } from "model/Tenant";
import { RootState } from "store";
import { getSelectedTenant } from "store/profile/Profile.selectors";

export const getAllInvoices = (state: RootState): BaseList<Invoice> => state.invoice?.value || newBaseList<Invoice>();
export const invoiceIsBusy = (state: RootState): boolean => state.invoice.isFetching;
export const getIsFetching = (state: RootState): boolean => state.invoice.isFetching;

export const getInvoices = createSelector(getAllInvoices, getSelectedTenant, (invoices: BaseList<Invoice>, tenant: Tenant | null) => {
	return tenant ? invoices?.items?.filter((invoice) => invoice.tenantId === tenant.id) : invoices;
});

export const getInvoice = (invoiceId: string) => {
	return createSelector(getAllInvoices, (invoices) => {
		return invoices?.items?.find((invoice) => invoice.id === invoiceId);
	});
};
