import "./style.scss";
import "../style.scss";

import { IonAlert, IonButton, IonCard, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonRow } from "@ionic/react";
import TenantSearchSelect from "components/tenant/TenantSearchSelect";
import { alertCircleOutline, checkmarkCircleOutline, refreshOutline } from "ionicons/icons";
import { newBaseList } from "model/BaseList";
import { Event } from "model/Event";
import { ClubRequired } from "model/events/ClubRequired";
import EventRegistration from "model/events/EventRegistration";
import { newTenant, Tenant } from "model/Tenant";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedTenant } from "store/profile/Profile.selectors";

interface IEventClubPartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventClubPart: React.FunctionComponent<IEventClubPartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const tenant = useSelector(getSelectedTenant);
	const [clubs, setClubs] = useState(newBaseList<Tenant>());
	const [club, setClub] = useState<Tenant | undefined>(undefined);
	const [showTenantSelect, setShowTenantSelect] = useState(false);

	const [showAlert, setShowAlert] = useState(false);
	const [isBusy, setIsBuy] = useState(false);

	return (
		<>
			<IonAlert
				isOpen={showAlert}
				onDidDismiss={() => setShowAlert(false)}
				cssClass="my-custom-class"
				header={"Verein angeben"}
				inputs={[
					{
						name: "Verein",
						type: "text",
						placeholder: "Vereinsname",
					},
				]}
				buttons={[
					{
						text: "Abbrechen",
						role: "cancel",
						cssClass: "danger",
						handler: () => {
							console.log("Confirm Cancel");
						},
					},
					{
						text: "Ok",
						handler: (alertData) => {
							const tenant = newTenant();
							tenant.name = alertData.Verein;
							eventRegistration.tenantId = undefined;
							eventRegistration.tenantName = alertData.Verein;

							setEventRegistration(eventRegistration);
							setClub(tenant);
						},
					},
				]}
			/>
			<div className="firstDivider"></div>
			<IonCard>
				<IonGrid className={club == undefined ? "gridNoSuccess" : "gridSuccess"}>
					<IonRow>
						<IonCol size="0" sizeLg="1" sizeMd="0" sizeXl="1" sizeXs="0">
							{club == undefined ? (
								<IonIcon className="notDoneIcon" src={alertCircleOutline} />
							) : (
								<IonIcon className="isDoneIcon" src={checkmarkCircleOutline} />
							)}
						</IonCol>
						<IonCol size="11" sizeLg="11" sizeMd="11" sizeXl="11" sizeXs="12">
							<IonCard>
								<IonItemDivider className={club == undefined ? "rowDivider" : "rowDividerSuccess"} mode="ios">
									Verein:
									{event?.clubRequired == ClubRequired.Required ? <IonLabel color="danger">*</IonLabel> : <></>}
									<IonButton color="medium" slot="end" fill="clear">
										<IonIcon color="medium" size="small" src={refreshOutline}></IonIcon>
									</IonButton>
								</IonItemDivider>
								<IonItem className="rowItem" lines="none">
									<TenantSearchSelect
										isOpen={showTenantSelect}
										onDismiss={() => setShowTenantSelect(false)}
										onSelect={(selectedTenant: Tenant) => {
											//Ausgewählter Tenant darf nicht der ersteller sein
											if (selectedTenant?.id == tenant?.id) {
												return;
											}
											eventRegistration.tenantId = selectedTenant.id;
											setClub(selectedTenant);
											setEventRegistration(eventRegistration);
											setShowTenantSelect(false);
										}}
										triggerManuelSearch={() => {
											setShowTenantSelect(false);
											setShowAlert(true);
										}}
										tenantId={tenant?.id ?? event?.creator?.id}
									/>
									{/*
									<IonSelect
					color="medium"
					onClick={async (e) => {
						setIsBuy(true);
						
						const res = await axios.get(
							`${process.env.REACT_APP_API_BASE_URL}/api/association/${
								tenant?.id ?? event?.creator?.id
							}/member?filtertype=association&top=1000&Page=1`,
							{
								headers: { userId: profile?.id },
							},
						);
						if (res.status == 200) {
							setClubs(res.data);
						}
						
						setIsBuy(false);
						e.preventDefault();
					}}
					onIonChange={(e) => {
						if (e.detail) {
							eventRegistration.tenantId = e.detail?.value?.id;
							setEventRegistration(eventRegistration);
							setClub(e.detail.value);
						}
					}}
					ref={refSelect}
					slot="start"
					mode="ios"
					title="Verein wählen"
					selectedText={club?.name}
					value={club}
					placeholder={club?.name ?? "Verein wählen"}
				>
					<IonItem>
						<IonLabel>Verein wählen</IonLabel>
					</IonItem>
					<IonLoading isOpen={isBusy} />
					{clubs.items.map((club, i) => {
						return (
							<IonSelectOption value={club}>
								<IonItem key={club.id} lines="full" className="ion-activatable">
									<IonLabel slot="start">{club.name}</IonLabel>
								</IonItem>
							</IonSelectOption>
						);
					})}
				</IonSelect>
				*/}
									<IonLabel>{club?.name}</IonLabel>
									<IonButton
										onClick={() => setShowTenantSelect(true)}
										fill="clear"
										className="buttonColor"
										slot={club ? "end" : "start"}
									>
										{club ? "Verein ändern" : "Verein auswählen"}
									</IonButton>
									<IonButton onClick={() => setShowAlert(true)} fill="clear" className="buttonColor" slot="end">
										Manuell eingeben
									</IonButton>
								</IonItem>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventClubPart;
