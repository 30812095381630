import "./style.scss";
import "../style.scss";

import {
	IonAvatar,
	IonButton,
	IonChip,
	IonCol,
	IonGrid,
	IonImg,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonText,
	IonTextarea,
	IonTitle,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { format } from "date-fns";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import * as React from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getAvatarImageUrl, getProfile } from "store/profile/Profile.selectors";

interface IEventNotePartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventNotePart: React.FunctionComponent<IEventNotePartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const profile = useSelector(getProfile);
	const tenant = useSelector(getAllTenants).find((t) => t.id == event.tenantId);
	const avatarImageUrl = useSelector(getAvatarImageUrl);

	return (
		<>
			<div className="firstDivider"></div>

			<IonItemDivider mode="ios">Notiz:</IonItemDivider>
			<IonItem lines="none">
				<IonTextarea
					placeholder="Weitere Informationen"
					value={eventRegistration.note}
					onIonChange={(e) => {
						eventRegistration.note = e.detail.value ?? "";
						setEventRegistration(eventRegistration);
					}}
				></IonTextarea>
			</IonItem>
		</>
	);
};

export default EventNotePart;
