import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { Phone } from "model/Phone";
import { Tenant } from "model/Tenant";

class PhoneService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<Phone>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.orderby = "CreatedAt";

		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/phone/search`, { headers: { userId: userId } });
		return res.data;
	}
	async add(userId: string, phone: Phone): Promise<Phone> {
		const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/phone`, phone, { headers: { userId: userId } });
		return res.data;
	}
	async update(userId: string, phone: Phone): Promise<Phone> {
		const res = await axios.put(`${process.env.REACT_APP_API_ME_URL}/api/me/phone/${phone.id}`, phone, { headers: { userId: userId } });
		return res.data;
	}
	async delete(userId: string, phone: Phone): Promise<Phone> {
		const res = await axios.delete(`${process.env.REACT_APP_API_ME_URL}/api/me/phone/${phone.id}`, { headers: { userId: userId } });
		return res.data;
	}
	async share(userId: string, phone: Phone): Promise<Phone> {
		const res = await axios.put(
			`${process.env.REACT_APP_API_ME_URL}/api/me/phone/${phone.id}/share`,
			{ tenantIds: phone.scopedSharingTenants?.map((t) => t.id) },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async shareExtern(userId: string, phoneId: string, tenantId: string): Promise<Phone> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/phone/${phoneId}/share/extern`,
			{ tenantId: tenantId, userId: userId, entityId: phoneId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async shareIntern(userId: string, phoneId: string, tenantId: string): Promise<Phone> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/phone/${phoneId}/share/intern`,
			{ tenantId: tenantId, userId: userId, entityId: phoneId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async deleteShareExtern(userId: string, phoneId: string, tenantId: string): Promise<Phone> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/phone/${phoneId}/share/extern/remove`,
			{ tenantId: tenantId, userId: userId, entityId: phoneId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async deleteShareIntern(userId: string, phoneId: string, tenantId: string): Promise<Phone> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/phone/${phoneId}/share/intern/remove`,
			{ tenantId: tenantId, userId: userId, entityId: phoneId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
}
const phoneService = new PhoneService();
export default phoneService;
