import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonChip,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonModal,
	IonToolbar,
	useIonToast,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import InputItem from "components/form/InputItem";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { closeOutline, trashOutline, pencilOutline } from "ionicons/icons";
import { FC } from "react";

export interface Props {
	isOpen: boolean;
	onCancel(): void;
}

const LogPage: FC<Props> = ({ isOpen, onCancel }) => {
	const [present] = useIonToast();

	function sendEmail() {
		console.log("sendEmail");
		onCancel();
		presentToast();
	}

	const presentToast = () => {
		present({
			message: "Ihre Anfrage wurde erfolgreich versendet.",
			duration: 1500,
			position: "top",
		});
	};
	return (
		<IonModal color="amber-50" isOpen={isOpen} onDidDismiss={(e) => onCancel()}>
			<IonHeader>
				<div className="ion-text-center">
					<IonToolbar color="amber-300">
						<IonButton
							fill="clear"
							slot="start"
							onClick={() => {
								onCancel();
							}}
						>
							<IonIcon color="medium" icon={closeOutline}></IonIcon>
						</IonButton>

						<IonLabel slot="start">
							<h2>
								<Trans>DSGVO: Auskunftsrecht</Trans>
							</h2>
						</IonLabel>
					</IonToolbar>
				</div>
			</IonHeader>

			<IonContent color="amber-50">
				<IonCard>
					<IonCardHeader>Rechtsgrundlage</IonCardHeader>
					<IonCardContent>
						Die am 25. Mai 2018 in Kraft getretene Datenschutz-Grundverordnung (DSGVO) sorgt für eine einheitliche Datenschutzgesetzgebung
						in der gesamten Europäischen Union. Sie findet unabhängig vom Firmensitz für alle Unternehmen Anwendung, die personenbezogene
						Daten von natürlichen Personen in der EU verarbeiten. Der Begriff „Verarbeitung“ ist sehr weit gefasst und bezieht sich auf
						jeglichen Umgang mit personenbezogenen Daten, einschließlich der Erfassung, Speicherung, Nutzung und Vernichtung dieser Daten.
					</IonCardContent>
				</IonCard>
				<IonCard>
					<IonCardHeader>Ihr DSGVO Export</IonCardHeader>
					<IonCardContent>
						Die DSGVO gewährt Einzelpersonen (oder betroffenen Personen) bestimmte Rechte in Zusammenhang mit der Verarbeitung ihrer
						personenbezogenen Daten einschließlich des Rechts auf Berichtigung, Löschung, Einschränkung, Aushändigung und Übertragung der
						Daten an einen anderen Verantwortlichen. Im folgenden haben Sie die Möglichkeit einen Export ihrer der bei uns gespeicherten
						Daten per Email anzufordern.{" "}
					</IonCardContent>
				</IonCard>
			</IonContent>
			<IonButton className="ion-margin" expand="block" size="default" onClick={() => sendEmail()}>
				<IonLabel>Export anfordern</IonLabel>
			</IonButton>
		</IonModal>
	);
};

export default LogPage;
