import { Address } from "model/Address";
import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";

import { RootState } from "../..";
import { getScope, getSelectedTenant } from "../Profile.selectors";

export const getAllAddresses = (state: RootState): BaseList<Address> => state.address.value || newBaseList<Address>();

export const getIsFetching = (state: RootState): boolean => state.address.isFetching;
export const getIsShareInternFetching = (state: RootState): boolean => state.address.isShareInternFetching;
export const getIsShareExternFetching = (state: RootState): boolean => state.address.isShareExternFetching;

export const getAddresses = createSelector(getAllAddresses, getSelectedTenant, (addresses: BaseList<Address>, tenant: Tenant | null) => {
	return tenant
		? filterBaseListItems(
			addresses,
			(address: Address) => address.scopedSharingTenants?.some((t) => t.id === tenant.id) || address.creator?.id == tenant.id,
		)
		: addresses;
});

export const getAddress = createSelector(
	getAllAddresses,
	getSelectedTenant,
	getScope,
	(addresses: BaseList<Address>, tenant: Tenant | null, scopeType?: ScopeType): Address | undefined => {
		let result;
		switch (scopeType) {
			case ScopeType.default:
				if (tenant) {
					result = addresses.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isDefault);
				} else {
					result = addresses.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isDefault === true)[0]);
				}
				break;
			case ScopeType.intern:
				if (tenant) {
					result = addresses.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isIntern);
				} else {
					result = addresses.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isIntern === true)[0]);
				}
				break;
			case ScopeType.extern:
				if (tenant) {
					result = addresses.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isExtern);
				} else {
					result = addresses.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isExtern === true)[0]);
				}
				break;
		}

		return result !== undefined ? result : addresses.items.length ? addresses.items[0] : undefined;
	},
);

export const makeGetAddresses = createSelector(getAllAddresses, getSelectedTenant, (addresses, tenant) => {
	if (tenant) {
		addresses.items = addresses.items?.filter((a) => a.scopedSharingTenants?.map((t) => t.id).includes(tenant?.id ?? ""));
	}

	return addresses;
});
