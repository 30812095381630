import "./style.scss";

import { IonAvatar, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonNote, IonTitle } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import { useCapacitorOAuth2 } from "auth/CapacitorOAuth2Hook";
import { chevronDownOutline, chevronForwardOutline, helpBuoyOutline, idCardOutline, logOutOutline, personCircleOutline } from "ionicons/icons";
import { Profile } from "model/Profile";
import { Tenant } from "model/Tenant";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { deleteIdToken } from "store/profile/Profile.thunks";

interface IProfileIconPopupProps {
	onHide: () => void;
	signOut: () => void;
	avatarImageUrl: string | undefined;
	profile: Profile;
	selectedTenant: Tenant;
}
//Hier darf man keine Provider benutzen! D.h. auch keine Hooks wie z.B. "useAppDispatch" oder dergleichen!!
const ProfileIconPopup: React.FunctionComponent<IProfileIconPopupProps> = (props) => {
	//const history = useHistory();
	const capacitorAuth = useCapacitorOAuth2();
	const dispatch = useAppDispatch();
	//const mountNode = document.getElementById("tenantSelect") as HTMLIonSelectElement;
	return (
		<IonList mode="md">
			<IonItem className="topItem" lines="full">
				<IonAvatar>
					<IonImg src={"assets/icon/favicon-96x96.png"} />
				</IonAvatar>
				<IonTitle>Sportcloud</IonTitle>
			</IonItem>
			<IonItem
				className="buttonCursor"
				button={true}
				onClick={async (e) => {
					await capacitorAuth.logOutAsync();
					//	dispatch<any>(deleteIdToken());
				}}
			>
				{props.avatarImageUrl == undefined || props.avatarImageUrl == null ? (
					<IonIcon size="large" icon={personCircleOutline} slot="start" />
				) : (
					<IonAvatar slot="start">
						<IonImg src={props.avatarImageUrl} />
					</IonAvatar>
				)}
				<div>
					<IonLabel>
						{props.profile?.lastName} {props.profile?.firstName}
						<br></br>
						<IonNote>Ausloggen</IonNote>
					</IonLabel>
				</div>
				<IonIcon slot="end" color="danger" icon={logOutOutline}></IonIcon>
			</IonItem>
			{/*	<IonItem
				className="buttonCursor"
				type="button"
				lines="inset"
				onClick={(e) => {
					mountNode.open();
					e.preventDefault();
				}}
			>
				<IonAvatar slot="start">
					<IonImg src={props.selectedTenant?.avatarImageUrl ?? "assets/icon/favicon-96x96.png"} />
				</IonAvatar>
				<div>
					<IonLabel>
						{props.selectedTenant?.name ?? "DSO"}
						<br></br>
						<IonNote>{i18n._(t`All tenants`)}</IonNote>
					</IonLabel>
				</div>
				<IonIcon size="small" slot="end" color="default" icon={chevronDownOutline}></IonIcon>
			</IonItem> */}
			<IonItem lines="none" target="_blank" href="https://support.dso-verein.de/hc/de" className="buttonCursor" button={true}>
				<IonIcon slot="start" size="large" color="default" icon={helpBuoyOutline}></IonIcon>

				<div>
					<IonLabel>
						Helpcenter
						<br></br>
						<IonNote>Kontakt • Support</IonNote>
					</IonLabel>
				</div>
				<IonIcon size="small" slot="end" color="default" icon={chevronForwardOutline}></IonIcon>
			</IonItem>
			{/*			<IonItem routerLink="account" className="buttonCursor" button={true} lines="none">
				<IonIcon slot="start" size="large" color="default" icon={idCardOutline}></IonIcon>

				<div>
					<IonLabel>
						Account
						<br></br>
						<IonNote>Email • Passwort</IonNote>
					</IonLabel>
				</div>
				<IonIcon size="small" slot="end" color="default" icon={chevronForwardOutline}></IonIcon>
			</IonItem>
*/}
		</IonList>
	);
};

export default ProfileIconPopup;
