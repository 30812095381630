import { RefresherEventDetail } from "@ionic/core";
import { IonCol } from "@ionic/react";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { ConfectionInformation } from "model/ConfectionInformation";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllConfectionInformations, getIsFetching } from "store/profile/confectionInformation/ConfectionInformation.selectors";

import { InfiniteBaseList } from "../infinite/InfiniteBaseList";
import ConfectionInformationTeaser from "./Teaser";

interface ListProps {
	onPageScrollEnd: (baseListeQuery: BaseListQuery) => Promise<void>;
	onRefresh: (baseListeQuery: BaseListQuery) => Promise<void>;
}

const List: VFC<ListProps> = ({ onPageScrollEnd, onRefresh }) => {
	const list = useSelector(getAllConfectionInformations);
	const isBusy = useSelector(getIsFetching);
	const [baseList, setBaseList] = useState(newBaseListQuery());

	const renderItem = (item: ConfectionInformation) => {
		return (
			<IonCol id={item?.id} size="12" key={item.id}>
				<ConfectionInformationTeaser key={`${item.id}_confectioninformation_teaser_list`} item={item} />
			</IonCol>
		);
	};
	const onInfinite = async (event: any) => {
		if (!isBusy && baseList?.page && baseList?.pagesize) {
			if ((baseList.page + 1) * baseList.pagesize <= list.maximumcount) {
				baseList.page++;
				setBaseList(baseList);
				await onPageScrollEnd(baseList);
			}
		}
		event.target.complete();
	};
	const reloadEntries = async (event: CustomEvent<RefresherEventDetail>) => {
		if (!isBusy) {
			onRefresh(newBaseListQuery());
		}
		event.detail.complete();
	};
	return <InfiniteBaseList items={list} isFetching={isBusy} renderItem={renderItem} onInfinite={onInfinite} onRefresh={reloadEntries} />;
};
export default List;
