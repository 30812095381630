import "./style.scss";
import "../style.scss";

import { IonHeader, IonProgressBar, IonTitle, IonToolbar } from "@ionic/react";
import * as React from "react";

interface IProfileValidationHeader {
	title: string;
	progress: number;
}

const ProfileValidationHeader: React.FunctionComponent<IProfileValidationHeader> = ({ title, progress }) => {
	return (
		<IonHeader>
			<IonToolbar mode="md">
				<IonTitle>
					<h4>{title}</h4>
				</IonTitle>
				<IonProgressBar value={progress / 10}></IonProgressBar>
			</IonToolbar>
		</IonHeader>
	);
};

export default ProfileValidationHeader;
