import Token from "model/Token";
import { createContext, useContext } from "react";

export interface AuthContextValue {
	token: Token | null;
	redirectTo: string;
	isAuthenticated: () => boolean;
	signinRedirect: () => void;
	signinRedirectCallback: () => Promise<void>;
	signOut: () => void;
}

export function emptyAuthContextValue(): AuthContextValue {
	return {
		token: null,
		redirectTo: "/",
		isAuthenticated: () => false,
		signinRedirect: () => ({}),
		signinRedirectCallback: async () => {
			return;
		},
		signOut: () => ({}),
	};
}

export const AuthContext = createContext<AuthContextValue>(emptyAuthContextValue());

export const useAuthContext = () => useContext(AuthContext);
