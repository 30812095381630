import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { newBaseListQuery } from "model/BaseListQuery";
import BlogEntry from "model/BlogEntry";
import { LoadBlogEntriesQuery } from "model/blogEntry/LoadBlogEntriesQuery";
import blogEntryService from "services/BlogEntryService";

import { RootState } from "..";

const baseString = "blogentry";
export const fetchBlogEntries = createAsyncThunk<
	// Return type of the payload creator
	BaseList<BlogEntry>,
	// First argument to the payload creator
	LoadBlogEntriesQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: LoadBlogEntriesQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			if (state.blogEntry?.value?.page) {
				filter.query = newBaseListQuery({
					page: state.blogEntry.value.page + 1,
				});
			}

			const list = await blogEntryService.getList(filter.query, filter.tenant);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: LoadBlogEntriesQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const blogEntryState = state.blogEntry;

			if (blogEntryState.value && blogEntryState.value.page) {
				if (blogEntryState.value.items.length === blogEntryState.value.maximumcount) {
					return false;
				}
			}
			return true && !blogEntryState.isFetching;
		},
	},
);

export const reloadBlogEntries = createAsyncThunk<
	// Return type of the payload creator
	BaseList<BlogEntry>,
	// First argument to the payload creator
	LoadBlogEntriesQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: LoadBlogEntriesQuery, thunkAPI) => {
	try {
		filter.query = newBaseListQuery({
			page: 1,
		});

		const list = await blogEntryService.getList(filter.query, filter.tenant);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});
