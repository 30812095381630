import { IonAvatar, IonButton, IonChip, IonIcon, IonImg, IonLabel } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { ScopeType } from "model/ScopeType";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { ShareModelDto } from "model/ShareModelDto";
import { FC, VFC } from "react";

export interface Props {
	scopedSharingTenant: ScopedSharingTenant;
	onDelete: (scopedSharingTenant: ScopedSharingTenant) => void;
}
const ShareScopeTenantChip: FC<Props> = ({ scopedSharingTenant, onDelete }) => {
	return (
		<IonChip onClick={(e) => onDelete(scopedSharingTenant)} id={scopedSharingTenant.id + "_scopeChip"} outline={true}>
			{scopedSharingTenant?.avatarImageUrl ? (
				<IonAvatar>
					<IonImg src={scopedSharingTenant?.avatarImageUrl} />
				</IonAvatar>
			) : (
				<></>
			)}
			<IonLabel>{scopedSharingTenant.name}</IonLabel>
			<IonIcon icon={closeCircle} />
		</IonChip>
	);
};

export default ShareScopeTenantChip;
