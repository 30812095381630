import "./style.scss";
import "../style.scss";

import { IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import { Event } from "model/Event";
import * as React from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getAvatarImageUrl, getProfile } from "store/profile/Profile.selectors";

interface IEventNotePartProps {
	event: Event;
}

const EventNotePart: React.FunctionComponent<IEventNotePartProps> = ({ event }) => {
	const profile = useSelector(getProfile);
	const tenant = useSelector(getAllTenants).find((t) => t.id == event.tenantId);
	const avatarImageUrl = useSelector(getAvatarImageUrl);

	return (
		<>
			<div className="firstDivider"></div>

			<IonItemDivider mode="ios">Notiz:</IonItemDivider>
			<IonItem lines="none">
				<IonItem lines="none">
					<IonLabel>{event.eventRegistration?.note}</IonLabel>
				</IonItem>
			</IonItem>
		</>
	);
};

export default EventNotePart;
