import { Toast } from "@capacitor/toast";
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCol,
	IonContent,
	IonFab,
	IonFabButton,
	IonFooter,
	IonGrid,
	IonHeader,
	IonIcon,
	IonLoading,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonAlert,
} from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import { useCapacitorOAuth2 } from "auth/CapacitorOAuth2Hook";
import axios from "axios";
import PrivacyApproval from "components/privacy/PrivacyApproval";
import { TabContex } from "context/TabContext";
import { arrowDownOutline, informationCircleOutline } from "ionicons/icons";
import { useContext, useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getProfile } from "store/profile/Profile.selectors";
import { fetchProfile, updateIdToken } from "store/profile/Profile.thunks";
import "./style.scss";
import approvalService from "services/ApprovalService";
import { getToken } from "store/token/Token.selectors";
import { Approval } from "model/Approval";
import { newBaseList } from "model/BaseList";
import { useHistory } from "react-router-dom";

export const PrivacyApprovalPage: VFC = () => {
	const capacitorAuth = useCapacitorOAuth2();
	const dispatch = useAppDispatch();
	const history = useHistory();

	const profile = useSelector(getProfile);
	const [presentAlert] = useIonAlert();
	const [showLoading, setShowLoading] = useState(false);
	const tabContext = useContext(TabContex);
	const auth = useCapacitorOAuth2();
	const [scrollButtonVisible, setScrollButtonVisible] = useState(true);
	const refContent = useRef<HTMLIonContentElement>(null);
	const [approvals, setApprovals] = useState(newBaseList<Approval>());
	const [lockLoad, setLockLoad] = useState(false);
	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);

	useEffect(() => {
		if (profile == undefined) {
			return;
		}

		const getApprovals = async () => {
			const res = await loadMissingApprovalsAsync();
			if (res?.data == null) {
				return;
			}

			if (res.data?.items.length == 0) {
				setLockLoad(true);
			}
		};
		if (approvals.items.length == 0 && !lockLoad) {
			getApprovals();
		}
	}, [approvals]);

	const finish = async (approvalVersionIds: Array<Approval>) => {
		try {
			setShowLoading(true);
			const token = await capacitorAuth.getTokenAsync();
			if (!token) {
				throw new Error("Token not found");
			}
			const idsToApprove = approvalVersionIds?.map(({ approvalversions }) => {
				console.log(approvalversions);
				if (approvalversions?.length == 0) {
					return null;
				}
				return approvalversions?.sort((x, y) => x.version - y.version)[0].id as string;
			}) as Array<string>;

			if (idsToApprove?.length == 0) {
				throw new Error("No approval versions found");
			}

			const res = await approvalService.approveAsync(token, idsToApprove);
			if (res.status == 200) {
				console.log(res);
				await Toast.show({
					text: "Sie haben ihren Account erfolgreich eingerichtet.",
					duration: "short",
					position: "center",
				});

				await Toast.show({
					text: "Sie werden in kürze weitergeleitet.",
					duration: "long",
					position: "center",
				});

				await Toast.show({
					text: "Alle Änderungen wurden erfolgreich gespeichert. Sie werden automatisch weitergeleitet.",
					duration: "long",
					position: "center",
				});
				//	setTimeout(() => window.close(), 3000);
				//		await capacitorAuth.clearTokenAsync();
				//		window.location.pathname = "/";
				//		window.location.reload();
				const authResult = await auth.refreshTokenAsync();
				dispatch<any>(updateIdToken(capacitorAuth.parseJwt(authResult.id_token)));
				dispatch<any>(fetchProfile(capacitorAuth.parseJwt(authResult.id_token).person_Id));
				window.location.href = process.env.REACT_APP_REDIRECT_URL_WEB ?? "https://sportcloud.de";

				/*
				const account = instance.getAllAccounts()[0];

				const accessTokenRequest = {
					scopes: ["openid", "profile", "offline_access"],
					account: account,
				};
				// Aquire new IdToken
				instance
					.acquireTokenSilent(accessTokenRequest)
					.then(function (accessTokenResponse) {
						// Acquire token silent success
						// Call API with token
						const accessToken = accessTokenResponse;
						dispatch<any>(updateIdToken(accessToken as unknown as IdTokenClaims));

						//var x = account.idTokenClaims;
						// Call your API with token
						if (selectedTenant?.id == null || selectedTenant?.id == undefined || selectedTenant?.id == "") {
							history.replace("/");
							window.location.pathname = "/";
						} else {
							history.replace("/?tenantId=" + selectedTenant?.id);
							window.location.pathname = "/?tenantId=" + selectedTenant?.id;
						}
					})
					.catch(function (error) {
						//Acquire token silent failure, and send an interactive request
						console.log(error);
						// chrome private tab fallback => if acquireTokenSilent fails, we try acquireTokenRedirect method
						if (error.errorMessage.includes("AADB2C90077")) {
							return instance.acquireTokenRedirect(accessTokenRequest);
						}
					});
					*/
			}
		} catch (error) {
			presentAlert({
				header: "Fehler",
				message: "Leider ist ein Fehler bei Ihrer Anmeldung aufgetreten.",
				buttons: ["OK"],
			});
		} finally {
			setShowLoading(false);
		}
	};

	const loadMissingApprovalsAsync = async () => {
		try {
			const token = await capacitorAuth.getTokenAsync();
			if (!token) {
				throw new Error("Token not found");
			}
			const res = await approvalService.getMissingApprovalsAsync(token);

			if (!res) {
				await Toast.show({
					text: "AGB konnten nicht geladen werden. Bitte aktualisieren Sie die Seite.",
					duration: "short",
					position: "center",
				});
				return;
			}

			if (res.status == 200) {
				// Check if Person got all approvals
				if (res.data.items.length == 0) {
					// Set loading true to hide logout or token progress
					await capacitorAuth.logOutAsync();
					return; // stop here as we dont need to approve anything
				}
				setApprovals(res.data);
			} else {
				console.log("Result not 200:", res.status);
				await Toast.show({
					text: "AGB konnten nicht geladen werden. Bitte aktualisieren Sie die Seite.",
					duration: "short",
					position: "center",
				});
			}
			return res;
		} catch (error) {
			await Toast.show({
				text: "AGB konnten nicht geladen werden. Bitte neu einloggen.",
				duration: "short",
				position: "center",
			});
		}
		return null;
	};

	const scroll = () => {
		refContent.current?.clientHeight;

		refContent.current?.scrollToBottom(1000);
		setTimeout(() => {
			setScrollButtonVisible(false);
		}, 100);
	};
	return (
		<IonPage>
			<IonLoading isOpen={showLoading}></IonLoading>

			{approvals.items.length != 0 ? (
				<>
					<IonContent scrollEvents={true} onIonScrollEnd={(e: any) => console.log(e?.target?.detail)} ref={refContent}>
						<IonRefresher slot="fixed" onIonRefresh={async () => await loadMissingApprovalsAsync()}>
							<IonRefresherContent></IonRefresherContent>
						</IonRefresher>
						{scrollButtonVisible ? (
							<IonFab horizontal="end" vertical="bottom" slot="fixed">
								<IonFabButton onClick={() => scroll()}>
									<IonIcon icon={arrowDownOutline}></IonIcon>
								</IonFabButton>
							</IonFab>
						) : (
							<></>
						)}

						<IonLoading isOpen={showLoading} message={"Ihre Daten werden geladen."} />
						<IonHeader>
							<IonToolbar mode="md">
								<IonTitle>
									<h4>Notwendige Geschäftsbedingungen</h4>
								</IonTitle>
								<IonIcon
									onClick={() => (window.location.href = "https://sportcloud.de/impressum/")}
									slot="end"
									className="ion-padding"
									size="large"
									icon={informationCircleOutline}
								></IonIcon>
							</IonToolbar>
						</IonHeader>
						<IonGrid>
							<IonRow>
								<IonCol></IonCol>
								<IonCol size="8" sizeXs="12" sizeSm="10">
									{approvals.items.map((approval) => {
										return <PrivacyApproval key={approval.id} approval={approval}></PrivacyApproval>;
									})}
								</IonCol>
								<IonCol></IonCol>
							</IonRow>
						</IonGrid>
					</IonContent>

					<IonFooter class="ion-padding-top ion-padding-bottom">
						<IonGrid>
							<IonRow>
								<IonCol></IonCol>
								<IonCol size="8" sizeXs="12" sizeSm="10">
									<IonCard>
										<IonCardHeader>
											<IonCardSubtitle>
												Durch Klicken auf Akzeptieren stimmen Sie den oben genannten Nutzungs so wie Datenschutzerklärungen
												zu.
												<br></br>
												Sie erhalten diese Dokumente per Mail und können Sie jederzeit in Ihrem Bereich unter Dokumente
												einsehen.
											</IonCardSubtitle>
										</IonCardHeader>

										<IonCardContent>
											<IonButton
												disabled={showLoading}
												onClick={async () => await finish(approvals.items)}
												fill="solid"
												expand="full"
											>
												Akzeptieren
											</IonButton>
										</IonCardContent>
									</IonCard>
								</IonCol>
								<IonCol></IonCol>
							</IonRow>
						</IonGrid>
					</IonFooter>
				</>
			) : (
				<></>
			)}
		</IonPage>
	);
};
