import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditModal from "components/confectionInformation/ConfectionInformationModal";
import List from "components/confectionInformation/List";
import ProfileValidationFooter from "components/profileValidation/components/ProfileValidationFooter";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { add } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newConfectionInformation } from "model/ConfectionInformation";
import { useContext, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/confectionInformation/ConfectionInformation.selectors";
import { fetchConfectionInformations, reloadConfectionInformations } from "store/profile/confectionInformation/ConfectionInformation.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

export const ProfileValidationProcessConfectionInformationsPage: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewItemModalOpen, setIsNewItemModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);
	const isLoading = useSelector(getIsFetching);
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);
	useEffect(() => {
		dispatch<any>(reloadConfectionInformations(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchConfectionInformations(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadConfectionInformations(newBaseListQuery(basListQuery)));
	};
	return (
		<IonPage>
			<IonContent>
				<ProfileValidationHeader title="Kleidungsangaben" progress={2} />

				<EditModal isOpen={isNewItemModalOpen} editItem={newConfectionInformation()} onCancel={() => setIsNewItemModalOpen(false)} />
				<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={() => setIsNewItemModalOpen(true)}>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>
			</IonContent>

			<ProfileValidationFooter
				isLoading={isLoading}
				progress={2}
				routerLinkNext="/validation/profile/1/miscellaneous"
				routerLinkBack="/validation/profile/1/profile"
			/>
		</IonPage>
	);
};
