import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import EditMailModal from "components/mail/EditModal";
import List from "components/mail/List";
import { add } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newMail } from "model/Mail";
import { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { fetchMails, reloadMails } from "store/profile/mail/Mail.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

import ProfilePageLayout from "./ProfilePageLayout";

const Mails: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewMailModalOpen, setIsNewMailModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);

	useEffect(() => {
		dispatch<any>(reloadMails(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchMails(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadMails(newBaseListQuery(basListQuery)));
	};
	return (
		<ProfilePageLayout title={i18n._(t`Mail addresses`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<EditMailModal isOpen={isNewMailModalOpen} editItem={newMail()} onCancel={() => setIsNewMailModalOpen(false)} />
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => setIsNewMailModalOpen(true)}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
		</ProfilePageLayout>
	);
};

export default Mails;
