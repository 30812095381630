import "./Card.scss";

import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonImg, IonItem, IonLabel } from "@ionic/react";
import { Trans } from "@lingui/macro";
import { createOutline } from "ionicons/icons";
import { FC } from "react";

import { Subtitle } from "../text/subtitle/Subtitle";

export interface Props {
	id?: string;
	title?: string;
	subtitle?: string;
	info?: string;
	text?: string;
	routerLink?: string;
	headerImageUrl?: string;
	avatarImageUrl?: string;
	children?: React.ReactNode;
	isHeaderCentered?: boolean;
	onEditClick?: () => void;
}

export const Card: FC<Props> = (props) => {
	return (
		<IonCard id={props?.id} color="amber-50" routerLink={props.routerLink} button={true}>
			<IonImg color="amber-50" src={props.headerImageUrl} class="card-hero" />
			<IonItem color="amber-50" lines="full">
				{props.title ? <h4 className="ion-text-wrap">{props.title}</h4> : <></>}
				<IonCardHeader className={props.isHeaderCentered === true ? "ion-text-center" : undefined}>
					{props.subtitle ? (
						<IonCardSubtitle>
							<Subtitle text={props.subtitle} />
						</IonCardSubtitle>
					) : (
						<></>
					)}
					{props.info ? (
						<IonCardSubtitle>
							<IonLabel className="card-title">text={props.info}</IonLabel>
						</IonCardSubtitle>
					) : (
						<></>
					)}
					{props.onEditClick ? (
						<IonButton fill="clear" size="small" onClick={props.onEditClick}>
							<Trans>Edit</Trans>
							<IonIcon slot="start" icon={createOutline} />
						</IonButton>
					) : (
						<></>
					)}
				</IonCardHeader>
			</IonItem>
			<IonCardContent color="light" class="card-content">
				{props.children}
			</IonCardContent>
		</IonCard>
	);
};
