import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { ConfectionInformation } from "model/ConfectionInformation";
import { Tenant } from "model/Tenant";

class ConfectionInformationService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<ConfectionInformation>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.orderby = "CreatedAt";

		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/confectionInformation/search`, {
			params,
			headers: { userId: userId },
		});
		return res.data;
	}
	async add(userId: string, confectionInformation: ConfectionInformation): Promise<ConfectionInformation> {
		const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/confectionInformation`, confectionInformation, {
			headers: { userId: userId },
		});
		return res.data;
	}
	async update(userId: string, confectionInformation: ConfectionInformation): Promise<ConfectionInformation> {
		const res = await axios.put(
			`${process.env.REACT_APP_API_ME_URL}/api/me/confectionInformation/${confectionInformation.id}`,
			confectionInformation,
			{ headers: { userId: userId } },
		);
		return res.data;
	}
	async delete(userId: string, confectionInformation: ConfectionInformation): Promise<ConfectionInformation> {
		const res = await axios.delete(`${process.env.REACT_APP_API_ME_URL}/api/me/confectionInformation/${confectionInformation.id}`, {
			headers: { userId: userId },
		});
		return res.data;
	}
	async share(userId: string, confectionInformation: ConfectionInformation): Promise<ConfectionInformation> {
		const res = await axios.put(
			`${process.env.REACT_APP_API_ME_URL}/api/me/confectionInformation/${confectionInformation.id}/share`,
			{ tenantIds: confectionInformation.scopedSharingTenants?.map((t) => t.id) },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
}
const confectionInformationService = new ConfectionInformationService();
export default confectionInformationService;
