import { BaseListQueryInstance } from "model/BaseListQuery";
import { Tenant } from "model/Tenant";

export interface LoadBlogEntriesQuery {
	query: BaseListQueryInstance;
	tenant?: Tenant;
}

export function newLoadBlogEntriesQuery(query: BaseListQueryInstance, tenant?: Tenant): LoadBlogEntriesQuery {
	return {
		query: query,
		tenant: tenant,
	};
}
