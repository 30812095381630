import { PriceRestrictionType } from "model/payment/PriceRestrictionType";

export function priceRestrictionTypeToString(priceRestrictionType: PriceRestrictionType | undefined): string | undefined {
	if (!priceRestrictionType) {
		return "Allgemein";
	}
	switch (priceRestrictionType) {
		case PriceRestrictionType.ActiveMember:
			return "Aktives Mitglied";
			break;
		case PriceRestrictionType.AgeOver18:
			return "Über 18";
			break;
		case PriceRestrictionType.AgeUnder18:
			return "Unter 18";
			break;
		case PriceRestrictionType.NoMember:
			return "Kein Mitglied";
			break;
		case PriceRestrictionType.Standard:
			return "Standard";
			break;
		case PriceRestrictionType.Team:
			return "Teammitglied";
			break;
		default:
			return "Allgemein";
			break;
	}
	return "Passives Mitglied";
}
