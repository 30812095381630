import { OAuth2AuthenticateOptions } from "@byteowls/capacitor-oauth2";

export const LoginCapacitorAzureB2cOptions = {
	appId: process.env.REACT_APP_B2C_CLIENT_ID,
	authorizationBaseUrl: `${process.env.REACT_APP_AUTH_ENDPOINT}`,
	scope: `openid offline_access ${process.env.REACT_APP_B2C_CLIENT_ID}`, // See Azure Portal -> API permission
	accessTokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
	//		resourceUrl: "https://graph.microsoft.com/v1.0/me/",
	responseType: "code",
	pkceEnabled: true, // needs to be false as we create ower own Code_Challenge
	logsEnabled: true,
	additionalParameters: {
		response_mode: "query",
		// Has to be set but will be rewritten by the plugin
		//code_challenge: "ThisIsntRandomButItNeedsToBe43CharactersLong", // MAY NOT BE SET!!
		//code_challenge_method: "plain", // MAY NOT BE SET!!
	},
	web: {
		redirectUrl: process.env.REACT_APP_REDIRECT_URL_WEB,
		windowOptions: "height=200,left=0,top=0",
		accessTokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
		/*
		_self kann zu Fehlern führen, da durch den redirect im selben Tab die App komplett neu gerendert wird und somit der Auth zyklus neu getriggert wird
		_blank kann dabei helfen Fehler zu finden, hierbei bleibt die App geöffnet und es entsteht ein neues Fenster -> dadurch kein neu rendern und man behält den App Status / Zustand
		*/
		windowTarget: "_self",
	},
	android: {
		redirectUrl: process.env.REACT_APP_REDIRECT_URL_ANDROID, // See Azure Portal -> Authentication -> Android Configuration "Redirect URI"
	},
	ios: {
		//scope: `openid offline_access https://dsobetaidp.onmicrosoft.com/4bb186fb-2b79-4ab0-8ce8-ca133e79dc7d/accesstoken ${process.env.REACT_APP_B2C_CLIENT_ID}`, // See Azure Portal -> API permission
		//	accessTokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
		redirectUrl: process.env.REACT_APP_REDIRECT_URL_IOS,
		pkceEnabled: true, // needs to be false as we create our own Code_Challenge
		responseType: "code",
		//		siwaUseScope: true,
		logsEnabled: true,
	},
} as OAuth2AuthenticateOptions;
