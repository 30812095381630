import "./style.scss";
import "../style.scss";

import { IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonRadio, IonRadioGroup, IonRow } from "@ionic/react";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import { Event } from "model/Event";
import { EventProgressType } from "model/events/EventProgressType";
import EventRegistration from "model/events/EventRegistration";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getAvatarImageUrl, getProfile } from "store/profile/Profile.selectors";

interface IEventTakePartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventTakePart: React.FunctionComponent<IEventTakePartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const [hasChecked, setHasChecked] = useState(true);
	return (
		<>
			<div className="firstDivider"></div>
			<IonCard>
				<IonGrid className={hasChecked ? "gridNoSuccess" : "gridSuccess"}>
					<IonRow>
						<IonCol size="0" sizeLg="1" sizeSm="0" sizeMd="0" sizeXl="1" sizeXs="0">
							{hasChecked ? (
								<IonIcon className="notDoneIcon" src={alertCircleOutline} />
							) : (
								<IonIcon className="isDoneIcon" src={checkmarkCircleOutline} />
							)}
						</IonCol>
						<IonCol size="12" sizeLg="11" sizeSm="12" sizeMd="12" sizeXl="11" sizeXs="12">
							<IonCard>
								<IonItemDivider className={hasChecked ? "rowDivider" : "rowDividerSuccess"} mode="ios">
									Anmelden als:
									<IonLabel color="danger">*</IonLabel>
								</IonItemDivider>
								<div className="rowItem">
									<IonRadioGroup
										value={eventRegistration.eventProgressType}
										onIonChange={(e) => {
											eventRegistration.eventProgressType = e.detail.value;
											setEventRegistration(eventRegistration);
											console.log(e);
											setHasChecked(false);
										}}
									>
										<IonItem
											disabled={(event?.participantCount ?? 0) >= (event?.maxParticipating ?? 0) ?? true}
											className="ion-activatable"
										>
											<IonLabel>Teilnehmer</IonLabel>
											<IonRadio mode="md" value={EventProgressType.Participating} />
										</IonItem>

										<IonItem disabled={(event?.waitingCount ?? 0) >= (event?.maxWaiting ?? 0) ?? true}>
											<IonLabel>Warteliste</IonLabel>
											<IonRadio mode="md" value={EventProgressType.Waiting} />
										</IonItem>
									</IonRadioGroup>
								</div>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventTakePart;
