import { Creator } from "./Creator";
import { IHasScopeSharingTenants } from './IHasScopeSharingTenants';
import { IsBusy } from "./IsBusy";
import { ScopedSharingTenant } from "./ScopedSharingTenant";

export interface Mail extends IsBusy, IHasScopeSharingTenants {
	id?: string;
	tenantId?: string;
	mailAddress?: string;
	isDefault?: boolean;
	isIntern?: boolean;
	isExtern?: boolean;
	scopedSharingTenants?: ScopedSharingTenant[];
	creator?: Creator;
}

export function newMail(): Mail {
	return {
		id: undefined,
	};
}

export function mailString(mail?: Mail): string | undefined {
	return mail?.mailAddress;
}
