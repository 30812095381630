export function dateString(date?: Date): string | undefined {
	if (!date || isNaN(date.getTime())) {
		return "";
	}

	return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getFullYear()}`;
}

export function dateTimeString(date?: Date): string | undefined {
	if (!date) {
		return "";
	}

	return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
		.toString()
		.padStart(2, "0")}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
}
export function dateTimeStringWithOutHour(date?: Date): string | undefined {
	if (!date) {
		return "";
	}

	return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getFullYear()}`;
}
