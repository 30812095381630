import { IonChip, IonItem, IonLabel, IonNote } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import CreatorChip from "components/creator/CreatorChip";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { Subtitle } from "components/text/subtitle/Subtitle";
import { Title } from "components/text/title/Title";
import { pencilOutline } from "ionicons/icons";
import { BankAccount, bankAccountString } from "model/BankAccount";
import { useRef, FC } from "react";

import EditModal from "./EditModal";

export interface Props {
	bankAccount: BankAccount;
}
const BankAccountTeaser: FC<Props> = ({ bankAccount }) => {
	const modal = useRef<HTMLIonModalElement>(null);

	return (
		<div id={bankAccount.id} key={`${bankAccount.id}_bankaccount_teaser_list_item_div`}>
			<IonItem
				onClick={() => modal.current?.present()}
				button
				detail={true}
				detailIcon={pencilOutline}
				key={`${bankAccount.id}_bankaccount_teaser_list_item`}
				id={bankAccount.id}
				disabled={bankAccount.isBusy}
			>
				<EditModal modalRef={modal} editItem={bankAccount} onCancel={() => modal.current?.dismiss()} />
				<div className="ion-padding">
					<IonLabel>
						<Title text={bankAccountString(bankAccount)} />

						<IonNote>
							<Subtitle text={i18n._(t`common.CreatedBy`)} />
						</IonNote>
						<CreatorChip creator={bankAccount.creator} />
						<IonNote>
							<Subtitle text={i18n._(t`common.SharedTo`)} />
						</IonNote>

						{bankAccount.scopedSharingTenants?.length ?? 0 > 1 ? (
							bankAccount.scopedSharingTenants?.map((tenant, i) => {
								return <ShareTenantChip scopedSharingTenant={tenant} />;
							})
						) : (
							<IonChip outline={false}>
								<IonLabel>Keine Freigaben</IonLabel>
							</IonChip>
						)}
					</IonLabel>
				</div>
			</IonItem>
		</div>
	);
};

export default BankAccountTeaser;
