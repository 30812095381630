import { createAsyncThunk } from "@reduxjs/toolkit";
import { Address } from "model/Address";
import { BaseList } from "model/BaseList";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { UpsertModel } from "model/UpsertModel";
import addressService from "services/AddressService";

import { RootState } from "../..";
import { ShareModelDto } from 'model/ShareModelDto';
import { ScopeType } from 'model/ScopeType';

const baseString = "address";
export const fetchAddresses = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Address>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: BaseListQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			const query = newBaseListQuery(filter);

			const list = await addressService.get(state.profile.idTokenClaim.person_id, query);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: BaseListQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const listeSate = state.address;

			if (listeSate.value && listeSate.value.page) {
				if (listeSate.value.items.length === listeSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const reloadAddresses = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Address>,
	// First argument to the payload creator
	BaseListQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: BaseListQuery, thunkAPI) => {
	try {
		const query = newBaseListQuery(filter);
		const state = thunkAPI.getState() as RootState;

		const list = await addressService.get(state.profile.idTokenClaim.person_id, query);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});

export const addAddress = createAsyncThunk<Address, UpsertModel<Address>, { rejectValue: string }>(
	`${baseString}/add`,
	async (addModel: UpsertModel<Address>, thunkAPI) => {
		try {
			const addressResult = await addressService.add(addModel.userId, addModel.item);
			return addressResult;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);

export const updateAddress = createAsyncThunk<Address, UpsertModel<Address>, { rejectValue: string }>(
	`${baseString}/update`,
	async (updateModel: UpsertModel<Address>, thunkAPI) => {
		try {
			const addressResult = await addressService.update(updateModel.userId, updateModel.item);
			console.log("addressResult", addressResult);
			return addressResult;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);

export const deleteAddress = createAsyncThunk<string | undefined, UpsertModel<Address>, { rejectValue: string }>(
	`${baseString}/delete`,
	async (deleteModel: UpsertModel<Address>, thunkAPI) => {
		try {
			const addressResult = await addressService.delete(deleteModel.userId, deleteModel.item);
			return deleteModel.item.id;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);

export const shareAddress = createAsyncThunk<Address, UpsertModel<Address>, { rejectValue: string }>(
	`${baseString}/share`,
	async (shareModel: UpsertModel<Address>, thunkAPI) => {
		try {
			const addressResult = await addressService.share(shareModel.userId, shareModel.item);
			return addressResult;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const shareAddressScoped = createAsyncThunk<Address, ShareModelDto, { rejectValue: string }>(
	`${baseString}/shareScoped`,
	async (shareModel: ShareModelDto, thunkAPI) => {
		try {
			if (shareModel.scopeType == ScopeType.intern) {
				const result = await addressService.shareIntern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
				return result;
			}
			const result = await addressService.shareExtern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
			return result;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const deleteShareAddressScoped = createAsyncThunk<Address, ShareModelDto, { rejectValue: string }>(
	`${baseString}/deleteShareScoped`,
	async (shareModel: ShareModelDto, thunkAPI) => {
		try {
			if (shareModel.scopeType == ScopeType.intern) {
				const result = await addressService.deleteShareIntern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
				return result;
			}
			const result = await addressService.deleteShareExtern(shareModel.userId, shareModel.entityId, shareModel.tenantId);
			return result;
		} catch (ex: any) {
			console.log(ex);
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	}
);
