import {
	IonButton,
	IonCard,
	IonChip,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonNote,
	IonSpinner,
	IonToolbar,
	useIonToast,
} from "@ionic/react";
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { addOutline, closeOutline, copyOutline, lockOpenOutline, mail, mailOutline, pencil, pencilOutline } from "ionicons/icons";
import { Mail } from "model/Mail";
import { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getProfile } from "store/profile/Profile.selectors";
import EditModal from "./EditModal";
import ScopeShareExternToTenant from "components/shareTenant/ScopeShareExternToTenant";
import { deleteShareMailScoped, shareMailScoped, updateMail } from "store/profile/mail/Mail.thunks";
import { getIsFetching, getIsShareExternFetching, getIsShareInternFetching } from "store/profile/mail/Mail.selectors";
import ScopeShareInternToTenant from "components/shareTenant/ScopeShareInternToTenant";
import { ShareModelDto } from "model/ShareModelDto";
import Loader from "components/loader/Loader";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import ShareTenantSelectModal from "components/shareTenant/ShareTenantSelectModal";
import { newUpsertModel } from "model/UpsertModel";
import { CloseFooter } from "components/common/CloseFooter";
import { ShareTenantChipContent } from "components/common/ShareTenantChipContent";

export interface Props {
	isOpen: boolean;
	detailItem: Mail;
	onCancel(): void;
}

const DetailModalMail: FC<Props> = ({ isOpen, detailItem, onCancel }) => {
	const dispatch = useAppDispatch();
	const profile = useSelector(getProfile);
	const isShareInternFetching = useSelector(getIsShareInternFetching);
	const isShareExternFetching = useSelector(getIsShareExternFetching);
	const isFetching = useSelector(getIsFetching);
	const selectRefShare = useRef<HTMLIonSelectElement>(null);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [present] = useIonToast();
	const [shareTenantSelectModalIsOpen, setShareTenantSelectModalIsOpen] = useState(false);
	const [sharedTenants, setSharedTenants] = useState(detailItem.scopedSharingTenants ?? new Array<ScopedSharingTenant>());

	function onShare(finalSharedTenants: ScopedSharingTenant[]) {
		const upsertItem = { ...detailItem };
		upsertItem.scopedSharingTenants = finalSharedTenants;
		dispatch<any>(updateMail(newUpsertModel<Mail>(profile.id, upsertItem)));
		setShareTenantSelectModalIsOpen(false);
	}
	function onShareCancel() {
		setShareTenantSelectModalIsOpen(false);
	}
	function closeModal() {
		onCancel();
	}
	const sharedTenantChips = (mail: Mail) => {
		if (mail?.scopedSharingTenants == undefined || mail?.scopedSharingTenants.length == 0) {
			return (
				<>
					<IonChip outline={false}>
						<IonLabel>Keine Freigaben</IonLabel>
					</IonChip>
				</>
			);
		}

		const result = mail?.scopedSharingTenants?.map((tenant, i) => {
			return <ShareTenantChip key={tenant.id} scopedSharingTenant={tenant} />;
		});
		return result;
	};
	const presentToast = () => {
		present({
			message: "Erfolgreich in die Zwischenablage kopiert.",
			duration: 1500,
			position: "top",
		});
	};

	return (
		<>
			<IonModal color="amber-50" key={"detailModal" + detailItem.id} isOpen={isOpen} onDidDismiss={(e) => closeModal()}>
				<EditModal key={"editModal" + detailItem.id} isOpen={editModalOpen} editItem={detailItem} onCancel={() => setEditModalOpen(false)} />
				<IonModal isOpen={shareTenantSelectModalIsOpen}>
					<ShareTenantSelectModal
						creatorId={detailItem.creator?.id}
						scopedSharingTenants={sharedTenants}
						done={onShare}
						cancel={onShareCancel}
					/>
				</IonModal>
				<IonHeader>
					<IonToolbar color="amber-300">
						<IonButton
							fill="clear"
							slot="start"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								closeModal();
							}}
						>
							<IonIcon color="amber-900" icon={closeOutline}></IonIcon>
						</IonButton>

						<IonLabel slot="start">
							<h2>
								<Trans>Detail mail address</Trans>
							</h2>
						</IonLabel>
						<Loader slot="end" isVisible={isFetching} />
					</IonToolbar>
				</IonHeader>
				<IonContent color="amber-50">
					<div>
						<IonCard>
							<IonItem
								onClick={() => {
									navigator.clipboard.writeText(mail ?? "");
									presentToast();
								}}
								className="ion-margin"
								button={true}
								detail={false}
								lines="none"
							>
								<IonIcon
									icon={mailOutline}
									onClick={(e) => {
										window.location.href = "mailto:" + detailItem.mailAddress;
										e.preventDefault();
									}}
									slot="start"
								/>
								<IonLabel class="ion-text-justify" slot="start">
									{detailItem.mailAddress}
								</IonLabel>

								<IonIcon
									icon={copyOutline}
									onClick={() => {
										navigator.clipboard.writeText(detailItem.mailAddress ?? "");
										presentToast();
									}}
									slot="end"
								/>
							</IonItem>
						</IonCard>
					</div>

					<IonItem lines="none">
						<IonChip
							className="ion-padding-end ion-padding-start"
							slot="end"
							disabled={isFetching}
							onClick={(e) => setEditModalOpen(true)}
							outline={false}
							color="primary"
						>
							{isFetching ? (
								<>
									<IonSpinner name="dots" className="ion-margin"></IonSpinner>
								</>
							) : (
								<>
									<IonIcon size="small" color="amber-900" icon={pencilOutline} />
									<IonLabel color="amber-900">Editieren</IonLabel>
								</>
							)}
						</IonChip>
					</IonItem>
					<IonItem
						disabled={isFetching}
						className="ion-padding-bottom"
						lines="full"
						onClick={(e) => selectRefShare.current?.open(e.nativeEvent)}
					>
						<div>
							<div className="ion-margin-bottom">
								<IonNote>Geteilt mit</IonNote>
							</div>

							{sharedTenantChips(detailItem)}
							<IonChip
								disabled={isFetching}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setShareTenantSelectModalIsOpen(true);
								}}
								outline={false}
								color="primary"
							>
								<ShareTenantChipContent isBusy={isFetching} />
							</IonChip>
						</div>
					</IonItem>
					<IonItem disabled={detailItem?.scopedSharingTenants?.length == 0} lines="full" className="ion-padding-bottom">
						<ScopeShareInternToTenant
							scopedSharingTenants={detailItem?.scopedSharingTenants}
							isFetching={isShareInternFetching}
							share={(dtoModal) => dispatch<any>(shareMailScoped(dtoModal))}
							deleteShare={(dtoModal) => dispatch<any>(deleteShareMailScoped(dtoModal))}
							userId={profile.id}
							entityId={detailItem.id ?? ""}
						/>
					</IonItem>

					<IonItem disabled={detailItem?.scopedSharingTenants?.length == 0} lines="none">
						<ScopeShareExternToTenant
							scopedSharingTenants={detailItem?.scopedSharingTenants}
							isFetching={isShareExternFetching}
							share={(dtoModal) => dispatch<any>(shareMailScoped(dtoModal))}
							deleteShare={(dtoModal) => dispatch<any>(deleteShareMailScoped(dtoModal))}
							userId={profile.id}
							entityId={detailItem.id ?? ""}
						/>
					</IonItem>
				</IonContent>

				<CloseFooter onClick={closeModal} />
			</IonModal>
		</>
	);
};

export default DetailModalMail;
