import { useAppDispatch } from "app/hooks";
import "./AvatarTenantScope.scss";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { IonAvatar, IonButton, IonFab, IonImg, IonLabel } from "@ionic/react";
import { checkImageAvatarUrl } from "helper/CheckImageUrlHelper";
import { ScopeType } from "model/ScopeType";
import { FC, VFC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { updateProfileAvatar, updateProfileTenantAvatar } from "store/profile/Profile.thunks";
import IUpdatePersonAvatarInTenantDto from "model/IUpdatePersonAvatarInTenantDto";
import IUpdatePersonAvatarDto from "model/IUpdatePersonAvatarDto";

interface AvatarProps {
	imageUrl?: string;
	tenantId?: string | undefined;
	scopeType: ScopeType;
}

const AvatarTenantScope: FC<AvatarProps> = ({ imageUrl, tenantId, scopeType }) => {
	const dispatch = useAppDispatch();
	const onDrop = useCallback((acceptedFiles: File[]) => {
		console.log(acceptedFiles);
		if (tenantId == null) {
			const data = { file: acceptedFiles[0], scopeType: scopeType } as IUpdatePersonAvatarDto;
			dispatch<any>(updateProfileAvatar(data));
		} else {
			const data = { tenantId: tenantId, file: acceptedFiles[0], scopeType: scopeType } as IUpdatePersonAvatarInTenantDto;
			dispatch<any>(updateProfileTenantAvatar(data));
		}
	}, []);
	const buttonText = (scopeType: ScopeType) => {
		switch (scopeType) {
			case ScopeType.default:
				return i18n._(t`common.Admin`);
			case ScopeType.intern:
				return i18n._(t`common.Intern`);
			case ScopeType.extern:
				return i18n._(t`common.Extern`);
		}
		return "";
	};
	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: {
			"image/png": [".png", ".jpg", ".jpeg"],
		},
	});
	return (
		<div>
			<IonButton shape="round" fill="clear" color="amber" {...getRootProps()} className="">
				<IonLabel color="amber-900">{buttonText(scopeType)}</IonLabel>
			</IonButton>
			<IonAvatar {...getRootProps()} class="imageAvatar">
				<input {...getInputProps()} />

				<IonImg src={checkImageAvatarUrl(imageUrl)}></IonImg>
			</IonAvatar>
		</div>
	);
};

export default AvatarTenantScope;
