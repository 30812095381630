import { ScopeType } from './ScopeType';

export interface ShareModelDto {
	userId: string;
	tenantId: string;
	entityId: string;
	scopeType: ScopeType;

}
export function newShareModelDto<T>(userId: string, tenantId: string, entityId: string, scopeType: ScopeType): ShareModelDto {
	return {
		userId: userId,
		tenantId: tenantId,
		entityId: entityId,
		scopeType: scopeType,
	};
}
