import "./style.scss";

import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonChip,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonModal,
	IonProgressBar,
	IonSearchbar,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import axios from "axios";
import { closeOutline } from "ionicons/icons";
import { newBaseList } from "model/BaseList";
import { Tenant } from "model/Tenant";
import { useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getProfile, getSelectedTenant } from "store/profile/Profile.selectors";
import { selectTenant } from "store/profile/Profile.slice";

interface TenantSearchSelectProps {
	isOpen: boolean;
	onDismiss: () => void;
	onSelect: (tenant: Tenant) => void;
	triggerManuelSearch: () => void;
	tenantId: string;
}

const TenantSearchSelect: VFC<TenantSearchSelectProps> = ({ isOpen, onDismiss, tenantId, onSelect, triggerManuelSearch }) => {
	const dispatch = useAppDispatch();
	const [isBusy, setIsBuy] = useState(false);
	const tenants = useSelector(getAllTenants);
	const profile = useSelector(getProfile);
	const [clubs, setClubs] = useState(newBaseList<Tenant>());
	const [hasSearchedOnce, setHasSearchedOnce] = useState(false);

	const changeTenant = (tenant: Tenant) => {
		dispatch(selectTenant(tenant));
	};

	const searchMemberTenants = async (textSearch: string) => {
		setIsBuy(true);
		try {
			setHasSearchedOnce(true);
			const res = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantId}/member?filtertype=association&top=1000&Page=1&keyWord=${textSearch}&OrderBy=Name&searchfields=name&OrderByType=ascending`,
				{
					headers: { userId: profile?.id },
				},
			);
			if (res.status == 200) {
				setClubs(res.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsBuy(false);
		}
	};

	return (
		<IonModal key={"select_tenant_list"} isOpen={isOpen} showBackdrop={true} backdropDismiss={true} onDidDismiss={(e) => onDismiss()}>
			<IonContent>
				<IonToolbar>
					<IonItem lines="full">
						<IonTitle slot="start">Verein auswählen:</IonTitle>
						<IonButton slot="end" fill="clear" onClick={(e) => onDismiss()}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonItem>
				</IonToolbar>

				<IonCard>
					<IonCardHeader>
						<IonLabel color="dark">
							<h2>Eigene Vereine:</h2>
						</IonLabel>
					</IonCardHeader>
					<IonCardContent>
						<IonList>
							{tenants?.map((item: Tenant) => (
								<IonChip disabled={tenantId == item.id} onClick={(e) => onSelect(item)} key={item.id}>
									{item.name}
								</IonChip>
							))}
						</IonList>
					</IonCardContent>
				</IonCard>
				<IonCard>
					<IonCardHeader>
						<IonLabel color="dark">
							<h2>Nicht dabei? Dann hier suchen:</h2>
						</IonLabel>
					</IonCardHeader>
					<IonListHeader>
						<IonSearchbar
							debounce={500}
							onIonChange={(e) => searchMemberTenants(e.detail.value ?? "")}
							placeholder="Suche"
						></IonSearchbar>
					</IonListHeader>
					<IonList>
						{isBusy ? <IonProgressBar type="indeterminate"></IonProgressBar> : <></>}
						{clubs?.items?.map((item: Tenant) => (
							<IonChip key={`${item.id}_tenant_chip`} disabled={tenantId == item.id} onClick={(e) => onSelect(item)}>
								{item.name}
							</IonChip>
						))}
						<IonItem lines="none"></IonItem>
					</IonList>
				</IonCard>
				{hasSearchedOnce ? (
					<IonCard>
						<IonCardHeader>
							<IonLabel color="dark">
								<h2>Dein Verein ist nicht in der Suche?</h2>
							</IonLabel>
						</IonCardHeader>

						<IonItem lines="none">
							<IonButton onClick={(e) => triggerManuelSearch()} fill="outline" color="medium" slot="start">
								Verein manuell angeben
							</IonButton>
						</IonItem>
						<IonItem></IonItem>
					</IonCard>
				) : (
					<></>
				)}
			</IonContent>
		</IonModal>
	);
};

export default TenantSearchSelect;
