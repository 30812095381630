import { useAppDispatch } from "app/hooks";
import { useCapacitorOAuth2 } from "auth/CapacitorOAuth2Hook";
//import { initialState, PageViewState, PageViewStateContext } from "context/PageViewStateContext";
import { FC, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getIdTokenState } from "store/profile/Profile.selectors";
import { updateIdToken } from "store/profile/Profile.thunks";
import LoggedOutCheckToken from "./parts/LoggedOutCheckToken";
import LoggedOutGetStarted from "./parts/LoggedOutGetStarted";
import LoggedOutPending from "./parts/LoggedOutPending";

enum LoggedOutPageStateType {
	CheckingForTokenInReduxStore,
	TokenNotFoundInReduxStore,
	FailedToGetTokenFromCapacitorAuth,
	TokenFoundInReduxStore,
}
/// <summary>
/// This page is shown when the user is logged out or starting the app and the app is checking if the user is logged in.
/// </summary>
const LoggedOutPage: FC = () => {
	const [loggedOutPageStateTypeState, setLoggedOutPageStateTypeState] = useState<LoggedOutPageStateType>(
		LoggedOutPageStateType.CheckingForTokenInReduxStore,
	);
	const [isTimerDone, setTimerDone] = useState(false);
	const dispatch = useAppDispatch();
	const capacitorAuth = useCapacitorOAuth2();
	const idToken = useSelector(getIdTokenState);

	setTimeout(() => {
		setTimerDone(true);
	}, 4000);

	useEffect(() => {
		//#region Functions
		// No async function in useEffect there define local functions
		const checkToken = async () => {
			const parsedIdTokenResult = await capacitorAuth.getParsedIdTokenAsync();
			if (parsedIdTokenResult == null) {
				setLoggedOutPageStateTypeState(LoggedOutPageStateType.FailedToGetTokenFromCapacitorAuth);
				return;
			}
			dispatch<any>(updateIdToken(parsedIdTokenResult));
			return;
		};
		/*
		const login = async () => {
			await capacitorAuth.authorizeAsync();
		};
		*/
		//#endregion
		switch (loggedOutPageStateTypeState) {
			case LoggedOutPageStateType.TokenFoundInReduxStore:
				// Token was found, nothing to do here
				break;
			case LoggedOutPageStateType.CheckingForTokenInReduxStore:
				// Nothing to do here, just wait if the selector gets its value, sometimes it takes a while till everything is setup
				// If this triggers to fast, add the timer to the useEffect
				break;
			case LoggedOutPageStateType.TokenNotFoundInReduxStore:
				// No token was found in redux store, so we need to check if we have a token in the Capacitor Storage
				// This is the localstorage or native the app preference
				checkToken();
				break;
			case LoggedOutPageStateType.FailedToGetTokenFromCapacitorAuth:
				// We could not get a token from Capacitor Auth, so we need to login
				// This currently leeds to the "Welcome Page". If you dont want this, you need to force the login with capacitor or use window.location.reload()
				//	login();
				break;
			default:
				break;
		}
		if (loggedOutPageStateTypeState != LoggedOutPageStateType.CheckingForTokenInReduxStore) {
			checkToken();
			return;
		}
	}, [loggedOutPageStateTypeState]);

	/// <summary>
	/// This function is called when the user is logged out or starting the app and the app is checking if the user is logged in.
	/// It is searching for the token in the Redux store and sets the state accordingly.
	/// </summary>
	useEffect(() => {
		const isIdTokenInReduxStore = idToken != null && idToken != undefined;
		if (isIdTokenInReduxStore) {
			setLoggedOutPageStateTypeState(LoggedOutPageStateType.TokenFoundInReduxStore);
		} else {
			setLoggedOutPageStateTypeState(LoggedOutPageStateType.TokenNotFoundInReduxStore);
		}
	}, [idToken]);

	if (isTimerDone == false) {
		//Eventually you dont want a spinner and just return <></> here
		return <LoggedOutPending />;
	}

	switch (loggedOutPageStateTypeState) {
		case LoggedOutPageStateType.CheckingForTokenInReduxStore:
			return <LoggedOutCheckToken />;
		case LoggedOutPageStateType.TokenFoundInReduxStore:
			// Nearly doesnt matter what to render here, as the App.tsx or AuthRoute will redirect to the correct page
			return <LoggedOutPending />;
		case LoggedOutPageStateType.TokenNotFoundInReduxStore:
			return <LoggedOutPending />;
		case LoggedOutPageStateType.FailedToGetTokenFromCapacitorAuth:
			// Nothing was found, so we need to login or register on a nice start page before redirecting to the identity provider
			return <LoggedOutGetStarted />;
		default:
			return <></>;
	}
};

export default memo(LoggedOutPage);
