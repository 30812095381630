import { IonContent, IonFab, IonFabButton, IonIcon, IonPage } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditMailModal from "components/mail/EditModal";
import List from "components/mail/List";
import ProfileValidationFooter from "components/profileValidation/components/ProfileValidationFooter";
import ProfileValidationHeader from "components/profileValidation/components/ProfileValidationHeader";
import { TabContex } from "context/TabContext";
import { add } from "ionicons/icons";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newMail } from "model/Mail";
import { useContext, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getIsFetching } from "store/profile/mail/Mail.selectors";
import { fetchMails, reloadMails } from "store/profile/mail/Mail.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

export const ProfileValidationProcessMailsPage: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewMailModalOpen, setIsNewMailModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);
	const isLoading = useSelector(getIsFetching);
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(true);
	}, []);

	useEffect(() => {
		dispatch<any>(reloadMails(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchMails(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadMails(newBaseListQuery(basListQuery)));
	};
	return (
		<IonPage>
			<IonContent>
				<ProfileValidationHeader title="Mail" progress={6} />

				<EditMailModal isOpen={isNewMailModalOpen} editItem={newMail()} onCancel={() => setIsNewMailModalOpen(false)} />

				<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={() => setIsNewMailModalOpen(true)}>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>
			</IonContent>
			<ProfileValidationFooter
				isLoading={isLoading}
				progress={6}
				routerLinkNext="/validation/profile/1/address"
				routerLinkBack="/validation/profile/1/profile"
			/>
		</IonPage>
	);
};
