export interface UpsertModel<T> {
	userId: string;
	item: T;
}
export function newUpsertModel<T>(userId: string, item: T): UpsertModel<T> {
	return {
		userId: userId,
		item: item,
	};
}
