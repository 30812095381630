import "./style.scss";

import { FC, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import { useAppDispatch } from "app/hooks";
import { Team } from "model/Team";
import TeamDetail from "../../components/team/teamDetail/TeamDetail";
import { t } from "@lingui/macro";
import Page from "components/page/Page";
import { useLingui } from "@lingui/react";
import TeamDetailSkeleton from "../../components/team/teamDetail/TeamDetailSkeleton";
import { getTeam } from "store/team/Team.selectors";
import { useSelector } from "react-redux";
import { fetchTeamById, getContactPersons, getTeamDocuments } from "store/team/Team.thunks";
interface MatchParams {
	id: string;
}

const TeamDetailPage: FC = () => {
	const dispatch = useAppDispatch();
	const { i18n } = useLingui();
	const params = useParams<MatchParams>();
	const team = useSelector(getTeam(params.id));

	useEffect(() => {
		if (!team) {
			//dispatch<any>(fetchTeamById(params.id));

			return;
		}
		if (team.creator?.id && team.documents == null) {
			dispatch<any>(getTeamDocuments([team.creator?.id, params.id]));
		}
		if (team.creator?.id && team.contactPersons == null) {
			dispatch<any>(getContactPersons([team.creator?.id, params.id]));
		}
	}, [team]);

	return (
		<Page title={team?.name ?? ""} backLink="/teams" backTitle={i18n._(t`Teams`)} tabBarHidden={true} backButton={true}>
			{!team || team == undefined ? <TeamDetailSkeleton /> : <TeamDetail team={team} />}
		</Page>
	);
};

export default TeamDetailPage;
