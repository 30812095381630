import "./style.scss";
import "../style.scss";

import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditAddressModal from "components/address/EditModal";
import EditPhoneModal from "components/phone/EditModal";
import { addOutline, alertCircleOutline, checkmarkCircleOutline, refreshOutline } from "ionicons/icons";
import { Address, newAddress } from "model/Address";
import { newBaseListQuery } from "model/BaseListQuery";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import { newPhone, Phone } from "model/Phone";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAllAddresses } from "store/profile/address/Address.selectors";
import { reloadAddresses } from "store/profile/address/Address.thunks";
import { getAllPhones } from "store/profile/phone/Phone.selectors";
import { reloadPhones } from "store/profile/phone/Phone.thunks";

interface IEventAddressPartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventAddressPart: React.FunctionComponent<IEventAddressPartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const phones = useSelector(getAllPhones);
	const refSelectPhones = useRef<HTMLIonSelectElement>(null);
	const [phone, setPhone] = useState(newPhone());
	const [showModalPhone, setShowModalPhone] = useState(false);
	const [hasClicked, setHasClicked] = useState(false);

	React.useEffect(() => {
		//	dispatch<any>(reloadBankAccounts(newBaseListQuery()));
		if (hasClicked) {
			refSelectPhones.current?.open();
		}
	}, [phones]);
	useEffect(() => {
		setPhone(newPhone());
	}, [showModalPhone]);

	const [selectedItemAddress, setSelectedItemAddress] = useState<Address | undefined>(undefined);
	const [selectedItemPhone, setSelectedItemPhone] = useState<Phone | undefined>(undefined);

	const dispatch = useAppDispatch();
	return (
		<>
			<EditPhoneModal editItem={phone} isOpen={showModalPhone} onCancel={() => setShowModalPhone(false)}></EditPhoneModal>
			<div className="firstDivider"></div>
			<IonCard>
				<IonGrid className={selectedItemPhone == null ? "gridNoSuccess" : "gridSuccess"}>
					<IonRow>
						<IonCol size="0" sizeLg="1" sizeMd="0" sizeXl="1" sizeXs="0">
							{selectedItemPhone == null ? (
								<IonIcon className="notDoneIcon" src={alertCircleOutline} />
							) : (
								<IonIcon className="isDoneIcon" src={checkmarkCircleOutline} />
							)}
						</IonCol>
						<IonCol size="11" sizeLg="11" sizeMd="11" sizeXl="11" sizeXs="12">
							<IonCard>
								<IonItemDivider className={selectedItemPhone == null ? "rowDivider" : "rowDividerSuccess"} mode="ios">
									Telefonnummer:
									<IonLabel color="danger">*</IonLabel>
									<IonButton
										onClick={(e) => {
											dispatch<any>(reloadAddresses(newBaseListQuery()));
											dispatch<any>(reloadPhones(newBaseListQuery()));
										}}
										color="medium"
										slot="end"
										fill="clear"
									>
										<IonIcon className="buttonColor" size="small" src={refreshOutline}></IonIcon>
									</IonButton>
								</IonItemDivider>

								<IonItem className="rowItem" lines="none">
									<IonSelect
										onClick={(e) => {
											dispatch<any>(reloadPhones(newBaseListQuery()));
										}}
										mode="ios"
										onIonChange={(e) => {
											if (e.detail) {
												if (e.detail.value.id != undefined) {
													eventRegistration.phoneId = e.detail.value?.id;
													eventRegistration.phoneModel = e.detail.value;
													eventRegistration.phonenumber =
														e.detail.value?.phoneNumberCountryCode + e.detail.value?.phoneNumber;
													setEventRegistration(eventRegistration);
													setSelectedItemPhone(e.detail.value);
													console.log(eventRegistration);
												}
											}
										}}
										title="Telefonummer:"
										ref={refSelectPhones}
										value={phones}
										selectedText={
											selectedItemPhone == undefined
												? undefined
												: `+${selectedItemPhone.phoneNumberCountryCode} ${selectedItemPhone?.phoneNumber}`
										}
										slot="start"
										placeholder="Telefonummer wählen"
										className="rowSelect"
									>
										{phones?.items
											?.slice()
											.reverse()
											?.map((item, i) => {
												return (
													<IonSelectOption key={item.id} value={item}>
														+{item.phoneNumberCountryCode} {item.phoneNumber}
													</IonSelectOption>
												);
											})}
									</IonSelect>

									<IonButton
										color="medium"
										slot="end"
										fill="clear"
										onClick={(e) => {
											setShowModalPhone(true);
											setHasClicked(true);
										}}
									>
										<IonIcon className="buttonColor" size="large" src={addOutline}></IonIcon>
									</IonButton>
								</IonItem>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventAddressPart;
