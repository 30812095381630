import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }: any) => {
	const isDesktop = useMediaQuery({ minWidth: 820 });
	return isDesktop ? children : null;
};
export const Mobile = ({ children }: any) => {
	const isTablet = useMediaQuery({ maxWidth: 819 });
	return isTablet ? children : null;
};

export const TabBarId = "myTabBar";

export const hideTabs = () => {
	const tabBar = document.getElementById(TabBarId);
	console.log(tabBar);
	if (tabBar !== null && tabBar.style.display !== "none") tabBar.style.display = "none";
}

export const showTabs = () => {
	const tabBar = document.getElementById(TabBarId);
	if (tabBar !== null && tabBar.style.display !== "flex") tabBar.style.display = "flex";
}
