import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getAllHonors } from "store/honor/Honor.selectors";

import InfoBlock from "./InfoBlock/InfoBlock";

const MyHonors: FC = () => {
	const { i18n } = useLingui();

	const columns = {
		honor: i18n._(t`honor`),
	};

	const honors = useSelector(getAllHonors)?.items.slice(0, 5);
	const rows = honors?.map((honor) => {
		return { id: honor.id as string, honor: honor.name };
	});

	return (
		<InfoBlock
			id="honors"
			title={i18n._(t`profile.myHonors`)}
			columns={columns}
			rows={rows}
			dataKey={"honors"}
			link={{
				link: "honors",
				title: i18n._(t`profile.myHonors.all`),
			}}
		/>
	);
};

export default MyHonors;
