import "./style.scss";
import "../style.scss";

import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import EditAddressModal from "components/address/EditModal";
import { addOutline, alertCircleOutline, checkmarkCircleOutline, refreshOutline } from "ionicons/icons";
import { Address, newAddress } from "model/Address";
import { newBaseListQuery } from "model/BaseListQuery";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import { newPhone } from "model/Phone";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAllAddresses } from "store/profile/address/Address.selectors";
import { reloadAddresses } from "store/profile/address/Address.thunks";

interface IEventAddressPartProps {
	event: Event;
	eventRegistration: EventRegistration;
	setEventRegistration: (eventRegistration: EventRegistration) => void;
}

const EventAddressPart: React.FunctionComponent<IEventAddressPartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	const addresses = useSelector(getAllAddresses);
	const refSelectAddress = useRef<HTMLIonSelectElement>(null);
	const [phone, setPhone] = useState(newPhone());
	const [address, setAddress] = useState(newAddress());
	const [showModalAddress, setShowModalAddress] = useState(false);
	const [hasClicked, setHasClicked] = useState(false);

	React.useEffect(() => {
		//	dispatch<any>(reloadBankAccounts(newBaseListQuery()));
		if (hasClicked) {
			refSelectAddress.current?.open();
		}
	}, [addresses]);
	useEffect(() => {
		setAddress(newAddress());
	}, [showModalAddress]);

	const [selectedItemAddress, setSelectedItemAddress] = useState<Address | undefined>(undefined);

	const dispatch = useAppDispatch();
	return (
		<>
			<EditAddressModal address={address} isOpen={showModalAddress} onCancel={() => setShowModalAddress(false)} />
			<div className="firstDivider"></div>
			<IonCard color="amber-50">
				<IonGrid className={selectedItemAddress == null ? "gridNoSuccess" : "gridSuccess"}>
					<IonRow>
						<IonCol size="0" sizeLg="1" sizeMd="0" sizeXl="1" sizeXs="0">
							{selectedItemAddress == null ? (
								<IonIcon className="notDoneIcon" src={alertCircleOutline} />
							) : (
								<IonIcon className="isDoneIcon" src={checkmarkCircleOutline} />
							)}
						</IonCol>
						<IonCol size="11" sizeLg="11" sizeMd="11" sizeXl="11" sizeXs="12">
							<IonCard>
								<IonItemDivider className={selectedItemAddress == null ? "rowDivider" : "rowDividerSuccess"} mode="ios">
									Adresse:
									<IonLabel color="danger">*</IonLabel>
									<IonButton
										onClick={(e) => {
											dispatch<any>(reloadAddresses(newBaseListQuery()));
										}}
										color="medium"
										slot="end"
										fill="clear"
									>
										<IonIcon className="buttonColor" size="small" src={refreshOutline}></IonIcon>
									</IonButton>
								</IonItemDivider>

								<IonItem className="rowItem" lines="none">
									<IonSelect
										onClick={(e) => {
											dispatch<any>(reloadAddresses(newBaseListQuery()));
										}}
										onIonChange={(e) => {
											if (e.detail) {
												if (e.detail.value.id != undefined) {
													eventRegistration.addressId = e.detail.value?.id;
													eventRegistration.address = e.detail.value;
													console.log(e.detail.value);
													setEventRegistration(eventRegistration);
													setSelectedItemAddress(e.detail.value);
													console.log(eventRegistration);
												}
											}
										}}
										title="Addresse:"
										ref={refSelectAddress}
										value={address}
										selectedText={
											selectedItemAddress == undefined
												? undefined
												: `${selectedItemAddress.street} ${selectedItemAddress?.streetnumber} ${selectedItemAddress?.postcode} ${selectedItemAddress?.postbox} ${selectedItemAddress?.city}`
										}
										slot="start"
										mode="ios"
										placeholder="Addresse wählen"
										className="rowSelect"
									>
										{addresses?.items
											?.slice()
											.reverse()
											?.map((item, i) => {
												return (
													<IonSelectOption id={item.id} key={item.id} value={item}>
														{item.street} {item.streetnumber} {item.postcode} {item.postbox} {item.city}
													</IonSelectOption>
												);
											})}
									</IonSelect>

									<IonButton
										color="medium"
										slot="end"
										fill="clear"
										onClick={(e) => {
											setShowModalAddress(true);
											setHasClicked(true);
										}}
									>
										<IonIcon className="buttonColor" size="large" src={addOutline}></IonIcon>
									</IonButton>
								</IonItem>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</>
	);
};

export default EventAddressPart;
