import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { Mail } from "model/Mail";
import { Tenant } from "model/Tenant";

class MailService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<Mail>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}
		query.orderby = "CreatedAt";
		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/mail/search`, { params, headers: { userId: userId } });
		return res.data;
	}
	async add(userId: string, mail: Mail): Promise<Mail> {
		const res = await axios.post(`${process.env.REACT_APP_API_ME_URL}/api/me/mail`, mail, { headers: { userId: userId } });
		return res.data;
	}
	async update(userId: string, mail: Mail): Promise<Mail> {
		const res = await axios.put(`${process.env.REACT_APP_API_ME_URL}/api/me/mail/${mail.id}`, mail, { headers: { userId: userId } });
		return res.data;
	}
	async delete(userId: string, mail: Mail): Promise<Mail> {
		const res = await axios.delete(`${process.env.REACT_APP_API_ME_URL}/api/me/mail/${mail.id}`, { headers: { userId: userId } });
		return res.data;
	}
	async share(userId: string, mail: Mail): Promise<Mail> {
		const res = await axios.put(
			`${process.env.REACT_APP_API_ME_URL}/api/me/mail/${mail.id}/share`,
			{ tenantIds: mail.scopedSharingTenants?.map((t) => t.id) },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async shareExtern(userId: string, mailId: string, tenantId: string): Promise<Mail> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/mail/${mailId}/share/extern`,
			{ tenantId: tenantId, userId: userId, entityId: mailId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async shareIntern(userId: string, mailId: string, tenantId: string): Promise<Mail> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/mail/${mailId}/share/intern`,
			{ tenantId: tenantId, userId: userId, entityId: mailId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async deleteShareExtern(userId: string, mailId: string, tenantId: string): Promise<Mail> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/mail/${mailId}/share/extern/remove`,
			{ tenantId: tenantId, userId: userId, entityId: mailId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
	async deleteShareIntern(userId: string, mailId: string, tenantId: string): Promise<Mail> {
		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/me/mail/${mailId}/share/intern/remove`,
			{ tenantId: tenantId, userId: userId, entityId: mailId },
			{
				headers: { userId: userId },
			},
		);
		return res.data;
	}
}
const mailService = new MailService();
export default mailService;
