export enum ComponentType {
	IMAGE = "image",
	TEXT = "text",
	YOUTUBE = "youtubevideo",
}

export interface Component {
	componentType: ComponentType;
}

export interface ImageComponent extends Component {
	imageUrl: string;
	componentTemplateId: string;
}

export interface TextComponent extends Component {
	content: string;
	textId: string;
}

export interface YoutubeComponent extends Component {
	youtubeVideoUrl: string;
	componentTemplateId: string;
}

type ObjectType<T> = T extends ComponentType.IMAGE
	? ImageComponent
	: T extends ComponentType.TEXT
	? TextComponent
	: T extends ComponentType.YOUTUBE
	? YoutubeComponent
	: never;

export function selectComponent<T extends ComponentType>(type: T, components: Component[]): ObjectType<T> {
	return components.find((component) => component.componentType === type) as unknown as ObjectType<T>;
}
