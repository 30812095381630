import { OrderByType } from "./OrderByType";

export interface BaseListQuery {
	page?: number;
	pagesize?: number;
	orderbytype?: OrderByType;
	orderby?: string;
	keyword?: string;
	searchfields?: string[];
}

type BaseListQueryKey = keyof Required<Omit<BaseListQuery, "toEntries">>;
type ToEntries = [BaseListQueryKey, BaseListQuery[BaseListQueryKey]][];

export interface BaseListQueryInstance extends BaseListQuery {
	toEntries(): ToEntries;
}

export function newBaseListQuery(query?: BaseListQuery): BaseListQueryInstance {
	return {
		page: query?.page ?? 1,
		pagesize: query?.pagesize ?? 25,
		orderbytype: query?.orderbytype ?? OrderByType.ascending,
		orderby: query?.orderby ?? "Name",
		keyword: query?.keyword,
		searchfields: query?.searchfields,

		toEntries: function (): ToEntries {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			return Object.entries(this as BaseListQuery).filter(([key, value]) => {
				return !(key === "toEntries" || value === undefined);
			}) as ToEntries;
		},
	};
}
