import { IonIcon, IonLabel, IonSpinner } from "@ionic/react";
import { lockOpenOutline } from "ionicons/icons";
import { FC } from "react";

interface ShareTenantChipContentProps {
	isBusy: boolean;
}

export const ShareTenantChipContent: FC<ShareTenantChipContentProps> = ({ isBusy }) => {
	return (
		<>
			{isBusy ? (
				<>
					<IonSpinner name="dots" className="ion-margin"></IonSpinner>
				</>
			) : (
				<>
					<IonIcon size="small" icon={lockOpenOutline} />
					<IonLabel>Freigabe anpassen</IonLabel>
				</>
			)}
		</>
	);
};
