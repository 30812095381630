import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { OrderElement } from "model/orderElement/OrderElement";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";
import { getSelectedTenant } from "store/profile/Profile.selectors";

import { RootState } from "..";
import { LogElement } from 'model/LogElement';

export const getIsFetching = (state: RootState): boolean => state.log.isFetching;

export const getAllLogElements = (state: RootState): BaseList<LogElement> => state.log.value || newBaseList<OrderElement>();

export const getLogs = createSelector(
	getAllLogElements,
	getSelectedTenant,
	(logElements: BaseList<LogElement>, tenant: Tenant | null) => {
		return tenant ? filterBaseListItems(logElements, (item) => item.tenantId === tenant.id) : logElements;
	},
);

export const getLatestLogs = createSelector(getLogs, (logElements: BaseList<LogElement>) => logElements?.items?.slice(0, 5));
