import "./style.scss";

import {
	IonContent,
	IonIcon,
	IonItem,
	IonItemDivider,
	IonItemGroup,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonNote,
	IonPage,
	IonRouterLink,
	IonRouterOutlet,
	IonSplitPane,
} from "@ionic/react";
import {
	alertCircleOutline,
	callOutline,
	documentTextOutline,
	homeOutline,
	informationCircleOutline,
	listOutline,
	mailOutline,
	medkitOutline,
	personCircleOutline,
	shirtOutline,
	walletOutline,
} from "ionicons/icons";
import { ValidationModel } from "model/profileValidation/ValidationModel";
import { ProfileValidationProcessAddressPage } from "pages/profileValidation/process/ProfileValidationProcessAddressPage";
import { ProfileValidationProcessAGBPage } from "pages/profileValidation/process/ProfileValidationProcessAGBPage";
import ProfileValidationProcessBankAccountPage from "pages/profileValidation/process/ProfileValidationProcessBankAccountPage";
import { ProfileValidationProcessConfectionInformationsPage } from "pages/profileValidation/process/ProfileValidationProcessConfectionInformationsPage";
import { ProfileValidationProcessInfoPage } from "pages/profileValidation/process/ProfileValidationProcessInfoPage";
import { ProfileValidationProcessMailsPage } from "pages/profileValidation/process/ProfileValidationProcessMailsPage";
import { ProfileValidationProcessMedicalInformationsPage } from "pages/profileValidation/process/ProfileValidationProcessMedicalInformationsPage";
import { ProfileValidationProcessPhonesPage } from "pages/profileValidation/process/ProfileValidationProcessPhonesPage";
import { ProfileValidationProcessProfilePage } from "pages/profileValidation/process/ProfileValidationProcessProfilePage";
import * as React from "react";
import { Route } from "react-router";

interface ProfileValidationModalProps {
	validationModel: ValidationModel;
}
//Hier darf man keine Provider benutzen! D.h. auch keine Hooks wie z.B. "useAppDispatch" oder dergleichen!!
const ProfileValidationModal: React.FunctionComponent<ProfileValidationModalProps> = ({ validationModel }) => {
	return (
		<>
			<IonSplitPane when="md" contentId="second">
				{/*--  the side menu  --*/}
				<IonMenu hidden={true} side="start">
					<IonContent className="ion-no-padding ion-no-margin" fullscreen={true} forceOverscroll={false}>
						<IonList>
							<IonListHeader mode="md">
								<IonLabel mode="md" className="ion-text-center">
									Schritt 1: <br></br> <IonNote> Allgemeine Angaben</IonNote>
								</IonLabel>
							</IonListHeader>
							<IonItemGroup>
								{validationModel.basicdata ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerDirection="none"
										routerLink="/validation/profile/1/profile"
									>
										<IonIcon size="large" icon={personCircleOutline} />
										<IonLabel className="ion-margin-start">Basisdaten</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
								{validationModel.confectioninformations ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerDirection="none"
										routerLink="/validation/profile/1/confectioninformation"
									>
										<IonIcon size="large" icon={shirtOutline} />
										<IonLabel className="ion-margin-start">Kleidung</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
								{validationModel.medicalinformations ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerDirection="none"
										routerLink="/validation/profile/1/miscellaneous"
									>
										<IonIcon size="large" icon={listOutline} />
										<IonLabel className="ion-margin-start">Sonstiges</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
								{/*
								validationModel.clubs ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerLink="/validation/profile/1/clubs"
									>
										<IonIcon size="large" icon={layersOutline} />
										<IonLabel className="ion-margin-start">Vereine</IonLabel>
									</IonItem>
								) : (
									<></>
								)
								*/}
							</IonItemGroup>
							<IonListHeader mode="md" className="ion-padding-top">
								<IonLabel mode="md" className="ion-text-center">
									Schritt 2:<br></br> <IonNote>Kontaktinformationen</IonNote>
								</IonLabel>
							</IonListHeader>

							<IonItemGroup>
								{validationModel.address ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerLink="/validation/profile/1/address"
									>
										<IonIcon size="large" icon={homeOutline} />
										<IonLabel className="ion-margin-start">Addresse</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
								{validationModel.phones ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerLink="/validation/profile/1/phone"
									>
										<IonIcon size="large" icon={callOutline} />
										<IonLabel className="ion-margin-start">Telefon</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
								{validationModel.mails ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerLink="/validation/profile/1/mail"
									>
										<IonIcon size="large" icon={mailOutline} />
										<IonLabel className="ion-margin-start">E-Mail</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
							</IonItemGroup>
							<IonListHeader mode="md" className="ion-padding-top">
								<IonLabel mode="md" className="ion-text-center">
									Schritt 3: <br></br>
									<IonNote> Zahlungsinformationen</IonNote>
								</IonLabel>
							</IonListHeader>
							<IonItemGroup>
								{validationModel.banks ? (
									<IonItem
										detail={true}
										detailIcon={alertCircleOutline}
										button
										lines="none"
										routerLink="/validation/profile/1/bank"
									>
										<IonIcon size="large" icon={walletOutline} />
										<IonLabel className="ion-margin-start">Bank</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
							</IonItemGroup>
							<IonListHeader mode="md" className="ion-padding-top">
								<IonLabel mode="md" className="ion-text-center">
									Schritt 4: <br></br>
									<IonNote> Rechtliches</IonNote>
								</IonLabel>
							</IonListHeader>
							<IonItemGroup>
								{validationModel.agbs ? (
									<IonItem detail={true} detailIcon={alertCircleOutline} button lines="none" routerLink="/validation/profile/1/agb">
										<IonIcon size="large" icon={documentTextOutline} />
										<IonLabel className="ion-margin-start">Allgemeine Geschäftsbedingungen</IonLabel>
									</IonItem>
								) : (
									<></>
								)}
							</IonItemGroup>
							<IonListHeader mode="md" className="ion-padding-top">
								<IonNote>Sonstiges</IonNote>
							</IonListHeader>
							<IonItem detail={false} button lines="none" routerLink="/validation/profile/1/info">
								<IonIcon size="large" icon={informationCircleOutline} />
								<IonLabel className="ion-margin-start">Informationen</IonLabel>
							</IonItem>

							<div />
							<IonItem lines="none"></IonItem>

							<IonItemDivider className="divider" mode="md" />

							<IonRouterLink target="_blank" href="https://www.sportcloud.de/impressum" className="inprintContainer" color="medium">
								<IonLabel className="inprintTextPrivacy">Impressum</IonLabel>
								<br />
								<IonLabel>Datenschutz</IonLabel>
							</IonRouterLink>
						</IonList>
					</IonContent>
				</IonMenu>

				<IonPage id="second">
					<IonRouterOutlet>
						<Route path="/validation/profile/:id/profile" render={() => <ProfileValidationProcessProfilePage />} exact />
						<Route path="/validation/profile/:id/address" render={() => <ProfileValidationProcessAddressPage />} exact />
						<Route path="/validation/profile/:id/info" render={() => <ProfileValidationProcessInfoPage />} exact />
						<Route path="/validation/profile/:id/mail" render={() => <ProfileValidationProcessMailsPage />} exact />
						<Route path="/validation/profile/:id/bank" render={() => <ProfileValidationProcessBankAccountPage />} exact />
						<Route path="/validation/profile/:id/phone" render={() => <ProfileValidationProcessPhonesPage />} exact />
						<Route
							path="/validation/profile/:id/confectioninformation"
							render={() => <ProfileValidationProcessConfectionInformationsPage />}
							exact
						/>
						<Route
							path="/validation/profile/:id/miscellaneous"
							render={() => <ProfileValidationProcessMedicalInformationsPage />}
							exact
						/>
						<Route path="/validation/profile/:id/clubs" render={() => <ProfileValidationProcessProfilePage />} exact />
						<Route path="/validation/profile/:id/agb" render={() => <ProfileValidationProcessAGBPage />} exact />
					</IonRouterOutlet>
				</IonPage>
			</IonSplitPane>
		</>
	);
};

export default ProfileValidationModal;
