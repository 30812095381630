import "./Loader.scss";

import { IonSpinner } from "@ionic/react";
import { FC } from "react";

export interface Props {
	isVisible: boolean;
	slot?: string;
}

const Loader: FC<Props> = ({ slot, isVisible }: Props) => {
	if (slot == undefined) {
		slot = "start";
	}
	if (isVisible) {
		return <IonSpinner slot={slot} name="circular" color="primary" className="ion-margin" />;
	} else {
		return <></>;
	}
};

export default Loader;
