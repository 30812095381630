import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { ConfectionInformation } from "model/ConfectionInformation";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";

import { RootState } from "../..";
import { getScope, getSelectedTenant } from "../Profile.selectors";

export const getAllConfectionInformations = (state: RootState): BaseList<ConfectionInformation> =>
	state.confectionInformation.value || newBaseList<ConfectionInformation>();

export const getIsFetching = (state: RootState): boolean => state.medicalInformation.isFetching;

export const getConfectionInformations = createSelector(
	getAllConfectionInformations,
	getSelectedTenant,
	(ConfectionInformations: BaseList<ConfectionInformation>, tenant: Tenant | null) => {
		return tenant
			? filterBaseListItems(
					ConfectionInformations,
					(confectionInformation: ConfectionInformation) =>
						confectionInformation.scopedSharingTenants?.some((t) => t.id === tenant.id) || confectionInformation.creator?.id == tenant.id,
			  )
			: ConfectionInformations;
	},
);

export const getConfectionInformation = createSelector(
	getAllConfectionInformations,
	getSelectedTenant,
	getScope,
	(confectionInformations: BaseList<ConfectionInformation>, tenant: Tenant | null, scopeType?: ScopeType): ConfectionInformation | undefined => {
		let result;
		switch (scopeType) {
			case ScopeType.default:
				if (tenant) {
					result = confectionInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isDefault);
				} else {
					result = confectionInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isDefault === true)[0]);
				}
				break;
			case ScopeType.intern:
				if (tenant) {
					result = confectionInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isIntern);
				} else {
					result = confectionInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isIntern === true)[0]);
				}
				break;
			case ScopeType.extern:
				if (tenant) {
					result = confectionInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isExtern);
				} else {
					result = confectionInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isExtern === true)[0]);
				}
				break;
		}

		return result !== undefined ? result : confectionInformations.items.length ? confectionInformations.items[0] : undefined;
	},
);

export const makeGetMedicalInformation = createSelector(getAllConfectionInformations, getSelectedTenant, (confectionInformations, tenant) => {
	if (tenant) {
		confectionInformations.items = confectionInformations.items?.filter((a) =>
			a.scopedSharingTenants?.map((t) => t.id).includes(tenant?.id ?? ""),
		);
	}

	return confectionInformations;
});
