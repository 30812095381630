import { IonButton, IonChip, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonToolbar } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import DecisionAlert, { newButton } from "components/alert/DecisionAlert";
import InputItem from "components/form/InputItem";
import { PrivacyVerfiyApprovement } from "components/privacy/PrivacyVerfiyApprovement";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import ShareTenantSelect from "components/shareTenant/ShareTenantSelect";
import ShareTenantSelectModal from "components/shareTenant/ShareTenantSelectModal";
import { closeOutline, pencilOutline, trashOutline } from "ionicons/icons";
import { Address } from "model/Address";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { newUpsertModel } from "model/UpsertModel";
import { FC, useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { addAddress, deleteAddress, updateAddress } from "store/profile/address/Address.thunks";
import { getProfile } from "store/profile/Profile.selectors";

export interface Props {
	isOpen: boolean;
	address: Address;
	onCancel(): void;
}

const EditAddressModal: FC<Props> = ({ isOpen, address, onCancel }) => {
	const modal = useRef<HTMLIonModalElement>(null);
	const [checked, setChecked] = useState(false);
	const modalTrigger = `${address.id}_select_share_tenant`;

	useEffect(() => {
		setSavedEditItem(JSON.parse(JSON.stringify(address)));
		editItem = JSON.parse(JSON.stringify(address));
		setSharedTenants(address.scopedSharingTenants ?? new Array<ScopedSharingTenant>());
		setChecked(false);
	}, [isOpen]);

	const [savedEditAddress, setSavedEditItem] = useState(address);
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
	const [sharedTenants, setSharedTenants] = useState(address.scopedSharingTenants ?? new Array<ScopedSharingTenant>());

	let editItem = JSON.parse(JSON.stringify(savedEditAddress)) as Address;

	const dispatch = useAppDispatch();
	const profile = useSelector(getProfile);

	function onConfirmDelete() {
		const upsertAddress = { ...address };
		dispatch<any>(deleteAddress(newUpsertModel<Address>(profile.id, upsertAddress)));
		closeModal();
	}
	function onEditModalDone(addressToUpdate: Address) {
		const upsertAddress = { ...addressToUpdate };
		upsertAddress.scopedSharingTenants = sharedTenants;
		if (upsertAddress.id) {
			dispatch<any>(updateAddress(newUpsertModel<Address>(profile.id, upsertAddress)));
		} else {
			dispatch<any>(addAddress(newUpsertModel<Address>(profile.id, upsertAddress)));
		}
		closeModal();
		setChecked(false);
	}
	function onDismissDelete() {
		setIsDeleteAlertOpen(false);
	}
	function onShare(tenants: ScopedSharingTenant[]) {
		if (!tenants) {
			setSharedTenants(new Array<ScopedSharingTenant>());
			editItem.scopedSharingTenants = new Array<ScopedSharingTenant>();
			setSavedEditItem(editItem);
		} else {
			setSharedTenants(tenants);
			editItem.scopedSharingTenants = tenants;
			setSavedEditItem(editItem);
		}
		modal.current?.dismiss();
	}
	function onShareCancel() {
		console.log(editItem.scopedSharingTenants);
		modal.current?.dismiss();
	}
	const deleteButtons = [
		newButton(i18n._(t`Cancel`), "cancel"),
		newButton(i18n._(t`Delete`), "delete", "danger", () => {
			onConfirmDelete();
		}),
	];
	function closeModal() {
		onCancel();
		setChecked(false);
	}
	return (
		<>
			<DecisionAlert
				isOpen={isDeleteAlertOpen}
				header={i18n._(t`Delete address`)}
				message={i18n._(t`Are you sure you want to delete ?`)}
				onDismiss={onDismissDelete}
				buttons={deleteButtons}
			/>
			<IonModal isOpen={isOpen} onDidDismiss={(e) => closeModal()}>
				<IonModal trigger={modalTrigger} ref={modal}>
					<ShareTenantSelectModal
						creatorId={editItem.creator?.id}
						scopedSharingTenants={sharedTenants}
						done={onShare}
						cancel={onShareCancel}
					/>
				</IonModal>
				<IonHeader color="amber-50">
					<div className="ion-text-center">
						<IonToolbar color="amber-300">
							<IonButton
								fill="clear"
								slot="start"
								onClick={() => {
									onCancel();
								}}
							>
								<IonIcon color="medium" icon={closeOutline}></IonIcon>
							</IonButton>
							{editItem.id ? (
								<IonLabel color="amber-950" slot="start">
									<Trans>Edit address</Trans>
								</IonLabel>
							) : (
								<IonLabel slot="start">
									<h2>
										<Trans>Create address</Trans>
									</h2>
								</IonLabel>
							)}

							{editItem.id && editItem.creator?.label != "association" ? (
								<>
									<IonButton disabled={!checked} slot="end" fill="clear" onClick={() => setIsDeleteAlertOpen(true)}>
										<IonIcon color="medium" icon={trashOutline}></IonIcon>
									</IonButton>
									{/*
									<IonButton slot="end" fill="clear" onClick={(e) => selectRefShare.current?.open(e.nativeEvent)}>
										<IonIcon color="medium" icon={lockOpenOutline}></IonIcon>
									</IonButton>
										*/}
								</>
							) : (
								<></>
							)}
						</IonToolbar>
					</div>
				</IonHeader>
				<IonContent>
					<InputItem
						name={i18n._(t`Street`)}
						placeholder={i18n._(t`Street`)}
						value={editItem.street}
						onChange={(value) => {
							editItem.street = value;
							setSavedEditItem(editItem);
						}}
					/>
					<InputItem
						name={i18n._(t`Street number`)}
						placeholder={i18n._(t`Street number`)}
						value={editItem.streetnumber}
						onChange={(value) => {
							editItem.streetnumber = value;
							setSavedEditItem(editItem);
						}}
					/>
					<InputItem
						name={i18n._(t`Post code`)}
						placeholder={i18n._(t`Post code`)}
						value={editItem.postcode}
						onChange={(value) => {
							editItem.postcode = value;
							setSavedEditItem(editItem);
						}}
					/>
					<InputItem
						name={i18n._(t`City`)}
						placeholder={i18n._(t`City`)}
						value={editItem.city}
						onChange={(value) => {
							editItem.city = value;
							setSavedEditItem(editItem);
						}}
					/>
				</IonContent>
				<PrivacyVerfiyApprovement checked={checked} setChecked={setChecked} />
				<IonButton disabled={!checked} expand="block" size="default" onClick={() => onEditModalDone(editItem)}>
					<IonLabel>Speichern</IonLabel>
				</IonButton>
			</IonModal>
		</>
	);
};

export default EditAddressModal;
