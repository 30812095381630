import { RefresherEventDetail } from "@ionic/core";
import { IonInfiniteScroll, IonInfiniteScrollContent, IonProgressBar, IonRefresher, IonRefresherContent } from "@ionic/react";
import { BaseList } from "model/BaseList";
import { ReactElement } from "react";
import { CustomProgressBar } from "./CustomProgressBar";

export interface Props<T> {
	items: BaseList<T>;
	renderItem: (item: T, index: number) => ReactElement;
	onInfinite: (event: CustomEvent<void>) => void;
	onRefresh?: (event: CustomEvent<RefresherEventDetail>) => void;
	isFetching: boolean;
}

export const InfiniteBaseList = <T extends any>({ items, renderItem, onInfinite, onRefresh, isFetching }: Props<T>): ReactElement => {
	return (
		<>
			<CustomProgressBar isFetching={isFetching} />
			<IonRefresher hidden={false} disabled={isFetching} slot="fixed" onIonRefresh={onRefresh}>
				<IonRefresherContent></IonRefresherContent>
			</IonRefresher>
			<IonInfiniteScroll onIonInfinite={onInfinite}>
				<IonInfiniteScrollContent loadingSpinner={isFetching ? "dots" : null}>
					<div className="ion-margin-top"></div>
					{items?.items?.map(renderItem)}
				</IonInfiniteScrollContent>
			</IonInfiniteScroll>
		</>
	);
};
