import "./style.scss";

import {
	IonAvatar,
	IonButton,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonNote,
	IonProgressBar,
	IonTitle,
	IonToolbar,
	useIonToast,
} from "@ionic/react";
import axios from "axios";
import ContactPersonCardModal from "components/contactPerson/ContactPersonCardModal";
import ContactPersonListItem from "components/contactPerson/ContactPersonListItem";
import { closeOutline, copyOutline, openOutline } from "ionicons/icons";
import { newBaseList } from "model/BaseList";
import { ContactPerson } from "model/ContactPerson";
import { Office } from "model/Office";
import { ScopeType } from "model/ScopeType";
import { TenantUrl } from "model/TenantUrl";
import { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getProfile } from "store/profile/Profile.selectors";

interface TenantHomepageSelectProps {
	isOpen: boolean;
	onDismiss: () => void;
	tenantId: string;
}

const TenantHomepageSelect: VFC<TenantHomepageSelectProps> = ({ isOpen, onDismiss, tenantId }) => {
	const [isBusy, setIsBuy] = useState(false);
	const tenants = useSelector(getAllTenants);
	const profile = useSelector(getProfile);
	const [tenantUrls, setTenantUrls] = useState(Array<TenantUrl>());
	const [offices, setOffices] = useState(newBaseList<Office>());
	const [contactPersons, setContactPersons] = useState(newBaseList<ContactPerson>());
	const [isContactPersonClicked, setIsContactPersonClicked] = useState(false);
	const [contactPersonClicked, setContactPersonClicked] = useState<ContactPerson>();
	const [present] = useIonToast();

	useEffect(() => {
		if (!isOpen) {
			return;
		}
		const fetchData = async () => {
			setIsBuy(true);
			await getTenantUrl(tenantId);
			await getContactPersons(tenantId);
			await getOffices(tenantId);
		};
		fetchData().then(() => setIsBuy(false));
	}, [isOpen]);

	const presentToast = () => {
		present({
			message: "Erfolgreich in die Zwischenablage kopiert.",
			duration: 1500,
			position: "top",
		});
	};

	const onContactPersonClicked = (contactPerson: ContactPerson) => {
		setContactPersonClicked(contactPerson);
		setIsContactPersonClicked(true);
	};

	const getTenantUrl = async (tenantId: string) => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/tenant/${tenantId}/tenanturl`, {
				headers: { userId: profile?.id },
			});
			if (res.status == 200) {
				setTenantUrls(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getOffices = async (tenantId: string) => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantId}/office`, {
				headers: { userId: profile?.id },
			});
			if (res.status == 200) {
				setOffices(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getContactPersons = async (tenantId: string) => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantId}/contactperson`, {
				headers: { userId: profile?.id },
			});
			if (res.status == 200) {
				setContactPersons(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<IonModal isOpen={isOpen} showBackdrop={true} backdropDismiss={true} onDidDismiss={(e) => onDismiss()}>
			<IonContent>
				<IonToolbar>
					<IonItem lines="full">
						<IonTitle slot="start">{tenants?.find((x) => x.id == tenantId)?.name}</IonTitle>
						<IonButton slot="end" fill="clear" onClick={(e) => onDismiss()}>
							<IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
						</IonButton>
					</IonItem>
				</IonToolbar>
				{isBusy ? (
					<>
						<IonProgressBar type="indeterminate"></IonProgressBar>
					</>
				) : (
					<>
						<IonCard>
							<IonCardHeader>
								<IonCardSubtitle>Homepage</IonCardSubtitle>
								<IonCardTitle>
									<IonList>
										{tenantUrls?.length ? (
											tenantUrls
												.sort((x, y) => (x.isPrimary === y.isPrimary ? 0 : x.isPrimary ? -1 : 1))
												.map((x) => (
													<IonItem target="_blank" href={x.url} button={true}>
														{x.url}
														<IonIcon icon={openOutline} slot="end" />
													</IonItem>
												))
										) : (
											<>Keine Webseite vorhanden</>
										)}
									</IonList>
								</IonCardTitle>
							</IonCardHeader>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardSubtitle>Anschrift</IonCardSubtitle>
								<IonCardTitle>
									<IonList>
										{offices?.items?.length ? (
											offices?.items.map((x) => (
												<>
													<IonItem
														button={true}
														onClick={() => {
															navigator.clipboard.writeText(
																x.street + "" + x.streetnumber + "" + "" + x.addressZusatz + x.country,
															);
															presentToast();
														}}
													>
														<IonLabel>
															<IonNote>{x.name}</IonNote> <br />
															{x.street} {x.streetnumber} {x.addressZusatz}
															<br />
															{x.country} {x.postcode} {x.city} {x.region}
															{x.mailAddress != "" ? (
																<>
																	<br />
																	{x.mailAddress}
																</>
															) : (
																<></>
															)}
															{x.phoneNumber != "" ? (
																<>
																	<br />
																	{x.phoneNumberCountryCode}
																	{x.phoneNumber}
																</>
															) : (
																<></>
															)}
															{x.postbox != "" ? (
																<>
																	<br />
																	{x.postbox}
																	<br />
																	{x.postfachNummer}
																	{x.postfachPort}
																</>
															) : (
																<></>
															)}
														</IonLabel>
														<IonIcon
															icon={copyOutline}
															onClick={() => {
																navigator.clipboard.writeText(
																	x.street + "" + x.streetnumber + "" + "" + x.addressZusatz + x.country,
																);
																presentToast();
															}}
															slot="end"
														/>
													</IonItem>
												</>
											))
										) : (
											<>Keine Anschrift vorhanden</>
										)}
									</IonList>
								</IonCardTitle>
							</IonCardHeader>
						</IonCard>

						<IonCard>
							<IonCardHeader>
								<IonCardSubtitle>Kontaktpersonen</IonCardSubtitle>
								<IonCardTitle>
									{contactPersons?.items?.length ? (
										contactPersons?.items.map((x) => (
											<>
												<ContactPersonListItem scopeType={ScopeType.extern} contactPerson={x} tenantId={tenantId} />
											</>
										))
									) : (
										<>Keine Kontaktpersonen vorhanden</>
									)}
								</IonCardTitle>
							</IonCardHeader>
						</IonCard>
					</>
				)}
			</IonContent>
		</IonModal>
	);
};

export default TenantHomepageSelect;
