import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { newBaseList } from "model/BaseList";
import BlogEntry from "model/BlogEntry";
import { defaultState } from "store/State";

import { fetchBlogEntries, reloadBlogEntries } from "./BlogEntry.thunks";

export const initialState = {
	...defaultState(newBaseList<BlogEntry>()),
};

export type BlogEntryState = typeof initialState;

const slice = createSlice({
	name: "blogEntry",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchBlogEntries.fulfilled, (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addCase(reloadBlogEntries.fulfilled, (state, action) => {
				state.value = action.payload;

				state.isFetching = false;
				const x = state.value;
				state.isFetched = true;
			}),
			builder.addCase(fetchBlogEntries.pending, (state, action) => {
				state.isFetching = true;
				state.isFetched = false;
			}),
			builder.addCase(reloadBlogEntries.pending, (state, action) => {
				state.isFetching = true;
				state.isFetched = false;
			});
		builder.addCase(fetchBlogEntries.rejected, (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
			state.error = action.error.message;
		});
		builder.addCase(reloadBlogEntries.rejected, (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
			state.error = action.error.message;
		});
	},
});

export default slice.reducer;
