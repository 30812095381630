import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { newBaseListQuery } from "model/BaseListQuery";
import { OrderElement } from "model/orderElement/OrderElement";
import { BaseListTenantQuery } from "model/query/BaseListTenantQuery";
import orderElementService from "services/OrderElementService";

import { RootState } from "..";

const baseString = "orderElements";
export const fetchOrderElements = createAsyncThunk<
	// Return type of the payload creator
	BaseList<OrderElement>,
	// First argument to the payload creator
	BaseListTenantQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: BaseListTenantQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			if (state.orderElement?.value?.page) {
				filter.query = newBaseListQuery({
					page: state.orderElement.value.page + 1,
				});
			}

			const list = await orderElementService.get(state.profile.idTokenClaim.person_id, filter.query, filter?.tenant);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: BaseListTenantQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const listeSate = state.orderElement;

			if (listeSate.value && listeSate.value.page) {
				if (listeSate.value.items.length === listeSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);
export const reloadOrderElements = createAsyncThunk<
	// Return type of the payload creator
	BaseList<OrderElement>,
	// First argument to the payload creator
	BaseListTenantQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: BaseListTenantQuery, thunkAPI) => {
	try {
		filter.query = newBaseListQuery({
			page: 1,
		});
		const state = thunkAPI.getState() as RootState;

		const list = await orderElementService.get(state.profile.idTokenClaim.person_id, filter.query, filter?.tenant);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});
