import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { OrderElement } from "model/orderElement/OrderElement";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { fetchOrderElements, reloadOrderElements } from "store/orderElement/OrderElement.thunks";
import { defaultState } from "store/State";

export const initialState = {
	...defaultState<BaseList<OrderElement>>(newBaseList<OrderElement>()),

	scope: ScopeType.extern,
	selectedTenant: null as unknown as Tenant,
};

export type OrderElementState = typeof initialState;

const slice = createSlice({
	name: "orderElement",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(fetchOrderElements.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadOrderElements.fulfilled), (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(reloadOrderElements.pending, fetchOrderElements.pending), (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addMatcher(isAnyOf(reloadOrderElements.rejected, fetchOrderElements.rejected), (state, action) => {
			state.isFetching = false;
			state.isFetched = true;
		});
	},
});

export default slice.reducer;
