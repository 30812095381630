import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import EditBankAccountModal from "components/bankAccount/EditModal";
import List from "components/bankAccount/List";
import { add } from "ionicons/icons";
import { newBankAccount } from "model/BankAccount";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useEffect, useRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { fetchBankAccounts, reloadBankAccounts } from "store/profile/bankAccount/BankAccount.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

import ProfilePageLayout from "./ProfilePageLayout";

const BankAccounts: VFC = () => {
	const dispatch = useAppDispatch();
	const modal = useRef<HTMLIonModalElement>(null);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchBankAccounts(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadBankAccounts(newBaseListQuery(basListQuery)));
	};
	return (
		<ProfilePageLayout title={i18n._(t`Bank accounts`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<EditBankAccountModal modalRef={modal} editItem={newBankAccount()} onCancel={() => modal.current?.dismiss()} />
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => modal.current?.present()}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
		</ProfilePageLayout>
	);
};

export default BankAccounts;
