import { createAsyncThunk } from "@reduxjs/toolkit";
import { ImageInformation } from "model/ImageInformation";
import IUpdatePersonAvatarInTenantDto from "model/IUpdatePersonAvatarInTenantDto";
import { newProfileUpdate } from "model/person/PersonUpdate";
import { Profile } from "model/Profile";
import { TalkJsUser } from "model/chat/TalkJsUser";
import profileService from "services/ProfileService";
import { IdTokenClaims } from "model/IdTokenClaims";
import IUpdatePersonAvatarDto from 'model/IUpdatePersonAvatarDto';

export const fetchProfile = createAsyncThunk<Profile, string, { rejectValue: string }>("profile/fetchById", async (userId: string, thunkAPI) => {
	try {
		const profile = await profileService.get(userId);
		profileService.setProfile(profile);
		return profile;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
	}
});
export const updateIdToken = createAsyncThunk<IdTokenClaims, IdTokenClaims, { rejectValue: string }>(
	"profile/idTokenClaim",
	async (idToken: IdTokenClaims, thunkAPI) => {
		try {
			return idToken;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const deleteIdToken = createAsyncThunk(
	"profile/deleteIdToken",
	async () => {
		try {
			return "";
		} catch (ex: any) {
			return ex;
		}
	},
);
export const updateProfile = createAsyncThunk<Profile, Profile, { rejectValue: string }>("profile/update", async (profile: Profile, thunkAPI) => {
	try {
		console.log(profile);

		const updateProfile = newProfileUpdate(profile);
		console.log(updateProfile);
		const result = await profileService.update(updateProfile);
		console.log(result);
		profile.age = result.age;
		profile.firstName = result.firstName;
		profile.birthdate = result.birthdate;
		profile.birthplace = result.birthplace;
		return profile;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
	}
});
export const updateProfileAvatar = createAsyncThunk<ImageInformation, IUpdatePersonAvatarDto, { rejectValue: string }>(
	"profile/photoupdate",
	async (dto: IUpdatePersonAvatarDto, thunkAPI) => {
		try {
			const result = await profileService.updatePhoto(dto);

			return result;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const updateProfileTenantAvatar = createAsyncThunk<ImageInformation, IUpdatePersonAvatarInTenantDto, { rejectValue: string }>(
	"profile/phototenantupdate",
	async (file: IUpdatePersonAvatarInTenantDto, thunkAPI) => {
		try {
			const result = await profileService.updateTenantPhoto(file);

			return result;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
		}
	},
);
export const fetchChatUser = createAsyncThunk<TalkJsUser, string, { rejectValue: string }>("profile/chatuser", async (userId: string, thunkAPI) => {
	try {
		console.log(userId);
		const result = await profileService.getChatUser(userId);

		return result;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data.ToString() ?? "");
	}
});
