import { IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonProgressBar, IonSpinner } from "@ionic/react";
import { BaseList } from "model/BaseList";
import { ReactElement } from "react";
import { CustomProgressBar } from "./CustomProgressBar";

export interface Props<T> {
	items: Array<T>;
	renderItem: (item: T, index: number) => ReactElement;
	onInfinite: (event: CustomEvent<void>) => void;
	isFetching: boolean;
}

export const InfiniteList = <T extends any>({ items, renderItem, onInfinite, isFetching }: Props<T>): ReactElement => {
	return (
		<>
			<CustomProgressBar isFetching={isFetching} />
			<IonList>{items?.map(renderItem)}</IonList>
			<IonInfiniteScroll onIonInfinite={onInfinite}>
				<IonInfiniteScrollContent loadingSpinner={isFetching ? "dots" : null} />
			</IonInfiniteScroll>
		</>
	);
};
