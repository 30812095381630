import { BaseListQueryInstance } from "model/BaseListQuery";
import { Tenant } from "model/Tenant";

export interface LoadInvoiceQuery {
	query: BaseListQueryInstance;
	tenant?: Tenant;
}

export function newLoadInvoiceQuery(query: BaseListQueryInstance, tenant?: Tenant): LoadInvoiceQuery {
	return {
		query: query,
		tenant: tenant,
	};
}
