import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import MedicalInformationList from "components/medicalInformation/List";
import EditMedicalInformationModal from "components/medicalInformation/MedicalInformationModal";
import { add } from "ionicons/icons";
import { newAddress } from "model/Address";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { MedicalInformation, newMedicalInformation } from "model/MedicalInformation";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { newUpsertModel } from "model/UpsertModel";
import { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { addMedicalInformation, fetchMedicalInformation, reloadMedicalInformation } from "store/profile/medicalInformation/MedicalInformation.thunks";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

import ProfilePageLayout from "./ProfilePageLayout";

export const MedicalInformationsPage: VFC = () => {
	const dispatch = useAppDispatch();
	const [isNewItemModalOpen, setIsNewItemModalOpen] = useState(false);
	const selectedTenant = useSelector(getSelectedTenant);

	const profile = useSelector(getProfile);

	function onNewItemModalDone(item: MedicalInformation) {
		dispatch<any>(addMedicalInformation(newUpsertModel<MedicalInformation>(profile.id, item)));
		setIsNewItemModalOpen(false);
	}
	useEffect(() => {
		dispatch<any>(reloadMedicalInformation(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchMedicalInformation(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadMedicalInformation(newBaseListQuery(basListQuery)));
	};
	return (
		<ProfilePageLayout title={i18n._(t`Additional Information`)} onViewEnter={() => loadEntries(newBaseListQuery())}>
			<EditMedicalInformationModal
				isOpen={isNewItemModalOpen}
				editItem={newMedicalInformation()}
				onCancel={() => setIsNewItemModalOpen(false)}
			/>
			<MedicalInformationList onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => setIsNewItemModalOpen(true)}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
		</ProfilePageLayout>
	);
};
