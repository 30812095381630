import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { BankAccount } from "model/BankAccount";
import { BaseList, newBaseList } from "model/BaseList";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";

import { fetchProfile } from "../Profile.thunks";
import { addBankAccount, deleteBankAccount, fetchBankAccounts, reloadBankAccounts, shareBankAccount, updateBankAccount } from "./BankAccount.thunks";

export const initialState = {
	...defaultState<BaseList<BankAccount>>(newBaseList<BankAccount>()),
	scope: ScopeType.extern,
	selectedTenant: null as unknown as Tenant,
	toShare: null as unknown as BankAccount | undefined,
};

export type BankAccountState = typeof initialState;

const slice = createSlice({
	name: "bankAccount",
	initialState,
	reducers: {
		cancelShareBankAccount(state) {
			state.toShare = undefined;
			state.isFetching = false;
			state.isFetched = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(addBankAccount.fulfilled, (state, action) => {
			state.value.items.push(action.payload);
			state.isFetching = false;
			state.isFetched = true;
			state.toShare = action.payload;
		});
		builder.addCase(deleteBankAccount.fulfilled, (state, action) => {
			state.value.items = state.value.items.filter((x) => x.id != action.payload);
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addCase(reloadBankAccounts.pending, (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addCase(reloadBankAccounts.fulfilled, (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(updateBankAccount.fulfilled, shareBankAccount.fulfilled), (state, action) => {
			if (!action.payload) {
				return;
			}
			const oldItem = state.value.items.find((x) => x.id === action.payload.id)!;
			const index = state.value.items.indexOf(oldItem);
			action.payload.isBusy = false;
			state.value.items[index] = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(reloadBankAccounts.fulfilled), (state, action) => {
				state.value = action.payload;
				state.isFetching = false;
				state.isFetched = true;
			});
		builder.addMatcher(isAnyOf(fetchBankAccounts.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(
				isAnyOf(updateBankAccount.pending, addBankAccount.pending, deleteBankAccount.pending, shareBankAccount.pending),
				(state, action) => {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.isBusy = true;
						state.value.items[index] = oldItem;
					}

					state.isFetching = true;
					state.isFetched = false;
				},
			),
			builder.addMatcher(isAnyOf(updateBankAccount.rejected, deleteBankAccount.rejected, shareBankAccount.rejected), (state, action) => {
				const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
				if (oldItem) {
					const index = state.value.items.indexOf(oldItem);
					oldItem.isBusy = false;
					state.value.items[index] = oldItem;
				}

				state.isFetching = false;
				state.isFetched = false;
				state.error = action.error.message;
			});
	},
});
export const { cancelShareBankAccount } = slice.actions;
export default slice.reducer;
