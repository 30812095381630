import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { ConfectionInformation } from "model/ConfectionInformation";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { defaultState } from "store/State";

import {
	addConfectionInformation,
	deleteConfectionInformation,
	fetchConfectionInformations,
	reloadConfectionInformations,
	shareConfectionInformation,
	updateConfectionInformation,
} from "./ConfectionInformation.thunks";

export const initialState = {
	...defaultState<BaseList<ConfectionInformation>>(newBaseList<ConfectionInformation>()),
	scope: ScopeType.extern,
	selectedTenant: null as unknown as Tenant,
	toShare: null as unknown as ConfectionInformation | undefined,
};

export type ConfectionInformationState = typeof initialState;

const slice = createSlice({
	name: "confectioninformation",
	initialState,
	reducers: {
		cancelShareMedicalInformation(state: any): void {
			state.toShare = undefined;
			state.isFetching = false;
			state.isFetched = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(addConfectionInformation.fulfilled, (state, action) => {
			state.value.items.push(action.payload);
			if (action.payload) {
				action.payload.isBusy = false;
				state.isFetching = false;
				state.isFetched = true;
				state.toShare = action.payload;
			}
		});
		builder.addCase(deleteConfectionInformation.fulfilled, (state, action) => {
			state.value.items = state.value.items.filter((x) => x.id != action.payload);
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addCase(reloadConfectionInformations.pending, (state, action) => {
			state.isFetching = true;
			state.isFetched = false;
		});
		builder.addCase(reloadConfectionInformations.fulfilled, (state, action) => {
			state.value = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		});
		builder.addMatcher(isAnyOf(updateConfectionInformation.fulfilled, shareConfectionInformation.fulfilled), (state, action) => {
			if (!action.payload) {
				return;
			}
			const oldItem = state.value.items.find((x) => x.id === action.payload.id)!;
			const index = state.value.items.indexOf(oldItem);
			action.payload.isBusy = false;
			state.value.items[index] = action.payload;
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(isAnyOf(reloadConfectionInformations.fulfilled), (state, action) => {
				state.value = action.payload;
				state.isFetching = false;
				state.isFetched = true;
			});
		builder.addMatcher(isAnyOf(fetchConfectionInformations.fulfilled), (state, action) => {
			if (!state.value) {
				state.value = action.payload;
			} else {
				const items = [...state.value.items, ...action.payload.items];
				state.value = { ...action.payload, items };
			}
			state.isFetching = false;
			state.isFetched = true;
		}),
			builder.addMatcher(
				isAnyOf(
					updateConfectionInformation.pending,
					addConfectionInformation.pending,
					deleteConfectionInformation.pending,
					shareConfectionInformation.pending,
				),
				(state, action) => {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.isBusy = true;
						state.value.items[index] = oldItem;
					}
					state.isFetching = true;
					state.isFetched = false;
				},
			),
			builder.addMatcher(
				isAnyOf(updateConfectionInformation.rejected, deleteConfectionInformation.rejected, shareConfectionInformation.rejected),
				(state, action) => {
					const oldItem = state.value.items.find((x) => x.id === action.meta.arg.item.id)!;
					if (oldItem) {
						const index = state.value.items.indexOf(oldItem);
						oldItem.isBusy = false;
						state.value.items[index] = oldItem;
					}
					state.isFetching = false;
					state.isFetched = false;
					state.error = action.error.message;
				},
			);
		builder.addMatcher(isAnyOf(fetchConfectionInformations.rejected, reloadConfectionInformations.rejected), (state, action) => {
			state.isFetching = false;
			state.isFetched = false;
			state.error = action.error.message;
		});
	},
});
export default slice.reducer;
