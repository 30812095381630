import { IonItem, IonItemSliding, IonLabel } from "@ionic/react";
import { dateTimeString, dateTimeStringWithOutHour } from "helper/DateHelper";
import { htmlBody, unhtmlify } from "helper/StringHelper";
import { communicationUseCaseString } from "model/orderElement/CommunicationUseCaseType";
import { OrderElement } from "model/orderElement/OrderElement";
import { SendStateType } from "model/orderElement/SendStateType";
import { vertexLabelString } from "model/VertexLabelType";
import { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { Subtitle } from "../text/subtitle/Subtitle";
import { Title } from "../text/title/Title";
import ContentModal from "./ContentModal";

export interface Props extends RouteComponentProps {
	orderElement: OrderElement;
}
const Teaser = (props: Props) => {
	const [isContentModalOpen, setIsContentModalOpen] = useState(false);
	const type = `${vertexLabelString(props.orderElement?.order?.objectInformation?.vertexLabelType)} - ${communicationUseCaseString(
		props.orderElement?.order?.useCase,
	)}`;
	return (
		<IonItemSliding>
			<ContentModal
				isOpen={isContentModalOpen}
				title={props.orderElement.subject}
				orderElement={props.orderElement}
				done={() => {
					setIsContentModalOpen(false);
				}}
			/>
			<IonItem
				color="amber-50"
				onClick={() => {
					setIsContentModalOpen(true);
				}}
			>
				<IonLabel>
					<Subtitle text={props.orderElement.subject} />

					<p>{props.orderElement?.updatedAt ? dateTimeStringWithOutHour(new Date(props.orderElement.updatedAt)) : undefined}</p>
					{/*<p>{unhtmlify(htmlBody(props.orderElement.content))}</p>*/}
				</IonLabel>
				{
					<div className={props.orderElement.sendState == SendStateType.sent ? "success" : "warning"}>
						{props.orderElement.sendState == SendStateType.sent ? "gesendet" : "nicht gesendet"}
					</div>
				}
			</IonItem>
		</IonItemSliding>
	);
};
export default withRouter(Teaser);
