import { IonContent, IonHeader, IonPage, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
//import { initialState, PageViewState, PageViewStateContext } from "context/PageViewStateContext";
import { FC } from "react";
import ToolbarContent from "components/header/ToolbarContent";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";

export interface Props {
	title: string;
	backLink?: string;
	backTitle?: string;
	children: React.ReactNode;
	tabBarHidden?: boolean;
	backButton?: boolean;
	menuButton?: boolean;
	logo?: boolean;
	pageColor?: string | undefined;
	onViewEnter?: () => void;
	onViewLeave?: () => void;
	onViewEntering?: () => void;
	onViewLeaving?: () => void;
}

const Page: FC<Props> = ({
	title,
	backLink,
	backTitle,
	children,
	backButton,
	tabBarHidden,
	menuButton,
	logo,
	pageColor,
	onViewEnter,
	onViewLeave,
	onViewLeaving,
	onViewEntering,
}) => {
	//const [viewState, setViewState] = useState<PageViewState>(initialState);
	/*
	const tabContext = useContext(TabContex);

	useEffect(() => {
		tabContext?.setHidden(tabBarHidden ?? false);
	}, [tabBarHidden]);
*/
	useIonViewWillEnter(() => {
		//console.log("useIonViewWillEnter", title);
		//setViewState({ ...initialState, viewEntering: true });
		if (onViewEntering) {
			onViewEntering();
		}
	}, []);

	useIonViewDidEnter(() => {
		console.log("useIonViewDidEnter", title);
		if (onViewEnter) {
			onViewEnter();
		}
		Device.getInfo().then((deviceInfo) => {
			if (deviceInfo.platform !== "web") {
				StatusBar.setStyle({ style: Style.Light }).then(() => {
					console.log("setStyleLight");
				});
			}
		});

		//setVisible(true);
		//setViewState({ ...initialState, viewEntered: true });
	});

	useIonViewWillLeave(() => {
		//console.log("useIonViewWillLeave", title);
		//setViewState({ ...initialState, viewLeaving: true });
		if (onViewLeaving) {
			onViewLeaving();
		}
	});

	useIonViewDidLeave(() => {
		console.log("useIonViewDidLeave", title);
		//setVisible(false);
		if (onViewLeave) {
			onViewLeave();
		}

		//setViewState({ ...initialState, viewLeft: true });
	}, []);

	const pageColorClass = pageColor ? `${pageColor}` : "amber-50";

	return (
		<IonPage color={pageColorClass} id="menu-content">
			<IonHeader mode="md" translucent={true}>
				<ToolbarContent logo={logo} menuButton={menuButton} title={title} backLink={backLink} backButton={tabBarHidden || backButton} />
			</IonHeader>

			{/* <PageViewStateContext.Provider value={viewState}> */}

			<IonContent color={pageColorClass}>{children}</IonContent>

			{/* </PageViewStateContext.Provider> */}
		</IonPage>
	);
};

export default Page;
