import { combineReducers } from "redux";

import blogEntry from "./blogEntry/BlogEntry.slice";
import event from "./event/Event.slice";
import honor from "./honor/Honor.slice";
import invoice from "./invoice/Invoice.slice";
import license from "./license/License.slice";
import orderElement from "./orderElement/OrderElement.slice";
import address from "./profile/address/Address.slice";
import bankAccount from "./profile/bankAccount/BankAccount.slice";
import confectionInformation from "./profile/confectionInformation/ConfectionInformation.slice";
import mail from "./profile/mail/Mail.slice";
import medicalInformation from "./profile/medicalInformation/MedicalInformation.slice";
import phone from "./profile/phone/Phone.slice";
import profile from "./profile/Profile.slice";
import profileValidation from "./profileValidation/ProfileValidation.slice";
import qualification from "./qualification/Qualification.slice";
import team from "./team/Team.slice";
import token from "./token/Token.slice";
import log from "./log/Log.slice";

export const rootReducer = combineReducers({
	profileValidation,
	medicalInformation,
	confectionInformation,
	honor,
	license,
	qualification,
	team,
	token,
	blogEntry,
	event,
	profile,
	invoice,
	orderElement,
	address,
	bankAccount,
	mail,
	phone,
	log,
});
