import { newTenant } from "model/Tenant";

export interface State<T extends unknown> {
	value: T;
	isFetching: boolean;
	isFetched: boolean;
	error?: string;
	[x: string]: unknown;
}

export function newState<T>(value: T): State<T> {
	return {
		value: value,
		isFetching: false,
		isFetched: false,
	};
}

export const defaultState = <T extends unknown>(value: T): State<T> => {
	return {
		value: value,
		isFetching: false,
		isFetched: false,
		error: undefined,
	};
};

export const emptyState = <T extends unknown>(): State<T> => {
	return {
		value: null as unknown as T,
		isFetching: false,
		isFetched: false,
		error: undefined,
	};
};
