import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { License } from "model/License";
import { Tenant } from "model/Tenant";

class LogService {
	async get(userId: string, query: BaseListQueryInstance, tenant?: Tenant): Promise<BaseList<License>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantId", tenant.id);
		}

		query.toEntries().forEach(([key, value]) => {
			if (key == 'orderby') {
				params.set(key, 'created');
			}
			else {
				params.set(key, value as string);
			}
		});

		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/logmessages`, {
			params,
			headers: { userId: userId },
		});
		return res.data;
	}
}
const logService = new LogService();
export default logService;
