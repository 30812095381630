import "./style.scss";
import "../style.scss";

import { IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import EditAddressModal from "components/address/EditModal";
import { newAddress } from "model/Address";
import { Event } from "model/Event";
import * as React from "react";
import { useState } from "react";

interface IEventAddressPartProps {
	event: Event;
}

const EventAddressPart: React.FunctionComponent<IEventAddressPartProps> = ({ event }) => {
	const [showModal, setShowModal] = useState(false);
	const [address, setAddress] = useState(newAddress());

	return (
		<>
			<EditAddressModal address={address} isOpen={showModal} onCancel={() => setShowModal(false)} />
			<div className="firstDivider"></div>

			<IonItemDivider mode="ios">Adresse und Telefonnummer:</IonItemDivider>
			<IonItem lines="none">
				<IonLabel>
					{event.eventRegistration?.address?.street} {event.eventRegistration?.address?.streetnumber}
					<br />
					{event.eventRegistration?.address?.postbox} {event.eventRegistration?.address?.city}
					<br />
					{event.eventRegistration?.phoneModel ? (
						<>
							+`${event.eventRegistration?.phoneModel?.phoneNumberCountryCode} $
							{event.eventRegistration?.phoneModel?.phoneNumberCountryCode}`
						</>
					) : (
						<></>
					)}
				</IonLabel>
			</IonItem>
		</>
	);
};

export default EventAddressPart;
