import { BaseList, filterBaseListItems, newBaseList } from "model/BaseList";
import { MedicalInformation } from "model/MedicalInformation";
import { ScopeType } from "model/ScopeType";
import { Tenant } from "model/Tenant";
import { createSelector } from "reselect";

import { RootState } from "../..";
import { getScope, getSelectedTenant } from "../Profile.selectors";

export const getAllMedicalInformations = (state: RootState): BaseList<MedicalInformation> =>
	state.medicalInformation.value || newBaseList<MedicalInformation>();

export const getIsFetching = (state: RootState): boolean => state.medicalInformation.isFetching;

export const getMedicalInformations = createSelector(
	getAllMedicalInformations,
	getSelectedTenant,
	(medicalInformations: BaseList<MedicalInformation>, tenant: Tenant | null) => {
		return tenant
			? filterBaseListItems(
					medicalInformations,
					(medicalInformation: MedicalInformation) =>
						medicalInformation.scopedSharingTenants?.some((t) => t.id === tenant.id) || medicalInformation.creator?.id == tenant.id,
			  )
			: medicalInformations;
	},
);

export const getMedicalInformation = createSelector(
	getAllMedicalInformations,
	getSelectedTenant,
	getScope,
	(medicalInformations: BaseList<MedicalInformation>, tenant: Tenant | null, scopeType?: ScopeType): MedicalInformation | undefined => {
		let result;
		switch (scopeType) {
			case ScopeType.default:
				if (tenant) {
					result = medicalInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isDefault);
				} else {
					result = medicalInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isDefault === true)[0]);
				}
				break;
			case ScopeType.intern:
				if (tenant) {
					result = medicalInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isIntern);
				} else {
					result = medicalInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isIntern === true)[0]);
				}
				break;
			case ScopeType.extern:
				if (tenant) {
					result = medicalInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.id === tenant?.id)[0]?.isExtern);
				} else {
					result = medicalInformations.items.find((x) => x.scopedSharingTenants?.filter((t) => t.isExtern === true)[0]);
				}
				break;
		}

		return result !== undefined ? result : medicalInformations.items.length ? medicalInformations.items[0] : undefined;
	},
);

export const makeGetMedicalInformation = createSelector(getAllMedicalInformations, getSelectedTenant, (medicalInformations, tenant) => {
	if (tenant) {
		medicalInformations.items = medicalInformations.items?.filter((a) => a.scopedSharingTenants?.map((t) => t.id).includes(tenant?.id ?? ""));
	}

	return medicalInformations;
});
