import "./style.scss";

import { IonAvatar, IonIcon, IonImg, IonItem, IonLabel, IonNote, IonSkeletonText, IonThumbnail, useIonPopover } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useCapacitorOAuth2 } from "auth/CapacitorOAuth2Hook";
import ProfileIconPopup from "components/profileIconPopup/ProfileIconPopup";
import { personCircleOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { getAvatarImageUrl, getProfile, getSelectedTenant } from "store/profile/Profile.selectors";

export default function ProfileMenuIcon() {
	const profile = useSelector(getProfile);
	const avatarImageUrl = useSelector(getAvatarImageUrl);
	const capacitorAuth = useCapacitorOAuth2();
	const selectedTenant = useSelector(getSelectedTenant);

	const [present, dismiss] = useIonPopover(ProfileIconPopup, {
		onHide: () => dismiss(),
		signOut: async () => await capacitorAuth.logOutAsync(),
		avatarImageUrl,
		profile,
		selectedTenant,
	});

	if (!profile) {
		return (
			<IonItem button={false} lines="none" color="--ion-background-color">
				<IonThumbnail slot="end">
					<IonSkeletonText animated style={{ width: "100%" }} />
				</IonThumbnail>
			</IonItem>
		);
	}

	return (
		<IonItem
			color="--ion-background-color"
			button={true}
			lines="none"
			onClick={(e) => {
				present({
					event: e.nativeEvent,
				});
			}}
		>
			<IonLabel class="ion-text-nowrap ion-text-right ion-hide-md-down">
				{profile?.lastName} {profile?.firstName}
				<br></br>
				<IonNote className="profileNote">{selectedTenant?.name ?? i18n._(t`All tenants`)}</IonNote>
			</IonLabel>
			{avatarImageUrl ? (
				<IonAvatar slot="end">
					{avatarImageUrl == undefined ? <IonImg src={process.env.PUBLIC_URL + "/assets/avatar.png"} /> : <IonImg src={avatarImageUrl} />}
				</IonAvatar>
			) : (
				<IonIcon size="large" icon={personCircleOutline} slot="end" />
			)}
		</IonItem>
	);
}
