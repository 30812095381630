import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseList } from "model/BaseList";
import { newBaseListQuery } from "model/BaseListQuery";
import { Invoice } from "model/Invoice";
import { LoadInvoiceQuery } from "model/invoice/LoadInvoiceQuery";
import invoiceService from "services/InvoiceService";

import { RootState } from "..";

const baseString = "invoice";
export const fetchInvoices = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Invoice>,
	// First argument to the payload creator
	LoadInvoiceQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(
	`${baseString}/getall`,
	async (filter: LoadInvoiceQuery, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			if (state.invoice?.value?.page) {
				filter.query = newBaseListQuery({
					page: state.invoice.value.page + 1,
				});
			}

			const list = await invoiceService.getList(filter.query, state.profile.idTokenClaim.person_id, filter?.tenant);
			return list;
		} catch (ex: any) {
			return thunkAPI.rejectWithValue(ex.response.data?.toString() ?? "");
		}
	},
	{
		condition: (filter: LoadInvoiceQuery, { getState, extra }) => {
			const state = getState() as RootState;
			const invoiceSate = state.invoice;

			if (invoiceSate.value && invoiceSate.value.page) {
				if (invoiceSate.value.items.length === invoiceSate.value.maximumcount) {
					return false;
				}
			}
			return true;
		},
	},
);

export const reloadInvoices = createAsyncThunk<
	// Return type of the payload creator
	BaseList<Invoice>,
	// First argument to the payload creator
	LoadInvoiceQuery,
	// Types for ThunkAPI
	{
		rejectValue: string;
	}
>(`${baseString}/reload`, async (filter: LoadInvoiceQuery, thunkAPI) => {
	try {
		filter.query = newBaseListQuery({
			page: 1,
		});
		const state = thunkAPI.getState() as RootState;

		const list = await invoiceService.getList(filter.query, state.profile.idTokenClaim.person_id, filter?.tenant);
		return list;
	} catch (ex: any) {
		return thunkAPI.rejectWithValue(ex.response.data?.ToString() ?? "");
	}
});
