import { IonAlert, IonButton, IonItem, IonLabel, IonSpinner } from "@ionic/react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import ButtonMore from "components/buttons/ButtonMore";
import isSameDay from "date-fns/isSameDay";
import { Event } from "model/Event";
import { EventProgressType } from "model/events/EventProgressType";
import { useState, VFC } from "react";
import { formatDate, formatDateTime, formatTime } from "utils";

import { Subtitle } from "../../text/subtitle/Subtitle";
import { Title } from "../../text/title/Title";

export interface Props {
	event: Event;
	onClick: () => void;
}
const TakePartEventButton: VFC<Props> = ({ event, onClick }) => {
	const [showAlert, setShowAlert] = useState(false);

	const takePartButtonText = (eventProgressType?: EventProgressType) => {
		switch (eventProgressType) {
			case EventProgressType.Unknown:
				return <IonSpinner name="dots" />;
			case EventProgressType.NoMember:
				return <Trans>event.takePart</Trans>;
			case EventProgressType.Waiting:
				return <Trans>event.alreadyTakePart</Trans>;
			case EventProgressType.Participating:
				return <Trans>event.alreadyTakePart</Trans>;
			case EventProgressType.Invited:
				return <Trans>event.takePart</Trans>;
			default:
				break;
		}
		return <IonSpinner name="dots" />;
	};
	const validateTakePartPossible = (event: Event) => {
		if (!event) {
			console.log("no event loaded");
			return false;
		}
		// Eventuell wurde durch einen Admin die Teilnahme geschlossen
		if (event.closed) {
			console.log("Event closed");
			return false;
		}
		// Check noch Platz vorhanden
		if (event.participantCount ?? 0 >= (event.maxParticipating ?? 0)) {
			if (event.waitingCount ?? 0 >= (event.maxWaiting ?? 0)) {
				if (event.waitingCount == event.maxWaiting) {
					if (event.waitingCount ?? 0 > (event.maxWaiting ?? 1)) {
						return false;
					}
					return false;
					console.log("No Space in event");
				}
			}
		}
		// Anmeldefrist checken
		if (event.startTime > new Date()) {
			console.log("startime over");
			return false;
		}

		if (event.eventProgressType == EventProgressType.Participating || event.eventProgressType == EventProgressType.Waiting) {
			console.log("progress type is not nomember");
			return false;
		}
		return true;
	};

	if (event?.eventRegistration) {
		return <></>;
	}
	if (event?.disableParticipants) {
		return (
			<IonButton size="default" color="primary" expand="block" disabled={true} className="btn-take-part" onClick={(e) => setShowAlert(true)}>
				Die Teilnahme ist deaktiviert
			</IonButton>
		);
	}
	if (event?.closed) {
		return (
			<>
				<IonButton
					size="default"
					color="primary"
					expand="block"
					disabled={false}
					className="btn-take-part"
					onClick={(e) => setShowAlert(true)}
				>
					Teilnahme anfragen
				</IonButton>
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => setShowAlert(false)}
					header="Teilnahme anfragen"
					subHeader="Anmeldung nicht direkt möglich"
					message="Der Veranstalter möchte zunächst persönlich mit Dir in Kontakt treten."
					buttons={[
						{
							text: "Teilnahme anfragen",
							role: "confirm",
							handler: () => {
								window.location.href = "mailto:";
								setShowAlert(false);
							},
						},
						{
							text: "Zurück",
							role: "cancel",
							handler: () => {
								setShowAlert(false);
							},
						},
					]}
				/>
			</>
		);
	}
	return (
		<>
			<IonButton
				size="default"
				color="primary"
				expand="block"
				disabled={!validateTakePartPossible(event)}
				className="btn-take-part"
				onClick={(e) => onClick()}
			>
				{takePartButtonText(event?.eventProgressType)}
			</IonButton>
		</>
	);
};
export default TakePartEventButton;
