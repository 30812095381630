import "./style.scss";
import "../style.scss";

import { IonAvatar, IonChip, IonImg, IonItem, IonItemDivider, IonLabel, IonText, IonTitle } from "@ionic/react";
import { Trans } from "@lingui/macro";
import { format } from "date-fns";
import { Event } from "model/Event";
import EventRegistration from "model/events/EventRegistration";
import * as React from "react";

interface IEventPricePartProps {
	event: Event;
	setEventRegistration: (eventRegistration: EventRegistration) => void;

	eventRegistration: EventRegistration;
}

const EventPricePart: React.FunctionComponent<IEventPricePartProps> = ({ event, eventRegistration, setEventRegistration }) => {
	return (
		<>
			<div className="firstDivider"></div>

			<IonItemDivider mode="ios">Veranstaltung:</IonItemDivider>
			<IonItem lines="none" className="ion-no-padding">
				{event.headerImageUrl ? (
					<IonImg src={event.headerImageUrl} className="hero-imgModal ion-no-padding ion-hide-lg-down" />
				) : (
					<IonImg
						src="https://public.cdn.dso-verein.de/img/stock/event/empty_event.jpeg"
						className="hero-imgModal ion-no-padding ion-hide-lg-down"
					/>
				)}
				<div className="eventDetails">
					<IonItem lines="none">
						<div>
							<IonLabel>{event.name}</IonLabel>

							<IonChip>
								{event.creator?.avatarImageUrl != null &&
								event.creator?.avatarImageUrl != "" &&
								event.creator?.avatarImageUrl != undefined ? (
									<IonAvatar>
										<IonImg src={event.creator?.avatarImageUrl} />
									</IonAvatar>
								) : (
									<></>
								)}
								<IonLabel mode="md">{event.creator?.name}</IonLabel>
							</IonChip>
						</div>
					</IonItem>
					<IonItem mode="md" lines="full">
						<div slot="start">
							<IonLabel color="normal">
								<Trans>event.date</Trans>
							</IonLabel>
							<IonText color="medium" slot="end">
								{format(new Date(event.startTime!), "dd.MM.yyyy")} {format(new Date(event.startTime!), "hh:mm")}
							</IonText>
						</div>
					</IonItem>

					<IonItem mode="md" lines="full">
						<div slot="start">
							<IonLabel color="normal">
								<Trans>event.end</Trans>
							</IonLabel>
							<IonText color="medium" slot="end">
								{format(new Date(event.endTime!), "dd.MM.yyyy")} {format(new Date(event.endTime!), "hh:mm")}
							</IonText>
						</div>
					</IonItem>
					<IonItem mode="md" lines="full">
						<div slot="start">
							<IonLabel color="normal">
								<Trans>event.deadline</Trans>
							</IonLabel>
							<IonText color="medium" slot="end">
								{format(new Date(event.withDrawalDeadlineTime!), "dd.MM.yyyy")}{" "}
								{format(new Date(event.withDrawalDeadlineTime!), "hh:mm")}
							</IonText>
						</div>
					</IonItem>
				</div>
			</IonItem>
			<div className="firstDivider"></div>
		</>
	);
};

export default EventPricePart;
