import { createSelector } from "@reduxjs/toolkit";
import { BaseList, newBaseList } from "model/BaseList";
import { License } from "model/License";

import { RootState } from "..";

export const getAllLicenses = (state: RootState): BaseList<License> => state.license.value || newBaseList<License>();

export const getLatestLicenses = createSelector(getAllLicenses, (events: BaseList<License>) => events.items.slice(0, 5) || []);
export const getLicense = (id: string) => createSelector(getAllLicenses, (list) => list.items.find((item) => item.id === id));
export const getIsFetching = (state: RootState): boolean => state.license.isFetching;
