import { ClothingPartType } from "./ClothingPartType";
import { Creator } from "./Creator";
import { IsBusy } from "./IsBusy";
import { ScopedSharingTenant } from "./ScopedSharingTenant";

export interface ConfectionInformation extends IsBusy {
	name: string | undefined;
	confectionSize?: string;
	clothingPartType?: string;
	id: string | undefined;
	scopedSharingTenants?: ScopedSharingTenant[];
	creator?: Creator;
}
export function newConfectionInformation(): ConfectionInformation {
	return {
		name: undefined,
		id: undefined,
	};
}
